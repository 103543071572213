import { useState, useEffect } from "react";
import Api from "../../../constants/api";
import { Link, useNavigate } from "react-router-dom";
import { SwitchButton } from "./SwitchButton";
import { Popup } from "../../../components/common/Popup";

export const Corps = () => {
  const navigate = useNavigate();
  const [corps, setCorps] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [openPopupIndex, setOpenPopupIndex] = useState(null);

  const handleEditPopup = () => {
    // Handle edit action
  };

  const handleDeletePopup = () => {
    // Handle delete action
  };

  const handlePopupClick = (index: any) => {
    setOpenPopupIndex(openPopupIndex === index ? null : index);
  };
  /**
   * This is  the switch state , abhi temp but later on what we will do is teh data will come from backend and its value will be show accordingly.
   */
  const [switchStates, setSwitchStates] = useState([]);

  const handleToggle = (index:any) => {
    // Create a copy of the switch states array and toggle the state for the specific corp
    const updatedSwitchStates:any = [...switchStates];
    updatedSwitchStates[index] = !updatedSwitchStates[index];
    setSwitchStates(updatedSwitchStates);
  };

  // const handleRowClick = (event: any, row: any) => {
  //   setAnchorEl(event.currentTarget);
  //   setSelectedRow(row);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleEdit = () => {
  //   // Implement the edit logic here
  //   alert("edit");
  //   console.log("Edit row:", selectedRow);

  //   handleClose();
  // };

  // const handleDelete = () => {
  //   // Implement the delete logic here
  //   alert("delete");
  //   console.log("Delete row:", selectedRow);
  //   handleClose();
  // };

  useEffect(() => {
    Api.getAllCorps()
      .then((res: any) => {
        console.log(res, "corps");
        setCorps(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  /**
   * Function to view corp
   */
  const handleViewCorp = (id: number) => {
    navigate("/singleCorp", { state: { id: id } });
  };

  return (
    <>
      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        <div className="max-w-5xl p-6">
          <h1 className="text-2xl font-semibold mb-4">Corp Table</h1>
          <div className="bg-white rounded shadow">
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-4 py-2">Corp Id</th>
                  <th className="px-4 py-2">Phone Number</th>
                  <th className="px-4 py-2">Full Name</th>
                  <th className="px-4 py-2">Image</th>
                  <th className="px-4 py-2">Organisation Name</th>
                  <th className="px-4 py-2">GovId</th>
                  <th className="px-4 py-2">GovId Type</th>
                  <th className="px-4 py-2">Employee Range</th>
                  <th className="px-4 py-2">Credit Conversion Ratio</th>
                  <th className="px-4 py-2">Referral Id</th>
                  <th className="px-4 py-2">Tax Id</th>
                  <th className="px-4 py-2">Tax Number</th>
                  <th className="px-4 py-2">Api Key</th>
                  <th className="px-4 py-2">Status</th>
                </tr>
              </thead>
              <tbody>
                {corps?.map((item: any, index) => {
                  return (
                    <>
                      <tr
                        className="border-b cursor-pointer hover:bg-neutrals-200"
                        onClick={() => {
                          handleViewCorp(item?.id);
                        }}
                      >
                        <td className="px-4 py-2">{item?.id}</td>
                        <td className="px-4 py-2">{item?.phoneNumber}</td>
                        <td className="px-4 py-2">
                          {" "}
                          {item?.firstName} {item?.middleName} {item?.lastName}
                        </td>
                        <td className="px-4 py-2">
                          {" "}
                          <img style={{ width: "20px" }} src={item?.logo} />
                        </td>
                        <td className="px-4 py-2">{item?.organisationName}</td>
                        <td className="px-4 py-2">{item?.govId}</td>
                        <td className="px-4 py-2">{item?.govIdType}</td>
                        <td className="px-4 py-2">{item?.employeeRange}</td>
                        <td className="px-4 py-2">
                          {item?.creditConversationRatio}
                        </td>
                        <td className="px-4 py-2">{item?.referralId}</td>
                        <td className="px-4 py-2">{item?.taxId}</td>
                        <td className="px-4 py-2">{item?.taxNumber}</td>
                        <td className="px-4 py-2">{item?.uat_apikey}</td>
                        <td className="px-4 py-2">
                          {" "}
                          <div className="p-8">
                          <SwitchButton
                              isOn={switchStates[index]}
                              onToggle={() => handleToggle(index)}
                            />
                            <p className="mt-2">
                              {switchStates[index] ? "ON" : "OFF"}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div key={index} className="relative">
                            <button
                              className="text-gray-700 hover:text-gray-900"
                              onClick={() => handlePopupClick(index)}
                            >
                              ...
                            </button>
                            <Popup
                              isOpen={openPopupIndex === index}
                              onClose={() => setOpenPopupIndex(null)}
                              onEdit={handleEditPopup}
                              onDelete={handleDeletePopup}
                            />
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
