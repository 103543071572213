import images from '../../constants/images';

type propType = {
  image: string;
  recipientMessage: string;
  setEmailPreviewModal: (value: React.SetStateAction<boolean>) => void;
  selectedPreview: 'Desktop' | 'Mobile';
};
const MessagePreviewModalCampaign = (props: propType) => {
  const { image, setEmailPreviewModal, selectedPreview } = props;

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className={`relative transform overflow-auto rounded-xl bg-white text-left shadow-xl transition-all sm:my-8 ${
              selectedPreview === 'Desktop' && 'sm:w-full sm:max-w-[60%]'
            }  max-h-[90vh]`}
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-6">
              {/* Body */}
              <div className="">
                <button
                  onClick={() => setEmailPreviewModal(false)}
                  className="self-start h-7"
                >
                  <img src={images.cross} className="" alt="" />
                </button>

                {selectedPreview === 'Desktop' && (
                  <div
                    className={`relative flex justify-center mt-2 max-h-[80vh] ${
                      selectedPreview === 'Desktop' && 'w-[808px]'
                    }`}
                  >
                    <img
                      src={image}
                      className="max-h-[80vh] w-[808px]"
                      alt=""
                    />

                    {selectedPreview === 'Desktop' && (
                      <div className="absolute flex flex-col justify-center items-center  left-[40%] top-[28%] h-[70%] w-[35%] bg-neutrals-200 border border-white shadow rounded-lg">
                        <img src={images.logo} alt="" />
                        <div className="bg-white m-2 py-2 flex flex-col justify-center items-center min-w-[130px]">
                          <img
                            src={images.trackOrderBottom2}
                            className="w-[120px]"
                            alt=""
                          />
                          <div className="text-center w-[70%]">
                            <div className="text-[10px] font-leagueSpartan-300">
                              Hi, Ronald Richards
                              <br />
                              <br />
                              You have received a special gifting experience
                              from <br />
                              {'<sender name>'}
                              <br />
                              of <br />
                              {'<company name>'}. <br />
                              Click on the link below to select a gift from the
                              options curated just for you!
                            </div>

                            <button className="p-1.5 px-4 bg-orange text-white text-[8px] rounded-lg ">
                              Select my gift!
                            </button>
                          </div>
                        </div>

                        <div className="font-leagueSpartan-300 text-[6px] mt-1 text-center text-orange">
                          This email was sent by Smilie.io on behalf of{' '}
                          {'<sender email>'}. <br />
                          Visit smilie.io to learn about what we do! br Contact{' '}
                          <br />
                          Smilie | Terms and Conditions | Privacy Policy
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {selectedPreview === 'Mobile' && (
                  <div className={`relative justify-center mt-2 max-h-[80vh]`}>
                    {selectedPreview === 'Mobile' && (
                      <img src={image} className="max-h-[80vh]" alt="" />
                    )}

                    {selectedPreview === 'Mobile' && (
                      <>
                        <img
                          src={images.whatsappPreviewChatBox}
                          className="absolute left-[0%] top-[18%] h-[50%] w-[250px] ml-2  object-cover"
                          alt=""
                        />
                        <div className="absolute left-[0%] top-[18%] h-[50%] w-[250px] rounded-lg p-4">
                          <div
                            className="w-full p-2 h-[70%] max-md:leading-3 resize-none font-leagueSpartan-300 max-md:text-[8px] text-[12px]  text-neutrals-800 placeholder:font-leagueSpartan-300 placeholder:text-neutrals-600"
                            // placeholder="Write a heartfelt message to your recipients - show them how much you appreciate them!"
                          >
                            Hello {'<Recipient Name>'},
                            <br />
                            <br />
                            This is Smilie's gifting genius.{' '}
                            {'<Organisation Name>'} would like to send you a
                            gift.
                            <br />
                            <br />
                            Click on link below to select from gifts that have
                            been specially chosen for you:
                            <br />
                            <br />
                            For validation; You may definitely drop{' '}
                            {'<Organisation Name>'}@{'<9090909090>'} a text to
                            verify our authenticity.
                            <br />
                            --smilie
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagePreviewModalCampaign;
