import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Api from "../../constants/api";
import images from "../../constants/images";
import {
  setIsCampaignDashboardDataLoaded,
  setIsDashboardCampaignList,
  updateCampaign,
} from "../../redux/dashboardSlice";
import { setIsLoading } from "../../redux/globalSlice";
import { useAppSelector } from "../../redux/hooks";
import CampaignRow from "./CampaignTab/CampaignRow";

const perPageItems = [10, 20, 30, 40, 50];

type propType = {
  filterDate?: boolean;
};

const CampaignTabs = (props: propType) => {
  const { filterDate } = props;
  const dispatch = useDispatch();
  const dashboardState = useAppSelector((state) => state.dashboardState);
  const campaigns = dashboardState.campaignList;
  const showOnWhatsappCount = campaigns.filter(
    (c) => c.showOnWhatsapp === true
  ).length;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = campaigns.slice(startIndex, endIndex);
  const totalPages = Math.ceil(campaigns.length / itemsPerPage);

  const [pageList, setPageList] = useState<number[]>([]);
  const _setPageList = useCallback(() => {
    let _currentPage = currentPage;
    if (_currentPage > totalPages) {
      _currentPage = 1;
      setCurrentPage(_currentPage);
    }

    if (totalPages <= 3) {
      setPageList([...Array(totalPages)].map((_, i) => i + 1));
    } else {
      if (_currentPage + 2 <= totalPages) {
        setPageList((opl) => {
          if (!opl.includes(_currentPage)) {
            let list = [_currentPage, _currentPage + 1, _currentPage + 2];
            // if (!list.includes(totalPages)) list.push(totalPages);
            // if (!list.includes(1)) list = [1, ...list];
            return list;
          }
          return opl;
        });
      } else {
        setPageList((opl) => {
          if (!opl.includes(_currentPage)) {
            console.log("Array length", totalPages - _currentPage + 1);
            console.log(totalPages, _currentPage);
            const list = [...Array(totalPages - _currentPage + 1)].map(
              (_, i) => i + _currentPage
            );
            if (list.length < 3)
              return [_currentPage - 2, _currentPage - 1, _currentPage];
            return list;
          }
          return opl;
        });
      }
    }
  }, [currentPage, totalPages]);
  // Pagination end

  useEffect(() => {
    // if (dashboardState.isDataLoaded) {
    //   return;
    // }
    dispatch(setIsLoading(true));

    const _reqData = dashboardState.data;
    const fromDate = moment(_reqData.fromDate).toISOString();
    const toDate = moment(_reqData.toDate).endOf("day").toISOString();
    const reqData = filterDate ? { fromDate, toDate } : undefined;

    Api.getallDashBoardCampaign(reqData)
      .then((res) => {
        dispatch(setIsCampaignDashboardDataLoaded(true));
        dispatch(setIsDashboardCampaignList(res.data));
        dispatch(setIsLoading(false));
        _setPageList();
      })
      .catch(() => {
        toast.error("Error occurred!");
        dispatch(setIsLoading(false));
      });
  }, [_setPageList, dashboardState.data, dispatch, filterDate]);

  useEffect(() => {
    _setPageList();
  }, [currentPage, campaigns, dashboardState.data, itemsPerPage, _setPageList]);

  /**
   * Updates a campaign in the state.
   *
   * @param updatedCampaign - The updated campaign object.
   * @param index - The index of the campaign in the state.
   */
  const updateCampaignInState = (
    updatedCampaign: any,
    index: number,
    success?: boolean,
    toastMessage?: string
  ) => {
    // Dispatch the action with the updated campaign
    dispatch(updateCampaign({ updatedCampaign }));

    // Toast
    if (success === true) {
      toast.success(
        `Success! Campaign ${updatedCampaign.name} Whatsapp ${
          updatedCampaign.showOnWhatsapp ? "Enabled" : "Disabled"
        }!`
      );
    } else {
      toast.error(toastMessage);
    }
  };

  let PageList = (
    <div className="pagination">
      {pageList.map((v) => (
        <button
          className={`text-orange rounded-lg border border-orange mx-1 w-8 h-8 hover:bg-lightOrange ${
            currentPage === v ? "bg-lightOrange" : "bg-transparent"
          }`}
          key={v}
          onClick={() => handlePageChange(v)}
        >
          {v}
        </button>
      ))}
    </div>
  );

  return (
    <div>
      <div className="text-right font-leagueSpartan text-[16px] my-2">
        <span className="font-bold">{showOnWhatsappCount}</span> / 3 Campaigns
        on Whatsapp
      </div>
      {/* Body */}
      <div className="">
        <table className="w-full table-auto">
          <thead className="bg-neutrals-300">
            <tr>
              <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700 p-4 text-left">
                CAMPAIGN
              </th>
              <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700 p-4">
                DURATION
              </th>
              <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700 p-4">
                CLAIMED BY
              </th>
              <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700 p-4">
                TOTAL SPENT
              </th>
              <th>{/* ELLIPSIS */}</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {currentItems.map((c, index) => (
              <CampaignRow
                key={c.id}
                campaign={c}
                index={index}
                updateCampaign={updateCampaignInState}
                selectedCampaign={selectedCampaign}
                setSelectedCampaign={setSelectedCampaign}
                showOnWhatsappCount={showOnWhatsappCount}
              />
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between">
        <div className="flex justify-start mt-2">
          <label className="text-neutral-600 mt-2 text-base">
            Rows per Page:
          </label>
          <select
            className="border-gray-400 rounded-xl mx-1"
            onChange={(e) => {
              setItemsPerPage(+e.target.value);
              setCurrentPage(1);
            }}
          >
            {perPageItems.map((v) => (
              <option key={v}>{v}</option>
            ))}
          </select>
        </div>
        <div className="flex justify-end mt-4">
          <div
            className="pt-1 text-sm cursor-pointer"
            onClick={() => handlePageChange(1)}
          >
            {"<<"}
          </div>
          <img
            src={images.leftsarrow}
            alt=""
            className="w-8 h-8"
            onClick={() => {
              if (currentPage > 1) handlePageChange(currentPage - 1);
            }}
          />
          {PageList}
          <img
            src={images.rightarrow}
            alt=""
            className=" w-8 h-8 "
            onClick={() => {
              if (currentPage < totalPages) handlePageChange(currentPage + 1);
            }}
          />
          <div
            className="pt-1 text-sm cursor-pointer"
            onClick={() => handlePageChange(totalPages)}
          >
            {">>"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignTabs;
