import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import colours from "../../constants/colours";
import { BarLoader, CircleLoader } from "react-spinners";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const Loading = () => {
  return (
    <div className="h-screen w-screen fixed bg-white/95 top-0 z-40">
      <div className="absolute top-[50%] left-[50%] translate-x-[-50%]">
        <BarLoader
          color={colours.orange}
          loading={true}
          cssOverride={override}
          //   size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
};

export default Loading;
