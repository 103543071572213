import { isAxiosError } from "axios";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import MultipleImageUploader from "../../../components/MulitpleImageUploader/MultipleImageUploader";
import Field from "../../../components/common/Field";
import TextEditor from "../../../components/common/TextEditor";
import Api from "../../../constants/api";

const colours = [
  { colourName: "Black", colourHexCode: "#000000" },
  { colourName: "Blue", colourHexCode: "#0000FF" },
  { colourName: "Gray", colourHexCode: "#808080" },
  { colourName: "Green", colourHexCode: "#008000" },
  { colourName: "Purple", colourHexCode: "#800080" },
  { colourName: "Red", colourHexCode: "#FF0000" },
  { colourName: "White", colourHexCode: "#FFFFFF" },
];

const validationSchema = yup.object().shape({
  name: yup.string().required("Document name is required."),
  description: yup
    .string()
    .test("has text", "Description is required", (value: any) => {
      const emptyParagraphRegex = /^<p><br><\/p>$/;
      return !emptyParagraphRegex.test(value);
    })
    .required("Description is required"),
  sellPlatformUrl: yup.string().url(),
  deliveryDurationEarliest: yup.number(),
  hasSelfPickupOption: yup.bool().when("hasDeliveryOption", {
    is: (hasDeliveryOption: boolean) => !hasDeliveryOption,
    then: () => yup.bool().oneOf([true], "At least one needs to be checked"),
  }),
  deliveryDurationLatest: yup
    .number()
    .integer()
    .when("hasDeliveryOption", {
      is: (hasDeliveryOption: boolean) => hasDeliveryOption,
      then: () => yup.number().moreThan(yup.ref("deliveryDurationEarliest")),
    }),
  image_1: yup.string().required("Please upload Images before submitting"),
});

const initialValues = {
  name: "",
  description: "",
  image_1: "",
  image_2: "",
  image_3: "",
  image_4: "",
  image_5: "",
  image_6: "",
  gender: "Male",
  listPrice: 0,
  retailPrice: 0,
  costPrice: 0,
  deliveryFee: 0,
  selfPickupAddress: "",
  hasSelfPickupOption: false,
  hasDeliveryOption: false,
  productCategoryId: 0,
  productType: "Digital",
  productSubCategoryId: 0,
  sellPlatform: "",
  sellPlatformUrl: "",
  deliveryDurationLatest: 0,
  deliveryDurationEarliest: 0,
  brand: "",
};

export const CreateProduct = (props: any) => {
  const navigate = useNavigate();
  const successMessage = () => toast("Success!");
  const errorMessage = (error?: string) => toast.error(error || "error!");

  const [productCats, setProductCats] = useState<DashboardResProductCategory[]>(
    []
  );
  const [productSubCats, setProductSubCats] = useState<ProductSubCategory[]>(
    []
  );
  const [isAutoUpdate, setIsAutoUpdate] = useState(false);
  const [selectedColour, setSelectedColour] = useState(0);
  const [productTagsMaster, setproductTagsMaster] = useState<
    GetProductTagsData[]
  >([]);

  const isEdit = props?.updateData && props?.tabNumber === props?.currentTab;

  const createProduct = async (values: TCreateProduct) => {
    try {
      const data: TCreateProduct = {
        ...values,
        listPrice: Number(values.listPrice),
        retailPrice: Number(values.retailPrice),
        costPrice: Number(values.costPrice),
        deliveryFee: Number(values.deliveryFee),
        productCategoryId: Number(values.productCategoryId),
        productSubCategoryId: Number(values.productSubCategoryId),
        deliveryDurationLatest:
          Number(values.deliveryDurationLatest) * 24 * 60 * 60,
        deliveryDurationEarliest:
          Number(values.deliveryDurationEarliest) * 24 * 60 * 60,

        colourName: colours[selectedColour].colourName,
        colourHexCode: colours[selectedColour].colourHexCode,
        productTagMasterIds: productTagsMaster
          .filter((item) => item.checked)
          .map((element) => element.id),
      };

      if (!data?.sellPlatformUrl) {
        delete data?.sellPlatformUrl;
      }

      if (!data.hasDeliveryOption) data.deliveryFee = 0;

      const res = await Api.createProduct(data);

      if (isAxiosError(res))
        errorMessage(res.response?.data.message || "Invalid data!");
      else successMessage();
    } catch (error) {
      errorMessage();
    }
  };

  const updateProduct = (values: TCreateProduct) => {
    if (!values?.sellPlatformUrl) {
      delete values?.sellPlatformUrl;
    }

    if (!values.hasDeliveryOption) values.deliveryFee = 0;

    values.colourName = colours[selectedColour].colourName;
    values.productTagMasterIds = productTagsMaster
      ? productTagsMaster
          .filter((item) => item.checked)
          .map((element) => element.id)
      : [];

    Api.updateProduct(props?.updateData?.id, values)
      .then((res) => {
        if (isAxiosError(res))
          errorMessage(res.response?.data.message || "Invalid data!");
        else {
          if (isAutoUpdate) {
            setIsAutoUpdate(false);
          }
          if (!isAutoUpdate) {
            successMessage();
            navigate("/admin", { state: { tab: props?.tabNumber, subtab: 1 } });
          }
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  const [productData, setProductData] = useState(initialValues);

  const {
    errors,
    values,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
  } = useFormik<TCreateProduct>({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: productData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values): Promise<void> => {
      if (isEdit) {
        updateProduct(values);
      } else {
        createProduct(values);
      }
    },
  });

  useEffect(() => {
    Api.getAllProductMaster().then((res) => setproductTagsMaster(res.data));
  }, []);

  /**
   * Handle Cancel Update
   */

  const handleCancelUpdate = () => {
    setProductData(initialValues);
    navigate("/admin", { state: { tab: props?.tabNumber, subtab: 1 } });
  };

  const getProductCats = useCallback(() => {
    Api.getAllProductCategories().then((res) => {
      setProductCats(res);
      setProductData((prev: any) => {
        return { ...prev, productCategoryId: res[0]?.id };
      });
    });
  }, []);

  const getProductSubCats = useCallback(() => {
    Api.getAllProductSubCategories().then((res) => {
      setProductSubCats(res);
      setProductData((prev: any) => {
        return { ...prev, productSubCategoryId: res[0]?.id };
      });
    });
  }, []);
  /**THIS function is passed as prop in image uploader to get the image link */
  const getAzureImageUrl = (imgUrlArray: string[], isAutoUpdate: boolean) => {
    setValues({
      ...values,
      image_1: imgUrlArray[0] || "",
      image_2: imgUrlArray[1] || "",
      image_3: imgUrlArray[2] || "",
      image_4: imgUrlArray[3] || "",
      image_5: imgUrlArray[4] || "",
      image_6: imgUrlArray[5] || "",
    });

    isAutoUpdate ? setIsAutoUpdate(true) : setIsAutoUpdate(false);
  };

  useEffect(() => {
    getProductCats();
    getProductSubCats();
  }, [getProductCats, getProductSubCats]);

  useEffect(() => {
    if (
      props.updateData &&
      props.tabNumber === 3 &&
      productTagsMaster.length &&
      !values.name
    ) {
      Api.getSingleProduct(props.updateData.id).then((res: any) => {
        const data = res?.data;
        const index = colours.findIndex(
          (colorObj) => colorObj.colourName === data.colourName
        );
        if (data?.productTags?.length) {
          const selectedTags = data?.productTags?.map(
            (tag: any) => tag?.productTagMaster?.id
          );
          const currentTags = [...productTagsMaster];
          const newTags = currentTags.map((tag) => {
            if (selectedTags.includes(tag.id)) {
              tag.checked = true;
            }
            return tag;
          });

          setproductTagsMaster(newTags);
        }
        setSelectedColour(index);
        setProductData({
          name: data.name,
          description: data.description,
          image_1: data.image_1,
          image_2: data.image_2,
          image_3: data.image_3,
          image_4: data.image_4,
          image_5: data.image_5,
          image_6: data.image_6,
          gender: data.gender,
          hasSelfPickupOption: data.hasSelfPickupOption,
          selfPickupAddress: data.selfPickupAddress,
          hasDeliveryOption: data.hasDeliveryOption,
          listPrice: data.listPrice,
          retailPrice: data.retailPrice,
          costPrice: data.costPrice,
          deliveryFee: data.deliveryFee,
          productCategoryId: data.productCategory?.id,
          productType: data.productType,
          productSubCategoryId: data.productSubCategory
            ? data.productSubCategory.id
            : undefined,
          sellPlatform: data.sellPlatform,
          sellPlatformUrl: data.sellPlatformUrl ?? "",
          deliveryDurationLatest: data.deliveryDurationLatest / 86400,
          deliveryDurationEarliest: data.deliveryDurationEarliest / 86400,
          brand: data.brand ?? "",
        });
      });
    }
  }, [props.updateData, props.tabNumber, productTagsMaster, values]);

  useEffect(() => {
    if (isAutoUpdate) {
      updateProduct(productData);
    }
  }, [isAutoUpdate, productData]);

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="m-4 grid grid-flow-row max-w-md mb-72"
      >
        <div className="text-lg text-orange">Product</div>
        <Field label="Brand" className="my-4">
          <input
            type="text"
            name="brand"
            placeholder="Product Brand"
            onChange={handleChange}
            value={values.brand}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </Field>

        <Field label="Product Name" errorMessage={errors?.name}>
          <input
            type="text"
            id="name"
            onChange={handleChange}
            value={values.name}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="e.g. John"
          />
        </Field>
        <Field
          label="Description"
          errorMessage={errors?.description}
          className="mt-4 mb-4"
        >
          <TextEditor
            placeholder="Enter description"
            onChangeProp={(value: any) => setFieldValue("description", value)}
            value={values.description}
          />
        </Field>
        <Field errorMessage={errors?.image_1}>
          <MultipleImageUploader
            getAzureImageUrl={getAzureImageUrl}
            updateImgUrl={[
              props?.updateData?.image_1,
              props?.updateData?.image_2,
              props?.updateData?.image_3,
              props?.updateData?.image_4,
              props?.updateData?.image_5,
              props?.updateData?.image_6,
            ]}
            numOfImages={6}
          />
        </Field>

        <Field label="Gender" errorMessage={errors?.gender} className="mt-4">
          <select
            name="gender"
            onChange={handleChange}
            id="gender"
            value={values.gender}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            {["Male", "Female", "Unisex"].map((val, index) => (
              <option key={index} value={val}>
                {val}
              </option>
            ))}
          </select>
        </Field>

        <Field
          label="Delivery Options"
          errorMessage={errors.hasSelfPickupOption}
          className="mt-4"
        >
          <div className="flex items-center">
            <input
              checked={values.hasSelfPickupOption}
              id="hasSelfPickupOption"
              onChange={handleChange}
              type="checkbox"
              value="hasSelfPickupOption"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="hasSelfPickupOption"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Self Pickup
            </label>
          </div>
          <div className="flex items-center">
            <input
              checked={values.hasDeliveryOption}
              id="hasDeliveryOption"
              onChange={handleChange}
              type="checkbox"
              value="hasDeliveryOption"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="hasDeliveryOption"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Delivery
            </label>
          </div>
        </Field>

        {values.hasSelfPickupOption && (
          <>
            <Field
              label="Self Pickup Address"
              errorMessage={errors?.selfPickupAddress}
              className="mt-4"
            >
              <input
                type="text"
                name="selfPickupAddress"
                placeholder="Self Pickup Address"
                onChange={handleChange}
                value={values.selfPickupAddress}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </Field>
          </>
        )}
        {values.hasDeliveryOption && (
          <>
            <Field
              label="Estimated earliest delivery duration (in days):"
              errorMessage={errors?.deliveryDurationEarliest}
              className="mt-4"
            >
              <input
                type="number"
                name="deliveryDurationEarliest"
                placeholder="Estimated latest delivery duration (in days)"
                onChange={handleChange}
                value={values.deliveryDurationEarliest}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                min={0}
              />
            </Field>
            <Field
              label="Estimated latest delivery duration (in days):"
              errorMessage={errors?.deliveryDurationLatest}
              className="mt-4"
            >
              <input
                type="number"
                name="deliveryDurationLatest"
                placeholder="Estimated latest delivery duration (in days)"
                onChange={handleChange}
                value={values.deliveryDurationLatest}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                min={0}
              />
            </Field>
            <Field
              label="Delivery Fee:"
              errorMessage={errors?.deliveryFee}
              className="mt-4"
            >
              <input
                type="number"
                name="deliveryFee"
                placeholder="e.g. 5"
                onChange={handleChange}
                value={values.deliveryFee}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                min={0}
              />
            </Field>
          </>
        )}

        <Field
          label="List Price"
          errorMessage={errors?.listPrice}
          className="mt-4"
        >
          <input
            type="number"
            name="listPrice"
            placeholder="listPrice"
            onChange={handleChange}
            value={values.listPrice}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </Field>
        <Field
          label="Retail Price"
          errorMessage={errors?.retailPrice}
          className="mt-4"
        >
          <input
            type="number"
            name="retailPrice"
            placeholder="retailPrice"
            onChange={handleChange}
            value={values.retailPrice}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </Field>
        <Field
          label="Cost Price"
          errorMessage={errors?.costPrice}
          className="mt-4"
        >
          <input
            type="number"
            name="costPrice"
            placeholder="costPrice"
            value={values.costPrice}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </Field>
        <Field
          label="Sell Platform"
          errorMessage={errors?.sellPlatform}
          className="mt-4"
        >
          <input
            type="text"
            name="sellPlatform"
            placeholder="sellPlatform"
            onChange={handleChange}
            value={values.sellPlatform}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </Field>
        <Field
          label="Sell Platform URL"
          errorMessage={errors?.sellPlatformUrl}
          className="mt-4"
        >
          <input
            type="text"
            name="sellPlatformUrl"
            placeholder="sellPlatform url"
            onChange={handleChange}
            value={values.sellPlatformUrl}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </Field>

        <Field
          label="Product Type"
          errorMessage={errors?.productType}
          className="mt-4"
        >
          <select
            name="productType"
            id="productType"
            value={values.productType}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            {["Digital", "Physical"].map((val, index) => (
              <option key={index} value={val}>
                {val}
              </option>
            ))}
          </select>
        </Field>
        <Field
          label="Product Category"
          errorMessage={errors?.productCategoryId}
          className="mt-4"
        >
          <select
            name="productCategoryId"
            onChange={(e) => {
              handleChange(e);
              const selectedProductSubCategorues =
                productCats.find((category) => category.id === +e.target.value)
                  ?.productCategories || [];
              setProductSubCats(selectedProductSubCategorues);
            }}
            value={values.productCategoryId}
            id="productCategoryId"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            {productCats?.map((val, index) => (
              <option key={index} value={val.id}>
                {val.name}
              </option>
            ))}
          </select>
        </Field>
        <Field
          label="Sub Category:"
          errorMessage={errors?.productSubCategoryId}
          className="mt-4"
        >
          <select
            name="productSubCategoryId"
            onChange={handleChange}
            value={values.productSubCategoryId}
            id="productSubCategoryId"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            {productSubCats?.map((val, index) => (
              <option key={index} value={val.id}>
                {val.name}
              </option>
            ))}
          </select>
        </Field>

        <Field label=" Product Colour:" className="mt-4">
          <select
            name="productColour"
            value={selectedColour}
            id="productColour"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={(e) => {
              setSelectedColour(+e.target.value);
            }}
          >
            {colours?.map((val, index) => (
              <option key={index} value={index}>
                {val.colourName}{" "}
              </option>
            ))}
          </select>
        </Field>

        <div className="mt-4">
          {productTagsMaster.map((tagMaster, index) => (
            <div className="flex items-center">
              <input
                type="checkbox"
                name="productTagsMaster"
                id={tagMaster.id.toString()}
                checked={tagMaster?.checked}
                onChange={(e) => {
                  productTagsMaster[index].checked = e.target.checked;
                  setproductTagsMaster([...productTagsMaster]);
                }}
              />
              <div className="ml-2">{tagMaster.name}</div>
            </div>
          ))}
        </div>
        <button
          type="submit"
          className="border rounded-lg p-2 bg-lightOrange mt-2"
        >
          Submit
        </button>
        {isEdit && (
          <button
            onClick={handleCancelUpdate}
            type="button"
            className="bg-red-600 text-white absolute left-2/4 w-40 h-12 "
          >
            Cancel
          </button>
        )}
      </form>
    </>
  );
};
