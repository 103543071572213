import React from "react";
import images from "../../constants/images";

const ThankyouNotesBottom = () => {
  return (
    <div className="grid grid-cols-7 bg-lightOrange mt-32">
      <div className="col-span-2 mx-6 mt-8 max-md:hidden">
        <img src={images.trackOrderBottom1} alt="" />
      </div>
      <div className="col-span-3 max-md:col-span-7 max-md:m-8 text-center flex flex-col justify-center items-center">
        <div className="font-leagueSpartan-600 text-[32px] max-md:text-[26px] text-orange">
          Enjoyed the experience with Smilie?
        </div>
        <div className="font-leagueSpartan-400 text-[24px] max-md:text-[20px] text-neutrals-900 mt-4">
          Pass this joy forward!
        </div>
        <a
          href={
            process.env.REACT_APP_SENDGIFT_URL ||
            "https://sendgiftdev.smilie.io/"
          }
        >
          <button className="p-2 px-6 bg-orange rounded-lg font-leagueSpartan-500 text-[16px] text-white mt-6">
            Start gifting with Smilie
          </button>
        </a>
      </div>
      <div className="col-span-2 max-md:col-span-7 mx-6 mt-8 flex items-end">
        <img src={images.trackOrderBottom2} className=" object-fill" alt="" />
      </div>
    </div>
  );
};

export default ThankyouNotesBottom;
