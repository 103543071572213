import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { formatCurrency } from "../../components/common/Currency";
import ToastCustomized from "../../components/common/ToastCustomized";
import CampaignTabs from "../../components/dashboard/CampaignTabs";
import DashboardDateSelect from "../../components/dashboard/DashboardDateSelect";
import Main from "../../components/dashboard/Main";
import Api from "../../constants/api";
import images from "../../constants/images";
import { setCorpData, setCorpFee } from "../../redux/corpSlice";
import { setIsLoading } from "../../redux/globalSlice";
import { useAppSelector } from "../../redux/hooks";
import CustomNavigate from "../../utils/navigate";

const Dashboard = () => {
  const navigate = new CustomNavigate(useNavigate());
  const dispatch = useDispatch();
  const dashboardState = useAppSelector((state) => state.dashboardState);
  const globalState = useAppSelector((state) => state.globalSliceState);
  const corpId = globalState.authData?.corp?.id!;

  const [selectedCategory, setselectedCategory] = useState(-1);
  const [datePickerModel, setDatePickerModel] = useState(false);

  const getCorp = useCallback(() => {
    if (!corpId) {
      toast.error("Corp not found");
      return;
    }
    dispatch(setIsLoading(true));
    Api.getOneCorp(corpId)
      .then((res) => {
        dispatch(setCorpData(res.data));
        dispatch(
          setCorpFee(res.data.customPlans[0].defaultPlan?.commission || 0)
        );
      })
      .finally(() => dispatch(setIsLoading(false)));
  }, [dispatch]);

  const getDashboardData = useCallback(() => {
    const reqData = dashboardState.data;
    const fromDate = moment(reqData.fromDate).toISOString();
    const toDate = moment(reqData.toDate).endOf("day").toISOString();

    dispatch(setIsLoading(true));
    Api.getallDashboardData({ fromDate, toDate })
      .then((res) => {
        dispatch(setIsLoading(false));
        setDashboardData(res.data);
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        console.error(err);

        toast.error("Error occurred.");
      });
  }, [dashboardState.data, dispatch]);

  useEffect(() => {
    if (dashboardState.isDataLoaded) {
      return;
    }
    getCorp();
  }, [getCorp]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);
  const [dashboardData, setDashboardData] = useState<DashboardResData>();

  const daysDiff =
    moment(dashboardState.data.toDate).diff(
      moment(dashboardState.data.fromDate),
      "days"
    ) + 1;

  return (
    <>
      <Main
        title={`Welcome ${
          (globalState.authData && globalState.authData.username) || ""
        }`}
        logo={true}
      >
        <>
          <div className="p-6 grid grid-flow-row gap-6">
            <div className="flex justify-between">
              <div className="flex justify-between">
                <DashboardDateSelect
                  datePickerModel={datePickerModel}
                  setDatePickerModel={setDatePickerModel}
                />
              </div>
              <div className="grid grid-flow-col gap-2 my-1 text-sm">
                <button
                  className="bg-orange text-white rounded-lg px-4"
                  onClick={() => navigate.to("/dashboard/campaigns/create")}
                >
                  Create Campaign{" "}
                </button>
              </div>
            </div>
            {/* Analytics */}
            <div className="grid grid-cols-5 gap-6">
              <div className="grid grid-flow-col border-l-4 border-orange p-4 bg-white rounded-r-lg">
                <div className="grid grid-flow-row gap-4">
                  <div className="flex gap-5 text-sm font-leagueSpartan-400 text-neutrals-700">
                    Gift Sent{" "}
                    <img src={images.giftBorder} className="h-5" alt="" />
                  </div>

                  <div className="font-leagueSpartan-500 text-3xl">
                    {dashboardData?.giftSent}
                  </div>
                </div>
              </div>
              <div className="grid grid-flow-col border-l-4 border-green p-4 bg-white rounded-r-lg">
                <div className="grid grid-flow-row gap-4">
                  <div className="flex gap-5 text-sm font-leagueSpartan-400 text-neutrals-700">
                    Gift Claimed{" "}
                    <img src={images.GiftsClaimed} className="h-5" alt="" />
                  </div>
                  <div className="font-leagueSpartan-500 text-3xl">
                    {dashboardData?.giftClaimed}
                  </div>
                </div>
                <div className="self-end text-center text-md font-leagueSpartan-400 text-green-500">
                  {/* + 5% */}
                </div>
              </div>
              <div className="grid grid-flow-col border-l-4 border-brightyellow p-4 bg-white rounded-r-lg">
                <div className="grid grid-flow-row gap-4">
                  <div className="flex gap-5 text-sm font-leagueSpartan-400 text-neutrals-700">
                    Total Spent{" "}
                    <img src={images.currency} className="h-5" alt="" />
                  </div>
                  <div className="font-leagueSpartan-500 text-3xl">
                    {dashboardData
                      ? `$${formatCurrency(dashboardData.totalSpent)}`
                      : "-"}
                  </div>
                </div>
                <div className="self-end text-center text-md font-leagueSpartan-400 text-green-500">
                  {/* + 4% */}
                </div>
              </div>
              <div className="grid grid-flow-col border-l-4 border-maroon p-4 bg-white rounded-r-lg">
                <div className="grid grid-flow-row gap-4">
                  <div className="flex gap-5 text-sm font-leagueSpartan-400 text-neutrals-700">
                    Total Saved{" "}
                    <img src={images.totalsaved} className="h-5" alt="" />
                  </div>
                  <div className="font-leagueSpartan-500 text-3xl">
                    {dashboardData
                      ? `$${formatCurrency(dashboardData.totalSaved)}`
                      : "-"}
                  </div>
                </div>
                <div className="self-end text-center text-md font-leagueSpartan-400 text-green-500">
                  {/* + 22% */}
                </div>
              </div>
              <div className="grid grid-flow-col border-l-4 border-indigo p-4 bg-white rounded-r-lg">
                <div className="grid grid-flow-row gap-4">
                  <div className="flex gap-5 text-sm font-leagueSpartan-400 text-neutrals-700">
                    Total Donated{" "}
                    <img src={images.donate} className="h-5" alt="" />
                  </div>
                  <div className="font-leagueSpartan-500 text-3xl">
                    {" "}
                    {dashboardData?.totalDonated}
                  </div>
                </div>
                <div className="self-end text-center text-md font-leagueSpartan-400 text-red-500">
                  {/* -10% */}
                </div>
              </div>
            </div>
            {/* campaigntable */}
            <div className="p-4 bg-white">
              <div className="flex justify-between">
                <div className="mb-3 font-leagueSpartan-600">
                  Campaigns in{" "}
                  <span
                    className="font-leagueSpartan-600 text-orange cursor-pointer"
                    onClick={() => setDatePickerModel(true)}
                  >
                    {`Last ${daysDiff} ${daysDiff < 2 ? "Day" : "Days"}`}
                  </span>
                </div>
                <a
                  href="/dashboard/campaigns"
                  className="flex items-center text-orange font-leagueSpartan-400 text-sm cursor-pointer"
                >
                  <div>View All</div>
                  <img
                    src={images.rightArrow_1}
                    alt=""
                    className="h-[10px] mx-2 cursor-pointer"
                  />
                </a>
              </div>
              <CampaignTabs filterDate={true} />
            </div>
          </div>
        </>
      </Main>
      <ToastCustomized />
    </>
  );
};

export default Dashboard;
