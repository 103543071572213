import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Accordin from "../../components/common/Accordin";
import BackButtonGifting from "../../components/giftSelection/BackButton";
import CartDrawerHamper from "../../components/giftSelectionHamper/CartDrawerHamper";
import FooterHamper from "../../components/giftSelectionHamper/FooterHamper";
import HeaderHamper from "../../components/giftSelectionHamper/HeaderHamper";
import ProdutDetailsModalHamper from "../../components/giftSelectionHamper/ProdutDetailsModalHamper";
import images from "../../constants/images";
import { addToCart } from "../../redux/cartSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUsedCredits } from "../../utils/cartUtils";
import CustomNavigate from "../../utils/navigate";
import { MIN_PRODUCT_CREDITS } from "./ChooseCategoryHamper";
import HamperQuantity from "./HamperQuantity";

const CategoryDetailHamper = () => {
  const navigate = new CustomNavigate(useNavigate());

  const state: RecipientRecommendedProductCartT = useLocation().state;
  const product = state;
  const cartState = useAppSelector((state) => state.cartState);
  const selectGiftState = useAppSelector((state) => state.selectGiftState);
  const totalCredits = cartState.totalCredits;
  const dispatch = useAppDispatch();
  const cart = cartState.cart;
  const voucherCart = cartState.voucherCart;
  const usedCredits = getUsedCredits(cart, voucherCart);
  const unusedCredits = totalCredits - usedCredits;
  const [selectedImage, setSelectedImage] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);

  // Product Modal
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<number>();
  const [recommendations, setRecommendations] = useState<
    GetCampaignRecipientProduct[]
  >([]);

  const productImages = [];
  if (product.image_1) productImages.push(product.image_1);
  if (product.image_2) productImages.push(product.image_2);
  if (product.image_3) productImages.push(product.image_3);
  if (product.image_4) productImages.push(product.image_4);
  if (product.image_5) productImages.push(product.image_5);
  if (product.image_6) productImages.push(product.image_6);

  useEffect(() => {
    const products: GetCampaignRecipientProduct[] = [];

    cartState.campaignRecipientData?.groupedProduct.forEach(
      (groupedProductItem) => {
        groupedProductItem.products.forEach((item) => {
          products.push(item);
        });
      }
    );
    setRecommendations(products);
  }, [cartState.campaignRecipientData]);

  const nextImage = useCallback(() => {
    setSelectedImage((previousSelect) => {
      if (previousSelect < productImages.length - 1) {
        return previousSelect + 1;
      }
      return previousSelect;
    });
  }, [productImages.length]);

  const previousImage = useCallback(() => {
    setSelectedImage((previousSelect) => {
      if (previousSelect - 1 > -1) {
        return previousSelect - 1;
      }
      return previousSelect;
    });
  }, []);

  const onViewBasketClick = useCallback(() => {
    setDrawerVisible(true);
  }, []);

  const isDeactivated = useMemo(
    () => selectGiftState.campaignRecipientData?.result?.isForTesting ?? false,
    [selectGiftState.campaignRecipientData?.result?.isForTesting]
  );

  return (
    <div className="relative h-screen">
      <HeaderHamper title="Choose your gift(s)!" isPreview={isDeactivated} />
      <div className="max-md:hidden m-6 mt-[100px]">
        <BackButtonGifting navigate={navigate} />
      </div>
      <div className="max-md:mt-[100px] max-md:pb-28 pb-24">
        <div className="md:mx-36 max-md:mx-5">
          <div className="grid md:grid-cols-2 max-md:grid-cols-1 md:gap-16 max-md:gap-4 md:mt-4 max-md:mt-5 ">
            <div>
              <div className="relative">
                <button
                  className="absolute top-[45%] left-1 p-2 rounded-full bg-white disabled:bg-slate-100"
                  disabled={selectedImage === 0}
                  onClick={() => previousImage()}
                >
                  <img
                    src={images.leftArrow}
                    className={selectedImage === 0 ? "bg-slate-100" : ""}
                    alt=""
                  />
                </button>
                <button
                  className="absolute top-[45%] right-1 p-2 rounded-full bg-white disabled:bg-slate-200"
                  disabled={selectedImage === productImages?.length - 1}
                  onClick={() => nextImage()}
                >
                  <img
                    src={images.rightArrow}
                    className={
                      selectedImage === productImages?.length - 1
                        ? "bg-slate-200"
                        : ""
                    }
                    alt=""
                  />
                </button>
                <div className="flex justify-center">
                  <img
                    src={productImages[selectedImage]}
                    className="w-full aspect-square object-cover rounded-2xl"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex  gap-4 overflow-x-auto mt-5">
                {productImages.map((item, index) => {
                  return (
                    <img
                      src={item}
                      key={index}
                      className="w-[18%] mr-1 cursor-pointer object-cover"
                      alt=""
                      onClick={() => setSelectedImage(index)}
                    />
                  );
                })}
              </div>
            </div>

            <div>
              <div className="font-leagueSpartan-500 text-neutrals-900 text-[28px]">
                {product.name}
              </div>
              {product && product.brand && (
                <div className="font-leagueSpartan-400 text-md text-neutrals-700 pt-1 ">
                  <span className="font-leagueSpartan-500">
                    {product.brand}{" "}
                  </span>
                </div>
              )}
              <HamperQuantity
                productCredit={product.credits || MIN_PRODUCT_CREDITS}
                addToCartHandler={(quantity: number) =>
                  dispatch(addToCart({ ...product, quantity: quantity }))
                }
                creditsAvailable={unusedCredits}
              />

              <div className="mt-4">
                <Accordin
                  data={[
                    {
                      title: "Description",
                      description: product.description,
                      id: 1,
                      checked: true,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterHamper
        onViewBasketClick={onViewBasketClick}
        productCart={cart}
        voucherCart={voucherCart}
        showProgress={true}
        credits={{
          total: totalCredits,
          used: getUsedCredits(cart, cartState.voucherCart),
        }}
      />
      {selectedProduct !== undefined && modalVisible && (
        <ProdutDetailsModalHamper
          setModalVisible={setModalVisible}
          product={recommendations[selectedProduct]}
        />
      )}
      {drawerVisible && (
        <CartDrawerHamper setDrawerVisible={setDrawerVisible} />
      )}
    </div>
  );
};

export default CategoryDetailHamper;
