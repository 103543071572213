import { useNavigate } from "react-router-dom";

import CustomNavigate from "../../utils/navigate";
import BackButton from "./BackButton";

type propType = {
  progress: number;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
};

const CampaignCreateProgress = (props: propType) => {
  const { progress, setProgress } = props;
  const navigate = new CustomNavigate(useNavigate());
  return (
    <div className="grid grid-cols-4">
      <div className="self-auto">
        <BackButton
          onClick={() => {
            setProgress((p) => {
              const r = p - 1;
              if (r === 0) navigate.back();
              if (r > 0 && r < 5) return r;
              return p;
            });
          }}
        />
      </div>
      <div className="col-span-2">
        <div className="flex justify-center">
          <div
            className={`relative ${
              progress >= 1
                ? "bg-orange text-white "
                : "bg-lightOrange text-orange "
            }  text-sm rounded-full py-1 px-2.5`}
          >
            1
            <div
              className={`absolute top-9 font-leagueSpartan-300 text-[18px] text-neutrals-800 translate-x-[-50%] w-[140px] text-center ${
                progress >= 1 && "font-leagueSpartan-600 text-neutrals-800"
              } `}
            >
              Campaign Details
            </div>
          </div>
          <div
            className={`border w-[20%] h-0 self-center mx-2 ${
              progress >= 2 && "border-gray-400"
            }`}
          ></div>
          <div
            className={`relative ${
              progress >= 2
                ? "bg-orange text-white "
                : "bg-lightOrange text-orange "
            }  text-sm rounded-full py-1 px-2.5`}
          >
            2
            <div
              className={`absolute top-9 font-leagueSpartan-300 text-[18px] text-neutrals-800 translate-x-[-50%] w-[140px] text-center ${
                progress >= 2 && "font-leagueSpartan-600 text-neutrals-800"
              } `}
            >
              Greeting Card
            </div>
          </div>
          <div
            className={`border w-[20%] h-0 self-center mx-2 ${
              progress >= 3 && "border-gray-400"
            }`}
          ></div>
          <div
            className={`relative ${
              progress >= 3
                ? "bg-orange text-white "
                : "bg-lightOrange text-orange "
            }  text-sm rounded-full py-1 px-2.5`}
          >
            3
            <div
              className={`absolute top-9 font-leagueSpartan-300 text-[18px] text-neutrals-800 translate-x-[-50%] w-[140px] text-center ${
                progress >= 3 && "font-leagueSpartan-600 text-neutrals-800"
              } `}
            >
              Recipients
            </div>
          </div>
          <div
            className={`border w-[20%] h-0 self-center mx-2 ${
              progress >= 4 && "border-gray-400"
            }`}
          ></div>
          <div
            className={`relative ${
              progress >= 4
                ? "bg-orange text-white "
                : "bg-lightOrange text-orange "
            }  text-sm rounded-full py-1 px-2.5`}
          >
            4
            <div
              className={`absolute top-9 font-leagueSpartan-300 text-[18px] text-neutrals-800 translate-x-[-50%] w-[140px] text-center ${
                progress >= 4 && "font-leagueSpartan-600 text-neutrals-800"
              } `}
            >
              Review & Pay
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignCreateProgress;
