import { SetStateAction } from "react";

import images from "../../constants/images";
// import EditableTablePositionDropdownComponent from "./EditableTablePositionDropdownComponent";
type propType = {
  selected: OrderRecipientPartial[];
  setSelected: (value: SetStateAction<OrderRecipientPartial[]>) => void;
  recipientList: OrderRecipientPartial[];
  setRecipientList: (value: SetStateAction<OrderRecipientPartial[]>) => void;
  selectAllRecipients: boolean;
};
export const EditableTable = ({
  selected,
  setSelected,
  recipientList,
  setRecipientList,
  selectAllRecipients = false,
}: propType) => {
  const toggle = (recipient: OrderRecipientPartial) => {
    if (selected.find((r) => r.id === recipient.id)) {
      setSelected(selected.filter((r) => r.id !== recipient.id));
    } else {
      const newSelected = [...selected, recipient];
      setSelected(newSelected);
    }
  };

  return (
    <tbody className="font-leagueSpartan-400 text-[16px] text-neutrals-900">
      {recipientList &&
        recipientList.map((v, _index) => {
          return (
            <tr key={_index} className="border-b ">
              <td className="p-3">
                <div
                  className="flex pl-1 cursor-pointer"
                  onClick={() => toggle(v)}
                >
                  <img
                    src={
                      selected.find((r) => r.id === v.id)
                        ? images.checkBoxSelected
                        : images.checkBoxInactive
                    }
                    className="h-[20px] min-w-[20px]"
                    alt=""
                  />
                </div>
              </td>
              <td className={v.editable ? "p-0" : "pl-4"}>
                {v.editable ? (
                  <>
                    <input
                      type="text"
                      placeholder="FIRST NAME"
                      className="border border-white rounded-lg mr-[1px] max-w-[170px] placeholder:font-leagueSpartan-400 placeholder:text-[15px] placeholder:text-neutral-400"
                      value={v.firstName || ""}
                      onChange={(e) =>
                        setRecipientList((ol) => {
                          ol[_index].firstName = e.target.value;
                          return [...ol];
                        })
                      }
                    />
                  </>
                ) : (
                  <>{v.firstName}</>
                )}
              </td>
              <td className={v.editable ? "p-0" : "pl-4"}>
                {v.editable ? (
                  <>
                    <input
                      type="text"
                      placeholder="LAST NAME"
                      className="border border-white rounded-lg mr-[1px] max-w-[170px] placeholder:font-leagueSpartan-400 placeholder:text-[15px] placeholder:text-neutral-400"
                      value={v.lastName || ""}
                      onChange={(e) =>
                        setRecipientList((ol) => {
                          ol[_index].lastName = e.target.value;
                          return [...ol];
                        })
                      }
                    />
                  </>
                ) : (
                  <>{v.lastName}</>
                )}
              </td>
              <td className={`flex max-w-48 ${v.editable ? "p-0" : ""}`}>
                {v.departmentGroup?.map((group) => (
                  <div
                    key={group.id}
                    className=" flex justify-center m-2 p-2 px-4 font-leagueSpartan-300 text-[16px] text-neutrals-900 bg-neutrals-300 rounded-3xl whitespace-nowrap"
                  >
                    {group.name}
                  </div>
                ))}
              </td>
              {/* <td className={v.editable ? "p-0" : ""}>
                {v.editable ? (
                  <>
                    <EditableTablePositionDropdownComponent
                      v={v}
                      i={_index}
                      setRecipientList={setRecipientList}
                      positionList={positionList}
                    />
                  </>
                ) : (
                  <>{v.role}</>
                )}
              </td> */}
              <td className={v.editable ? "p-0" : "pl-4"}>
                {v.editable ? (
                  <>
                    <input
                      type="email"
                      placeholder="EMAIL"
                      className={`border  rounded-lg mr-[1px] max-w-[170px] placeholder:font-leagueSpartan-400 placeholder:text-[15px] placeholder:text-neutral-400 ${
                        v.emailValid === false
                          ? "border-red-500"
                          : "border-white"
                      }`}
                      value={v.email}
                      onChange={(e) =>
                        setRecipientList((ol) => {
                          let valid = true;
                          if (!/^\S+@\S+\.\S+$/.test(e.target.value))
                            valid = false;

                          ol[_index].emailValid = valid;
                          ol[_index].email = e.target.value;
                          return [...ol];
                        })
                      }
                    />
                  </>
                ) : (
                  <>{v.email}</>
                )}
              </td>
              <td className={v.editable ? "p-0" : "pl-4"}>
                {v.editable ? (
                  <>
                    <input
                      type="text"
                      placeholder="MOBILE NUMBER"
                      className="border border-white rounded-lg mr-[1px] max-w-[170px] placeholder:font-leagueSpartan-400 placeholder:text-[15px] placeholder:text-neutral-400"
                      value={v.phoneNumber}
                      onChange={(e) => {
                        if (!/^[0-9]{0,10}$/.test(e.target.value)) {
                          return;
                        }
                        setRecipientList((ol) => {
                          ol[_index].phoneNumber = e.target.value;
                          return [...ol];
                        });
                      }}
                    />
                  </>
                ) : (
                  <>{v.phoneNumber}</>
                )}
              </td>

              {v.editable ? (
                <>
                  <td></td>
                  <td className={v.editable ? "p-0" : "pl-4"}>
                    <textarea
                      placeholder="Message"
                      className="min-w-[16rem] border border-white rounded-lg mr-[1px]  placeholder:font-leagueSpartan-400 placeholder:text-[15px] placeholder:text-neutral-400"
                      value={v.recipientMessage}
                      onChange={(e) => {
                        setRecipientList((ol) => {
                          ol[_index].recipientMessage = e.target.value;
                          return [...ol];
                        });
                      }}
                    />
                  </td>
                </>
              ) : (
                <></>
              )}

              <td className="pl-4">
                {v.recipientCustomFieldValues?.map((fieldValue) => (
                  <span
                    key={fieldValue.id}
                    className="inline-flex items-center px-2.5 py-0.5 mt-2 rounded-full text-base font-medium bg-blue-100 text-blue-800 mr-2 mb-2"
                  >
                    {fieldValue.recipientCustomField.field}
                  </span>
                ))}
              </td>

              {v.editable && (
                <td>
                  <button
                    className="text-red-500 border border-red-500 aspect-square rounded-lg w-[20px]"
                    onClick={() => {
                      setRecipientList((ol) => {
                        ol.splice(_index, 1);
                        return [...ol];
                      });
                    }}
                  >
                    x
                  </button>
                </td>
              )}
            </tr>
          );
        })}
    </tbody>
  );
};
