import { isAxiosError } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import Api from "../../constants/api";

interface MyComponentProps {
  getAzureImageUrl: Function;
  updateImgUrl: string[];
  numOfImages: number;
}

const MultipleImageUploader: React.FC<MyComponentProps> = ({
  getAzureImageUrl,
  updateImgUrl,
  numOfImages,
}) => {
  const successMessage = () => toast("Success!");
  const errorMessage = (error?: string) => toast.error(error || "error!");
  const [selectedImages, setSelectedImages] = useState<File[]>([]); //holds the file selected locally first time before upload.
  const [azureImageUrls, setAzureImageUrls] = useState<string[]>([]); //holds the image urls in an array after receiving from azure.

  /**
   * this function accecpts the images/
   */
  /**OnDrop image upload function */
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setSelectedImages((prevSelectedImages) => [
      ...prevSelectedImages,
      ...acceptedFiles,
    ]);
  }, []);

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    multiple: true,
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
    },
    // maxFiles: numOfImages,  // condition applied here.
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone(dropzoneOptions);

  /**
   * Upload image function, Upload to azure after selecting locally.
   */
  const handleUploadImage = async () => {
    if (
      selectedImages.length > numOfImages ||
      selectedImages.length + azureImageUrls.length > numOfImages
    ) {
      alert(`Only ${numOfImages} allowed`);
      setSelectedImages([]);
      return;
    }
    try {
      const formDataArray = selectedImages.map((image, index) => {
        const formData = new FormData();
        formData.append("file", image);
        return formData;
      });

      const uploadToAzure = (formData: any) => {
        return Api.uploadingImgToAzure(formData)
          .then((res) => {
            if (isAxiosError(res)) {
              // Handle error if needed
              return Promise.reject(
                res.response?.data.message || "Invalid data!"
              );
            } else {
              // Return the response data if successful
              return res?.data;
            }
          })
          .catch((error) => {
            // Handle error if needed
            console.error("Error uploading image:", error);
            return Promise.reject(error);
          });
      };

      Promise.all(formDataArray.map(uploadToAzure))
        .then((responses) => {
          // Handle the responses from all the uploads here
          setAzureImageUrls((prev) => {
            return [...prev, ...responses];
          });

          getAzureImageUrl([...azureImageUrls, ...responses], false);

          setSelectedImages([]); //EMPTYING THIS STATE IS IMPORTANT.
        })
        .catch((error) => {
          // Handle any error that occurred during the uploads
          console.error("Error uploading images:", error);
        });
    } catch (error) {
      console.log(error);
      errorMessage();
    }
  };

  const handleRemoveUpdatingImg = (index: any) => {
    /**
     * Deleting from azure-
     */
    try {
      Api.deletingImgFromAzure(azureImageUrls[index]).then((res) => {
        successMessage();
        let arr = azureImageUrls.filter((item, i) => {
          return i !== index;
        });
        setAzureImageUrls(arr);
        let isImgDel: boolean;
        updateImgUrl[0] === undefined ? (isImgDel = false) : (isImgDel = true);
        getAzureImageUrl(arr, isImgDel);
      });
    } catch (error) {
      console.log(error);
      errorMessage();
    }
  };

  /**
   * while updaing this will happen.
   */
  useEffect(() => {
    if (selectedImages.length > 0) {
      handleUploadImage();
    }
    if (updateImgUrl[0] !== undefined && !azureImageUrls.length) {
      setAzureImageUrls(
        updateImgUrl.filter((url) => {
          return url;
        })
      );
    }
  }, [updateImgUrl, selectedImages]);

  return (
    <div>
      {azureImageUrls.length !== numOfImages && (
        <div
          {...getRootProps()}
          className="p-5 rounded border-2 border-[#ccc] border-dashed text-center cursor-pointer"
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            isDragAccept ? (
              <p>Drop the image files here</p>
            ) : (
              <p>Only image files are allowed</p>
            )
          ) : (
            <p>
              Drag Or drop some image files here, or click to select image files
            </p>
          )}
        </div>
      )}
      {azureImageUrls?.map((url, index) => {
        return (
          <div key={index} className="flex flex-col items-center m-[5px]">
            <img
              src={url}
              alt=""
              className="max-w-[200px] max-h-[200px] m-[5px] border-2 border-green-500 border-solid"
            />
            {updateImgUrl[0] === undefined || azureImageUrls.length > 1 ? (
              <button
                type="button"
                onClick={() => handleRemoveUpdatingImg(index)}
              >
                Remove
              </button>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MultipleImageUploader;
