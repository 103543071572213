import React, { useState } from "react";

import Model from "../../components/common/CreateNewAccModel";
import Main from "../../components/dashboard/Main";

const Accounts = () => {
  const [showMyModel, setShowMyModel] = useState(false);

  return (
    <>
      <Main title={"All Campaigns"} logo={true}>
        <div>
          <div className="p-6 grid grid-flow-row gap-6"></div>
          {showMyModel === true && <Model setShowMyModel={setShowMyModel} />}
        </div>
      </Main>
    </>
  );
};

export default Accounts;
