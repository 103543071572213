import { ChangeEvent, useCallback, useEffect, useState } from "react";

import images from "../../constants/images";

type Iprops = {
  setSearch: (input: string) => void;
};

const SearchInput = ({ setSearch }: Iprops) => {
  const [debounceTimeout, setDebounceTimeout] = useState<number | null>(null);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const searchVal = event.target.value;

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const newTimeout: any = setTimeout(() => {
      setSearch(searchVal);
    }, 700);

    setDebounceTimeout(newTimeout);
  }, []);

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [debounceTimeout]);

  return (
    <div className="relative flex items-center border border-neutrals-400 bg-white rounded-lg w-full">
      <img src={images.search} alt="" className="m-3" />
      <input
        type="text"
        placeholder="Search"
        className="px-2 outline-none focus:ring-0 border-none rounded-lg font-leagueSpartan-300 h-full w-full text-lg"
        onChange={onChange}
      />
    </div>
  );
};

export default SearchInput;
