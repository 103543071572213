import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

// Define the initial state using that type
const initialState: {
  data?: GetOneCorpData;
  fee: number;
} = {
  fee: 0,
};

export const corpSlice = createSlice({
  name: "corpState",
  initialState,
  reducers: {
    setCorpData: (state, action: PayloadAction<GetOneCorpData | undefined>) => {
      state.data = action.payload;
      const fee =
        action.payload?.customPlans.length &&
        action.payload.customPlans[0].defaultPlan &&
        action.payload.customPlans[0].defaultPlan.commission;
      if (fee) state.fee = fee;
    },
    setCorpFee: (state, action: PayloadAction<number>) => {
      state.fee = action.payload;
    },
    updateBalance: (state, action: PayloadAction<number>) => {
      // Assuming that the balance is a property of the data object
      if (state.data) {
        state.data.balance = action.payload;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCorpData, setCorpFee, updateBalance } = corpSlice.actions;

export const selectCount = (state: RootState) => state.cartState;

export default corpSlice.reducer;
