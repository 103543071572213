import React, { useCallback, useEffect, useState } from "react";
import Api from "../../constants/api";
import { toast } from "react-toastify";

const CreateCorpCollection = () => {
  const [corpList, setCorpList] = useState<GetAllCorpData[]>([]);
  const [collections, setCollections] = useState<GetAllCollectionListT[]>([]);
  useEffect(() => {
    Api.getAllCorp().then((res) => setCorpList(res.data));
    Api.getAllCollection().then((res) => setCollections(res.data));
    return () => {};
  }, []);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLDivElement>) => {
      e.preventDefault();
      // @ts-ignore
      const corpId = +e.target[0].value;
      const data: CreateCorpCollectionMultipleDtoData[] = [];
      collections.forEach((v, i) => {
        // @ts-ignore
        const checked = e.target[i + 1].checked;
        if (checked) {
          // @ts-ignore
          const collectionId = +e.target[i + 1].id;
          data.push({
            collectionId,
            corpId,
          });
        }
      });
      const reqData: CreateCorpCollectionMultiple = {
        data,
      };
      if (data.length) {
        Api.createCorpCollections(reqData)
          .then((res) => {
            toast.success("Success");
          })
          .catch(() => toast.error("Error"));
      }
      console.log(data);
    },
    [collections]
  );

  return (
    <div className="m-4" onSubmit={onSubmit}>
      <form className="">
        <div>Corp</div>
        <select name="corp" id="corp" className="rounded-lg my-2">
          {corpList.map((v, i) => {
            return <option value={v.id}>{v.organisationName}</option>;
          })}
        </select>
        <div>Collection</div>
        {collections.map((v, i) => {
          return (
            <div className="flex items-center gap-2 mt-2">
              <input type="checkbox" name="collection" id={v.id.toString()} />
              {v.name}
            </div>
          );
        })}
        <button
          className="mt-2 bg-orange text-white p-2 px-3 rounded-lg"
          type="submit"
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default CreateCorpCollection;
