import { useState } from "react";
import images from "../../../constants/images";

type propsType = {
  setWhatsappNameModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  handleWhatsappNameSubmit: (whatsappName: string) => void;
};

const WhatsappNameModal = (props: propsType) => {
  const [whatsappName, setWhatsappName] = useState<string>("");

  return (
    <>
      <div
        className="font-leagueSpartan-400 fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 cursor-default"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-white p-6 rounded-md w-[596px] h-auto">
          <div className="flex justify-between">
            <h2 className="text-2xl font-semibold mb-2">
              Before enabling WhatsApp
            </h2>
            <img
              className="self-start cursor-pointer"
              src={images.cross}
              alt="close"
              onClick={() => {
                props.setWhatsappNameModalVisibility(false);
              }}
            />
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.handleWhatsappNameSubmit(whatsappName);
            }}
          >
            <div className="grid grid-rows-4 content-evenly">
              <label className="text-[18px] font-medium h-auto">
                Create a short name for this campaign on WhatsApp
              </label>
              <input
                className="rounded"
                type="text"
                minLength={1}
                maxLength={15}
                required={true}
                onChange={(e) => setWhatsappName(e.target.value)}
              ></input>
              <p className="text-[13px] text-slate-400">15 characters max</p>
              <button
                className=" justify-self-end bg-orange rounded-lg text-[16px] text-white w-[97px] h-[44px]"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default WhatsappNameModal;
