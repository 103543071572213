import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import images from "../../constants/images";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setCharity, setProduct } from "../../redux/selectGiftSlice";
import { Voucher } from "../../types/vouchers";
import CustomNavigate from "../../utils/navigate";
import AccordinGifting from "./accordin/AccordinGifting";
type propType = {
  product?: GetRecipientRecommendedProduct;
  voucher?: Voucher;
  campaignCharity?: GetCampaignRecipientCampaignCharity | undefined;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
const ProdutDetailsModal = (props: propType) => {
  const { setModalVisible, product, campaignCharity, voucher } = props;
  const selectGiftState = useAppSelector((state) => state.selectGiftState);
  const recipientId =
    useSearchParams()[0].get("recid") ||
    selectGiftState.campaignRecipientData?.result.url_id;
  const { corpName } = useParams();
  const navigate = new CustomNavigate(useNavigate(), {
    recipientId,
    corpId: corpName,
  });
  const isTesting = selectGiftState.campaignRecipientData?.result.isForTesting;
  const charity = campaignCharity?.charity || undefined;
  const perPersonBudgetWithoutFee = 10; //selectGiftState.campaignRecipientData?.result.campaign.perPersonBudgetWithoutFee;
  let name = "",
    description = "";

  const productImages = [];

  if (product) {
    name = product.name;
    description = product.description;
    if (product.image_1) productImages.push(product.image_1);
    if (product.image_2) productImages.push(product.image_2);
    if (product.image_3) productImages.push(product.image_3);
  }
  if (charity) {
    name = charity.name;
    description = charity.description;
    if (charity.pic1) productImages.push(charity.pic1);
    if (charity.pic2) productImages.push(charity.pic2);
    if (charity.pic3) productImages.push(charity.pic3);
    if (charity.pic4) productImages.push(charity.pic4);
  }
  if (voucher) {
    name = voucher.name;
    description = voucher?.description || "";
    if (voucher.image_1) productImages.push(voucher.image_1);
    if (voucher.image_2) productImages.push(voucher.image_2);
    if (voucher.image_3) productImages.push(voucher.image_3);
    if (voucher.image_4) productImages.push(voucher.image_4);
  }
  const handleFullDetailClick = () => {
    if (voucher) {
      navigate.to(
        "/gift-selection/choose-voucher-category/:id",
        { key: ":id", value: 1 },
        { voucher: voucher, isHamper: false }
      );
    } else {
      dispatch(setCharity(campaignCharity));
      dispatch(setProduct(product));
      navigate.to(
        navigate.corpId
          ? "/gift-selection/:corpName/choose-category/:id"
          : "/gift-selection/choose-category/:id",
        { key: ":id", value: 1 },
        product
      );
    }
    setModalVisible(false);
  };
  const dispatch = useAppDispatch();

  const [selectedImage, setSelectedImage] = useState(0);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setModalVisible(false);
      }
    },
    [setModalVisible]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, false);
    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [handleKeyPress]);

  const nextImage = useCallback(() => {
    setSelectedImage((si) => {
      if (si < productImages.length - 1) {
        return si + 1;
      }
      return si;
    });
  }, [productImages.length]);

  const previousImage = useCallback(() => {
    setSelectedImage((si) => {
      if (si - 1 > -1) {
        return si - 1;
      }
      return si;
    });
  }, []);
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center max-sm:p-0 text-center max-sm:items-end sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-sm:mt-7 sm:my-8 max-sm:w-full sm:w-full sm:max-w-[800px]">
            <div className="bg-white px-2 pb-2 pt-2 sm:p-8 sm:pb-5">
              {/* Body */}
              <div className="sm:hidden flex justify-end">
                <button
                  onClick={() => setModalVisible(false)}
                  className="self-start"
                >
                  <img src={images.cross} className="h-[13.5px]" alt="" />
                </button>
              </div>
              <div className="flex relative">
                <div
                  className={`max-sm:w-full max-md:grid max-md:grid-cols-2 md:flex md:gap-6 max-sm:grid-cols-1`}
                >
                  {/* Images */}
                  <div className="flex flex-col justify-center lg:w-[320px]">
                    <div className="relative border rounded-2xl">
                      <button
                        className="absolute top-[45%] left-1 p-2 rounded-full bg-neutrals-200"
                        onClick={() => previousImage()}
                      >
                        <img
                          src={
                            selectedImage !== 0
                              ? images.leftArrow
                              : images.leftArrowGrey
                          }
                          className="w-[16px] aspect-square "
                          alt=""
                        />
                      </button>
                      <button
                        className="absolute top-[45%] right-1 p-2 rounded-full bg-neutrals-200"
                        onClick={() => nextImage()}
                      >
                        <img
                          src={
                            selectedImage + 1 === productImages.length
                              ? images.leftArrowGrey
                              : images.leftArrow
                          }
                          className="w-[16px] aspect-square rotate-180"
                          alt=""
                        />
                      </button>
                      <div className="flex justify-center">
                        <img
                          src={productImages[selectedImage]}
                          className="w-[100%] aspect-square rounded-2xl object-contain"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 overflow-x-auto mt-5 max-md:hidden">
                      {productImages.map((p, i) => {
                        return (
                          <img
                            src={p}
                            key={i}
                            className={`w-[20%] aspect-square rounded-lg border ${
                              selectedImage === i && " border-orange"
                            }`}
                            alt=""
                            onClick={() => setSelectedImage(i)}
                          />
                        );
                      })}
                    </div>
                    <div className="md:hidden mt-4 flex gap-2 justify-center items-center">
                      {productImages.map((v, i) => {
                        if (selectedImage === i)
                          return (
                            <div className="w-[37px] h-[8px] rounded-full bg-orange"></div>
                          );
                        return (
                          <div className="w-[8px] h-[8px] rounded-full bg-neutrals-400"></div>
                        );
                      })}
                    </div>
                  </div>
                  {/* Content */}
                  <div className="ml-4 flex flex-col lg:w-[385px]">
                    <div className="font-leagueSpartan-400 text-[24px] text-neutrals-900 my-4">
                      {name}
                    </div>
                    <div className="shadow-lg p-4 rounded-lg my-4">
                      <div className="max-sm:my-2 sm:mt-4 sm:mb-8 ">
                        <AccordinGifting
                          data={[
                            {
                              id: 1,
                              title: "Description",
                              description: description,
                              link: charity ? charity.link : undefined,
                              checked: true,
                            },
                          ]}
                        />
                      </div>
                      <button
                        className={`w-full p-2 rounded-lg text-white my-3 ${
                          isTesting ? "bg-neutrals-500" : "bg-orange"
                        }`}
                        disabled={isTesting}
                        onClick={() => {
                          dispatch(setProduct(product));
                          dispatch(setCharity(campaignCharity));
                          navigate.to(
                            navigate.corpId
                              ? "/gift-selection/:corpName/checkout"
                              : "/gift-selection/checkout",
                            undefined,
                            { product: product, voucher: voucher }
                          );
                        }}
                      >
                        {!charity && "CHOOSE OPTION"}
                        {charity && `Donate $${perPersonBudgetWithoutFee}`}
                      </button>
                    </div>

                    <button
                      className="flex items-center  text-orange font-leagueSpartan-500 text-[16px] my-4"
                      onClick={handleFullDetailClick}
                    >
                      View full details
                      <img src={images.rightArrow} className="mx-2" alt="" />
                    </button>
                  </div>
                </div>
                <div className="max-sm:hidden absolute right-[-6px] top-[-6px]">
                  <button
                    onClick={() => setModalVisible(false)}
                    className="self-start"
                  >
                    <img src={images.cross} className="w-[18.5px]" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProdutDetailsModal;
