import React from 'react';
import Header from "./Header";
import Sidebar from "./Sidebar";

type propType = {
  children: JSX.Element;
  title?: string;
  logo?: boolean;
  headerChildren?: JSX.Element;
};
const Main = ({ children, title, logo, headerChildren }: propType) => {
  return (
    <>
      <div className="min-w-full grid grid-cols-12">
        <div className="col-span-12 bg-neutrals-200">
          <Header title={title} logo={logo}>
            {headerChildren && headerChildren}
          </Header>
        </div>
        <div className="col-span-2 shadow-lg mt-6 min-h-screen">
          <Sidebar hidden={false} />
        </div>
        <div className="col-span-10 bg-neutrals-200 mt-0.5 min-h-screen">
          {children}
        </div>
      </div>
    </>
  );
};

export default Main;
