import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Divider from "../../components/common/Divider";
import Loading from "../../components/common/Loading";
import BackButtonGifting from "../../components/giftSelection/BackButton";
import CharityDetailsModalHamper from "../../components/giftSelectionHamper/CharityDetailsModalHamper";
import CheckoutFormHamper from "../../components/giftSelectionHamper/CheckoutFormHamper";
import HeaderHamper from "../../components/giftSelectionHamper/HeaderHamper";
import Api from "../../constants/api";
import images from "../../constants/images";
import {
  orderStatusEnum,
  orderTypeEnum,
} from "../../constants/order.constants";
import {
  decreaseCartItemQuantity,
  decreaseVoucherCartItemQuantity,
  increaseCartItemQuantity,
  increaseVoucherCartItemQuantity,
  removeCartItem,
  removeVoucherCartItem,
  setShippingMethod,
  setorderStatus,
} from "../../redux/cartSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setMultipleOrderData } from "../../redux/orderSlice";
import { getUsedCredits } from "../../utils/cartUtils";
import CustomNavigate from "../../utils/navigate";
import CheckouthamperCartItem from "./CheckoutHamperCartItem";
import { MIN_PRODUCT_CREDITS } from "./ChooseCategoryHamper";

const CheckoutHamper = () => {
  const cartState = useAppSelector((state) => state.cartState);
  const selectGiftState = useAppSelector((state) => state.selectGiftState);
  const corpState = useAppSelector((state) => state.corpSliceState);
  const orderState = useAppSelector((state) => state.orderState);

  const recipientId =
    useSearchParams()[0].get("recid") ||
    cartState.campaignRecipientData?.result.url_id;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const navigate = new CustomNavigate(useNavigate(), { recipientId });

  const cart = cartState.cart;
  const voucherCart = cartState.voucherCart;
  const totalCredits = cartState.totalCredits;
  const usedCredits = getUsedCredits(cart, cartState.voucherCart);
  const unusedCredits = totalCredits - usedCredits;
  let charities: GetCampaignRecipientCharity[] = [];
  if (cartState.campaignRecipientData)
    charities =
      cartState.campaignRecipientData?.result.campaign.campaignCharities.map(
        (index) => index.charity
      );
  const dispatch = useAppDispatch();

  const [selectedCharity, setSelectedCharity] = useState(-1);
  const [selectedShippingCheckBox, setSelectedShippingCheckBox] = useState<
    boolean | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [isVoucherOnly, setIsVoucherOnly] = useState(false);
  const [selectedModalCharity, setSelectedModalCharity] = useState(0);
  const [charityModalVisible, setCharityModalVisible] = useState(false);

  const campaignInfo = cartState?.campaignRecipientData?.result?.campaign;
  const orderAgreements =
    cartState?.campaignRecipientData?.orderAgreements || [];
  const onCheckBoxClick = useCallback(
    (shippingMethods: ShippingMethodTypes) => {
      if (shippingMethods === "Self Pick up") {
        setSelectedShippingCheckBox(false);
        dispatch(setShippingMethod(orderTypeEnum.PHYSICAL_PICKUP));
      } else if (shippingMethods === "Delivery") {
        setSelectedShippingCheckBox(true);
        dispatch(setShippingMethod(orderTypeEnum.PHYSICAL_DELIVER));
      } else {
        setSelectedShippingCheckBox(undefined);
      }
      dispatch(setorderStatus(orderStatusEnum.ORDER_RECEIVED));
    },
    [dispatch]
  );

  const createOrder = useCallback(
    (values: CreateOrderRecipientDetails) => {
      const { cart, shippingMethod, orderStatus, address } = cartState;
      if (cartState.campaignRecipientData?.result.isForTesting) {
        return;
      }

      const products: CreateOrderMultipleProduct[] = cart.map((index) => {
        return {
          productId: index.id,
          costInCredits: index.credits! * index.quantity,
          costInCurrency: index.costPrice * index.quantity,
          quantity: index.quantity,
        };
      });
      if (selectedCharity !== -1) {
        const charity = charities[selectedCharity];
        products.push({
          charityId: charity.id,
          costInCredits: unusedCredits,
          costInCurrency: unusedCredits,
          quantity: 1,
        });
      }
      const _address = {
        ...address,
        street: address.apartment,
        city: "",
        postalCode: address.postalCode,
      };
      const vouchers: CreateOrderMultipleVoucher[] = voucherCart.map(
        (voucher) => ({
          quantity: voucher.quantity,
          voucherId: voucher.id,
          voucherDenomination: voucher.denomination,
          currency: voucher.currency,
          costInCredits: voucher.credits * voucher.quantity,
          costInCurrency: voucher.denomination, // single voucher cost
        })
      );
      const data: CreateOrderMultiple & CreateOrderRecipientDetails = {
        products,
        currency: "SGD",
        shippingMethod: shippingMethod ?? "",
        orderType: shippingMethod,
        orderStatus: orderStatus!,
        url_id: recipientId!,
        ...values,
        vouchers: vouchers,
      };
      setIsLoading(true);
      Api.createMultipleOrder(data)
        .then((res) => {
          dispatch(setMultipleOrderData(res.data));
          setIsLoading(true);
          setTimeout(() => {
            navigate.to("/gift-selection/thank-you");
          }, 500);
        })
        .catch(() => {
          toast.error("Error occurred!");
          setIsLoading(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [cartState, charities, dispatch, recipientId, selectedCharity]
  );

  // if order found route to thankyou
  useEffect(() => {
    if (orderState.multipleData?.length) {
      navigate.to("/gift-selection/thank-you");
    }
  }, [corpState.data, orderState.multipleData]);

  useEffect(() => {
    if (cart.length + voucherCart.length === 0) {
      navigate.to("/hamper/gift-selection/choose-category");
    }
    setIsVoucherOnly(voucherCart.length > 0 && cart.length === 0);
  }, [cart, voucherCart, navigate]);

  const isDeactivated = useMemo(
    () => selectGiftState.campaignRecipientData?.result?.isForTesting ?? false,
    [selectGiftState.campaignRecipientData?.result?.isForTesting]
  );

  // TODO: remove this variable after cny
  const isForcedDelivery = true;
  useEffect(() => {
    // TODO: remove this effect after cny
    if (isForcedDelivery && !campaignInfo?.isSelfPickupOnly) {
      onCheckBoxClick("Delivery");
    }
  }, [campaignInfo?.isSelfPickupOnly, isForcedDelivery, onCheckBoxClick]);

  const location = useLocation();

  useEffect(() => {
    if (selectGiftState.campaignRecipientData?.result.giftClaimed) {
      navigate.to("/gift-selection/thank-you-notes");
    }
  }, [
    location,
    navigate,
    selectGiftState.campaignRecipientData?.result.giftClaimed,
  ]);

  return (
    <div className="relative">
      <HeaderHamper title="Checkout" isPreview={isDeactivated} />
      <div className="max-md:hidden m-6 mt-[100px]">
        <BackButtonGifting navigate={navigate} />
      </div>
      <div className="max-md:mt-[100px] max-md:pb-28 pb-24">
        <div className="grid lg:grid-cols-5 max-lg:grid-cols-1 md:gap-8 max-md:gap-4 md:mt-0 max-md:mt-0 md:mx-[10%] max-md:mx-5">
          {/* col 1 */}
          <div className="col-span-3 max-lg:col-span-3">
            {charities && charities.length > 0 && unusedCredits > 0 && (
              <>
                <div className="px-6 mb-4">
                  <div className="font-leagueSpartan-400 text-[24px] text-neutrals-900">
                    For your remaining {unusedCredits} credits, do you want to
                    donate to a charity?
                  </div>
                  <div className="font-leagueSpartan-400 text-[18px] text-neutrals-700">
                    1 credit is equivalent to SGD $1, and will be donated in
                    your name
                  </div>
                  {charities.map((val, index) => (
                    <div
                      className={`flex gap-2 p-3 py-3 my-6 shadow rounded-lg cursor-pointer ${
                        selectedCharity === index && "border border-orange"
                      }`}
                      onClick={() => setSelectedCharity(index)}
                    >
                      <img
                        src={
                          selectedCharity === index
                            ? images.radioActive
                            : images.radioInactive
                        }
                        className=""
                        alt=""
                      />
                      <img
                        src={val.pic1}
                        className=" ml-2 w-[80px] h-[80px]"
                        alt=""
                      />
                      <div className="grid grid-flow-row gap-2 text-sm ml-2">
                        <div className="font-leagueSpartan-400 text-[18px] text-neutrals-900">
                          {val.name}
                        </div>
                        <div
                          className="font-leagueSpartan-300 text-[18px] text-neutrals-700 overflow-hidden whitespace-nowrap h-6 text-ellipsis"
                          dangerouslySetInnerHTML={{ __html: val.description }}
                        ></div>
                        <div
                          className="flex gap-2 font-leagueSpartan-500 text-[16px] text-orange tracking-[0.16px]"
                          onClick={() => {
                            setSelectedModalCharity(index);
                            setCharityModalVisible(true);
                          }}
                        >
                          Learn more{" "}
                          <img
                            src={images.rightArrow}
                            className="h-[20px] aspect-square"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="p-6">
                  <Divider />
                </div>
              </>
            )}
            <div className="px-6  max-md:px-0">
              {!campaignInfo?.isSelfPickupOnly && !isForcedDelivery && (
                <>
                  <div className="m-2">
                    <h5 className="font-leagueSpartan-400 text-[24px] text-neutrals-900">
                      Choose one option
                    </h5>
                    <div className="mt-4">
                      <div
                        className="my-3 flex items-center cursor-pointer"
                        onClick={() => onCheckBoxClick("Self Pick up")}
                      >
                        <img
                          src={
                            selectedShippingCheckBox === false
                              ? images.radioActive
                              : images.radioInactive
                          }
                          className="h-[20px] min-w-[20px]"
                          alt=""
                        />
                        <div className="font-leagueSpartan-400 text-[16px] text-neutrals-900  mx-3">
                          Self Pick up
                        </div>
                      </div>

                      <div
                        className="my-3 flex items-center cursor-pointer"
                        onClick={() => onCheckBoxClick("Delivery")}
                      >
                        <img
                          src={
                            selectedShippingCheckBox === true
                              ? images.radioActive
                              : images.radioInactive
                          }
                          className="h-[20px] min-w-[20px]"
                          alt=""
                        />
                        <div className="font-leagueSpartan-400 text-[16px] text-neutrals-900  mx-3">
                          Delivery
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider />
                </>
              )}
              <>
                <div className={!campaignInfo?.isSelfPickupOnly ? "mt-8 " : ""}>
                  <CheckoutFormHamper
                    orderAgreements={orderAgreements}
                    isVoucherOnly={isVoucherOnly}
                    createOrder={createOrder}
                    isDelivery={selectedShippingCheckBox}
                    pickupAddress={
                      campaignInfo?.pickupAddress ||
                      "Smilie team will reach out to you separately to arrange for pick up."
                    }
                    isSelfPickupOnly={campaignInfo?.isSelfPickupOnly}
                  />
                </div>
              </>
            </div>
          </div>

          {/* col 2 */}
          <div className="col-span-2 max-lg:col-span-3">
            <div className="p-6  border rounded-xl">
              <div className="">
                <h4 className="font-leagueSpartan-400 text-[24px] text-neutrals-900">
                  Your Basket
                </h4>
              </div>

              {cart.map((cartProduct, index) => (
                <CheckouthamperCartItem
                  image={cartProduct.image_1}
                  name={cartProduct.name}
                  quantity={cartProduct.quantity}
                  credits={cartProduct.credits || MIN_PRODUCT_CREDITS}
                  handleDecrement={() =>
                    dispatch(
                      decreaseCartItemQuantity({
                        index: index,
                        item: cartProduct,
                      })
                    )
                  }
                  handleDelete={() => dispatch(removeCartItem(index))}
                  handleIncrement={() =>
                    dispatch(
                      increaseCartItemQuantity({
                        index: index,
                        item: cartProduct,
                      })
                    )
                  }
                />
              ))}

              {voucherCart.map((cartProduct, index) => (
                <CheckouthamperCartItem
                  image={cartProduct.image_1}
                  name={cartProduct.name}
                  quantity={cartProduct.quantity}
                  credits={cartProduct.credits || MIN_PRODUCT_CREDITS}
                  handleDecrement={() =>
                    dispatch(decreaseVoucherCartItemQuantity(index))
                  }
                  handleDelete={() => dispatch(removeVoucherCartItem(index))}
                  handleIncrement={() =>
                    dispatch(increaseVoucherCartItemQuantity(index))
                  }
                />
              ))}
              <div className="my-4">
                <Divider />
              </div>
              <div className="mt-8 text-neutrals-900 font-leagueSpartan-400">
                <div className="my-4 flex justify-between">
                  <div className="font-leagueSpartan-300 text-[16px] text-neutrals-800">
                    Total gift credits utilised
                  </div>{" "}
                  <div className="float-right font-leagueSpartan-400 text-[16px] text-neutrals-900">
                    {" "}
                    {usedCredits} credits
                  </div>
                </div>
                <div className="my-4 flex justify-between">
                  <div className="font-leagueSpartan-300 text-[16px] text-neutrals-800">
                    Outstanding gift credits
                  </div>
                  <div className="float-right font-leagueSpartan-400 text-[16px] text-neutrals-900">
                    {unusedCredits} credits
                  </div>
                </div>
                <div className="my-4 flex justify-between">
                  <div className="font-leagueSpartan-300 text-[16px] text-neutrals-800">
                    Shipping
                  </div>
                  <div className="float-right font-leagueSpartan-400 text-[16px] text-neutrals-900">
                    Free
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {charities &&
        selectedModalCharity !== undefined &&
        charityModalVisible && (
          <CharityDetailsModalHamper
            charity={charities[selectedModalCharity]}
            setModalVisible={setCharityModalVisible}
          />
        )}
      {isLoading && <Loading />}
    </div>
  );
};

export default CheckoutHamper;
