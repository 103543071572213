import React from "react";
import Main from "../../components/dashboard/Main";

const Billing = () => {
  return (
    <>
      <Main>
        <div></div>
      </Main>
    </>
  );
};

export default Billing;
