import classNames from "classnames";
import { HTMLProps, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Api from "../../constants/api";
import { updateBalance } from "../../redux/corpSlice";
import { setIsLoading } from "../../redux/globalSlice";
import { useAppSelector } from "../../redux/hooks";

interface IProps extends HTMLProps<HTMLDivElement> {
  recipientList: OrderRecipientPartial[];
  campaign: CampaignListData;
  onclickTopUpWallet: () => void;
  onConfim?: () => any;
  selectedRecipients: OrderRecipientPartial[];
}

const FooterAddRecipients = ({
  campaign,
  onclickTopUpWallet,
  onConfim,
  className,
  selectedRecipients = [],
}: IProps) => {
  const totalAmount = useMemo(() => {
    return selectedRecipients.length * campaign.perPersonBudget;
  }, [selectedRecipients, campaign]);

  const corp = useAppSelector((state) => state.corpSliceState);
  const balance = useMemo(() => corp.data?.balance || 0, [corp]);
  const corpId = corp.data?.id;
  const dispatch = useDispatch();

  const needsTopup = useMemo(
    () => balance < totalAmount,
    [balance, totalAmount]
  );
  const canConfirm = useMemo(() => !needsTopup, [needsTopup]);

  const onRefresh = useCallback(() => {
    if (corpId) {
      dispatch(setIsLoading(true));
      Api.getOneCorp(corpId)
        .then((res) => {
          dispatch(updateBalance(res.data.balance));
          toast.success("Balance refreshed!");
        })
        .catch((error) => {
          console.error(error);
          toast.error("There was an error refreshing your balance.");
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    } else {
      console.log("corpId is null/undefined");
    }
  }, [corpId, dispatch]);

  return (
    <div
      className={classNames(
        "fixed",
        "bottom-0",
        "w-screen",
        "flex",
        "items-center",
        "p-4",
        "bg-white",
        "border-2",
        "border-t-orange",
        className
      )}
    >
      {/* Left */}
      <div className="flex grow font-leagueSpartan-500 gap-2">
        <div>Total Cost:</div>
        <div> S$ {totalAmount} </div>
      </div>
      {/* Right */}
      <div className="flex gap-2">
        {/* Refresh */}
        {needsTopup && (
          <button
            className={classNames(
              "border",
              "border-orange",
              "text-orange",
              "font-leagueSpartan-500",
              "text-[16px]",
              "rounded-lg",
              "py-2",
              "px-3"
            )}
            onClick={onRefresh}
          >
            Refresh
          </button>
        )}
        {/* Top Up */}
        {needsTopup && (
          <button
            className={classNames(
              "text-white",
              "font-leagueSpartan-500",
              "text-[16px]",
              "rounded-lg",
              "py-2",
              "px-3",
              "bg-orange"
            )}
            onClick={onclickTopUpWallet}
          >
            Top up wallet
          </button>
        )}
        {/* Confirm & Pay */}
        {canConfirm && (
          <button
            className={classNames(
              "text-white",
              "font-leagueSpartan-500",
              "text-[16px]",
              "rounded-lg",
              "py-2",
              "px-3",
              "bg-orange",
              "disabled:bg-gray-400"
            )}
            onClick={onConfim}
          >
            Confirm & Pay
          </button>
        )}
      </div>
    </div>
  );
};

export default FooterAddRecipients;
