import moment from "moment";

export const getTriggerTimestamp = (
  birthDate: string,
  campaignTriggerTime: eventTriggreBeforeTypes,
  campaignStartTime: string
) => {
  let triggerTimeStamp;
  if (moment(birthDate).isValid()) {
    const concatinated = birthDate + "T" + campaignStartTime;
    let birthDateThisYear = moment(concatinated);

    // const now = new Date();
    // const _birthDateThisYear = new Date(birthDateThisYear);
    // if (_birthDateThisYear < now)
    //   birthDateThisYear = moment(_birthDateThisYear)
    //     .add(1, "year")
    //     .format("YYYY-MM-DD");

    if (campaignTriggerTime === "on occasion day")
      triggerTimeStamp = birthDateThisYear.toDate();
    else if (campaignTriggerTime === "1 day before")
      triggerTimeStamp = birthDateThisYear.subtract(1, "day").toDate();
    else if (campaignTriggerTime === "1 week before")
      triggerTimeStamp = birthDateThisYear.subtract(1, "week").toDate();
    else if (campaignTriggerTime === "1 month before")
      triggerTimeStamp = birthDateThisYear.subtract(1, "month").toDate();
  }

  return triggerTimeStamp;
};

export const getFilteredProducts = (
  collection: GetAllOccasionWithCollectionTCollection,
  finalSelectedAmount: number
) => {
  const filteredProducts = collection.collectionTags.filter((ct) => {
    return ct.product.costPrice <= finalSelectedAmount;
  });
  return filteredProducts;
};

export const CampaignOccasion = {
  NO_OCCASION: "No Occasion",
};
