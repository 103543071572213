import moment from "moment";
import { Tooltip } from "react-tooltip";

import { Dispatch, SetStateAction } from "react";
import { amountsDummyData } from "../../../constants/dummyData";
import images from "../../../constants/images";
import { TCampaignValue } from "../../../pages/dashboard/CampaignCreate";
import Divider from "../../common/Divider";

type propType = {
  setProgress: Dispatch<SetStateAction<number>>;
  selectedProduct: number;
  selectedMessageViaEmailCheckBox: boolean;
  selectedMessageViaWhatsappCheckBox: boolean;
  recipientGroups: GetAllRecipientGroupData[];
  selectedRecipientGroup: number;
  numberOfSelectedRecipient: any;
  amountWithoutFee: number;
  totalAmountWithoutFee: number;
  corpState: { fee: number };
  totalFeeAmount: number;
  totalAmount: number;
  value: TCampaignValue;
};

const ReviewProgress = ({
  setProgress,
  selectedProduct,
  selectedMessageViaEmailCheckBox,
  selectedMessageViaWhatsappCheckBox,
  recipientGroups,
  selectedRecipientGroup,
  numberOfSelectedRecipient,
  amountWithoutFee,
  totalAmountWithoutFee,
  corpState,
  totalFeeAmount,
  totalAmount,
  value,
}: propType) => {
  const {
    campaignName,
    occasionList,
    campaignType,
    otherAmount,
    selectedAmount,
    collectionList,
    selectedOccasion,
    campaignTriggerTime,
    campaignStartDate,
    campaignStartTime,
    recipientMessageTitle,
    recipientMessage,
    recipientMessageFrom,
    recipientMessageFooter,
    selectedGreetingCard,
    recipientCustomField,
    isRecurring,
    recipientCustomFieldTriggerTime,
    recipientStartDate,
    recipientStartTime,
    isTouchpointEnabled,
    isReferralEnabled,
    selectedThemeName,
  } = value;
  return (
    <div className="mt-20 mx-auto w-[80%] mb-28">
      <div className="grid grid-cols-5">
        {/* Col 1 */}
        <div className="col-span-3 p-4">
          <div className="border rounded-xl p-8">
            <div className="flex justify-between">
              <div className="font-leagueSpartan-500 text-[28px] text-neutrals-800">
                1. Campaign Details
              </div>
              <button
                className="flex gap-4 border border-orange text-orange font-leagueSpartan-500 text-[15px] p-2 px-4 rounded-lg"
                onClick={() => setProgress(1)}
              >
                Edit <img src={images.edit} alt="" />
              </button>
            </div>
            <div className="mt-4 font-leagueSpartan-300 text-[18px] text-neutrals-700">
              Campaign Title
            </div>
            <div className="mt-[0px] font-leagueSpartan-500 text-[18px] text-neutrals-800">
              {campaignName}
            </div>

            <div className="mt-4 font-leagueSpartan-300 text-[18px] text-neutrals-700">
              Gift Type
            </div>
            <div className="mt-[0px] font-leagueSpartan-500 text-[18px] text-neutrals-800">
              {campaignType}
            </div>
            <div className="mt-4 font-leagueSpartan-300 text-[18px] text-neutrals-700">
              {" "}
              Budget per recipient
            </div>
            <div className="mt-[0px] font-leagueSpartan-500 text-[18px] text-neutrals-800">
              {otherAmount === ""
                ? amountsDummyData[selectedAmount]?.str
                : "$" + otherAmount}
            </div>
            <div className="mt-4 font-leagueSpartan-300 text-[18px] text-neutrals-700">
              Theme
            </div>
            <div className="mt-[0px] font-leagueSpartan-500 text-[18px] text-neutrals-800">
              {selectedThemeName}
            </div>
            {isTouchpointEnabled && (
              <>
                <div className="mt-4 font-leagueSpartan-300 text-[18px] text-neutrals-700">
                  Engagement
                </div>
                <div className="mt-[0px] font-leagueSpartan-500 text-[18px] text-neutrals-800">
                  Enabled
                </div>
              </>
            )}
            {isReferralEnabled && (
              <>
                <div className="mt-4 font-leagueSpartan-300 text-[18px] text-neutrals-700">
                  Referral
                </div>
                <div className="mt-[0px] font-leagueSpartan-500 text-[18px] text-neutrals-800">
                  Enabled
                </div>
              </>
            )}
          </div>
          <div className="border rounded-xl p-8 mt-6">
            <div className="flex justify-between">
              <div className="font-leagueSpartan-500 text-[28px] text-neutrals-800">
                2. Greeting Card
              </div>
              <button
                className="flex gap-4 border border-orange text-orange font-leagueSpartan-500 text-[15px] p-2 px-4 rounded-lg"
                onClick={() => setProgress(2)}
              >
                Edit <img src={images.edit} alt="" />
              </button>
            </div>
            <div className="mt-4 font-leagueSpartan-300 text-[18px] text-neutrals-700">
              Customise your message to your recipient(s)
            </div>
            <div className="border rounded-lg bg-neutrals-200 text-neutrals-700 mt-4 p-4 text-[16px]">
              {recipientMessageTitle} {"<Recipient Name>"}, <br />
              <br />
              {recipientMessage}
              <br />
              <br />
              <p>{recipientMessageFooter}</p>
              <br />
              <span className="whitespace-pre-line">
                {recipientMessageFrom}
              </span>
            </div>

            <div className="mt-4 font-leagueSpartan-300 text-[18px] text-neutrals-700">
              Send message via
            </div>
            <div className="mt-[0px] font-leagueSpartan-500 text-[18px] text-neutrals-800">
              {selectedMessageViaEmailCheckBox && "Email"}
              {selectedMessageViaWhatsappCheckBox &&
                (selectedMessageViaEmailCheckBox ? ", Whatsapp" : "Whatsapp")}
            </div>
          </div>

          <div className="border rounded-xl p-8 mt-6">
            <div className="flex justify-between">
              <div className="font-leagueSpartan-500 text-[28px] text-neutrals-800">
                3. Recipients
              </div>
              <button
                className="flex gap-4 border border-orange text-orange font-leagueSpartan-500 text-[15px] p-2 px-4 rounded-lg"
                onClick={() => setProgress(3)}
              >
                Edit <img src={images.edit} alt="" />
              </button>
            </div>
            {selectedGreetingCard === "SelectGroup" &&
              recipientGroups[selectedRecipientGroup] && (
                <>
                  <div className="mt-4 font-leagueSpartan-300 text-[18px] text-neutrals-700">
                    Recipient List
                  </div>
                  <div className="mt-[0px] font-leagueSpartan-500 text-[18px] text-neutrals-800">
                    Recipient Group:{" "}
                    {recipientGroups[selectedRecipientGroup].name}
                  </div>
                </>
              )}
            <div className="mt-4 font-leagueSpartan-300 text-[18px] text-neutrals-700">
              Number of Recipients
            </div>
            <div className="mt-[0px] font-leagueSpartan-500 text-[18px] text-neutrals-800">
              {numberOfSelectedRecipient}
            </div>
            <div className="mt-4 font-leagueSpartan-300 text-[18px] text-neutrals-700">
              Campaign Duration & Notification
            </div>
            <div className="mt-[0px] font-leagueSpartan-500 text-[18px] text-neutrals-800">
              {recipientCustomField
                ? `Send trigger to recipients on ${recipientCustomField}`
                : recipientStartDate && recipientStartTime
                ? `Send trigger to recipients on ${recipientStartDate} - ${recipientStartTime}`
                : `No email sent`}{" "}
              <br /> <br />
              {campaignStartDate && (
                <>
                  Campaign start:
                  {" " + moment(campaignStartDate).format("DD MMM YYYY")}
                  {campaignStartTime && `, ${campaignStartTime}`}
                </>
              )}
            </div>
          </div>
        </div>

        {/* Col 2 */}
        <div className="col-span-2 p-4">
          <div className="p-4">
            <p className="text-xl font-leagueSpartan-500 text-neutrals-800">
              Payment Summary
            </p>
            <div className="flex justify-between my-2 mt-4">
              {" "}
              <div className="text-neutrals-800 font-leagueSpartan-400 flex">
                Max gift cost
                <div className="ml-1">
                  S${amountWithoutFee} x {numberOfSelectedRecipient} recipients
                </div>
              </div>
              <div className="text-neutrals-900 font-leagueSpartan-500">
                {" "}
                S${totalAmountWithoutFee}
              </div>
            </div>
            <div className="flex justify-between my-2">
              {" "}
              <div className="text-neutrals-800 font-leagueSpartan-400 flex whitespace-nowrap">
                Smilie platform fee
                <img
                  src={images.questionMark}
                  alt=""
                  className="ml-2"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={`Smilie platform fee is ${corpState.fee}% of the Gift Budget. It helps us to run the platform!`}
                />
                <Tooltip id="my-tooltip" />
              </div>
              <div> S${totalFeeAmount}</div>
            </div>
            <div className="my-3">
              <Divider />
            </div>
            <div className="flex justify-between text-neutrals-800 font-leagueSpartan-500 text-lg">
              {" "}
              <div>Total Cost </div>
              <div> S${totalAmount} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewProgress;
