import { HTMLProps } from "react";
import { TCampaignValue } from "../../pages/dashboard/CampaignCreate";

type IProps = HTMLProps<HTMLDivElement> & {
  values: TCampaignValue;
  updateValues: (
    value: string | boolean | Date,
    key: keyof TCampaignValue
  ) => void;
};

const RecipientSchedule = ({ values, updateValues, className }: IProps) => {
  return (
    <div className={`${className} flex flex-col`}>
      <div className="flex items-center">
        <span className="mr-[72px]">
          {/* <input
            type="radio"
            name="toAllRecipients"
            className="mr-1"
            checked={values.toAllRecipients}
            onChange={(e) => {
              const v = e.target.checked;
              updateValues(v, "toAllRecipients");
            }}
          /> */}
          To all recipients on
        </span>
        <input
          type="date"
          value={values.recipientStartDate}
          className="w-[330px] border border-neutral-300 font-leagueSpartan-400  mt-2 rounded-lg margin"
          onChange={(e) => {
            updateValues(e.target.value, "recipientStartDate");
          }}
          placeholder="Select date"
        />
        {/* The time component will be added later */}
        <input
          type="time"
          className="w-[330px] border border-neutral-300 font-leagueSpartan-400  mt-2 ml-2 rounded-lg"
          value={values.recipientStartTime}
          onChange={(e) => {
            const v = e.target.value;
            updateValues(v, "recipientStartTime");
          }}
        />
      </div>
    </div>
  );
};

export default RecipientSchedule;
