import classNames from "classnames";
import React, { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import images from "../../constants/images";
import { MIN_PRODUCT_CREDITS } from "../../pages/giftSelectionHamper/ChooseCategoryHamper";
import {
  decreaseCartItemQuantity,
  decreaseVoucherCartItemQuantity,
  increaseCartItemQuantity,
  increaseVoucherCartItemQuantity,
  removeCartItem,
  removeVoucherCartItem,
} from "../../redux/cartSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUsedCredits } from "../../utils/cartUtils";
import CustomNavigate from "../../utils/navigate";
import Divider from "../common/Divider";
import { CartItem } from "./CartItem";
import ProgressbarHamper from "./ProgressbarHamper";

type propType = {
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const CartDrawerHamper = (props: propType) => {
  const { setDrawerVisible } = props;

  const cartState = useAppSelector((state) => state.cartState);
  const cart = cartState.cart;
  const totalCredits = cartState.totalCredits;
  const voucherCart = cartState.voucherCart;
  const usedCredits = getUsedCredits(cart, voucherCart);
  const unusedCredits = totalCredits - usedCredits;
  const dispatch = useAppDispatch();

  const recipientId =
    useSearchParams()[0].get("recid") ||
    cartState.campaignRecipientData?.result.url_id;
  const navigate = new CustomNavigate(useNavigate(), { recipientId });

  const percentRemaining = (usedCredits * 100) / totalCredits;

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setDrawerVisible(false);
      }
    },
    [setDrawerVisible]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, false);
    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [handleKeyPress]);

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity cursor-pointer"
        onClick={() => setDrawerVisible(false)}
      ></div>

      <div className="fixed inset-0 z-10">
        <div className="fixed top-0 right-0 w-full p-4 px-6 z-30 bg-white max-md:w-[90%] md:w-[35%] flex justify-between">
          <div className="font-leagueSpartan-600 text-[32px] text-neutrals-900">
            Your Basket
          </div>
          <button
            onClick={() => setDrawerVisible(false)}
            className="self-start h-7"
          >
            <img src={images.cross} className="w-5" alt="" />
          </button>
        </div>
        <div className="overflow-auto flex min-h-full items-center justify-end  text-center sm:items-center sm:p-0">
          <div className="relative transform bg-white text-left shadow-xl transition-all h-screen max-md:w-[90%] md:w-[35%] ">
            <div className=" bg-white px-0 pb-[260px] pt-[65px]  sm:p-1 sm:pb-[260px] sm:pt-[65px]">
              <div className="my-4">
                <Divider />
              </div>
              <div>
                {cart.map((item, index) => (
                  <CartItem
                    id={item.id}
                    key={item.id}
                    name={item.name}
                    image={item.image_1}
                    quantity={item.quantity}
                    index={index}
                    credits={
                      (item.credits || MIN_PRODUCT_CREDITS) * item.quantity
                    }
                    handleDecrement={(index: number) =>
                      dispatch(
                        decreaseCartItemQuantity({
                          index: index,
                          item: item,
                        })
                      )
                    }
                    handleIncrement={(index: number) =>
                      dispatch(
                        increaseCartItemQuantity({ index: index, item: item })
                      )
                    }
                    handleDelete={(index: number) =>
                      dispatch(removeCartItem(index))
                    }
                  />
                ))}
                {voucherCart.map((item, index) => (
                  <CartItem
                    id={item.id}
                    key={item.id}
                    name={`${item.name} $${item.denomination}`}
                    image={item?.image_1}
                    quantity={item.quantity}
                    index={index}
                    credits={
                      (item.credits || MIN_PRODUCT_CREDITS) * item.quantity
                    }
                    handleDecrement={(index: number) =>
                      dispatch(decreaseVoucherCartItemQuantity(index))
                    }
                    handleIncrement={(index: number) =>
                      dispatch(increaseVoucherCartItemQuantity(index))
                    }
                    handleDelete={(index: number) =>
                      dispatch(removeVoucherCartItem(index))
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="fixed bottom-0 right-0 w-full border-t-2 border-lightOrange p-4 px-6 z-30 bg-white max-md:w-[90%] md:w-[35%]">
          <div className="flex justify-between my-3 font-leagueSpartan-400 text-[20px] text-neutrals-900">
            <div>Total</div>
            <div>{usedCredits} credits</div>
          </div>
          <div className="flex justify-end font-leagueSpartan-400 text-[14px] text-neutrals-800 tracking-[0.56px]">
            {usedCredits < totalCredits
              ? `${unusedCredits} / ${totalCredits} credits unused`
              : "All credits used!"}
          </div>
          <div className="mt-2">
            <ProgressbarHamper progress={percentRemaining} />
          </div>
          <div className="my-4">
            <Divider />
          </div>
          <button
            className={classNames(
              "bg-orange",
              "disabled:bg-gray-400",
              "text-white",
              "rounded-lg",
              "font-leagueSpartan-500",
              "text-[16px]",
              "tracking-[0.16px]",
              "text-sm",
              "p-3",
              "w-full",
              "my-4"
            )}
            onClick={() => navigate.to("/hamper/gift-selection/checkout")}
            disabled={!cart && !voucherCart}
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartDrawerHamper;
