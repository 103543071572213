import { useState } from "react";
import { useLocation } from "react-router-dom";
import images from "../../constants/images";

export const CampaignDetailThanknotes = () => {
  const campaign = useLocation().state as CampaignListData;
  const CampaignRecipentThankNotes = campaign.recipients;
  const [campaignthanknotes, setcampaignThankNotes] = useState(
    CampaignRecipentThankNotes
  );

  return (
    <div className="w-full min-h-[70vh] mx-[30px] p-4 bg-[#EDF3FB] rounded-2xl shadow">
      {" "}
      {campaignthanknotes.map((val, index) => {
        if (val.thankyouNote == null || "") {
          return <></>;
        }
        const recipientAvatar = val.name
          .split(" ")
          .map((v) => v[0].toUpperCase())
          .filter((v, i) => i < 2);
        return (
          <div
            key={index}
            className="relative h-[86px] px-4 py-2 bg-white rounded-xl flex-col mt-5 mx-5  gap-2 inline-flex "
          >
            <img
              src={images.thankyouNoteCone}
              className="absolute -left-4 h-[22.73px] bottom-1"
              alt=""
            />
            <div className="text-zinc-800 text-base font-normal leading-relaxed font-leagueSpartan-300">
              {val.thankyouNote}
            </div>
            <div className="justify-start items-center gap-1.5 inline-flex">
              <div className="w-9 h-9 p-2 bg-rose-100 rounded-[100px] justify-center items-center gap-2.5 flex">
                <div className="w-4 h-4 text-center text-orange-500 text-sm font-normal font-leagueSpartan-600">
                  {recipientAvatar}
                </div>
              </div>
              <div>
                <span className="text-neutral-600 text-sm font-normal font-leagueSpartan-600">
                  {val.name}{" "}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
