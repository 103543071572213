import { useEffect, useState } from "react";
import { ActivateVoucherModal } from "../../components/Modal/ActivateVoucherModal";
import Accordin from "../../components/common/Accordin";
import { Voucher } from "../../types/vouchers";

type propsType = {
  dataForAccordin: {
    title: string;
    value: string;
  }[];
  voucherOrder: VoucherOrder;
  email: string;
  voucher: Voucher;
};
const UnclaimedVoucherThankYouNotes = (props: propsType) => {
  const { dataForAccordin, voucherOrder, email } = props;
  const [showActivateVoucherModal, setShowActivateVoucherModal] =
    useState(false);
  const handleSuccessDone = () => {
    setShowActivateVoucherModal(false);
  };
  const [isActivated, setIsActivated] = useState(false);

  return (
    <>
      {dataForAccordin.map((i, index) => (
        <Accordin
          data={[
            {
              title: i.title,
              description: i.value,
              id: index,
              checked: false,
            },
          ]}
          key={index}
        />
      ))}

      <div className="my-2 p-4 text-neutrals-900 text-[16px]">
        Please activate this voucher only at point of use to minimise risk of
        voucher expiry before use.
      </div>
      {/* ACTIVATE BUTTON */}
      {!isActivated && (
        <div className="flex justify-center">
          <button
            className="ml-2 bg-orange text-white font-leagueSpartan-500 text-[16px] rounded-lg p-2 px-3 w-[212px] h-[44px]"
            onClick={() => setShowActivateVoucherModal(true)}
          >
            Activate Voucher
          </button>
        </div>
      )}
      {showActivateVoucherModal && (
        <ActivateVoucherModal
          setShowActivateVoucherModal={setShowActivateVoucherModal}
          voucherOrders={[voucherOrder]}
          onSuccessDone={handleSuccessDone}
          email={email}
          setIsActivated={setIsActivated}
        />
      )}
    </>
  );
};
export default UnclaimedVoucherThankYouNotes;
