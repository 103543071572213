import { HTMLProps } from "react";
import images from "../../constants/images";
import { TCampaignValue } from "../../pages/dashboard/CampaignCreate";

interface IProps extends HTMLProps<HTMLDivElement> {
  values: TCampaignValue;
  updateValues: (value: string | boolean, key: keyof TCampaignValue) => void;
}

const GiftCollection = ({ values, updateValues, className }: IProps) => {
  return (
    <div className={className}>
      <p className="font-leagueSpartan-500 text-[28px] text-neutrals-900 mb-6">
        Gift Collection
      </p>
      <div
        className="self-center flex cursor-pointer"
        onClick={() =>
          updateValues(!values.isSelfPickupOnly, "isSelfPickupOnly")
        }
      >
        <img
          src={
            values.isSelfPickupOnly
              ? images.checkBoxSelected
              : images.checkBoxInactive
          }
          className="h-[20px] min-w-[20px]"
          alt=""
        />
        <div className="ml-2 font-leagueSpartan-400 text-[18px] text-neutrals-900">
          Self collect at specified address
        </div>
      </div>
      {values.isSelfPickupOnly && (
        <>
          <div className="flex flex-col mt-4">
            <label
              htmlFor=""
              className="font-leagueSpartan-400 text-[18px] text-neutrals-900"
            >
              Pick up address:
            </label>
            <div className="flex">
              <textarea
                className={
                  "w-[700px] border border-neutral-300 font-leagueSpartan-400  mt-2 rounded-lg"
                }
                placeholder="Type here ..."
                value={values.pickupAddress}
                onChange={(e) => {
                  const v = e.target.value;
                  updateValues(v, "pickupAddress");
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GiftCollection;
