import React, { useCallback, useState } from "react";
import images from "../../constants/images";
import Api from "../../constants/api";
import { useAppDispatch } from "../../redux/hooks";
import { setIsLoading } from "../../redux/globalSlice";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
type propType = {
  setModel: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function ForgotPasswordModel(props: propType) {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);

  const { setModel } = props;

  const resetPassword = useCallback(() => {
    if (email !== "" && !emailInvalid) {
      dispatch(setIsLoading(true));
      Api.resetPassword(email)
        .then(() => {
          dispatch(setIsLoading(false));
          toast.success("Reset password link sent.");
          setModel(false);
        })
        .catch((err) => {
          if (isAxiosError(err)) {
            dispatch(setIsLoading(false));
            toast.error(err.response?.data.message);
          }
        });
    }
  }, [email, emailInvalid]);

  return (
    <>
      <div className="inset-0 fixed bg-[#381B1AE5] flex justify-center items-center">
        <div>
          <div className="relative w-[596px] h-[380px] px-10 shadow flex-col rounded-xl   bg-white">
            <button
              className="absolute top-2 end-4 text-black text-xl mt-2"
              onClick={() => setModel(false)}
            >
              <img src={images.Close}></img>
            </button>

            <div className="flex flex-col gap-2 p-12">
              <div className="flex justify-center ">
                <img src={images.logo} className=" w-15 h-15 mt-5 " alt="" />
              </div>
              <div className="text-center text-neutrals-900 text[20px] mt-2  font-leagueSpartan-500">
                Enter your email address to reset your password
              </div>
              <label className="mx-1 mt-5 font-leagueSpartan-400 text-[16px]">
                Email Address
              </label>
              <div className="flex justify-center">
                <input
                  type="text"
                  className={`w-[100%] border border-neutral-200 rounded-md mt-0 placeholder:text-neutrals-600 ${
                    emailInvalid ? "border-l border-red-500" : ""
                  }`}
                  placeholder="Enter your email address"
                  onChange={(e) => {
                    const v = e.target.value;
                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(v)) {
                      setEmailInvalid(true);
                    } else {
                      setEmailInvalid(false);
                    }
                    console.log(emailInvalid);
                    setEmail(v);
                  }}
                />
              </div>
              <div className="flex justify-center">
                <button
                  className="p-2.5 mt-5  bg-orange rounded-lg text-white font-leagueSpartan-500 text-[16px]"
                  style={{ width: "100%" }}
                  onClick={() => resetPassword()}
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
