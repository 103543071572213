import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { getUsedCredits } from "../../utils/cartUtils";
import CustomNavigate from "../../utils/navigate";
import { CREDITS_PER_DOLLAR } from "./ChooseCategoryHamper";

type propType = {
  productCredit: number;
  creditsAvailable: number;
  addToCartHandler: (quantity: number, denomination: number) => void;
  denominations: number[];
};

export const HamperDenominationQuantity = ({
  productCredit,
  creditsAvailable,
  addToCartHandler,
  denominations,
}: propType) => {
  const [quantity, setQuantity] = useState(1);
  const insuffecientCredits = creditsAvailable < productCredit;

  const cartState = useAppSelector((state) => state.cartState);
  const totalCredits = cartState.totalCredits;
  const [selectedDenomination, setSelectedDenomination] = useState(
    denominations[0]
  );

  const handleIncreaseQuantity = () => {
    const credit = Math.ceil(selectedDenomination / 5);
    const usedCredits =
      getUsedCredits(cartState.cart, cartState.voucherCart) +
      credit * (quantity + 1);
    if (usedCredits > totalCredits) {
      return;
    }
    setQuantity((quantity) => quantity + 1);
  };

  const recipientId = useSearchParams()[0].get("recid");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const navigate = new CustomNavigate(useNavigate(), { recipientId });
  useEffect(() => {
    if (denominations.length === 0) {
      navigate.to("/hamper/gift-selection/choose-category");
    }
  }, [denominations, navigate]);
  return (
    <div className="shadow-md p-4 rounded-lg my-4">
      <div className="text-orange text-lg font-leagueSpartan-600 my-3">
        {Math.ceil(selectedDenomination / CREDITS_PER_DOLLAR)} Credit
      </div>
      <div className="grid grid-cols-6 my-3">
        <div className="col-span-3">
          <div className="col-span-3 font-leagueSpartan-400">Value ($)</div>
          <div
            className={`col-span-2 grid grid-cols-1 border rounded-lg mr-2 justify-items-center ${
              insuffecientCredits &&
              "bg-neutrals-300 text-neutrals-600 text-[18px]"
            }`}
          >
            <select
              className="w-full p-1 col-span-3 border rounded-lg justify-items-center"
              onChange={(e) => setSelectedDenomination(Number(e.target.value))}
            >
              {denominations.map((denomination, index) => (
                <option key={index} value={denomination}>
                  {denomination}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-span-3">
          <div className="col-span-3 font-leagueSpartan-400">Quantity</div>
          <div
            className={`col-span-2 grid grid-cols-3 border rounded-lg justify-items-center ${
              insuffecientCredits &&
              "bg-neutrals-300 text-neutrals-600 text-[18px]"
            }`}
          >
            <button
              className="p-1"
              onClick={() =>
                setQuantity((previousQuantity) =>
                  Math.max(0, previousQuantity - 1)
                )
              }
              disabled={insuffecientCredits}
            >
              -
            </button>
            <button
              className="p-1 font-leagueSpartan-400 "
              disabled={insuffecientCredits}
            >
              {quantity}
            </button>
            <button
              className="p-1"
              onClick={handleIncreaseQuantity}
              disabled={insuffecientCredits}
            >
              +
            </button>
          </div>
        </div>
      </div>
      <button
        className={`w-full p-2  rounded-lg text-white font-leagueSpartan-400 my-3 ${
          insuffecientCredits ? "bg-neutrals-500" : "bg-orange"
        }`}
        onClick={() => {
          addToCartHandler(quantity, selectedDenomination);
        }}
        disabled={insuffecientCredits}
      >
        {insuffecientCredits ? "Insufficient productCredit" : "Add to basket"}
      </button>
    </div>
  );
};
