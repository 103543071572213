import React, { useCallback, useState } from "react";
import images from "../../constants/images";
import { toast } from "react-toastify";
import Api from "../../constants/api";
import CustomNavigate from "../../utils/navigate";
import { useNavigate } from "react-router-dom";

type propType = {
  setmodel: React.Dispatch<React.SetStateAction<boolean>>;
  email?: string;
};

export default function VerifyEmailModel(props: propType) {
  const { setmodel, email } = props;

  const navigate = new CustomNavigate(useNavigate());

  return (
    <>
      <div className="inset-0 fixed bg-[#381B1AE5] flex justify-center items-center">
        <div>
          <div className="sm:w-[200px] md:w-[596px] min-h-[380px] shadow flex-col rounded-xl   bg-white">
            <div className="flex justify-end mx-3  ">
              <div className="w-6 h-6 " />{" "}
              <button
                className="text-black text-xl mt-2"
                onClick={() => setmodel(false)}
              >
                <img src={images.Close} />
              </button>
            </div>
            <div className="flex flex-col gap-2 p-7 px-14 text-center">
              <div className="flex justify-center ">
                <img src={images.logo} className=" w-15 h-15 mt-5 " alt="" />
              </div>
              <div className="font-leagueSpartan-500 text-[24px] mt-10">
                Verify Your Email Address
              </div>
              <div className="font-leagueSpartan-400 text-md mt-5">
                A verification email has been sent to{" "}
                {email ? email : "your account"}. Please check your inbox to
                verify.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
