import { useCallback, useEffect, useState } from "react";
import Api from "../../../constants/api";
import { isAxiosError } from "axios";
import { ErrorMessage } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

export const CreateCorp = (props: any) => {
  const navigate = useNavigate();
  const successMessage = () => toast("Success!");
  const errorMessage = (error?: string) => toast.error(error || "error!");
  const [corpData, setCorpData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    organisationName: "",
    phoneNumber: "",
    logo: "",
    referralId: "",
    employeeRange: "",
    template: "",
    govId: "",
    govIdType: "",
    taxId: "",
    taxNumber: "",
    balance: "",
    oldBalance: "",
    creditConversationRatio: "",
  });

  const [customPlan, setCustomPlan] = useState({
    defaultPlanId: "",
    name: "",
    description: "",
    isActive: true,
    isAnnual: true,
    listPrice: "",
    discount: "",
    discountAb: "",
    actualPrice: "",
    renualPrice: "",
    deliveryCharge: "",
  });
  /**
   * Handle custom plan change
   */

  const handleCustomPlanChange = (e: any) => {
    const { name, value } = e.target;
    setCustomPlan((prev) => {
      return { ...prev, [name]: value };
    });
  };

  /**
   * Handle input fileds other than address
   */
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setCorpData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  /**
   * Code to add Multiple address
   */
  const [singleAddress, setSingleAddress] = useState({
    street: "",
    address: "",
    city: "",
    postalCode: "",
  });
  const [addresses, setAddressess] = useState([
    {
      street: "",
      address: "",
      city: "",
      postalCode: "",
    },
  ]);

  /**
   * Add Address inputs
   */
  const handleAddMore = () => {
    setAddressess((prev) => {
      return [...prev, singleAddress];
    });
  };

  /**
   * Delete address inputs
   */
  const handleDelete = (index: number) => {
    if (addresses.length === 1) {
      alert("cannot delete one");
      return;
    }
    setAddressess((prev) => {
      return prev.filter((item, i) => {
        return i !== index;
      });
    });
  };
  console.log(addresses, "address");
  /**
   * handle Adress change
   */

  const handleChange = (e: any, index: number) => {
    const { name, value } = e.target;
    let arr = [...addresses]; //not directly assinging-render issue as arrays and objects pass by reference.
    let obj = arr[index];
    let newObj = { ...obj, [name]: value };
    arr[index] = { ...newObj };
    console.log(arr, "arr");
    setAddressess(arr);
  };

  /**
   * Updating corpdata;
   */

  const handleUpdateCorpData = () => {
    Api.updateCorp(props.updateData.id, corpData, addresses, customPlan)
      .then((res) => {
        if (isAxiosError(res))
          errorMessage(res.response?.data.message || "Invalid data!");
        else {
          successMessage();
          navigate("/admin", { state: { tab: props?.tabNumber, subtab: 1 } });
        }
      })
      .catch((err) => {
        console.log(err);
        errorMessage();
      });
  };
  console.log(props, "props");
  /**
   * handle Cancel update
   */
  const handleCancelUpdate = () => {
    setCorpData({
      firstName: "",
      middleName: "",
      lastName: "",
      organisationName: "",
      phoneNumber: "",
      logo: "",
      referralId: "",
      template: "",
      employeeRange: "",
      govId: "",
      govIdType: "",
      taxId: "",
      taxNumber: "",
      balance: "",
      oldBalance: "",
      creditConversationRatio: "",
    });
    setAddressess([
      {
        street: "",
        address: "",
        city: "",
        postalCode: "",
      },
    ]);
    setCustomPlan({
      defaultPlanId: "",
      name: "",
      description: "",
      isActive: true,
      isAnnual: true,
      listPrice: "",
      discount: "",
      discountAb: "",
      actualPrice: "",
      renualPrice: "",
      deliveryCharge: "",
    });
    navigate("/admin", { state: { tab: props?.tabNumber, subtab: 1 } });
  };

  /**
   * Use Effect
   */
  useEffect(() => {
    console.log(props, "locsdfasfdsadfasdfs");
    if (props.updateData && props.tabNumber === 6) {
      const data = props?.updateData;
      const customPlanData = props?.updateData.customPlans[0];
      setCorpData({
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        organisationName: data.organisationName,
        phoneNumber: data.phoneNumber,
        logo: data.logo,
        referralId: data.logo,
        template: data.template,
        employeeRange: data.employeeRange,
        govId: data.govId,
        govIdType: data.govIdType,
        taxId: data.taxId,
        taxNumber: data.taxNumber,
        balance: data.balance,
        oldBalance: data.oldBalance,
        creditConversationRatio: data.creditConversationRatio,
      });
      setCustomPlan({
        defaultPlanId: customPlanData.defaultPlan.id,
        name: customPlanData.name,
        description: customPlanData.description,
        isActive: true,
        isAnnual: true,
        listPrice: customPlanData.listPrice,
        discount: customPlanData.discount,
        discountAb: customPlanData.discountAb,
        actualPrice: customPlan.actualPrice,
        renualPrice: customPlan.renualPrice,
        deliveryCharge: customPlan.deliveryCharge,
      });
      setAddressess(data.addresses);
      // setAddressess(data?.singleCorp.addresses)
    }
  }, [props]);

  console.log(corpData, "corpdata");
  return (
    <>
      <form
        onSubmit={(e) => {
          if (
            !corpData.firstName ||
            !corpData.middleName ||
            !corpData.lastName
          ) {
            corpData.firstName = corpData.phoneNumber;
          } else if (!corpData.organisationName) {
            corpData.organisationName = corpData.phoneNumber;
          }
          Api.createCorp(e, corpData, addresses, customPlan)
            .then((res) => {
              if (isAxiosError(res))
                errorMessage(res?.response?.data.message || "Invalid data!");
              else successMessage();
            })
            .catch((err) => {
              console.log(err);
              errorMessage();
            });
        }}
        className="m-4 grid grid-flow-row max-w-md"
      >
        <div className="text-lg text-orange">Corp</div>
        <input
          type="text"
          name="firstName"
          placeholder="firstName"
          onChange={handleInputChange}
          value={corpData?.firstName}
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="middleName"
          placeholder="middleName"
          onChange={handleInputChange}
          value={corpData?.middleName}
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="lastName"
          placeholder="lastName"
          onChange={handleInputChange}
          value={corpData?.lastName}
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="organisationName"
          placeholder="organisationName"
          onChange={handleInputChange}
          value={corpData?.organisationName}
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="phoneNumber"
          placeholder="phone Number"
          onChange={handleInputChange}
          value={corpData?.phoneNumber}
          className="border rounded-lg my-4"
        />
        <div className="border rounded-lg p-2 bg-lightOrange">
          {addresses?.map((item, index) => {
            return (
              <>
                <h1> Address {index + 1}</h1>
                <input
                  type="text"
                  name="street"
                  placeholder="street name"
                  onChange={(e) => {
                    handleChange(e, index);
                  }}
                  value={addresses[index]?.street}
                  className="border rounded-lg my-4"
                />
                <input
                  type="text"
                  name="address"
                  placeholder="address"
                  onChange={(e) => {
                    handleChange(e, index);
                  }}
                  value={addresses[index]?.address}
                  className="border rounded-lg my-4"
                />
                <input
                  type="text"
                  name="city"
                  placeholder="city"
                  onChange={(e) => {
                    handleChange(e, index);
                  }}
                  value={addresses[index]?.city}
                  className="border rounded-lg my-4"
                />
                <input
                  type="text"
                  name="postalCode"
                  placeholder="postal code"
                  onChange={(e) => {
                    handleChange(e, index);
                  }}
                  value={addresses[index]?.postalCode}
                  className="border rounded-lg my-4"
                />
                <button
                  type="button"
                  className="border rounded-lg p-2 bg-orange "
                  onClick={() => {
                    handleDelete(index);
                  }}
                >
                  Delete
                </button>
              </>
            );
          })}
          <button
            className="border rounded-lg p-2 bg-orange "
            type="button"
            onClick={handleAddMore}
          >
            add more
          </button>
        </div>
        <input
          type="text"
          name="logo"
          placeholder="logo"
          onChange={handleInputChange}
          value={corpData?.logo}
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="template"
          placeholder="template"
          onChange={handleInputChange}
          value={corpData?.template}
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="employeeRange"
          placeholder="employee range"
          onChange={handleInputChange}
          value={corpData?.employeeRange}
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="govId"
          placeholder="govId"
          onChange={handleInputChange}
          value={corpData?.govId}
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="govIdType"
          placeholder="gov id type"
          onChange={handleInputChange}
          value={corpData?.govIdType}
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="taxId"
          placeholder="taxId"
          onChange={handleInputChange}
          value={corpData?.taxId}
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="taxNumber"
          placeholder="tax Number"
          onChange={handleInputChange}
          value={corpData?.taxNumber}
          className="border rounded-lg my-4"
        />
        <input
          type="number"
          name="balance"
          placeholder="balance"
          onChange={handleInputChange}
          value={corpData?.balance}
          className="border rounded-lg my-4"
        />
        <input
          type="number"
          name="oldBalance"
          placeholder="old balance"
          onChange={handleInputChange}
          value={corpData?.oldBalance}
          className="border rounded-lg my-4"
        />
        <input
          type="number"
          name="creditConversationRatio"
          placeholder="credit conversion ratio"
          onChange={handleInputChange}
          value={corpData?.creditConversationRatio}
          className="border rounded-lg my-4"
        />

        <div className="border rounded-lg p-2 bg-lightOrange">
          Custom Plan
          <br />
          <input
            type="number"
            name="defaultPlanId"
            placeholder="defaultPlanId"
            onChange={handleCustomPlanChange}
            value={customPlan?.defaultPlanId}
            className="border rounded-lg my-4"
          />
          <input
            type="text"
            name="name"
            placeholder="name"
            onChange={handleCustomPlanChange}
            value={customPlan?.name}
            className="border rounded-lg my-4"
          />
          <input
            type="text"
            name="description"
            placeholder="description"
            onChange={handleCustomPlanChange}
            value={customPlan?.description}
            className="border rounded-lg my-4"
          />
          <input
            type="number"
            name="listPrice"
            placeholder="list price"
            onChange={handleCustomPlanChange}
            value={customPlan?.listPrice}
            className="border rounded-lg my-4"
          />
          <input
            type="number"
            name="discount"
            placeholder="discount"
            onChange={handleCustomPlanChange}
            value={customPlan?.discount}
            className="border rounded-lg my-4"
          />
          <input
            type="text"
            name="discountAb"
            placeholder="discount Ab"
            onChange={handleCustomPlanChange}
            value={customPlan?.discountAb}
            className="border rounded-lg my-4"
          />
          <input
            type="number"
            name="actualPrice"
            placeholder="actual price"
            onChange={handleCustomPlanChange}
            value={customPlan?.actualPrice}
            className="border rounded-lg my-4"
          />
          <input
            type="number"
            name="renualPrice"
            placeholder="renewal price"
            onChange={handleCustomPlanChange}
            value={customPlan?.renualPrice}
            className="border rounded-lg my-4"
          />
          <input
            type="number"
            name="deliveryCharge"
            placeholder="delivery charge"
            onChange={handleCustomPlanChange}
            value={customPlan?.deliveryCharge}
            className="border rounded-lg my-4"
          />
        </div>
        {/* <ImageUploader
          getAzureImageUrl={getAzureImageUrl}
          updateImgUrl={props?.updateData?.picture}
        /> */}
        {props?.updateData && props?.tabNumber === props?.currentTab ? (
          <>
            <button
              type="button"
              onClick={handleUpdateCorpData}
              className="border rounded-lg p-2 bg-lightOrange "
            >
              Update
            </button>
            <button
              onClick={handleCancelUpdate}
              type="button"
              // className="border rounded-lg p-2 bg-lightOrange "
              style={{
                backgroundColor: "red",
                color: "white",
                position: "absolute",
                left: "50rem",
                width: "150px",
                height: "50px",
              }}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            type="submit"
            className="border rounded-lg p-2 bg-lightOrange "
          >
            Submit
          </button>
        )}
      </form>
    </>
  );
};
