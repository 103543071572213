import { isAxiosError } from "axios";
import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import Api from "../../constants/api";
import { setIsLoading } from "../../redux/globalSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import JwtService from "../../service/jwtService";

const GoogleOAuth = () => {
  const dispatch = useAppDispatch();
  const globalState = useAppSelector((state) => state.globalSliceState);

  const init = useCallback(() => {
    // @ts-ignore
    if (!google) {
      setTimeout(() => {
        init();
      }, 1000);
    }
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID_WEB,
      callback: function oAuthRes(response: { credential: string }) {
        dispatch(setIsLoading(true));
        Api.googleLogin(response.credential)
          .then(async (res) => {
            dispatch(setIsLoading(false));
            await JwtService.signInWithToken(res.data.tokens.access_token);
          })
          .catch((err) => {
            dispatch(setIsLoading(false));
            if (isAxiosError(err)) {
              toast.error(err.response?.data.message);
            }
          });
      },
    });
    // @ts-ignore
    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", size: "large", width: "250px" } // customization attributes
    );
    // @ts-ignore
    google.accounts.id.prompt(); // also display the One Tap dialog
  }, [dispatch]);

  useEffect(() => {
    init();
  }, [init]);

  if (globalState.isAuthenticated === undefined) {
    return <></>;
  }

  return (
    <div className="w-full">
      <div id="buttonDiv" data-width="400px" className="w-full"></div>
    </div>
  );
};

export default GoogleOAuth;
