import { useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import Api from "../../constants/api";
import images from "../../constants/images";
import { setIsLoading } from "../../redux/globalSlice";
import AccordinCampaigns from "./AccordinCampaigns";
import CreateRecipientModel from "./CreateRecipientModel";

const perPageItems = [10, 20, 30, 40, 50];

export const RecipentTabs = () => {
  const dispatch = useDispatch();
  const [recipentData, setRecipentData] = useState<DashboardRecipientData[]>(
    []
  );

  const [createRecipientModel, setCreateRecipientModel] = useState(false);
  const [createRecipientModelEditId, setCreateRecipientModelEditId] =
    useState<number>();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = recipentData.slice(startIndex, endIndex);
  const totalPages = Math.ceil(recipentData.length / itemsPerPage);

  const [pageList, setPageList] = useState<number[]>([]);
  const _setPageList = useCallback(() => {
    let _currentPage = currentPage;
    if (_currentPage > totalPages) {
      _currentPage = 1;
      setCurrentPage(_currentPage);
    }

    if (totalPages <= 3) {
      setPageList([...Array(totalPages)].map((_, i) => i + 1));
    } else {
      if (_currentPage + 2 <= totalPages) {
        setPageList((opl) => {
          if (!opl.includes(_currentPage)) {
            let list = [_currentPage, _currentPage + 1, _currentPage + 2];
            // if (!list.includes(totalPages)) list.push(totalPages);
            // if (!list.includes(1)) list = [1, ...list];
            return list;
          }
          return opl;
        });
      } else {
        setPageList((opl) => {
          if (!opl.includes(_currentPage)) {
            const list = [...Array(totalPages - _currentPage + 1)].map(
              (_, i) => i + _currentPage
            );
            if (list.length < 3)
              return [_currentPage - 2, _currentPage - 1, _currentPage];
            return list;
          }
          return opl;
        });
      }
    }
  }, [currentPage, totalPages, itemsPerPage]);
  // Pagination end

  const getRecipients = useCallback(() => {
    dispatch(setIsLoading(true));
    Api.getallDashboardRecipient().then((res) => {
      dispatch(setIsLoading(false));
      setRecipentData(res.data);
      _setPageList();
    });
  }, [_setPageList]);

  const deleteRecipient = useCallback(
    (id: number) => {
      dispatch(setIsLoading(true));
      Api.deleteRecipient(id)
        .then((res) => {
          getRecipients();
          dispatch(setIsLoading(false));
        })
        .catch(() => dispatch(setIsLoading(false)));
    },
    [_setPageList]
  );

  useEffect(() => {
    getRecipients();
    return () => {};
  }, []);

  useEffect(() => {
    _setPageList();
    return () => {};
  }, [currentPage, recipentData, itemsPerPage]);

  const accordin = (
    <AccordinCampaigns
      key={1}
      data={currentItems.map((v, i) => {
        // v.campaigns;
        return {
          id: v.id,
          firstName: v.firstName,
          lastName: v.lastName,
          email: v.email,
          phoneNumber: v.phoneNumber,
          department: v.departmentGroup.name,
          role: v.role,
          campaigns: v.campaigns,
          checked: false,
        };
      })}
      createRecipientModel={createRecipientModel}
      setCreateRecipientModel={setCreateRecipientModel}
      setCreateRecipientModelEditId={setCreateRecipientModelEditId}
      deleteRecipient={deleteRecipient}
    />
  );

  let PageList = (
    <div className="pagination">
      {pageList.map((v, index) => (
        <button
          className={`text-orange rounded-lg border border-orange mx-1 w-8 h-8 hover:bg-lightOrange ${
            currentPage === v ? "bg-lightOrange" : "bg-transparent"
          }`}
          key={v}
          onClick={() => handlePageChange(v)}
        >
          {v}
        </button>
      ))}
    </div>
  );

  return (
    <div>
      <div className="mb-4 flex justify-between">
        <div className="flex justify-between"></div>
        <button
          className="p-2 px-3 bg-orange text-white text-[14px] font-leagueSpartan-400 rounded-lg"
          onClick={() => {
            setCreateRecipientModelEditId(undefined);
            setCreateRecipientModel(true);
          }}
        >
          Add Recipient
        </button>
      </div>
      <div> {accordin}</div>
      {/* Pagination */}
      <div className="flex justify-between">
        <div className="flex justify-start mt-2">
          <label className="text-neutral-600 mt-2 text-base">
            Rows per Page:
          </label>
          <select
            className="border-gray-400 rounded-xl mx-1"
            onChange={(e) => {
              setItemsPerPage(+e.target.value);
              setCurrentPage(1);
            }}
          >
            {perPageItems.map((v) => (
              <option key={v}>{v}</option>
            ))}
          </select>
        </div>
        <div className="flex justify-end mt-4">
          <div
            className="pt-1 text-sm cursor-pointer"
            onClick={() => handlePageChange(1)}
          >
            {"<<"}
          </div>
          <img
            src={images.leftsarrow}
            alt=""
            className="w-8 h-8"
            onClick={() => {
              if (currentPage > 1) handlePageChange(currentPage - 1);
            }}
          />
          {PageList}
          <img
            src={images.rightarrow}
            alt=""
            className=" w-8 h-8 "
            onClick={() => {
              if (currentPage < totalPages) handlePageChange(currentPage + 1);
            }}
          />
          <div
            className="pt-1 text-sm cursor-pointer"
            onClick={() => handlePageChange(totalPages)}
          >
            {">>"}
          </div>
        </div>
      </div>
      {createRecipientModel && (
        <CreateRecipientModel
          setShowMyModel={setCreateRecipientModel}
          recipentData={recipentData}
          getRecipients={getRecipients}
          createRecipientModelEditId={createRecipientModelEditId}
          setCreateRecipientModelEditId={setCreateRecipientModelEditId}
        />
      )}
    </div>
  );
};
