import { useLocation, useNavigate } from "react-router-dom";

import { pathType } from "../../Routes/Routes";
import images from "../../constants/images";
import CustomNavigate from "../../utils/navigate";

type propType = {
  hidden: boolean;
};

const items: {
  path: pathType;
  childPaths?: pathType[];
  name: string;
  image: string;
  imageGrey: string;
}[] = [
    {
      path: "/dashboard",
      name: "Dashboard",
      image: images.dashboard,
      imageGrey: images.dashboardGrey,
    },
    {
      path: "/dashboard/campaigns",
      childPaths: ["/dashboard/campaigns"],
      name: "Campaigns",
      image: images.campaigns,
      imageGrey: images.campaignsGrey,
    },
    {
      path: "/dashboard/recipents",
      name: "Recipents",
      image: images.recipents,
      imageGrey: images.recipentsGrey,
    },
    {
      path: "/dashboard/engagement",
      childPaths: [
        "/dashboard/engagement",
        "/dashboard/touchpoints/create",
      ],
      name: "Engagement",
      image: images.engagement,
      imageGrey: images.engagementGrey,
    },
  ];

const Sidebar = (props: propType) => {
  const { hidden } = props;
  const navigate = new CustomNavigate(useNavigate());
  const location = useLocation();

  return (
    <div className={`${hidden && "hidden"}`}>
      {items.map((i) => {
        let highlight = location.pathname === i.path;
        if (i.childPaths?.find((cp) => location.pathname.includes(cp))) {
          highlight = true;
        }
        return (
          <div
            key={i.path}
            className={`grid grid-cols-5 p-2 py-3 rounded-r-2xl cursor-pointer mr-2 ${highlight ? "bg-lightOrange text-orange" : "text-neutrals-800"
              }`}
            onClick={() => navigate.to(i.path)}
          >
            <img
              src={highlight ? i.image : i.imageGrey}
              className={`col-span-2 justify-self-center`}
              alt=""
            />
            <div className="col-span-3">{i.name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Sidebar;
