import React from "react";
type propType = {
  progress: number;
};
const ProgressbarHamper = (props: propType) => {
  const { progress } = props;
  return (
    <div className="w-full bg-lightOrange h-[14px] dark:bg-gray-700 rounded-lg">
      <div
        className={`bg-orange h-[14px] rounded-lg`}
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default ProgressbarHamper;
