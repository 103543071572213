import React, { useCallback, useState } from "react";
import images from "../../constants/images";
import { toast } from "react-toastify";
import Api from "../../constants/api";
import CustomNavigate from "../../utils/navigate";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDashboardData } from "../../redux/dashboardSlice";
import { useAppSelector } from "../../redux/hooks";
import moment from "moment";

type propType = {
  setmodel: React.Dispatch<React.SetStateAction<boolean>>;
  onDateSelect?: () => void;
};

export default function DashboardDatePickerModel(props: propType) {
  const { setmodel, onDateSelect } = props;
  const dashboardState = useAppSelector((state) => state.dashboardState);

  const dispatch = useDispatch();

  const setDates = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // @ts-ignore
      console.log(e.target[3]);

      // @ts-ignore
      const from = e.target[3].value;
      // @ts-ignore
      const to = e.target[4].value;
      console.log("from, to");
      console.log(from, to);

      if (new Date(from) > new Date(to)) {
        toast.error("From date should be less than to date.");
        return;
      }
      dispatch(
        setDashboardData({
          fromDate: from,
          toDate: to,
        })
      );
      // onDateSelect();
      setmodel(false);
    },
    [dispatch, onDateSelect, setmodel]
  );

  const setFromDays = useCallback(
    (n: number) => {
      const to = moment().endOf("day");
      const from = moment(to).subtract(n, "days");
      console.log(from.toDate().toISOString());
      console.log(to.toDate().toISOString());

      dispatch(
        setDashboardData({
          fromDate: from.toDate().toISOString(),
          toDate: to.toDate().toISOString(),
        })
      );
      setmodel(false);
    },
    [dispatch, onDateSelect, setmodel]
  );

  return (
    <>
      <div className="inset-0 fixed backdrop-blur-sm bg-black bg-opacity-80 flex justify-center items-center z-10">
        <div>
          <div className="w-[596px] min-h-[380px] shadow flex-col rounded-xl   bg-white">
            <div className="flex justify-end mx-3  ">
              <div className="w-6 h-6 " />{" "}
              <button
                className="text-black text-xl mt-2"
                onClick={() => setmodel(false)}
              >
                <img src={images.Close} alt="" />
              </button>
            </div>
            <form onSubmit={(e) => setDates(e)} className="p-[75px]">
              <div className="flex flex-col items-center">
                <div className="flex gap-2">
                  <button
                    className="p-2 px-3 font-leagueSpartan-400 text-orange border border-orange rounded-lg hover:bg-lightOrange"
                    onClick={(e) => {
                      e.preventDefault();
                      setFromDays(6);
                    }}
                  >
                    Last 7 days
                  </button>
                  <button
                    className="p-2 px-3 font-leagueSpartan-400 text-orange border border-orange rounded-lg hover:bg-lightOrange"
                    onClick={(e) => {
                      e.preventDefault();
                      setFromDays(14);
                    }}
                  >
                    Last 15 days
                  </button>
                  <button
                    className="p-2 px-3 font-leagueSpartan-400 text-orange border border-orange rounded-lg hover:bg-lightOrange"
                    onClick={(e) => {
                      e.preventDefault();
                      setFromDays(29);
                    }}
                  >
                    Last 30 days
                  </button>
                </div>
                <div className="flex justify-center">
                  <div className="flex flex-col gap-2 p-4">
                    <label className="mx-1 font-leagueSpartan-400">From</label>
                    <input
                      type="date"
                      className="w-[180px] border border-neutral-300 font-leagueSpartan-400 rounded-lg"
                      defaultValue={dashboardState.data.fromDate}
                    />
                  </div>
                  <div className="flex flex-col gap-2 p-4">
                    <label className="mx-1 font-leagueSpartan-400">To</label>
                    <input
                      type="date"
                      className="w-[180px] border border-neutral-300 font-leagueSpartan-400 rounded-lg"
                      defaultValue={dashboardState.data.toDate}
                    />
                  </div>
                </div>
                <div className="">
                  <button
                    className="mt-4 p-2 px-3 rounded-lg bg-orange font-leagueSpartan-400 text-sm text-white"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
