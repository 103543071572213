import classNames from "classnames";
import images from "../../constants/images";
import CustomNavigate from "../../utils/navigate";
type propsType = {
  navigate: CustomNavigate;
  isPreview?: boolean;
};

const BackButtonGifting = (props: propsType) => {
  const { navigate, isPreview = false } = props;
  return (
    <button
      className={classNames(
        "flex",
        "flex-row",
        "items-center",
        "hover:bg-gray-100",
        isPreview ? "text-white" : "text-orange",
        "rounded-lg",
        "p-2",
        "ps-0",
        "max-sm:py-0",
        "px-3"
      )}
      onClick={() => navigate.back()}
    >
      <img
        src={isPreview ? images.leftArrowGrey : images.leftArrow}
        className="pr-2"
        alt=""
      />
      <div className="text-sm">Back</div>
    </button>
  );
};

export default BackButtonGifting;
