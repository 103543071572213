import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Accordin from "../../components/common/Accordin";
import BackButtonGifting from "../../components/giftSelection/BackButton";
import Header from "../../components/giftSelection/Header";
import ProdutDetailsModal from "../../components/giftSelection/ProdutDetailsModal";
import images from "../../constants/images";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setProduct } from "../../redux/selectGiftSlice";
import CustomNavigate from "../../utils/navigate";

const CategoryDetail = () => {
  const selectGiftState = useAppSelector((state) => state.selectGiftState);

  const recipientId =
    useSearchParams()[0].get("recid") ||
    selectGiftState.campaignRecipientData?.result.url_id;

  const navigate = new CustomNavigate(useNavigate(), { recipientId });

  const dispatch = useAppDispatch();

  const [selectedImage, setSelectedImage] = useState(0);
  const state: GetCampaignRecipientProduct = useLocation().state;

  // Product Modal
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<number>();
  const [recommendations, setRecommendations] = useState<
    GetCampaignRecipientProduct[]
  >([]);

  const product = state;
  const isTesting = selectGiftState.campaignRecipientData?.result.isForTesting;
  const productImages = [];
  if (product?.image_1) productImages.push(product?.image_1);
  if (product?.image_2) productImages.push(product?.image_2);
  if (product?.image_3) productImages.push(product?.image_3);
  if (product?.image_4) productImages.push(product?.image_4);
  if (product?.image_5) productImages.push(product?.image_5);
  if (product?.image_6) productImages.push(product?.image_6);

  const nextImage = useCallback(() => {
    setSelectedImage((previousSelect) => {
      if (previousSelect + 1 < productImages.length) {
        return previousSelect + 1;
      }
      return previousSelect;
    });
  }, [productImages.length]);

  const previousImage = useCallback(() => {
    setSelectedImage((previousSelect) => {
      if (previousSelect - 1 > -1) {
        return previousSelect - 1;
      }
      return previousSelect;
    });
  }, []);

  useEffect(() => {
    const products: GetCampaignRecipientProduct[] = [];

    selectGiftState.campaignRecipientData?.groupedProduct.forEach(
      (groupedProductItem) => {
        groupedProductItem.products.forEach((item) => {
          products.push(item);
        });
      }
    );
    setRecommendations(products);
  }, [selectGiftState.campaignRecipientData]);

  const corpState = useAppSelector((state) => state.corpSliceState);
  const logo =
    corpState.data && corpState.data.logo && corpState.data.logo !== ""
      ? corpState.data.logo
      : undefined;

  const isDeactivated = useMemo(
    () => selectGiftState.campaignRecipientData?.result?.isForTesting ?? false,
    [selectGiftState.campaignRecipientData?.result?.isForTesting]
  );

  return (
    <div className="">
      <Header navigate={navigate} logo={logo} isPreview={isDeactivated} />
      <div className="m-6 mt-[100px]">
        <BackButtonGifting navigate={navigate} />
      </div>
      <div className="grid md:grid-cols-2 max-md:grid-cols-1 md:gap-16 max-md:gap-4 md:mt-5 max-md:mt-5 md:mx-36 max-md:mx-5">
        <div>
          <div className="relative border rounded-2xl">
            <button
              className="absolute top-[45%] left-2 p-[12px] rounded-full bg-white"
              onClick={() => previousImage()}
            >
              <img
                src={
                  selectedImage !== 0 ? images.leftArrow : images.leftArrowGrey
                }
                className="w-[16px] aspect-square "
                alt=""
              />
            </button>
            <button
              className="absolute top-[45%] right-2 p-[12px] rounded-full bg-white"
              onClick={() => nextImage()}
            >
              <img
                src={
                  selectedImage + 1 === productImages.length
                    ? images.leftArrowGrey
                    : images.leftArrow
                }
                className="w-[16px] aspect-square rotate-180"
                alt=""
              />
            </button>
            <div className="flex justify-center">
              <img
                src={productImages[selectedImage]}
                className="w-full aspect-square object-contain rounded-2xl"
                alt=""
              />
            </div>
          </div>
          <div className="flex gap-6 overflow-x-auto mt-5">
            {productImages.map((item, index) => {
              return (
                <img
                  src={item}
                  key={index}
                  className={`w-[93px] aspect-square mr-1 rounded-lg cursor-pointer object-contain ${
                    selectedImage === index && "border border-orange"
                  }`}
                  onClick={() => setSelectedImage(index)}
                  alt=""
                />
              );
            })}
          </div>
        </div>

        <div>
          <div className="font-leagueSpartan-500 text-[32px] text-neutrals-900 ">
            {product?.name}
          </div>
          {product && product.brand && (
            <div className="font-leagueSpartan-400 text-md text-neutrals-700 pt-1 ">
              <span className="font-leagueSpartan-500">{product.brand} </span>
            </div>
          )}
          {product.hasDeliveryOption && (
            <>
              <div className="flex mt-4">
                <div className="flex rounded-xl p-2 px-3 bg-green-100 font-leagueSpartan-500 text-[14px] text-green-800 whitespace-nowrap">
                  <img src={images.truck} className="mx-1" alt="delivery" />
                  Free Delivery
                </div>
              </div>
              <div className="flex items-center mt-4 font-leagueSpartan-500">
                <h2 className="font-bold">Estimated Delivery Time: </h2>
                <span className="ml-2 font-leagueSpartan-400">
                  {`${moment()
                    .add(product?.deliveryDurationEarliest, "seconds")
                    .format("ddd, MMM D, YYYY")} - ${moment()
                    .add(product?.deliveryDurationLatest, "seconds")
                    .format("ddd, MMM D, YYYY")}`}
                </span>
              </div>
            </>
          )}
          <div className="mt-4  rounded-xl">
            <button
              className={`mt-2 p-3 px-8 rounded-lg font-leagueSpartan-500 text-[16px] text-white tracking-[1.6px] ${
                isTesting ? "bg-neutrals-500" : "bg-orange"
              }`}
              onClick={() => {
                dispatch(setProduct(state));
                navigate.to("/gift-selection/checkout", undefined, {
                  product: state,
                  voucher: null,
                });
              }}
              disabled={isTesting}
            >
              Choose option
            </button>
          </div>
          <div className="mt-4">
            <Accordin
              data={[
                {
                  title: "Description",
                  description: product?.description,
                  id: 1,
                  checked: true,
                },
              ]}
            />
          </div>
        </div>
      </div>
      {/* TODO: Implement product ranking to show as recommendations */}
      {/* <div className="mt-12 md:mx-36 max-md:mx-5">
        <div className="font-leagueSpartan-500 text-neutrals-900 text-[28px]">
          You may also like
        </div>
        <div className="flex grid-flow-col gap-6 py-4 px-1 overflow-y-auto ">
          {recommendations.map((item, index) => {
            return (
              <div
                className="flex flex-col justify-between shadow-lg rounded-xl cursor-pointer overflow-hidden  w-[250px] min-w-[250px]"
              >
                <div>
                  <div className="relative rounded-t-xl overflow-hidden hoverimagezoom">
                    <img
                      src={item.image_1}
                      className="w-[100%] aspect-square object-cover"
                      alt=""
                    />
                  </div>
                  <div className="font-leagueSpartan-400 text-[18px] leading-[24px] px-4 pt-4">
                    {item.name}
                  </div>
                </div>
                <div className="grid gap-4 p-4 py-[12px] px-[16px] md:min-h-[40px]">
                  <div className="self-end">
                    <div
                      className="flex text-orange font-leagueSpartan-500 text-[16px] items-center mt-2 cursor-pointer"
                      onClick={() => {
                        setSelectedProduct(index);
                        setModalVisible(true);
                      }}
                    >
                      Quick View
                      <img src={images.eye} className="mx-2 h-[10px]" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
      {selectedProduct !== undefined && modalVisible && (
        <ProdutDetailsModal
          setModalVisible={setModalVisible}
          product={recommendations[selectedProduct]}
        />
      )}
    </div>
  );
};

export default CategoryDetail;
