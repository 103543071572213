import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import CustomNavigate from "../../utils/navigate";
import CheckBox from "../common/CheckBox";
import CustomInput from "../common/CustomInput";
type propType = {};
const PaymentFormCampaign = (props: propType) => {
  const navigate = new CustomNavigate(useNavigate());
  return (
    <div>
      <Formik
        initialValues={{
          cardNumber: "",
          expiryDate: "",
          cvv: "",
          name: "",
          checkbox: false,
        }}
        validate={(values) => {
          const errors: paymentFormErrorT = {};
          if (!values.cardNumber) {
            errors.cardNumber = "Required";
          } else if (values.cardNumber.length < 16) {
            errors.cardNumber = "Invalid Card Number";
          }
          const test = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/.test(values.expiryDate);
          if (!test) {
            errors.expiryDate = "Invalid";
          }
          if (!values.cvv) {
            errors.cvv = "Required";
          } else if (values.cvv.length < 3) {
            errors.cvv = "Invalid CVV";
          }
          if (!values.name) {
            errors.name = "Required";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setValues,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-4">
              <div className="">
                <CustomInput
                  type="text"
                  name="cardNumber"
                  title="Card Number"
                  placeholder="1234 5678 9012 3456"
                  // onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cardNumber}
                  error={errors.cardNumber}
                  touched={touched.cardNumber}
                  onChange={(e) => {
                    // @ts-ignore
                    let value = e.target.value as string;

                    const test = /^[0-9]{0,16}$/.test(value);
                    if (!test) return;
                    // if (!(value.length % 4)) value = value + " ";
                    setFieldValue("cardNumber", value);
                  }}
                />
                {/* <div className="text-red-500 h-5 text-sm">
                {errors.firstName && touched.firstName && errors.firstName}
              </div> */}
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <CustomInput
                    type="text"
                    name="expiryDate"
                    title="Expiry Date"
                    placeholder="MM/YY"
                    // onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.expiryDate}
                    error={errors.expiryDate}
                    touched={touched.expiryDate}
                    onInput={(e) => {
                      // @ts-ignore
                      let value = e.target.value as string;
                      if (!value.length) {
                        setFieldValue("expiryDate", value);
                        return;
                      }
                      const test = /[0-9/]$/.test(value[value.length - 1]);
                      console.log(value, test);
                      if (!test || value.length > 5) return;
                      if (!value.includes("/") && value.length === 3)
                        value = value[0] + value[1] + "/" + value[2];
                      else if (value.includes("/") && value.length === 3)
                        value = value.replace("/", "");
                      console.log(value);

                      setFieldValue("expiryDate", value);
                    }}
                  />
                  {/* <div className="text-red-500 h-5 text-sm">
                {errors.lastName && touched.lastName && errors.lastName}
              </div> */}
                </div>
                <div className="">
                  <CustomInput
                    type="text"
                    name="cvv"
                    title="CVV"
                    placeholder="3 digits"
                    // onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cvv}
                    error={errors.cvv}
                    touched={touched.cvv}
                    onChange={(e) => {
                      // @ts-ignore
                      let value = e.target.value as string;

                      const test = /^[0-9]{0,3}$/.test(value);
                      if (!test) return;
                      setFieldValue("cvv", value);
                    }}
                  />
                  {/* <div className="text-red-500 h-5 text-sm">
                {errors.address && touched.address && errors.address}
              </div> */}
                </div>
              </div>

              <div className="">
                <CustomInput
                  type="text"
                  name="name"
                  title="Name on Card"
                  placeholder="Cardholder name"
                  // onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={errors.name}
                  touched={touched.name}
                  onChange={(e) => {
                    // @ts-ignore
                    let value = e.target.value as string;
                    const test = /^[a-zA-Z ]*$/.test(value);
                    if (!test) return;
                    setFieldValue("name", value);
                  }}
                />
                {/* <div className="text-red-500 h-5 text-sm">
                {errors.apartment && touched.apartment && errors.apartment}
              </div> */}
              </div>
              <div></div>
              <CheckBox
                checked={values.checkbox}
                onClick={() => setFieldValue("checkbox", !values.checkbox)}
                name=""
                elementBesideText={
                  <>
                    <div className="font-leagueSpartan-300 text-md">
                      My billing and shipping address are the same
                    </div>
                  </>
                }
              />
            </div>
            {/* <button
              type="submit"
              disabled={isSubmitting}
              title="isSubmitting"
              className="mt-6 p-2 px-4 rounded-lg  bg-orange text-white"
            >
              CONFIRM AND PAY
            </button> */}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PaymentFormCampaign;
