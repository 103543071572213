import React from "react";
import images from "../../constants/images";

const EmailVerificationRequired = () => {
  return (
    <div className="h-screen pt-[15%]">
      <div className="flex flex-col items-center">
        <img src={images.logo} alt="" className="h-20" />
        <div className="font-leagueSpartan-500 text-[32px] mt-10">
          Verify Your Email Address
        </div>
        <div className="font-leagueSpartan-400 text-md mt-5">
          A verification email has been sent to your account. Please check your
          inbox to verify.
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationRequired;
