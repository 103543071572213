import { MouseEventHandler } from "react";

type TCheckbox = {
  checked: boolean;
  className?: string;
  readOnly?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  text: string;
  description?: string;
  name?: string;
};
export const Checkbox = ({
  checked,
  className,
  readOnly,
  onClick,
  text,
  description,
  name,
}: TCheckbox) => {
  return (
    <div
      className={`flex rounded-xl cursor-pointer ${className}`}
      onClick={onClick}
    >
      <div className="my-auto px-2">
        <input
          className="border-neutral-400 focus:ring-orange text-orange w-6 h-6 rounded"
          type="checkbox"
          name={name}
          checked={checked}
          readOnly={readOnly}
        />
      </div>
      <div className="ps-4 py-2 text-md">
        <div>{text}</div>
        <div className="text-sm text-neutrals-700">{description}</div>
      </div>
    </div>
  );
};
