import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { utils, writeFile } from "xlsx";
import { TopUpModal } from "../../components/Modal/TopUpModal";
import Table, { DEFAULT_PAGE_LIMIT } from "../../components/Table/Table";
import Main from "../../components/dashboard/Main";
import Api from "../../constants/api";
import images from "../../constants/images";
import { updateBalance } from "../../redux/corpSlice";
import { setIsLoading } from "../../redux/globalSlice";
import { useAppSelector } from "../../redux/hooks";

const HEADERS = [
  {
    key: "type",
    heading: "ACTIVITY",
    isSortable: false,
  },
  {
    key: "breakdown",
    heading: "DESCRIPTION",
    isSortable: false,
  },
  {
    key: "availableAmount",
    heading: "AMOUNT",
    isSortable: false,
  },
  {
    key: "createdAt",
    heading: "DATETIME",
    isSortable: true,
  },
];
const getAllTransactionsURL =
  process.env.REACT_APP_CORP_URL + "transaction/wallet/list";

const transactionTypes = new Map([
  ["cr", "Topup"],
  ["dr", "Deduct"],
  ["hd", "Hold"],
  ["rl", "Release"],
]);

const initialStartDate = moment().subtract(1, "month").format("YYYY-MM-DD");
const initialEndDate = moment().format("YYYY-MM-DD");
const currentDate = moment().format("YYYY-MM-DD");

const WalletTransaction = () => {
  const corp = useAppSelector((state) => state.corpSliceState);
  const corpId = corp.data?.id;
  const balance = corp.data?.balance || 0;

  const [walletTransactions, setWalletTransactions] = useState<string[][]>([]);
  const [totalWalletTransactionsCount, setTotalWalletTransactionsCount] =
    useState<number>(0);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [sortColumn, setSortColumn] = useState<string>("createdAt");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(DEFAULT_PAGE_LIMIT);

  const [showTopUpModal, setShowTopUpModal] = useState(false);

  const [startDate, setStartDate] = useState<string>(initialStartDate);
  const [endDate, setEndDate] = useState<string>(initialEndDate);

  const dispatch = useDispatch();

  const fetchTransactions = useCallback(
    (forExport = false, successMessage?: string) => {
      const reqBody = {
        startDate,
        endDate,
        type: Array.from(transactionTypes.keys()),
        recentFirst: sortOrder === "DESC",
        limit,
        skip: (pageNumber - 1) * limit,
      };

      if (startDate > endDate) {
        toast.warning("Start date should not be later than export date");
        return;
      }

      return axios.post(getAllTransactionsURL, reqBody).then((res) => {
        if (res.data.success) {
          if (
            typeof successMessage === "string" &&
            successMessage.trim() !== ""
          )
            toast.success(successMessage);

          if (!forExport) {
            const transformedData = transformTransactions(res.data.data.result);
            setWalletTransactions(transformedData);
            setTotalWalletTransactionsCount(res.data.data.count);
            return;
          } else {
            return res.data.data.result;
          }
        }
      });
    },
    [endDate, limit, pageNumber, sortOrder, startDate]
  );

  useEffect(() => {
    try {
      fetchTransactions();
    } catch (error) {
      toast.error("Failed to fetch transactions. Please try again.");
    }
  }, [fetchTransactions]);

  useEffect(() => {
    setPageNumber(1);
  }, [startDate, endDate]);

  const handleRefreshButtonClick = () => {
    try {
      fetchTransactions();
      toast.success("Transactions successfully refreshed!");
    } catch (error) {
      toast.error("Failed to refresh transactions. Please try again.");
    }
  };

  const transformTransactions = (transactions: any[]) => {
    return transactions.map((transaction: any) => {
      let activity = "-";
      let description = "-";
      let firstName = "";
      let amount = `${transaction.amount || 0}`;

      switch (transaction.type) {
        case "cr":
          activity = "Topup";
          description = "Topup balance credit";
          amount = `+${amount}`;
          break;
        case "dr":
          activity = "Deduction";
          description = "Deduct balance credit";
          amount = `-${amount}`;
          break;
        case "hd":
          activity = "On Hold";
          firstName =
            transaction.campaignRecipient?.orderRecipientDetails?.firstName ||
            "";
          description = `Gift on hold for: ${firstName}`;
          amount = `-${amount}`;
          break;
        case "cg":
          activity = "Charge";
          firstName =
            transaction.campaignRecipient?.orderRecipientDetails?.firstName ||
            "";
          description = `Gift claimed by: ${firstName}`;
          amount = `-${amount}`;
          break;
        case "rl":
          const budget = transaction.campaign?.perPersonBudget || 0;
          const txnAmount = transaction.amount || 0;
          firstName =
            transaction.campaignRecipient?.orderRecipientDetails?.firstName ||
            "";
          if (txnAmount >= budget) {
            activity = "Link Expiration Refund";
            description = `Link Expired for: ${firstName}`;
          } else {
            activity = "Cost Saving Split";
            description = `Saving Split for: ${firstName}`;
          }
          amount = `+${amount}`;
          break;
      }

      return [
        activity,
        description,
        amount,
        moment(transaction.createdAt).format("YYYY-MM-DD hh:mm A") || "-",
      ];
    });
  };

  const transformTransactionsForExport = (transactions: any[]) => {
    return transactions.map((transaction: any) => {
      return {
        Activity: transactionTypes.get(transaction.type) || "-",
        Amount: transaction.amount ?? 0,
        Description: transaction.breakdown || "-",
        DateTime:
          moment(transaction.createdAt).format("YYYY-MM-DD hh:mm A") || "-",
      };
    });
  };

  const handleExportClick = async () => {
    if (startDate > endDate) {
      toast.warning("Start date should not be later than export date");
      return;
    }

    try {
      const forExport = true;
      const exportData = await fetchTransactions(forExport);
      const transformedDataForExport =
        transformTransactionsForExport(exportData);

      const worksheet = utils.json_to_sheet(transformedDataForExport);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Transactions");
      writeFile(workbook, "transactions.csv");
      toast.success("Transactions successfully exported!");
    } catch (error) {
      toast.error("Failed to export transactions. Please try again.");
    }
  };

  const onRefresh = useCallback(() => {
    if (corpId) {
      dispatch(setIsLoading(true));
      Api.getOneCorp(corpId)
        .then((res) => {
          dispatch(updateBalance(res.data.balance));
        })
        .catch((error) => {
          console.error(error);
          toast.error("There was an error refreshing your balance.");
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    } else {
      console.log("corpId is null/undefined");
    }
  }, [corpId, dispatch]);

  return (
    <div>
      <Main title="Transactions" logo={true}>
        <div>
          <div className="">
            {/* Credit Balance Row */}
            <div className="flex justify-start items-center p-6">
              <h2 className="font-leagueSpartan-600 text-[28px]">
                Credit Balance: ${balance}
              </h2>
              <button
                className="ml-2 bg-orange text-white font-leagueSpartan-500 text-[16px] rounded-lg p-2 px-3"
                onClick={() => setShowTopUpModal(true)}
              >
                Top up
              </button>
              <button
                className="ml-2 flex items-center bg-white border border-orange text-orange font-leagueSpartan-500 text-[16px] rounded-lg p-2 px-3"
                onClick={() => onRefresh()}
              >
                Refresh
                <img src={images.refresh} alt="refresh"></img>
              </button>
            </div>
            {/* Transaction History Row, Dates, Export, Refresh */}
            <div className="flex justify-between items-center p-6 -my-4">
              <h2 className="font-leagueSpartan-600 text-[28px]">
                Transaction History
              </h2>
              <div className="flex items-center space-x-4">
                <div className="flex flex-col gap-2 p-4">
                  <label className="mx-1 font-leagueSpartan-400">
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="w-[180px] border border-neutral-300 font-leagueSpartan-400 rounded-lg"
                    defaultValue={initialStartDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    max={currentDate}
                  />
                </div>
                <div className="flex flex-col gap-2 p-4">
                  <label className="mx-1 font-leagueSpartan-400">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="w-[180px] border border-neutral-300 font-leagueSpartan-400 rounded-lg"
                    defaultValue={initialEndDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    max={currentDate}
                  />
                </div>
                <button
                  className="mt-8 bg-white border border-orange text-orange py-2 px-4 rounded-lg font-leagueSpartan-500 text-[16px] h-min"
                  onClick={() => handleExportClick()}
                >
                  Export
                </button>
                <button
                  className="mt-8 flex items-center bg-white border border-orange text-orange py-2 px-4 rounded-lg font-leagueSpartan-500 text-[16px] h-min"
                  onClick={() => handleRefreshButtonClick()}
                >
                  Refresh
                  <img src={images.refresh} alt="refresh"></img>
                </button>
              </div>
            </div>
            {/* Table */}
            <Table
              header={HEADERS}
              rows={walletTransactions}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              setSortColumn={setSortColumn}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              setLimit={setLimit}
              limit={limit}
              totalItemCount={totalWalletTransactionsCount}
              isSearchable={false}
            ></Table>
          </div>
        </div>
      </Main>
      {showTopUpModal && <TopUpModal setShowTopUpModal={setShowTopUpModal} />}
    </div>
  );
};

export default WalletTransaction;
