import { isAxiosError } from "axios";
import moment from "moment";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Api from "../../constants/api";
import images from "../../constants/images";
import { setIsDashboardCampaignList } from "../../redux/dashboardSlice";
import { setIsLoading } from "../../redux/globalSlice";
import { useAppSelector } from "../../redux/hooks";

type propType = {
  setShowMyModel: Dispatch<SetStateAction<boolean>>;
  recipentData: DashboardRecipientData[];
  getRecipients: () => void;
  createRecipientModelEditId: number | undefined;
  setCreateRecipientModelEditId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
};
const CreateRecipientModel = (props: propType) => {
  const {
    setShowMyModel,
    recipentData,
    getRecipients,
    createRecipientModelEditId,
    setCreateRecipientModelEditId,
  } = props;

  const dispatch = useDispatch();

  const dashboardState = useAppSelector((state) => state.dashboardState);
  const _reqData = dashboardState.data;
  const fromDate = moment(_reqData.fromDate).toISOString();
  const toDate = moment(_reqData.toDate).endOf("day").toISOString();

  const [showDropdown, setShowDropdown] = useState(false);
  const [department, setDepartment] = useState("");
  const [defaultValues, setDefaultValues] = useState<
    DashboardRecipientData | undefined
  >();
  console.log(department);

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    department: "",
    role: "",
  });

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setCreateRecipientModelEditId(undefined);
        setShowMyModel(false);
      }
    },
    [setCreateRecipientModelEditId, setShowMyModel]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, false);
    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (createRecipientModelEditId) {
      const _defaultValues = recipentData.find(
        (r) => r.id === createRecipientModelEditId
      );
      setDefaultValues(_defaultValues);
      setDepartment(_defaultValues?.departmentGroup?.name || "");
    }

    return () => {};
  }, []);

  const groups: string[] = [];
  recipentData.forEach((r) => {
    if (r.departmentGroup && !groups.includes(r.departmentGroup.name)) {
      groups.push(r.departmentGroup.name);
    }
  });

  const reloadCampaigns = useCallback(() => {
    const reqData = { fromDate, toDate };
    Api.getallDashBoardCampaign(reqData).then((res) => {
      dispatch(setIsDashboardCampaignList(res.data));
    });
  }, [fromDate, toDate]);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // @ts-ignore
      const firstName = e.target[0].value;
      // @ts-ignore
      const lastName = e.target[1].value;
      // @ts-ignore
      const email = e.target[2].value ? e.target[2].value : undefined;
      // @ts-ignore
      const phoneNumber = e.target[3].value ? e.target[3].value : undefined;
      // @ts-ignore
      const role = e.target[5].value;

      if (Object.values(errors).find((v) => v !== "")) {
        return;
      }

      const _defaultValues = recipentData.find(
        (r) => r.id === createRecipientModelEditId
      );

      if (firstName === "") {
        setErrors((e) => {
          return { ...e, firstName: "Invalid first name" };
        });
        return;
      }
      if (lastName === "") {
        setErrors((e) => {
          return { ...e, lastName: "Invalid last name" };
        });
        return;
      }

      const data: CreateRecipient = {
        id: createRecipientModelEditId,
        firstName,
        lastName,
        email,
        phoneNumber,
        birthDate:
          _defaultValues && _defaultValues.birthDate
            ? _defaultValues.birthDate
            : new Date().toISOString(),
        group: department,
        role,
      };
      console.log(firstName, email, phoneNumber, department, role);

      dispatch(setIsLoading(true));
      Api.createUpdateRecipient(data)
        .then(() => {
          toast.success(`Recipient ${_defaultValues ? "updated" : "added"}!`);
          getRecipients();
          setCreateRecipientModelEditId(undefined);
          setShowMyModel(false);
        })
        .catch((err) => {
          console.error(err);
          if (isAxiosError(err)) {
            if (err.response && err.response.status === 400) {
              toast.error(err.response?.data.message);
            } else {
              toast.error("Error occurred!");
            }
            return;
          }
          toast.error("Error occurred!");
        })
        .finally(() => {
          dispatch(setIsLoading(false));
          reloadCampaigns();
        });
    },
    [
      errors,
      recipentData,
      department,
      createRecipientModelEditId,
      dispatch,
      getRecipients,
      setShowMyModel,
      setCreateRecipientModelEditId,
      reloadCampaigns,
    ]
  );

  const DepartmentDropdown = (
    <div
      className="relative w-full"
      onFocus={() => setShowDropdown(true)}
      onBlur={() => {
        setTimeout(() => {
          setShowDropdown(false);
        }, 700);
      }}
    >
      <input
        type="text"
        placeholder="GROUP"
        className="border rounded mr-[1px] w-full placeholder:font-leagueSpartan-400 placeholder:text-[15px] placeholder:text-neutral-400"
        defaultValue={defaultValues && defaultValues.firstName}
        value={department}
        onChange={(e) => {
          if (!/^[0-9A-Za-z/-_\s]{0,60}$/.test(e.target.value))
            setErrors((e) => {
              return { ...e, department: "Invalid department." };
            });
          else
            setErrors((e) => {
              return { ...e, department: "" };
            });
          setDepartment(e.target.value);
        }}
      />
      <div
        className={`absolute top-[45px] w-full flex flex-col bg-white border rounded z-10 ${
          !showDropdown && "hidden"
        }`}
      >
        {groups.map((rg, index) => (
          <div
            key={index}
            className="border-b p-2 hover:bg-neutrals-200 cursor-pointer"
            onClick={() => {
              setDepartment(rg);
              setShowDropdown(false);
            }}
          >
            {rg}
          </div>
        ))}
      </div>
    </div>
  );

  console.log(errors);

  return (
    <>
      <div className="inset-0 fixed backdrop-blur-sm bg-black bg-opacity-25 flex justify-center items-center">
        <div>
          <div className="w-[596px] p-6 shadow flex-col justify-start items-center gap-6 inline-flex  bg-white rounded-lg">
            <div className="self-stretch justify-between items-center gap-[15px] inline-flex">
              <div className="font-leagueSpartan-600 text-neutrals-900 text-2xl font-semibold leading-loose">
                Create Recipient
              </div>
              <div className="justify-start items-start gap-2.5 flex">
                <div className="w-12 p-3 rounded-xl justify-center items-start gap-2.5 flex">
                  <div className="w-6 h-6 relative" />{" "}
                  <img
                    src={images.Close}
                    alt=""
                    className="h-[20px] w-[20px] cursor-pointer"
                    onClick={() => {
                      setCreateRecipientModelEditId(undefined);
                      setShowMyModel(false);
                    }}
                  />
                </div>
              </div>
            </div>
            <form
              onSubmit={onSubmit}
              className="grid grid-cols-1 gap-4 w-full font-leagueSpartan-400"
            >
              <div className="flex flex-col">
                First Name
                <input
                  type="text"
                  name="firstname"
                  className="p-1.5 px-3 rounded border border-neutrals-600"
                  placeholder="First Name"
                  defaultValue={defaultValues && defaultValues.firstName}
                  onChange={(e) => {
                    if (!/^[A-Za-z\s]{1,60}$/.test(e.target.value))
                      setErrors((e) => {
                        return { ...e, firstName: "Invalid first name." };
                      });
                    else
                      setErrors((e) => {
                        return { ...e, firstName: "" };
                      });
                  }}
                />
                <div className="text-red-600">{errors.firstName}</div>
              </div>
              <div className="flex flex-col">
                Last Name
                <input
                  type="text"
                  name="lastname"
                  className="p-1.5 px-3 rounded border border-neutrals-600"
                  placeholder="Last Name"
                  defaultValue={defaultValues && defaultValues.lastName}
                  onChange={(e) => {
                    if (!/^[A-Za-z\s]{1,60}$/.test(e.target.value))
                      setErrors((e) => {
                        return { ...e, lastNAme: "Invalid last name." };
                      });
                    else
                      setErrors((e) => {
                        return { ...e, lastName: "" };
                      });
                  }}
                />
                <div className="text-red-600">{errors.lastName}</div>
              </div>
              <div className="flex flex-col">
                Email
                <input
                  type="email"
                  name="email"
                  className="p-1.5 px-3 rounded border border-neutrals-600"
                  placeholder="Email"
                  defaultValue={defaultValues && defaultValues.email}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      !/^\S+@\S+\.\S+$/.test(e.target.value)
                    )
                      setErrors((e) => {
                        return { ...e, email: "Invalid email." };
                      });
                    else
                      setErrors((e) => {
                        return { ...e, email: "" };
                      });
                  }}
                />
                <div className="text-red-600">{errors.email}</div>
              </div>
              <div className="flex flex-col">
                Phone Number
                <input
                  type="tel"
                  name="phoneNumber"
                  className="p-1.5 px-3 rounded border border-neutrals-600"
                  placeholder="Phone Number"
                  defaultValue={defaultValues && defaultValues.phoneNumber}
                  onChange={(e) => {
                    if (e.target.value && !/^[0-9]{8,10}$/.test(e.target.value))
                      setErrors((e) => {
                        return { ...e, phoneNumber: "Invalid phone number." };
                      });
                    else
                      setErrors((e) => {
                        return { ...e, phoneNumber: "" };
                      });
                  }}
                />
                <div className="text-red-600">{errors.phoneNumber}</div>
              </div>
              <div className="flex flex-col">
                Department
                {DepartmentDropdown}
                <div className="text-red-600">{errors.department}</div>
              </div>
              <div className="flex flex-col">
                Role
                <input
                  type="text"
                  name="role"
                  className="p-1.5 px-3 rounded border border-neutrals-600"
                  placeholder="Role"
                  defaultValue={defaultValues && defaultValues.role}
                  onChange={(e) => {
                    if (!/^[0-9A-Za-z/-_\s]{0,60}$/.test(e.target.value))
                      setErrors((e) => {
                        return { ...e, role: "Invalid role." };
                      });
                    else
                      setErrors((e) => {
                        return { ...e, role: "" };
                      });
                  }}
                />
                <div className="text-red-600">{errors.role}</div>
              </div>
              <div className="flex items-center justify-center">
                <button className="p-2 px-6 bg-orange text-white rounded-lg">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateRecipientModel;
