import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";

import images from "../../constants/images";
import { TCampaignValue } from "../../pages/dashboard/CampaignCreate";

type propType = {
  charityList: GetAllCharitiesData[];
  setCampaignValue: Dispatch<SetStateAction<TCampaignValue>>;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const CharityModalCampaign = (props: propType) => {
  const { setModalVisible, charityList, setCampaignValue } = props;

  const [_charityList, set_CharityList] = useState<GetAllCharitiesData[]>([]);

  useEffect(() => {
    const check = charityList.find((v) => v.checked);
    if (!check) {
      charityList.forEach((v, i) => {
        if (i < 4) v.checked = true;
      });
    }
    set_CharityList([
      ...charityList.map((i) => {
        return { ...i };
      }),
    ]);
  }, [charityList]);

  const handleSave = () => {
    setCampaignValue((prev: TCampaignValue) => ({ ...prev, charityList: [..._charityList] }));
    setModalVisible(false);
  }

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-auto rounded-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[60%]">
            {/* Body */}
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-6">
              {/* Header */}
              <div className="flex justify-between">
                <div className="font-leagueSpartan-500 text-[24px] text-neutrals-800 ">
                  Choose up to 4 charities
                </div>
                <button
                  onClick={() => setModalVisible(false)}
                  className="self-start h-7"
                >
                  <img src={images.cross} className="w-5" alt="" />
                </button>
              </div>
              <div className="w-full max-h-[60vh] overflow-scroll">
                <table className="table-auto my-4 text-sm w-full">
                  <thead className="bg-neutrals-300 font-leagueSpartan-600 text-neutrals-700 text-[14px] capitalize">
                    <tr>
                      <th></th>
                      <th></th>
                      <th className="uppercase">charity organisation</th>
                      <th className="uppercase">Supported CauseS</th>
                    </tr>
                  </thead>
                  <tbody className="font-leagueSpartan-300 text-[15px] text-neutrals-900">
                    {_charityList.map((v, i) => (
                      <tr className="border-b">
                        <td className="p-0">
                          <div
                            className={`flex items-center cursor-pointer`}
                            onClick={() => {
                              set_CharityList((td) => {
                                const _td = td;
                                if (
                                  !_td[i].checked &&
                                  _td.filter((t) => t.checked).length > 3
                                ) {
                                  toast.warn("Maximum 4 charities allowed");
                                  return [..._td];
                                }
                                _td[i].checked = !_td[i].checked;

                                return [..._td];
                              });
                            }}
                          >
                            <img
                              src={
                                v.checked
                                  ? images.checkBoxSelected
                                  : images.checkBoxInactive
                              }
                              className="mx-2 h-[20px] w-[16px]"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="p-0">
                          <img src={v.pic1} className="w-[38px] ml-2" alt="" />
                        </td>
                        <td>{v.name}</td>
                        <td
                          dangerouslySetInnerHTML={{ __html: v.description }}
                        ></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-end">
                <button
                  className="p-1 px-3 rounded-lg bg-orange font-leagueSpartan-400 text-[16px] text-white"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharityModalCampaign;
