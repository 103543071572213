import images from "../../constants/images";
import { useAppSelector } from "../../redux/hooks";
import CustomNavigate from "../../utils/navigate";
import DeactivatedHeader from "./DeactivatedHeader";
import PreviewHeader from "./PreviewHeader";

type propType = {
  title?: string;
  logo?: string;
  navigate?: CustomNavigate;
  isPreview?: boolean;
  preViewTitle?: string;
  isDeactivated?: boolean; 
};

const Header = (props: propType) => {
  const { title, logo, navigate, isPreview = false, preViewTitle, isDeactivated = false } = props;

  const corpState = useAppSelector((state) => state.corpSliceState);
  const _logo =
    corpState.data && corpState.data.logo && corpState.data.logo !== ""
      ? corpState.data.logo
      : undefined;

  const __logo = _logo ? _logo : logo;

  if (isDeactivated) {
    return <DeactivatedHeader logo={__logo ?? images.logo} />;
  }

  if (isPreview)
    return (
      <PreviewHeader
        title={preViewTitle}
        logo={__logo ?? isPreview ? images.whiteLogo : images.logo}
      />
    );

  return (
    <div className="fixed top-0 z-10 w-screen flex p-3 px-6 shadow-md bg-white items-center justify-start max-md:justify-start">
      <div className="flex items-center">
        <img src={__logo ?? images.logo} className="h-[50px]" alt="" />
        <div className="ml-8 font-leagueSpartan-400 max-sm:text-[16px] text-[22px] max-md:hidden">
          {title}
        </div>
      </div>
    </div>
  );
};

export default Header;
