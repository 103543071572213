import { ChangeEventHandler, MouseEventHandler } from "react";
import images from "../../constants/images";

type TReferralSectionProps = {
  referralNameInput: {
    value: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
  };
  referralContactInput: {
    value: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
  };
  handleDeleteReferral?: MouseEventHandler<HTMLButtonElement>;
};

const ReferralSection = ({
  referralNameInput,
  referralContactInput,
  handleDeleteReferral,
}: TReferralSectionProps) => {
  return (
    <div className="flex text-sm justify-between py-2 text-left">
      <div className="flex flex-col grow pe-1">
        Friend's Name
        <input
          value={referralNameInput.value}
          onChange={referralNameInput.onChange}
          placeholder="James Dole"
          className="rounded mt-3 p-2 border border-gray-300 focus:outline-none focus:border-orange-500"
        />
      </div>
      <div className="flex flex-col grow ps-1">
        Friend's Mobile
        <input
          value={referralContactInput.value}
          onChange={referralContactInput.onChange}
          placeholder="+65"
          className="rounded mt-3 p-2 border border-gray-300 focus:outline-none focus:border-orange-500"
        />
      </div>
      <div className="flex ps-2">
        <button className="" onClick={handleDeleteReferral}>
          <span className="flex rounded-full text-neutral-400">
            <img src={images.cross} className="mt-8" alt="remove referral" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default ReferralSection;
