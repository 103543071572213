export enum orderStatusEnum {
  NOT_PLACED = "NOT_PLACED",
  PLACED = "PLACED",
  SHIPPED = "SHIPPED",
  DELIVERED = "DELIVERED",
  CANCELLED = "CANCELLED",
  ORDER_RECEIVED = "ORDER_RECEIVED",
  ON_THE_WAY = "ON_ROUTE",
  GIFT_READY_TO_COLLECT = "GIFT_READY_TO_COLLECT",
  COLLECTED = "COLLECTED",
}

export type orderStatusT =
  | "NOT_PLACED"
  | "PLACED"
  | "SHIPPED"
  | "DELIVERED"
  | "CANCELLED"
  | "ORDER_RECEIVED"
  | "ON_ROUTE"
  | "GIFT_READY_TO_COLLECT"
  | "COLLECTED";

export const orderStatusArray = Object.values(orderStatusEnum);

export type OrderTypesT =
  | "PHYSICAL_PICKUP"
  | "PHYSICAL_DELIVER"
  | "DIGITAL"
  | "DONATION";

export enum orderTypeEnum {
  PHYSICAL_PICKUP = "PHYSICAL_PICKUP",
  PHYSICAL_DELIVER = "PHYSICAL_DELIVER",
  DIGITAL = "DIGITAL",
  DONATION = "DONATION",
}

export const orderTypeArray = Object.values(orderTypeEnum);
