import { isAxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../constants/api";

export const AllAddOn = () => {
  const navigate = useNavigate();
  const errorMessage = (error?: string) => toast.error(error || "error!");
  const [addOn, setAddOn] = useState<AddOn[]>([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [addOnToDelete, setAddOnToDelete] = useState<AddOn>();
  const [isDeleting, setIsDeleting] = useState(false);

  const loadAddOns = useCallback(
    () =>
      Api.getAllAddOns()
        .then(({ data }) => setAddOn(data))
        .catch(console.error),
    [Api, setAddOn]
  );

  useEffect(() => {
    loadAddOns();
  }, []);

  const handleEdit = (item: AddOn) => {
    navigate("/admin", { state: { item, tab: 10, subTab: 0 } });
  };

  const handleDelete = useCallback(() => {
    if (addOnToDelete) {
      setIsDeleting(true);
      Api.deleteAddOn(addOnToDelete.id)
        .catch((err) => {
          if (isAxiosError(err))
            errorMessage(err.response?.data.message ?? "Error deleting add-on");
          else errorMessage();
        })
        .finally(() => {
          setIsDeleting(false);
          setShowConfirmDelete(false);
          loadAddOns();
        });
    }
  }, [addOnToDelete, Api, setIsDeleting, setShowConfirmDelete, loadAddOns]);

  return (
    <>
      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        {addOn?.map((item) => {
          return (
            <div className="border-2 p-5 border-black" key={item.id}>
              <h1>{item.id}</h1>
              <h2>{item.name}</h2>
              <img style={{ width: "100px" }} src={item.image_1} />
              <h2>{item.description}</h2>
              <h2>{item.listPrice}</h2>
              <h2>{item.costPrice}</h2>
              <button
                type="button"
                onClick={() => {
                  handleEdit(item);
                }}
                style={{
                  backgroundColor: "Blue", // Changed to Blue
                  color: "white",
                  boxShadow: "2px 2px 2px black",
                  marginRight: "5px", // Added margin for spacing
                }}
              >
                Edit
              </button>
              <button
                className="mx-3"
                onClick={() => {
                  setAddOnToDelete(item);
                  setShowConfirmDelete(true);
                }}
                style={{
                  backgroundColor: "Red",
                  color: "white",
                  boxShadow: "2px 2px 2px black",
                }}
              >
                Delete
              </button>
            </div>
          );
        })}
      </div>
      {showConfirmDelete && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md w-96">
            <h2 className="text-xl font-semibold mb-4">Delete AddOn</h2>
            <div className="mb-5">
              Are you sure you want to delete{" "}
              <span className="font-bold">{addOnToDelete?.name}</span>?
            </div>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 mr-2 text-white bg-gray-500 rounded-md hover:bg-gray-600 disabled:bg-slate-500"
                onClick={() => {
                  setShowConfirmDelete(false);
                }}
                disabled={isDeleting}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-white bg-red-700 rounded-md hover:bg-red-600 disabled:bg-slate-500"
                onClick={() => {
                  handleDelete();
                }}
                disabled={isDeleting}
              >
                {isDeleting ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
