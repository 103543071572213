import images from "../../../constants/images";

type propsType = {
  setIsMaxCampaignsErrorModalVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

const MaxWhatsappCampaignsErrorModal = (props: propsType) => {
  return (
    <>
      <div
        className="font-leagueSpartan-400 fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 cursor-default"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-white p-6 rounded-md w-[596px] h-auto">
          <div className="flex justify-between">
            <h2 className="text-2xl font-semibold mb-2">Uh oh!</h2>
            <img
              className="self-start cursor-pointer"
              src={images.cross}
              alt="close"
              onClick={() => {
                props.setIsMaxCampaignsErrorModalVisible(false);
              }}
            />
          </div>
          <div className="grid gap-2 text-lg">
            <p>
              There can only be 3 campaigns listed on WhatsApp at any time.
              Please remove an existing campaign from WhatsApp before enabling a
              new one.
            </p>
            <p className="font-semibold">Please follow these instructions:</p>
            <ul className="ml-2">
              <li>1. Click the 3 dots of selected campaign.</li>
              <li>2. Select "Unselect from Whatsapp"</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MaxWhatsappCampaignsErrorModal;
