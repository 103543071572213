import axios from "axios";
import jwtDecode from "jwt-decode";
import { AppDispatch } from "../redux/store";
import {
  setAccessData,
  setIsAuthenticated,
  setIsLoading,
} from "../redux/globalSlice";
import Api from "../constants/api";
import { setCorpData } from "../redux/corpSlice";
/* eslint-disable camelcase */

class _JwtService {
  dispatch?: AppDispatch;
  init(dispatch?: AppDispatch) {
    if (dispatch) this.dispatch = dispatch;
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response?.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            // this.emit('onAutoLogout', 'Invalid access_token');
            this.logout();
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      // this.emit('onNoAccessToken');
      this.logout();
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.signInWithToken(access_token);
      // this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      // this.emit('onAutoLogout', 'access_token expired');
    }
  };

  // createUser = data => {
  // 	return new Promise((resolve, reject) => {
  // 		axios.post('/api/auth/register', data).then(response => {
  // 			if (response.data.user) {
  // 				this.setSession(response.data.access_token);
  // 				resolve(response.data.user);
  // 			} else {
  // 				reject(response.data.error);
  // 			}
  // 		});
  // 	});
  // };

  // signInWithEmailAndPassword = (phoneNumber, pin) => {
  // 	return new Promise((resolve, reject) => {
  // 		axios
  // 			.post(`${API}/auth/login`, {
  // 				phoneNumber: phoneNumber.toString(),
  // 				pin
  // 			})
  // 			.then(response => {
  // 				if (response.data?.data?.user) {
  // 					this.setSession(response.data.data.accessToken);

  // 					resolve({ data: response.data.data.user, role: ['admin'] });
  // 				} else {
  // 					reject(response.data.error);
  // 				}
  // 			})
  // 			.catch(err => {
  // 				toast.error(
  // 					err.response
  // 						? err.response.data.message
  // 							? err.response.data.message
  // 							: err.response.data
  // 						: 'Network Error'
  // 				);
  // 			});
  // 	});
  // };

  signInWithToken = (token: string) => {
    return new Promise((resolve, reject) => {
      if (this.dispatch) this.dispatch(setIsLoading(true));
      Api.loginWithToken(token)
        .then((res) => {
          this.setSession(res.data.tokens.access_token);

          if (res.data.corp)
            Api.getOneCorp(res.data.corp.id).then((res) => {
              if (this.dispatch) {
                this.dispatch(setCorpData(res.data));
              }
            });

          if (this.dispatch) {
            this.dispatch(setIsLoading(false));
            this.dispatch(setAccessData(res.data));
          }
          resolve(res);
        })
        .catch((error) => {
          if (this.dispatch) this.dispatch(setIsLoading(false));
          this.logout();
          // reject(new Error("Failed to login with token."));
        });
    });
  };

  // updateUserData = user => {
  // 	return axios.post('/api/auth/user/update', {
  // 		user
  // 	});
  // };

  setSession = async (access_token: string | null) => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
      if (this.dispatch) {
        this.dispatch(setIsAuthenticated(true));
      }
    } else {
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common.Authorization;
      if (this.dispatch) {
        this.dispatch(setIsAuthenticated(false));
        this.dispatch(setAccessData(undefined));
        this.dispatch(setCorpData(undefined));
      }
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = (access_token: string | null) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token) as any;
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };
}

const JwtService = new _JwtService();

export default JwtService;
