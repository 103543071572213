import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProductDetailsCard from "../../components/common/ProductDetailsCard";
import BackButtonGifting from "../../components/giftSelection/BackButton";
import CartDrawerHamper from "../../components/giftSelectionHamper/CartDrawerHamper";
import FooterHamper from "../../components/giftSelectionHamper/FooterHamper";
import HeaderHamper from "../../components/giftSelectionHamper/HeaderHamper";
import ProdutDetailsModalHamper from "../../components/giftSelectionHamper/ProdutDetailsModalHamper";
import Api from "../../constants/api";
import images from "../../constants/images";
import {
  setCampaignRecipientData,
  setTotalCredits,
} from "../../redux/cartSlice";
import { setCorpData } from "../../redux/corpSlice";
import { setIsLoading } from "../../redux/globalSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Voucher } from "../../types/vouchers";
import { getUsedCredits } from "../../utils/cartUtils";
import { isShowVoucher, populateHeader } from "../../utils/giftUtils";
import CustomNavigate from "../../utils/navigate";

// TODO: Move credit calculation to backend
export const CREDITS_PER_DOLLAR = 5;
export const MIN_PRODUCT_CREDITS = 1;

const ChooseCategoryHamper = () => {
  const cartState = useAppSelector((state) => state.cartState);
  const selectGiftState = useAppSelector((state) => state.selectGiftState);

  const recipientId =
    useSearchParams()[0].get("recid") ||
    cartState.campaignRecipientData?.result.url_id;
  const navigate = new CustomNavigate(useNavigate(), { recipientId });

  const conversationRatio = cartState.conversationRatio;
  const totalCredits = cartState.totalCredits;
  const dispatch = useAppDispatch();
  const cart = cartState.cart;
  const voucherCart = cartState.voucherCart;
  const usedCredits = getUsedCredits(cart, voucherCart);
  const unusedCredits = totalCredits - usedCredits;

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const addImageToVouchers = (vouchers: Voucher[]): Voucher[] => {
    return vouchers.map(
      (
        {
          image_1,
          image_2,
          image_3,
          image_4,
          image_5,
          image_6,
          credits,
          ...voucher
        },
        index
      ) => {
        if (
          [image_1, image_2, image_3, image_4, image_5, image_6].every(
            (image) => !image
          )
        ) {
          image_1 = images.defaultVoucher;
        }
        return {
          image_1,
          image_2,
          image_3,
          image_4,
          image_5,
          image_6,
          credits: Math.ceil(
            Math.min(...voucher.denominations) / CREDITS_PER_DOLLAR
          ), // min voucher denomination
          ...voucher,
        };
      }
    );
  };

  const groupedProduct = cartState.campaignRecipientData?.groupedProduct || [];
  const vouchers: Voucher[] = addImageToVouchers(
    cartState.campaignRecipientData?.vouchers || []
  );
  const headers = useMemo(
    () => populateHeader(groupedProduct, vouchers),
    [groupedProduct, vouchers]
  );
  useEffect(() => {
    if (cartState.campaignRecipientData) return;
    dispatch(setIsLoading(true));
    Api.getCampaignRecipient(recipientId!)
      .then((res) => {
        Api.getOneCorp(res.data.result.campaign.corp.id).then((res) => {
          dispatch(setCorpData(res.data));
        });
        // TODO: Calculate credits in backend, products in response should already have credits
        res.data.groupedProduct.forEach((item) =>
          item.products.forEach(
            (product) =>
              (product.credits =
                Math.ceil(
                  (product.listPrice + product.deliveryFee) / CREDITS_PER_DOLLAR
                ) || MIN_PRODUCT_CREDITS)
          )
        );
        dispatch(setCampaignRecipientData(res.data));

        // TODO: Calculate credits in backend, campaign in response should already have budget in credits
        dispatch(
          setTotalCredits(
            Math.floor(
              res.data.result.campaign.perPersonBudgetWithoutFee /
                CREDITS_PER_DOLLAR
            )
          )
        );
        dispatch(setCampaignRecipientData(res.data));
      })
      .finally(() => dispatch(setIsLoading(false)));
  }, [cartState.campaignRecipientData, dispatch, recipientId]);

  const onViewBasketClick = useCallback(() => {
    setDrawerVisible(true);
  }, []);

  useEffect(() => {
    if (!cart.length) {
      setDrawerVisible(false);
    }
  }, [cart.length]);

  useEffect(() => {
    if (window.innerWidth < 1024) return;
    const element = document.querySelector(".hoverRight");
    let idx: any;
    if (element) {
      element.addEventListener("mouseenter", function () {
        idx = setInterval(() => (element.scrollLeft += 1), 10);
      });

      element.addEventListener("mouseleave", function () {
        clearInterval(idx);
      });
    }
    return () => {
      // @ts-ignore
      if (element) element?.removeEventListener("mouseenter", {});
      // @ts-ignore
      if (element) element?.removeEventListener("mouseleave", {});
    };
  }, []);

  const handleProductClick = (product: GetCampaignRecipientProduct) => {
    if (product) {
      navigate.to(
        "/hamper/gift-selection/choose-category/:id",
        { key: ":id", value: 1 },
        product
      );
    }
  };
  const handleVoucherClick = (voucher: Voucher) => {
    if (voucher) {
      navigate.to(
        "/gift-selection/choose-voucher-category/:id",
        { key: ":id", value: 1 },
        { voucher: voucher, isHamper: true }
      );
    }
  };

  const handleQuickViewClick = (index: number) => {
    if (index !== -1) {
      setSelectedProduct(index);
      setModalVisible(true);
    }
  };

  const isDeactivated = useMemo(
    () => selectGiftState.campaignRecipientData?.result?.isForTesting ?? false,
    [selectGiftState.campaignRecipientData?.result?.isForTesting]
  );

  return (
    <div className="relative">
      <HeaderHamper title="Choose your gift(s)!" isPreview={isDeactivated} />
      <div className="max-md:hidden m-6 mt-[100px]">
        <BackButtonGifting navigate={navigate} />
      </div>
      <div className="max-md:mt-[100px] max-md:pb-28 md:pb-24">
        {/* CATEGORIES MD */}
        <div className="py-2 mx-5 flex overflow-x-auto md:mx-[10%] hoverRight lg:justify-center">
          {headers.map((category, index) => {
            return (
              <div
                className={`grid grid-flow-col auto-cols-max mx-2 max-sm:py-[6px] max-sm:px-[12px] py-[8px] px-[16px] w-fit text-center border  hover:bg-lightOrange rounded-lg cursor-pointer ${
                  index === selectedCategory
                    ? "border-orange bg-lightOrange text-orange"
                    : "border-neutrals-400 text-neutrals-700"
                } `}
                key={category.id}
                onClick={() => setSelectedCategory(index)}
              >
                <div
                  className={`flex items-center font-leagueSpartan-400  max-sm:text-[14px] text-[16px] tracking-[2.24px]`}
                >
                  <img
                    src={category?.image}
                    className="h-[24px] aspect-square"
                    alt=""
                  />
                  &nbsp;&nbsp;
                  {category?.name}
                </div>
              </div>
            );
          })}
        </div>

        {/* PRODUCTS */}
        {!isShowVoucher(selectedCategory, headers, vouchers) && (
          <ProductDetailsCard
            productProp={{
              products:
                groupedProduct.length > 0
                  ? groupedProduct[selectedCategory].products
                  : [],
              handleProductClick: handleProductClick,
            }}
            handleQuickViewClick={handleQuickViewClick}
            hamperProps={{ conversationRatio, unusedCredits }}
          />
        )}
        {isShowVoucher(selectedCategory, headers, vouchers) && (
          <ProductDetailsCard
            voucherProp={{
              vouchers: vouchers,
              handleVoucherClick: handleVoucherClick,
            }}
            handleQuickViewClick={handleQuickViewClick}
            hamperProps={{ conversationRatio, unusedCredits }}
          />
        )}
      </div>
      {/* Footer (with Selected items, Credits, View Basket) */}
      <FooterHamper
        productCart={cart}
        voucherCart={voucherCart}
        onViewBasketClick={onViewBasketClick}
        showProgress={true}
        credits={{
          total: totalCredits,
          used: getUsedCredits(cart, cartState.voucherCart),
        }}
      />
      {modalVisible && (
        <>
          {!isShowVoucher(selectedCategory, headers, vouchers) &&
            selectedProduct <
              groupedProduct[selectedCategory].products.length && (
              <ProdutDetailsModalHamper
                setModalVisible={setModalVisible}
                product={
                  groupedProduct[selectedCategory].products[selectedProduct]
                }
              />
            )}
          {isShowVoucher(selectedCategory, headers, vouchers) &&
            selectedProduct < vouchers.length && (
              <ProdutDetailsModalHamper
                setModalVisible={setModalVisible}
                voucher={vouchers[selectedProduct]}
              />
            )}
        </>
      )}
      {drawerVisible && (
        <CartDrawerHamper setDrawerVisible={setDrawerVisible} />
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default ChooseCategoryHamper;
