import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ActivateVoucherModal } from "../../components/Modal/ActivateVoucherModal";
import Accordin from "../../components/common/Accordin";
import images from "../../constants/images";
import { Voucher } from "../../types/vouchers";
import { getVoucherOrderKey } from "../../utils/giftUtils";
import Quantity from "../giftSelectionHamper/Quantity";

type propType = {
  voucher: Voucher;
  availableVouchers: number;
  setModalVisible: React.Dispatch<React.SetStateAction<number>>;
  voucherOrders: VoucherOrder[];
  email: string;
  toGenerateIdsMap: Map<string, VoucherOrder[]>;
  setToGenerateIdsMap: Dispatch<SetStateAction<Map<string, VoucherOrder[]>>>;
};
const VoucherDetailsModal = ({
  voucher,
  setModalVisible,
  email,
  availableVouchers,
  toGenerateIdsMap,
  voucherOrders,
  setToGenerateIdsMap,
}: propType) => {
  const [name] = useState(voucher?.name);
  const [voucherQuantity, setVoucherQuantity] = useState(0);
  const [description] = useState(voucher?.description || "");
  const [productImages, setProductImage] = useState<string[]>([]);
  const [showActivateVoucherModal, setShowActivateVoucherModal] =
    useState(false);

  useEffect(() => {
    const pictures: string[] = [];
    if (voucher?.image_1) pictures.push(voucher?.image_1);
    if (voucher?.image_2) pictures.push(voucher?.image_2);
    if (voucher?.image_3) pictures.push(voucher?.image_3);
    if (voucher?.image_4) pictures.push(voucher?.image_4);
    if (voucher?.image_5) pictures.push(voucher?.image_5);
    if (voucher?.image_6) pictures.push(voucher?.image_6);
    if (pictures.length === 0) {
      pictures.push(images.defaultVoucher);
    }
    setProductImage(pictures);
  }, [voucher]);

  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedVoucherOrders, setSelectedVoucherOrder] = useState<
    VoucherOrder[]
  >([]);
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setModalVisible(-1);
      }
    },
    [setModalVisible]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, false);
    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [handleKeyPress]);

  const nextImage = useCallback(() => {
    setSelectedImage((si) => {
      if (si < productImages.length - 1) {
        return si + 1;
      }
      return si;
    });
  }, [productImages.length]);

  const previousImage = useCallback(() => {
    setSelectedImage((si) => {
      if (si - 1 > -1) {
        return si - 1;
      }
      return si;
    });
  }, []);
  const updateVouchers = () => {
    setShowActivateVoucherModal(false);
    if (selectedVoucherOrders.length === 0) {
      return;
    }
    const key = getVoucherOrderKey(selectedVoucherOrders[0]);
    const idSet = new Set(
      selectedVoucherOrders.map((voucherOrder) => voucherOrder.id)
    );
    const vouchers = toGenerateIdsMap.get(key) ?? [];
    const filteredVouchers = [...vouchers].filter(
      (voucher) => !idSet.has(voucher.id)
    );
    const newIdMap = new Map(toGenerateIdsMap);
    newIdMap.set(key, filteredVouchers);
    setToGenerateIdsMap(newIdMap);
  };

  const handleVoucherActivation = () => {
    if (voucherQuantity === 0 || voucherQuantity > voucherOrders.length) {
      return;
    }
    setShowActivateVoucherModal(true);
    setSelectedVoucherOrder(voucherOrders.slice(0, voucherQuantity));
  };
  return (
    <>
      <div
        className="relative z-0"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center max-sm:p-0 text-center max-sm:items-end sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-sm:mt-7 sm:my-8 max-sm:w-full sm:w-full sm:max-w-[800px]">
              <div className="bg-white px-2 pb-2 pt-2 sm:p-8 sm:pb-5">
                {/* Body */}
                <div className="sm:hidden flex justify-end">
                  <button
                    onClick={() => setModalVisible(-1)}
                    className="self-start"
                  >
                    <img src={images.cross} className="h-[13.5px]" alt="" />
                  </button>
                </div>
                <div className="flex relative">
                  <div
                    className={`max-sm:w-full max-md:grid max-md:grid-cols-2 md:flex md:gap-6 max-sm:grid-cols-1`}
                  >
                    {/* Images */}
                    <div className="flex flex-col justify-center lg:w-[320px]">
                      <div className="relative border rounded-2xl">
                        <button
                          className="absolute top-[45%] left-1 p-2 rounded-full bg-neutrals-200"
                          onClick={() => previousImage()}
                        >
                          <img
                            src={
                              selectedImage !== 0
                                ? images.leftArrow
                                : images.leftArrowGrey
                            }
                            className="w-[16px] aspect-square "
                            alt=""
                          />
                        </button>
                        <button
                          className="absolute top-[45%] right-1 p-2 rounded-full bg-neutrals-200"
                          onClick={() => nextImage()}
                        >
                          <img
                            src={
                              selectedImage + 1 === productImages.length
                                ? images.leftArrowGrey
                                : images.leftArrow
                            }
                            className="w-[16px] aspect-square rotate-180"
                            alt=""
                          />
                        </button>
                        <div className="flex justify-center">
                          <img
                            src={productImages[selectedImage]}
                            className="w-[100%] aspect-square rounded-2xl object-cover"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 overflow-x-auto mt-5 max-md:hidden">
                        {productImages.map((p, i) => {
                          return (
                            <img
                              src={p}
                              key={i}
                              className={`w-[20%] aspect-square rounded-lg border ${
                                selectedImage === i && " border-orange"
                              }`}
                              alt=""
                              onClick={() => setSelectedImage(i)}
                            />
                          );
                        })}
                      </div>
                      <div className="md:hidden mt-4 flex gap-2 justify-center items-center">
                        {productImages.map((v, i) => {
                          if (selectedImage === i)
                            return (
                              <div className="w-[37px] h-[8px] rounded-full bg-orange"></div>
                            );
                          return (
                            <div className="w-[8px] h-[8px] rounded-full bg-neutrals-400"></div>
                          );
                        })}
                      </div>
                    </div>
                    {/* Content */}
                    <div className="ml-4 flex flex-col lg:w-[385px]">
                      <div className="font-leagueSpartan-400 text-[24px] text-neutrals-900 my-4">
                        {name}
                      </div>

                      <div className="shadow-lg p-4 rounded-lg my-4">
                        <Quantity
                          quantityAvailable={availableVouchers}
                          quantity={voucherQuantity}
                          setQuantity={setVoucherQuantity}
                        />
                        <div className="max-sm:my-2 sm:mt-4 sm:mb-8 ">
                          <Accordin
                            data={[
                              {
                                id: 1,
                                title: "Description",
                                description: description,
                                checked: false,
                              },
                            ]}
                          />
                          <Accordin
                            data={[
                              {
                                title: "Terms and Conditions",
                                description: voucher?.termsContent || "",
                                id: 2,
                                checked: false,
                              },
                            ]}
                          />

                          <Accordin
                            data={[
                              {
                                title: "Validity",
                                description:
                                  voucher?.expiryDays?.toString() ||
                                  "No Expiry Date",
                                id: 3,
                                checked: false,
                              },
                            ]}
                          />
                        </div>
                        {availableVouchers > 0 && (
                          <button
                            className={`w-full p-2 rounded-lg text-white my-3 ${
                              voucherQuantity === 0
                                ? "bg-neutrals-500"
                                : "bg-orange"
                            }`}
                            onClick={handleVoucherActivation}
                            disabled={voucherQuantity === 0}
                          >
                            Activate Voucher
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="max-sm:hidden absolute right-[-6px] top-[-6px]">
                    <button
                      onClick={() => setModalVisible(-1)}
                      className="self-start"
                    >
                      <img src={images.cross} className="w-[18.5px]" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showActivateVoucherModal && selectedVoucherOrders.length > 0 && (
        <ActivateVoucherModal
          setShowActivateVoucherModal={setShowActivateVoucherModal}
          voucherOrders={selectedVoucherOrders}
          onSuccessDone={updateVouchers}
          email={email}
        />
      )}
    </>
  );
};

export default VoucherDetailsModal;
