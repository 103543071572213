import React from "react";

interface IProps {
  className?: string;
  children: React.ReactNode;
  label?: string;
  errorMessage?: string;
  wordCount?: string;
}

const Field = ({
  className = "",
  children,
  label,
  errorMessage,
  wordCount,
}: IProps) => (
  <div className={className}>
    {label && (
      <div className="flex justify-between items-center">
        <label className="block mb-2 font-medium text-gray-900 dark:text-white">
          {label}
        </label>
        {wordCount && (
          <span className="font-leagueSpartan-400 text-[14px] text-neutrals-600">
            {wordCount}
          </span>
        )}
      </div>
    )}
    {children}
    <p className="mt-2 text-red-600 min-h-[20px]">
      {errorMessage ? errorMessage : ""}
    </p>
  </div>
);

export default Field;
