import ToastCustomized from "../../components/common/ToastCustomized";
import Main from "../../components/dashboard/Main";
import { RecipentTabs } from "../../components/dashboard/RecipentTabs";
const Recipients = () => {
  return (
    <>
      <Main title="All Recipients" logo={true}>
        <div>
          <div className="p-6 grid grid-flow-row gap-6">
            <div>
              <RecipentTabs />
            </div>
          </div>
          <ToastCustomized />
        </div>
      </Main>
    </>
  );
};

export default Recipients;
