import images from "../../constants/images";
type props = {
  id: number;
  name: string;
  image?: string;
  credits: number;
  handleDecrement: (index: number) => {};
  handleIncrement: (index: number) => {};
  handleDelete: (index: number) => {};
  quantity: number;
  index: number;
};
export const CartItem = ({
  id,
  name,
  image,
  handleDecrement,
  handleIncrement,
  handleDelete,
  quantity,
  index,
  credits,
}: props) => {
  return (
    <div key={id} className="grid grid-cols-4 gap-4 p-4">
      <div>
        <img
          src={image}
          className="rounded-2xl h-[88px] w-[88px] object-contain"
          alt=""
        />
      </div>
      <div className="col-span-2">
        <p className="font-leagueSpartan-400 text-[18px] text-neutrals-900">
          {name}
        </p>
        <div className="grid grid-cols-3 border rounded-lg justify-items-center w-[60%] mt-4">
          <button className="p-1.5" onClick={() => handleDecrement(index)}>
            -
          </button>
          <button className="p-1.5">{quantity}</button>
          <button className="p-1.5" onClick={() => handleIncrement(index)}>
            +
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-between items-center">
        <p className="font-leagueSpartan-500  text-[18px] text-orange">
          {credits} Credits
        </p>
        <img
          src={images.dustbin}
          alt=""
          className="cursor-pointer"
          onClick={() => handleDelete(index)}
        />
      </div>
    </div>
  );
};
