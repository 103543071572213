import { isAxiosError } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import ToastCustomized from "../../components/common/ToastCustomized";
import Header from "../../components/giftSelection/Header";
import Api from "../../constants/api";
import colours from "../../constants/colours";
import images from "../../constants/images";
import { setCorpData } from "../../redux/corpSlice";
import { setIsLoading } from "../../redux/globalSlice";
import { useAppSelector } from "../../redux/hooks";
import {
  setCampaignRecipientData,
  setRecipientId,
  setRedirectUrl,
} from "../../redux/selectGiftSlice";
import CustomNavigate from "../../utils/navigate";

const GiftSelectionLanding = () => {
  const selectGiftState = useAppSelector((state) => state.selectGiftState);
  const corpState = useAppSelector((state) => state.corpSliceState);
  const params = useParams();
  const recipientId =
    params.id || selectGiftState.campaignRecipientData?.result.url_id;
  const message = useSearchParams()[0].get("message");
  const productIdsString = useSearchParams()[0].get("productIds");
  const recipientMessageTitle = useSearchParams()[0].get(
    "recipientMessageTitle"
  );
  const messageFromName = useSearchParams()[0].get("messageFromName");
  const footerGreeting = useSearchParams()[0].get("footerGreeting");
  const recipientName = useSearchParams()[0].get("recipientName");

  const navigate = useNavigate();
  const customNavigate = useMemo(
    () => new CustomNavigate(navigate, { recipientId }),
    [navigate, recipientId]
  );

  const dispatch = useDispatch();

  const redirectURI =
    useSearchParams()[0].get("callback") || "https://sendagift.sg/";
  dispatch(setRedirectUrl(redirectURI));
  const [dataLoaded, setDataLoaded] = useState(false);

  const loadData = useCallback(async () => {
    try {
      if (dataLoaded) return;
      setDataLoaded(true);
      dispatch(setIsLoading(true));
      let campaignRecipientData = null;
      const productIds = productIdsString
        ?.split(",")
        .map((item) => parseInt(item, 10));
      if (productIds && productIds.length) {
        campaignRecipientData = await Api.getPreviewGift(
          productIds,
          message ?? "",
          recipientMessageTitle ?? "",
          messageFromName ?? "",
          footerGreeting ?? "",
          recipientName ?? ""
        );
      } else {
        campaignRecipientData = await Api.getCampaignRecipient(recipientId!);
      }
      if (!campaignRecipientData) return;
      const corpData = await Api.getOneCorp(
        campaignRecipientData.data.result.campaign.corp.id
      );
      dispatch(setCorpData(corpData.data));
      if (campaignRecipientData.data.result.giftClaimed) {
        customNavigate.to("/gift-selection/thank-you-notes");
        dispatch(setIsLoading(false));
        return;
      }
      dispatch(setCampaignRecipientData(campaignRecipientData.data));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.log(error);
      if (isAxiosError(error) && error?.response?.data?.data?.message)
        toast.error(error.response?.data.data.message);
      else toast.error("Data not found!");
      dispatch(setIsLoading(false));
    }
  }, [
    dataLoaded,
    dispatch,
    productIdsString,
    message,
    recipientMessageTitle,
    messageFromName,
    footerGreeting,
    recipientName,
    recipientId,
    customNavigate,
  ]);

  useEffect(() => {
    if (!selectGiftState.campaignRecipientData) {
      if (recipientId) dispatch(setRecipientId(recipientId));
      if (!dataLoaded) loadData();
    }
  }, [
    dataLoaded,
    dispatch,
    loadData,
    recipientId,
    selectGiftState.campaignRecipientData,
  ]);

  const isDeactivated = useMemo(
    () => selectGiftState.campaignRecipientData?.result?.isForTesting ?? false,
    [selectGiftState.campaignRecipientData?.result?.isForTesting]
  );

  const isGiftLinkDeactivated = useMemo(
    () => selectGiftState.campaignRecipientData?.result?.isDeactivated ?? false,
    [selectGiftState.campaignRecipientData?.result?.isDeactivated]
  );

  if (
    !(recipientId || productIdsString) ||
    !selectGiftState.campaignRecipientData
  ) {
    return <ToastCustomized />;
  }

  const logo =
    corpState.data && corpState.data.logo && corpState.data.logo !== ""
      ? corpState.data.logo
      : undefined;

  const handleClick = () => {
    const result = selectGiftState.campaignRecipientData?.result;
    const message =
      result?.recipientMessage && result.recipientMessage.length > 0
        ? result.recipientMessage
        : result?.campaign.recipientMessage;
    if (message && message.length > 0) {
      customNavigate.to("/gift-selection/personalized-message");
    } else {
      customNavigate.to("/gift-selection/choose-category");
    }
  };

  return (
    <>
      <div className="relative h-[100vh]">
        <Header
          logo={logo}
          isPreview={isDeactivated}
          isDeactivated={isGiftLinkDeactivated}
        />
        {!isGiftLinkDeactivated && (
          <>
            <div
              className={`absolute flex justify-center bottom-[75vh] w-[100%] font-leagueSpartan-600 lg:text-4xl text-2xl text-orange sm:tracking-[5.12px]`}
            >
              TAP TO OPEN YOUR GIFT!
            </div>
            <div className="absolute flex justify-center bottom-[40vh] w-[100%]">
              <img
                src={images.giftGif}
                alt=""
                className="cursor-pointer h-64 max-md:h-60"
                onClick={handleClick}
              />
            </div>
            <div
              style={{ backgroundColor: colours.lightOrange }}
              className={`absolute bottom-0 h-[40vh] w-[100%]`}
            ></div>
          </>
        )}
      </div>

      <ToastCustomized />
    </>
  );
};

export default GiftSelectionLanding;
