import { ChangeEventHandler } from "react";

type TTextarea = {
  className?: string;
  header: string;
  placeholder?: string;
  name?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
};
export const Textarea = ({
  className,
  header,
  placeholder,
  name,
  onChange,
}: TTextarea) => {
  return (
    <div className={`flex flex-col rounded-xl ${className}`}>
      <div className="ps-2 py-2 text-md">
        <div>{header}</div>
      </div>
      <div className="my-auto">
        <textarea
          name={name}
          rows={4}
          placeholder={placeholder}
          onChange={onChange}
          className="w-full text-slate-500 max-sm:text-[16px] text-[16px] font-leagueSpartan-400 resize-none rounded-xl border border-gray-300 focus:ring-orange-500 focus:border-orange-500"
        />
      </div>
    </div>
  );
};
