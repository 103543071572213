import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import GoogleOAuth from "../../components/Auth/GoogleOAuth";
import CreateAccountPage from "../../components/CreateAccountPage/CreateAccountPage";
import ResetPasswordModel from "../../components/common/ResetPasswordModel";
import SetPhoneNumberModel from "../../components/common/SetPhoneNumberModel";
import ToastCustomized from "../../components/common/ToastCustomized";
import Api from "../../constants/api";
import images from "../../constants/images";
import { useAppSelector } from "../../redux/hooks";
import { getCallbackURL } from "../../utils/callbackUrl";
import CustomNavigate from "../../utils/navigate";
import Signup from "./Signup";

enum SelectablePages {
  SIGN_IN,
  CREATE_ACCOUNT,
}

export const SignUpPage = () => {
  const navigate = new CustomNavigate(useNavigate());
  const globalState = useAppSelector((state) => state.globalSliceState);
  const [model, setmodel] = useState(false);
  const [selected, setSelected] = useState<SelectablePages>(
    SelectablePages.SIGN_IN
  );
  const [searchParams] = useSearchParams();
  const [showPhoneNumberModel, setShowPhoneNumberModel] = useState(false);

  const resetPasswordCode = searchParams.get("resetPasswordCode");
  useEffect(() => {
    if (resetPasswordCode) {
      setmodel(true);
    }
  }, [resetPasswordCode]);

  useEffect(() => {
    if (!globalState.isAuthenticated) {
      return;
    }

    const callback = searchParams.get("callback");

    if (!callback) {
      navigate.to("/dashboard");
      return;
    }

    if (!globalState.authData?.phoneNumber) {
      setShowPhoneNumberModel(true);
      return;
    }

    Api.getSubscription()
      .then(({ data }) => {
        if (data.active) {
          // Already has an active subscription
          return getCallbackURL(callback);
        }
        // Generate a subscription payment link
        return Api.getStripeCheckoutUrl().then(({ data }) => {
          return data.checkoutUrl;
        });
      })
      .then((redirectUrl) => {
        window.location.href = redirectUrl;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalState.authData?.phoneNumber,
    globalState.isAuthenticated,
    searchParams,
  ]);

  useEffect(() => {
    const callback = searchParams.get("callback");
    if (callback === "whatsapp") {
      setSelected(SelectablePages.CREATE_ACCOUNT);
    }
  }, [searchParams]);

  return (
    <div className="min-h-screen flex justify-center relative bg-neutrals-200 md:justify-end">
      <img
        src={images.trackOrderBottom2}
        className="absolute bottom-0 left-0 h-[70%] w-[100%] md:w-[61%] md:-left-[8%]"
        alt=""
      />

      <div className="w-full bg-white shadow-lg rounded-lg m-0 px-[8%] flex flex-col justify-center z-10 md:m-5 lg:w-[50%]">
        <div className="rounded p-0 mt-5 flex flex-col items-center gap-2 lg:p-10">
          <div className="flex justify-center">
            <img src={images.logo} className="w-52" alt="smilie logo" />
          </div>

          {selected === SelectablePages.SIGN_IN && (
            <div className="text-center font-leagueSpartan-400 text-[22px] mt-4 mb-2">
              Ignite delight with Smilie
            </div>
          )}

          <div className="flex justify-center w-full">
            <div className="grid grid-cols-2 rounded-lg mt-2 bg-neutrals-300 w-full">
              <span
                onClick={() => setSelected(0)}
                className={`my-auto font-rubik-600 text-[13px] tracking-[-0.28px] text-center cursor-pointer py-1.5 m-[3px] hover:bg-white rounded-md ${
                  selected === SelectablePages.SIGN_IN
                    ? "bg-white text-neutrals-900"
                    : "text-[#6C7275]"
                }`}
              >
                Sign in
              </span>
              <span
                onClick={() => setSelected(1)}
                className={`font-rubik-600 text-[13px] tracking-[-0.28px] text-center  cursor-pointer  py-1.5 m-[3px] hover:bg-white  rounded-md ${
                  selected === SelectablePages.CREATE_ACCOUNT
                    ? "bg-white text-neutrals-900"
                    : "text-[#6C7275]"
                }`}
              >
                Create Account
              </span>
            </div>
          </div>
          <div className="my-2">
            <GoogleOAuth />
          </div>
          <div className="flex justify-center">
            <img src={images.or} className=" mt-2 w-[100%] " alt="" />
          </div>
          <div className="flex w-full">
            {selected === SelectablePages.SIGN_IN && (
              <Signup setSelected={setSelected} />
            )}
          </div>
          <div className="flex">
            {selected === SelectablePages.CREATE_ACCOUNT && (
              <CreateAccountPage />
            )}
          </div>
        </div>

        {model && resetPasswordCode && (
          <ResetPasswordModel
            setmodel={setmodel}
            resetPasswordCode={resetPasswordCode}
          />
        )}

        {showPhoneNumberModel && <SetPhoneNumberModel />}
      </div>
      <ToastCustomized />
    </div>
  );
};
