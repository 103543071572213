import React from "react";
import { useNavigate } from "react-router-dom";

import CampaignTabs from "../../components/dashboard/CampaignTabs";
import Main from "../../components/dashboard/Main";
import CustomNavigate from "../../utils/navigate";
import CampaignSearch from "../../components/dashboard/CampaignSearch";

const Campaigns = () => {
  const navigate = new CustomNavigate(useNavigate());

  return (
    <>
      <Main title="All Campaigns" logo={true}>
        <div>
          <div className="p-6 grid grid-flow-row gap-6">
            {/* row */}
            <div className="flex justify-between">
              <div className="flex justify-between gap-2">
                <CampaignSearch />
              </div>
              <div className="grid grid-flow-col gap-2 my-1 text-sm">
                <button
                  className="bg-orange text-white rounded-lg px-4"
                  onClick={() => navigate.to("/dashboard/campaigns/create")}
                >
                  Create Campaign{" "}
                </button>
              </div>
            </div>
            {/* row */}
            <div>
              <CampaignTabs />{" "}
            </div>
          </div>
        </div>
      </Main>
    </>
  );
};

export default Campaigns;
