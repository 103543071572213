export const SwitchButton = ({ isOn, onToggle }: { isOn: any; onToggle: any }) => {
  return (
    <button
      className={`w-14 h-8 flex items-center rounded-full transition-colors ${
        isOn ? "bg-green-500" : "bg-gray-300"
      }`}
      onClick={onToggle}
    >
      <span
        className={`block w-6 h-6 rounded-full transform transition-transform ${
          isOn ? "translate-x-6 bg-white" : "translate-x-0 bg-gray-500"
        }`}
      ></span>
    </button>
  );
};