import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../components/common/BackButton";
import Main from "../../../components/dashboard/Main";
import Table, { DEFAULT_PAGE_LIMIT } from "../../../components/Table/Table";
import Api from "../../../constants/api";
import CustomNavigate from "../../../utils/navigate";

const HEADERS = [
  {
    key: "details.firstName",
    heading: "NAME",
  },
  {
    key: "details.email",
    heading: "EMAIL",
  },
  {
    key: "details.phoneNumber",
    heading: "PHONE NUMBER",
  },
  {
    key: "campaign.name",
    heading: "CAMPAIGN",
  },
  {
    key: "Interactions.textResponse",
    heading: "RESPONSES",
  },
];

const TouchPointResponse = () => {
  const [responses, setResponses] = useState<string[][]>([]);
  const [search, setSearch] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [sortColumn, setSortColumn] = useState<string>("details.firstName");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(DEFAULT_PAGE_LIMIT);
  const [totalTouchpointResponsesCount, setTotalTouchpointResponsesCount] =
    useState<number>(0);
  const navigate = new CustomNavigate(useNavigate());
  const { id = "" } = useParams();
  const getAllTouchPointResponses = useCallback(async () => {
    try {
      const result = await Api.getAllTouchPointResponses(
        id,
        limit,
        pageNumber,
        sortColumn,
        sortOrder,
        search
      );
      if (result) {
        setResponses(transformDataIntoArray(result.data));
        setTotalTouchpointResponsesCount(result.data.count);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  }, [id, limit, pageNumber, sortColumn, sortOrder, search]);

  useEffect(() => {
    getAllTouchPointResponses();
  }, [getAllTouchPointResponses]);

  const transformDataIntoArray = (data: {
    count: number;
    interactions: any[];
  }) => {
    return data.interactions
      .filter((row) => row.recipient.orderRecipientDetails)
      .map((row) => {
        const details = row.recipient.orderRecipientDetails;
        return [
          details.firstName + " " + details.lastName,
          details.email,
          details.phoneNumber,
          row.recipient.campaign.name,
          row.textResponse,
        ];
      });
  };

  return (
    <Main logo={true}>
      <div className="px-4 font-leagueSpartan">
        <div className="flex justify-between items-center w-full">
          <div className="flex-none">
            <BackButton onClick={() => navigate.back()} />
          </div>
          <h1 className="flex-1 text-center font-leagueSpartan-600 text-[28px] text-neutrals-900 mb-4">
            Recipients Responses
          </h1>
        </div>

        <Table
          header={HEADERS}
          rows={responses}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          setSortColumn={setSortColumn}
          setSearch={setSearch}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          setLimit={setLimit}
          limit={limit}
          totalItemCount={totalTouchpointResponsesCount}
        />
      </div>
    </Main>
  );
};

export default TouchPointResponse;
