import moment from "moment";
import { useLocation } from "react-router-dom";

export const CampaignDetailOverview = () => {
  const campaign = useLocation().state as CampaignListData;

  const val = campaign.overview;

  let status = true;
  if (val.duration.end) {
    const now = new Date();
    if (
      now > new Date(val.duration.start) ||
      now < new Date(val.duration.end)
    ) {
      status = false;
    }
  }

  let start = campaign.startDate
    ? moment(campaign.startDate).format("DD MMM")
    : "No Start Date";

  let end = campaign.endDate
    ? `${moment(campaign.endDate).format("DD MMM YYYY")}`
    : "No End Date";
  const duration = `${start} - ${end}`;

  const formatNumber = (input: number) =>
    input ? input.toFixed(2).replace(".00", "") : "0";

  return (
    <div className="w-[80vw] font-leagueSpartan">
      <div
        className="h-[140px] p-4 bg-white rounded-lg flex-col justify-start items-start gap-2 "
        style={{ marginLeft: "30px", marginRight: "30px" }}
      >
        <div className="text-[20px] text-neutrals-900">Summary</div>
        <div className="justify-start items-start gap-12 inline-flex">
          <div className="flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-[14px] text-neutrals-600">DURATION</div>
            <div>
              <div className="text-zinc-800  text-base font-normal leading-normal">
                {duration}
              </div>
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-[14px] text-neutrals-600">BUDGET</div>
            <div>
              <div className="text-zinc-800  text-base font-normal leading-normal">
                ${val.budget} per pax
              </div>
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-[14px] text-neutrals-600">CAMPAIGN OWNER</div>
            <div>
              <div className="text-zinc-800  text-base font-normal leading-normal">
                {val.campaignOwner}
              </div>
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-[14px] text-neutrals-600">DATE CREATED</div>
            <div>
              <div className="text-zinc-800  text-base font-normal leading-normal">
                {moment(val.dateCreatedAt).format("DD MMMM YYYY, hh:mm")}
              </div>
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-[14px] text-neutrals-600">STATUS</div>
            <div className="justify-start items-center gap-2 inline-flex">
              <div
                className={`w-2 h-2 ${
                  status ? "bg-green-500" : "bg-red-500"
                } rounded-full`}
              />
              <div className="text-zinc-800  text-base font-normal leading-normal">
                {status ? "Active" : "Inactive"}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* // {section2} */}
      <div
        className="grid grid-cols-2 gap-6 mt-4"
        style={{ marginLeft: "30px", marginRight: "30px" }}
      >
        <div className="bg-white p-7 rounded-lg h-40 ">
          <div className="flex justify-between text-lg">
            <div className="text-[20px] text-neutrals-900">
              Gifts Funnel Stats
            </div>
          </div>
          <div className=" grid grid-flow-col">
            <div className="">
              <div className="flex justify-between mt-4">
                <div className=" ">
                  <div className="text-[14px] text-neutrals-600 ">
                    GIFTS SENT
                  </div>
                  <div>
                    <div className="text-[32px] text-neutrals-900 font-semibold">
                      {val.giftSent}
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-lg  flex-col justify-start items-start gap-2 inline-flex ">
                  <div className="text-[14px] text-neutrals-600 ">
                    GIFTS OPENED
                  </div>
                  <div>
                    <div className="text-[32px] text-neutrals-900 font-semibold">
                      {val.giftOpend}
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-lg  flex-col justify-start items-start gap-2 inline-flex ">
                  <div className="text-[14px] text-neutrals-600 ">
                    GIFTS CLAIMED
                  </div>
                  <div>
                    <div className="text-[32px] text-neutrals-900 font-semibold">
                      {val.giftClaimed}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-7 rounded-lg h-40">
          <div className="flex justify-between text-lg">
            <div className="text-[20px] text-neutrals-900">
              Gifts Funnel Stats
            </div>
          </div>
          <div className="">
            <div className="h-52">
              <div className="grid grid-cols-3 mt-4">
                <div className="bg-white rounded-lg  flex-col justify-start items-start gap-2 inline-flex ">
                  <div className="text-nowrap text-[14px] text-neutrals-600">
                    % OPENED
                  </div>
                  <div>
                    <div className="text-3xl font-bold text-green-500">
                      {formatNumber(val?.giftOpenedPercent)}%
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-lg  flex-col justify-start items-start gap-2 inline-flex ">
                  <div className="text-nowrap text-[14px] text-neutrals-600 ">
                    % CLAIMED
                  </div>
                  <div>
                    <div className="text-3xl font-bold text-green-500">
                      {formatNumber(val?.giftClaimedPercent)}%
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-lg flex-col justify-start items-start gap-2 inline-flex ">
                  <div className="text-nowrap text-[14px] text-neutrals-600">
                    % UNCLAIMED
                  </div>
                  <div>
                    <div className="text-3xl font-bold text-red-700">
                      {formatNumber(val?.giftUnClaimedPercent)}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="grid grid-cols-2 gap-6 mt-4"
        style={{ marginLeft: "30px", marginRight: "30px" }}
      >
        <div className="bg-white p-7 rounded-lg">
          <div className="flex justify-between text-lg">
            <div className="text-[20px] text-neutrals-900">Spending</div>
          </div>
          <div className="grid grid-flow-col">
            <div className="flex justify-between mt-4">
              <div className="flex-col">
                <div className="text-[14px] text-neutrals-600 line-clamp-2">
                  TOTAL SPENT
                </div>
                <div>
                  <div className="text-[32px] text-neutrals-900">
                    ${val.totalSpent}
                  </div>
                </div>
              </div>
              <div className="flex-col">
                <div className="text-[14px] text-neutrals-600 line-clamp-2">
                  AVERAGE SPENDING PER RECIPIENT
                </div>
                <div>
                  <div className="text-[32px] text-neutrals-900">
                    ${val.avgSpendingPerRecipient}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-zinc-200 p-7 rounded-lg h-40">
          <div className="flex justify-between text-lg">
            <div className="text-[20px] text-neutrals-900">
              Conversion Rate (Not Activated)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
