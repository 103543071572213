import { Field, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import Api from "../../constants/api";
import { useAppSelector } from "../../redux/hooks";
import { setCampaignRecipientData } from "../../redux/selectGiftSlice";
import { Checkbox } from "../Checkbox/Checkbox";
import Divider from "../common/Divider";
import CheckoutFormInputHamper from "./CheckoutFormInputHamper";

type propType = {
  createOrder: (
    createOrderRecipientDetails: CreateOrderRecipientDetails
  ) => void;
  isDelivery?: boolean;
  pickupAddress?: string;
  isSelfPickupOnly?: boolean;
  orderAgreements: OrderAgreement[];
  isVoucherOnly: boolean;
};

const CheckoutFormHamper = ({
  orderAgreements,
  createOrder,
  isDelivery,
  pickupAddress,
  isSelfPickupOnly,
  isVoucherOnly,
}: propType) => {
  const selectGiftState = useAppSelector((state) => state.cartState);

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    phoneNumber: yup.string().required("Required"),
    email: yup
      .string()
      .trim()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Email is incorrect")
      .required("Required"),
    isVoucherOnly: yup.boolean(),
    address: yup
      .string()
      .required()
      .when("isVoucherOnly", {
        is: true,
        otherwise: (schema) => schema.optional(),
      }),
    postalCode: yup
      .string()
      .required()
      .when("isVoucherOnly", {
        is: true,
        otherwise: (schema) => schema.optional(),
      }),
    orderAgreements: yup.array().of(
      yup
        .object()
        .shape({
          isRequired: yup.boolean(),
          checked: yup.boolean(),
        })
        .test(
          "`checked` must be true if `isRequired`",
          "Required",
          ({ isRequired, checked }) => {
            return !isRequired || checked;
          }
        )
    ),
  });

  const dispatch = useDispatch();
  const [giftClaimed, setGiftClaimed] = useState(false);
  const recipientId =
    useSearchParams()[0].get("recid") ||
    selectGiftState.campaignRecipientData?.result.url_id;

  const updateRecipientDetails = useCallback(async () => {
    const campaignRecipientData = await Api.getCampaignRecipient(recipientId!);
    dispatch(setCampaignRecipientData(campaignRecipientData.data));
  }, [dispatch, recipientId]);

  useEffect(() => {
    updateRecipientDetails();
  }, [giftClaimed, updateRecipientDetails]);

  return (
    <div>
      <Formik
        initialValues={{
          ...selectGiftState.address!,
          orderAgreements: orderAgreements.map((orderAgreement) => ({
            id: orderAgreement.id,
            checked: true, // Default order agreements to checked
            isRequired: orderAgreement.isRequired,
          })),
        }}
        validationSchema={validationSchema}
        onSubmit={({ orderAgreements, ...values }) => {
          createOrder({
            agreementIds: orderAgreements
              .filter((orderAgreement) => orderAgreement.checked)
              .map((orderAgreement) => orderAgreement.id),
            city: "Singapore",
            ...values,
          });
          setGiftClaimed(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit} className="font-leagueSpartan">
              <div className="grid grid-cols-1">
                <div className="">
                  <CheckoutFormInputHamper
                    type="text"
                    name="firstName"
                    title="First Name"
                    onChange={(e) => {
                      let value = e.target.value;
                      const test = /^[a-zA-Z\s]{0,30}$/.test(value);
                      if (!test) return;
                      setFieldValue("firstName", value);
                    }}
                    onBlur={handleBlur}
                    value={values.firstName}
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                </div>
                <div className="">
                  <CheckoutFormInputHamper
                    type="text"
                    name="lastName"
                    title="Last Name"
                    onChange={(e) => {
                      let value = e.target.value;

                      const test = /^[a-zA-Z\s]{0,30}$/.test(value);
                      if (!test) return;
                      setFieldValue("lastName", value);
                    }}
                    onBlur={handleBlur}
                    value={values.lastName}
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>

                <div className="">
                  {
                    <CheckoutFormInputHamper
                      type="text"
                      name="phoneNumber"
                      title="Phone Number"
                      onChange={(e) => {
                        let value = e.target.value as string;

                        const test = /^[0-9]{0,10}$/.test(value);
                        if (!test) return;
                        setFieldValue("phoneNumber", value);
                      }}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                      error={errors.phoneNumber}
                      touched={touched.phoneNumber}
                    />
                  }
                </div>
                <div className="">
                  <CheckoutFormInputHamper
                    type="email"
                    name="email"
                    title="Email Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={errors.email}
                    touched={touched.email}
                  />
                </div>
                {!isVoucherOnly && isDelivery && (
                  <>
                    <div className="mt-6">
                      <Divider />
                    </div>
                    <div className={`mt-6`}>
                      <h5 className="font-leagueSpartan-400 text-[20px] text-neutrals-900 mb-4">
                        Where should we ship your order?
                      </h5>
                    </div>
                    <div className="mb-3">
                      <CheckoutFormInputHamper
                        type="text"
                        name="address"
                        title="Block Number and Street Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                        error={errors.address}
                        touched={touched.address}
                      />
                      <span className="italic text-xs text-gray-400">
                        Example: Block Number, Street Name, Building Name
                      </span>
                    </div>
                    <div className="mb-3">
                      <CheckoutFormInputHamper
                        type="text"
                        name="apartment"
                        title="Floor and Unit Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.apartment}
                        error={errors.apartment}
                        touched={touched.apartment}
                      />
                      <span className="italic text-xs text-gray-400">
                        Provide floor and unit number
                      </span>
                    </div>
                    <div className="">
                      <CheckoutFormInputHamper
                        type="text"
                        name="postalCode"
                        title="Postal Code"
                        onChange={(e) => {
                          let value = e.target.value as string;
                          const test = /^[0-9]{0,10}$/.test(value);
                          if (!test) return;
                          setFieldValue("postalCode", value);
                        }}
                        onBlur={handleBlur}
                        value={values.postalCode}
                        error={errors.postalCode}
                        touched={touched.postalCode}
                      />
                    </div>
                  </>
                )}
                {!isVoucherOnly && (!isDelivery || isSelfPickupOnly) && (
                  <>
                    <div className="mt-2">
                      <Divider />
                    </div>
                    <div className="mb-2">
                      <p className="mt-4 font-leagueSpartan-400 text-[18px] text-neutrals-900">
                        Collection Details:
                      </p>
                      <p className="font-leagueSpartan-300 text-[16px] text-neutrals-700">
                        {pickupAddress}
                      </p>
                    </div>
                  </>
                )}

                {orderAgreements.map((orderAgreement, index) => {
                  const newCheckedValue =
                    !values.orderAgreements[index].checked;
                  const error = errors.orderAgreements?.at(index) as string;

                  return (
                    <Field name={`agreement[${index}]`} key={orderAgreement.id}>
                      {() => (
                        <div className="py-3">
                          <Checkbox
                            checked={values.orderAgreements[index].checked}
                            onClick={() => {
                              setFieldValue(`orderAgreements[${index}]`, {
                                id: orderAgreement.id,
                                checked: newCheckedValue,
                                isRequired: orderAgreement.isRequired,
                              });
                            }}
                            text={
                              orderAgreement.text +
                              (orderAgreement.isRequired ? " (Required)" : "")
                            }
                            readOnly
                          />
                          <div className="text-red-500">{error}</div>
                        </div>
                      )}
                    </Field>
                  );
                })}
              </div>

              {(isVoucherOnly ||
                isDelivery !== undefined ||
                isSelfPickupOnly) && (
                <button
                  type="submit"
                  title="isSubmitting"
                  className="mt-3 p-2 px-4 rounded-lg  bg-orange  font-leagueSpartan-500 text-[16px] text-white"
                >
                  Confirm Selection
                </button>
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CheckoutFormHamper;
