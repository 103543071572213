import { useCallback, useEffect, useState } from "react";
import Api from "../../../constants/api";
import { ToastContainer, toast } from "react-toastify";
import { isAxiosError } from "axios";
import { useLocation } from "react-router-dom";
import { ImageUploader } from "../../../components/ImageUploader/ImageUploader";
import { useNavigate } from "react-router-dom";

export const CreateOcassion = (props: any) => {
  console.log(props, "props");
  const navigate = useNavigate();
  const successMessage = () => toast("Success!");
  const errorMessage = (error?: string) => toast.error(error || "error!");
  const [ocassionData, setOcassionData] = useState({
    name: "",
    type: "",
    sentiment: "Positive",
    picture: "",
  });
console.log(ocassionData, "ocassiondata");
  /**
   * Function to handle input change
   */

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    console.log(name, value, "value");
    setOcassionData((_prev) => {
      return { ..._prev, [name]: value };
    });
  };

  /**THIS function is passed as prop in image uploader to get the image link */
  const getAzureImageUrl = (url: string) => {
    console.log(url, "url");
    setOcassionData((prev) => {
      return { ...prev, picture: url };
    });
  };

  /**
   * This is to Update Ocassion
   */
  const updateOcassion = ()=>{
    Api.updateOcassion(props.updateData.id,ocassionData)
    .then((res)=>{
        if(isAxiosError(res))
            errorMessage(res.response?.data.message || "Invalid data!");
        else{
            successMessage();
            navigate("/admin", {state: {tab:props?.tabNumber , subtab: 1}});
          }
    }).catch((err)=>{
        console.log(err);
        errorMessage();
    })
  }
/**
 * Cancel update functionality
 */
  const handleCancelUpdate =()=>{
    setOcassionData({
      name: "",
      type: "",
      sentiment: "",
      picture: "",
    });
    navigate("/admin", {state: {tab:props?.tabNumber , subtab: 1}});

  }
  /**UseEffect */
  useEffect(() => {
    console.log(props.tabNumber, "tabnum")
    if (props.updateData && props.tabNumber ===0) {
      const data= props.updateData;
      setOcassionData({
        name: data.name,
        type: data.type,
        sentiment: data.sentiment,
        picture: data.picture,
      });
    }
  }, [props]);

  console.log(ocassionData, "ocassiondata");
  return (
    <>
      <form
        onSubmit={(e) => {
          Api.createOccasion(e, ocassionData)
            .then((res) => {
              if (isAxiosError(res))
                errorMessage(res.response?.data.message || "Invalid data!");
              else {
                successMessage();
                // setAzureImageUrl("");
                navigate("/admin", { state: { tab: 0, subtab: 1 } });
              }
            })
            .catch((err) => {
              console.log(err);
              errorMessage();
            });
        }}
        className="m-4 grid grid-flow-row max-w-md"
      >
        <div className="text-lg text-orange">Occasion</div>
        <input
          type="text"
          name="name"
          placeholder="name"
          value={ocassionData?.name}
          onChange={handleInputChange}
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="type"
          value={ocassionData?.type}
          placeholder="type"
          onChange={handleInputChange}
          className="border rounded-lg my-4"
        />
        <div className="mt-2">
          <label htmlFor="sentiment" className="mr-2">
            Sentiment:
          </label>
          <select
            onChange={handleInputChange}
            name="sentiment"
            id="sentiment"
            value={ocassionData?.sentiment}
            className="border rounded-lg"
          >
            {["Positive", "Negative", "Neutral"].map((val, i) => (
              <option key={i} value={val}>
                {val}
              </option>
            ))}
          </select>
        </div>
        {/* <input
            type="text"
            name="sentiment"
            placeholder="sentiment"
            className="border rounded-lg my-4"
          /> */}
        {/* <input
            type="text"
            name="picture"
            placeholder="picture url"
            className="border rounded-lg my-4"
          /> */}

        <ImageUploader
          getAzureImageUrl={getAzureImageUrl}
          updateImgUrl={props?.updateData?.picture}
          tabNumber={props?.tabNumber}
          currentTab={props?.currentTab}
        />
        {props?.updateData  &&props?.tabNumber === props?.currentTab ? (
          <>
          <button 
          onClick={updateOcassion}
            type="button"
            className="border rounded-lg p-2 bg-lightOrange "
          >
            Update
          </button>
          <button
              onClick={handleCancelUpdate}
              type="button"
              // className="border rounded-lg p-2 bg-lightOrange "
              style={{backgroundColor: "red", color: "white", position: "absolute" , left: "50rem", width: "150px", height: "50px"}}
            >
              Cancel
            </button>
          </>
        ) : (
          ocassionData.picture?
          <button
            type="submit"
            className="border rounded-lg p-2 bg-lightOrange "
          >
            Submit
          </button>
          : <button
          type="button"
          className="border rounded-lg p-2 bg-lightOrange "
          onClick={()=>{alert("Upload Images before submitting")}}
        >
          Submit
        </button>
        )}
      </form>
    </>
  );
};
