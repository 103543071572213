import images from "../../constants/images";
type propsType = {
  checked: boolean;
  name: string;
  onClick: () => void;
  elementBesideText?: JSX.Element;
};
/**
 * @deprecated Use src/components/Checkbox/Checkbox.tsx instead
 */
const CheckBox = (props: propsType) => {
  const { checked, name, onClick, elementBesideText } = props;
  const image = checked ? images.checkBoxSelected : images.checkBoxInactive;
  return (
    <div className="flex items-center cursor-pointer" onClick={() => onClick()}>
      <img src={image} alt="" />
      <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
        {name}
      </label>
      {elementBesideText && elementBesideText}
    </div>
  );
};

export default CheckBox;
