import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderTypesT } from "../constants/order.constants";
import { MIN_PRODUCT_CREDITS } from "../pages/giftSelectionHamper/ChooseCategoryHamper";
import { VoucherCart } from "../types/vouchers";
import { getUsedCredits } from "../utils/cartUtils";
import { RootState } from "./store";

// Define the initial state using that type
const initialState: {
  cart: RecipientRecommendedProductCartT[];
  conversationRatio: number;
  totalCredits: number;
  address: AddressT;
  shippingMethod?: OrderTypesT;
  orderStatus?: orderStatusT;
  paymentMethod?: string;
  campaignRecipientData?: GetCampaignRecipientData;
  voucherCart: VoucherCart[];
} = {
  cart: [],
  conversationRatio: 1,
  totalCredits: 0,
  address: {
    firstName: "",
    lastName: "",
    address: "",
    apartment: "",
    postalCode: "",
    phoneNumber: "",
    email: "",
  },
  voucherCart: [],
};

export const cartSlice = createSlice({
  name: "cartState",
  initialState,
  reducers: {
    setCampaignRecipientData: (
      state,
      action: PayloadAction<GetCampaignRecipientData>
    ) => {
      state.campaignRecipientData = action.payload;
    },
    setTotalCredits: (state, action: PayloadAction<number>) => {
      state.totalCredits = action.payload;
    },
    setAddress: (state, action: PayloadAction<AddressT>) => {
      state.address = action.payload;
    },
    setShippingMethod: (state, action: PayloadAction<OrderTypesT>) => {
      state.shippingMethod = action.payload;
    },
    setorderStatus: (state, action: PayloadAction<orderStatusT>) => {
      state.orderStatus = action.payload;
    },
    setpaymentMethod: (state, action: PayloadAction<string>) => {
      state.paymentMethod = action.payload;
    },
    addToCart: (
      state,
      action: PayloadAction<RecipientRecommendedProductCartT>
    ) => {
      const item = action.payload;
      const usedCredits = getUsedCredits(state.cart, state.voucherCart);
      if (
        usedCredits + (item.credits || MIN_PRODUCT_CREDITS) * item.quantity <=
        state.totalCredits
      ) {
        const index = state.cart.findIndex((v) => v.name === item.name);
        if (index !== -1) {
          const _item = state.cart[index];
          _item.quantity += item.quantity;
        } else {
          state.cart = [...state.cart, item];
        }
      }
    },
    addToVoucherCart: (state, action: PayloadAction<VoucherCart>) => {
      const voucher = action.payload;
      const usedCredits =
        getUsedCredits(state.cart, state.voucherCart) +
        voucher.credits * voucher.quantity;
      if (usedCredits > state.totalCredits) {
        return;
      }
      const voucherCart = [...state.voucherCart];
      const index = voucherCart.findIndex(
        (v) => v.id === voucher.id && v.denomination === voucher.denomination
      );
      if (index !== -1) {
        const _item = voucherCart[index];
        _item.quantity += voucher.quantity;
      } else {
        voucherCart.push(voucher);
      }
      state.voucherCart = voucherCart;
    },
    increaseCartItemQuantity: (
      state,
      action: PayloadAction<{
        index: number;
        item: RecipientRecommendedProductCartT;
      }>
    ) => {
      const payload = action.payload;
      const cart = [...state.cart];
      const cartItem = { ...cart[payload.index] };
      cartItem.quantity += 1;
      cart[payload.index] = cartItem;

      if (getUsedCredits(cart, state.voucherCart) <= state.totalCredits) {
        state.cart[payload.index] = cartItem;
      }
    },
    increaseVoucherCartItemQuantity: (state, action: PayloadAction<number>) => {
      const { payload: index } = action;

      if (index >= state.voucherCart.length) {
        return;
      }

      const voucherCart = [...state.voucherCart];
      // create a deep clone of the object
      const voucherItem = { ...voucherCart[index] };
      voucherItem.quantity += 1;
      voucherCart[index] = voucherItem;
      const usedCredits = getUsedCredits(state.cart, voucherCart);
      if (usedCredits <= state.totalCredits) {
        state.voucherCart = voucherCart;
      }
    },
    decreaseCartItemQuantity: (
      state,
      action: PayloadAction<{
        index: number;
        item: RecipientRecommendedProductCartT;
      }>
    ) => {
      const payload = action.payload;
      const cart = [...state.cart];
      cart[payload.index].quantity -= 1;

      if (cart[payload.index].quantity < 1) {
        cart.splice(payload.index, 1);
      }
      state.cart = cart;
    },
    decreaseVoucherCartItemQuantity: (state, action: PayloadAction<number>) => {
      const { payload: index } = action;

      if (index >= state.voucherCart.length) {
        return;
      }
      const voucherCart = [...state.voucherCart];
      voucherCart[index].quantity -= 1;

      if (voucherCart[index].quantity < 1) {
        voucherCart.splice(index, 1);
      }
      state.voucherCart = voucherCart;
    },

    removeCartItem: (state, action: PayloadAction<number>) => {
      const payload = action.payload;
      const cart = [...state.cart];

      cart.splice(payload, 1);

      state.cart = cart;
    },
    removeVoucherCartItem: (state, action: PayloadAction<number>) => {
      const payload = action.payload;
      const voucherCart = [...state.voucherCart];

      voucherCart.splice(payload, 1);

      state.voucherCart = voucherCart;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTotalCredits,
  addToCart,
  increaseCartItemQuantity,
  decreaseCartItemQuantity,
  removeCartItem,
  setAddress,
  setShippingMethod,
  setorderStatus,
  setpaymentMethod,
  setCampaignRecipientData,
  addToVoucherCart,
  decreaseVoucherCartItemQuantity,
  increaseVoucherCartItemQuantity,
  removeVoucherCartItem,
} = cartSlice.actions;

export const selectCount = (state: RootState) => state.cartState;

export default cartSlice.reducer;
