import { useCallback, useEffect, useState } from "react";
import Api from "../../../constants/api";
import { isAxiosError } from "axios";
import { ErrorMessage } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import MultipleImageUploader from "../../../components/MulitpleImageUploader/MultipleImageUploader";

export const CreateCharity = (props: any) => {
    const navigate = useNavigate();
  const successMessage = () => toast("Success!");
  const errorMessage = (error?: string) => toast.error(error || "error!");
  const [charityData, setCharityData] = useState({
    name: "",
    description: "",
    location: "",
    accountInfo: "",
    pic1: "",
    pic2: "",
    pic3: "",
    pic4: "",
    balance: "",
    lastBalance: "",
  });
  const [isAutoUpdate, setIsAutoUpdate] = useState(false);

  /**
   * Handle Input change
   */
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setCharityData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  /**
   * Handle update
   */

  const handleCharityUpdate = () => {
    Api.updateCharity(props?.updateData?.id, charityData)
      .then((res) => {
        if (isAxiosError(res))
          errorMessage(res.response?.data.message || "Invalid data!");
        else {
          if(isAutoUpdate){
            setIsAutoUpdate(false);
          }
          if(!isAutoUpdate){
            successMessage();
            navigate("/admin", {state: {tab:props?.tabNumber , subtab: 1}});
          }
        }
      })
      .catch((err) => {
        console.log(err);
        errorMessage();
      });
  };

  useEffect(()=>{
    if(isAutoUpdate){
      handleCharityUpdate();
    }
  })

  /**
   * Handle Cancel Update
   */

  const handleCancelUpdate = ()=>{
    setCharityData({
        name: "",
        description: "",
        location: "",
        accountInfo: "",
        pic1: "",
        pic2: "",
        pic3: "",
        pic4: "",
        balance: "",
        lastBalance: "",
    })
    navigate("/admin", {state: {tab:props?.tabNumber , subtab: 1}});

  }


    /**THIS function is passed as prop in image uploader to get the image link */
    const getAzureImageUrl = (imgUrlArray: string[], isAutoUpdate:boolean) => {
        setCharityData((prev) => {
          return {
            ...prev,
            pic1: imgUrlArray[0] || "",
            pic2: imgUrlArray[1] || "",
            pic3: imgUrlArray[2] || "",
            pic4: imgUrlArray[3] || ""
          };
        });
        isAutoUpdate ? setIsAutoUpdate(true) : setIsAutoUpdate(false);
      };
  /**
   * UseEffect while updating
   */

  useEffect(() => {

    if(props?.updateData && props.tabNumber === 7){
        let data = props?.updateData;
        console.log(data, "DAATATAATA")
        setCharityData({
          name: data?.name,
          description: data?.description,
          location: data?.location,
          accountInfo: data?.accountInfo,
          pic1: data?.pic1,
          pic2: data?.pic2,
          pic3: data?.pic3,
          pic4: data?.pic4,
          balance: data?.balance,
          lastBalance: data?.lastBalance,
        });
    }
  
  }, []);
  console.log(charityData, "dattof the dcharity");
  return (
    <>
      <form
        onSubmit={(e) => {
          console.log(charityData, "insidefform");
          Api.createCharity(e, charityData)
            .then((res) => {
              if (isAxiosError(res))
                errorMessage(res.response?.data.message || "Invalid Data");
              else successMessage();
            })
            .catch((err) => {
              console.log(err);
              errorMessage();
            });
        }}
        className="m-4 grid grid-flow-row max-w-md"
      >
        <div className="text-lg text-orange">Charity</div>
        <input
          type="text"
          name="name"
          onChange={handleInputChange}
          value={charityData?.name}
          placeholder="name"
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="description"
          onChange={handleInputChange}
          value={charityData?.description}
          placeholder="description"
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="location"
          onChange={handleInputChange}
          value={charityData?.location}
          placeholder="location"
          className="border rounded-lg my-4"
        />
         
        <input
          type="text"
          name="accountInfo"
          onChange={handleInputChange}
          value={charityData?.accountInfo}
          placeholder="accountInfo"
          className="border rounded-lg my-4"
        />
          <MultipleImageUploader
          getAzureImageUrl={getAzureImageUrl}
          updateImgUrl={[
            props?.updateData?.pic1,
            props?.updateData?.pic2,
            props?.updateData?.pic3,
            props?.updateData?.pic4
          ]}
          numOfImages={4}
        />
        <input
          type="number"
          name="balance"
          onChange={handleInputChange}
          value={charityData?.balance}
          placeholder="balance"
          className="border rounded-lg my-4"
        />
        <input
          type="number"
          name="lastBalance"
          onChange={handleInputChange}
          value={charityData?.lastBalance}
          placeholder="lastBalance"
          className="border rounded-lg my-4"
        />
        { props?.updateData  &&props?.tabNumber === props?.currentTab ? (
            <>
          <button
            onClick={handleCharityUpdate}
            type="button"
            className="border rounded-lg p-2 bg-lightOrange "
          >
            Update
          </button>
          <button
              onClick={handleCancelUpdate}
              type="button"
            //   className="border rounded-lg p-2 bg-lightOrange "
              style={{backgroundColor: "red", color: "white", position: "absolute" , left: "50rem", width: "150px", height: "50px"}}
            >
              Cancel
            </button>
          </>
        ) : (
          charityData.pic1 ?
          <button
            type="submit"
            className="border rounded-lg p-2 bg-lightOrange "
          >
            Submit
          </button>
          :
          <button
            type="button"
            className="border rounded-lg p-2 bg-lightOrange "
            onClick={()=>{alert("Upload Images before submitting")}}
          >
            Submit
          </button>
        )}
      </form>
    </>
  );
};
