import React, { Dispatch, SetStateAction } from "react";

interface ActivationSuccessModalProps {
  setShowActivationSuccessModal: Dispatch<SetStateAction<boolean>>;
  onSuccessDone: () => void;
  email: string | null;
}

export const ActivationSuccessModal: React.FC<ActivationSuccessModalProps> = ({
  setShowActivationSuccessModal,
  onSuccessDone,
  email,
}) => {
  const handleDoneClick = () => {
    onSuccessDone();
    setShowActivationSuccessModal(false);
  };
  return (
    <div className="font-leagueSpartan-400 fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-md w-96 space-y-2">
        <div className="text-center text-sm">
          <span>Please check your email for voucher details.</span>
        </div>
        <div className="text-center text-sm">
          {email ? (
            <span>Please check your email ({email}) for voucher details.</span>
          ) : (
            <span>Please check your email for voucher details.</span>
          )}
        </div>
        <div className="flex justify-center pt-5">
          <button
            className="bg-orange text-white text-sm font-leagueSpartan-400 rounded-lg p-2 px-5"
            onClick={handleDoneClick}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};
