import React, { DetailedHTMLProps } from "react";
type propType = {
  title: string;
  error?: string;
  touched?: boolean;
} & DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const CustomInput = (props: propType) => {
  const { title, error, touched } = props;
  const errorText = error && touched && error;
  return (
    <div className="grid grid-cols-1">
      <div className="font-leagueSpartan-400 text-neutrals-900 text-md mb-2">
        {title}
      </div>
      <input
        type="text"
        {...props}
        className={`rounded-md placeholder:text-neutrals-600 ${
          errorText ? "border-red-500" : "border-neutrals-400"
        }`}
      />
      <div className="text-red-500">{errorText}</div>
    </div>
  );
};

export default CustomInput;
