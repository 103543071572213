import axios from "axios";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Checkbox } from "../../../components/Checkbox/Checkbox";
import BackButton from "../../../components/common/BackButton";
import Field from "../../../components/common/Field";
import ToastCustomized from "../../../components/common/ToastCustomized";
import Main from "../../../components/dashboard/Main";
import { TUserList, UserList } from "../../../components/dashboard/UserList";
import Api from "../../../constants/api";
import { setIsLoading } from "../../../redux/globalSlice";
import CustomNavigate from "../../../utils/navigate";

export type NewTouchpoint = Omit<Touchpoint, "id">;

const initialValues: NewTouchpoint = {
  title: "",
  description: "",
  startDate: "",
  endDate: "",
  allowTextResponse: false,
};

const MAX_DESCRIPTION_CHARACTERS = 100;

const validationSchema = yup.object().shape({
  title: yup.string().required("Title is required."),
  description: yup.string().required("Description is required"),
  allowTextResponse: yup.boolean().required(),
  startDate: yup.date().required("Start date is required"),
  endDate: yup
    .date()
    .required("End date is required")
    .min(yup.ref("startDate"), "End date must be greater than start date"),
});

const TouchpointCreate = () => {
  const { id = "" } = useParams();
  const touchpointId = parseInt(id);
  const dispatch = useDispatch();
  const navigate = new CustomNavigate(useNavigate());

  const navigateRef = useRef(navigate);
  const [interactedUserList, setInteractedUserList] = useState<TUserList>([]);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
  } = useFormik<NewTouchpoint>({
    validateOnChange: false,
    validateOnBlur: true,
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values: NewTouchpoint, { resetForm }): Promise<void> => {
      try {
        dispatch(setIsLoading(true));
        if (id) {
          // Update Exisiting Touchpoint
          const response = await axios.patch(
            Api.baseCorpUrl + "engagement/touchpoints/" + touchpointId,
            values
          );

          if (response.data.success) {
            toast.success("Touchpoint updated successfully!");
          }
          dispatch(setIsLoading(false));
        } else {
          // Create a new Touchpoint
          const response = await axios.post(
            Api.baseCorpUrl + "engagement/touchpoints/create",
            values
          );

          if (response.data.success) {
            toast.success("Created Successfully!");
          }

          resetForm();
          dispatch(setIsLoading(false));
          navigate.to("/dashboard/engagement");
        }
      } catch (error) {
        toast.error("Error while creating touchpoint");
        dispatch(setIsLoading(false));
      }
    },
  });

  useEffect(() => {
    if (!touchpointId) {
      return;
    }
    dispatch(setIsLoading(true));
    axios
      .get(Api.baseCorpUrl + "engagement/touchpoints/" + touchpointId)
      .then(({ data: resData }) => {
        const {
          title,
          description,
          allowTextResponse,
          startDate,
          endDate,
          interactions = [],
        } = resData.data;
        setValues({
          title,
          description,
          allowTextResponse,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
        });
        const userList: TUserList = interactions.map((interaction: any) => {
          const recipientDetails = interaction.recipient.orderRecipientDetails;
          let result = {
            id: 0,
            firstName: "",
            lastName: "",
          };
          if (recipientDetails) {
            const {
              id,
              firstName = "",
              lastName = "",
              name = "",
            } = recipientDetails;
            if (name && !firstName && !lastName) {
              const [fN = "", lN = ""] = name.split(" ");
              result = { id, firstName: fN, lastName: lN };
            } else result = { id, firstName, lastName };
            return result;
          }
          return result;
        });
        setInteractedUserList(userList);
      })
      .catch(() => {
        navigateRef.current.to("/dashboard/engagement");
      })
      .finally(() => dispatch(setIsLoading(false)));
  }, [dispatch, setValues, touchpointId]);
  return (
    <Main title="Engagement" logo={true}>
      <div className="px-4 font-leagueSpartan">
        <div className="self-auto">
          <BackButton onClick={() => navigate.back()} />
        </div>

        <form
          onSubmit={handleSubmit}
          className="m-4 flex flex-col mb-72 max-w-[90%]"
        >
          <h1 className="font-leagueSpartan-600 text-[28px] text-neutrals-900 mb-4">
            {id ? "Touchpoint" : "Create a Touchpoint"}
          </h1>
          <Field label="Title" errorMessage={errors?.title}>
            <input
              type="text"
              id="title"
              onChange={handleChange}
              defaultValue={values.title}
              className="w-full border border-neutral-300 mt-2 rounded-lg font-leagueSpartan-400 text-neutrals-800"
              placeholder="Enter Title"
            />
          </Field>
          <Field
            label="Description (100 characters)"
            className="mt-6 mb-4"
            errorMessage={errors?.description}
            wordCount={`${
              values.description?.length || 0
            } / ${MAX_DESCRIPTION_CHARACTERS}`}
          >
            <textarea
              className="w-full border border-neutral-300 py-2 h-28 resize-none mt-2 rounded-lg font-leagueSpartan-400 text-neutrals-800"
              placeholder="Write a description"
              onChange={(e) => {
                const length = e.target.value.length;
                if (length <= MAX_DESCRIPTION_CHARACTERS) {
                  setFieldValue("description", e.target.value);
                }
              }}
              defaultValue={values.description}
            />
          </Field>
          <Field label="Response Type" errorMessage={errors?.title}>
            <Checkbox
              text={"Use text box when presenting Touchpoint"}
              checked={values.allowTextResponse}
              onClick={(e) => {
                setFieldValue("allowTextResponse", !values.allowTextResponse);
              }}
              readOnly
            />
          </Field>
          <div className="mt-2">
            <div className="w-[80%] flex items-center max-sm:flex-col max-sm:items-start max-sm:w-full mb-2">
              <Field
                label="Publishing Start Date"
                className="w-[90%] max-sm:w-full max-sm:mb-4"
                errorMessage={errors?.startDate}
              >
                <input
                  type="date"
                  className="w-[90%] border border-neutral-300 font-leagueSpartan-400  mt-2 rounded-lg max:sm-w-full"
                  defaultValue={values.startDate}
                  onChange={(e) =>
                    setFieldValue("startDate", e.target.value, false)
                  }
                />
              </Field>
              <Field
                label="Publishing End Date"
                className="w-[90%] max:sm-w-full"
                errorMessage={errors?.endDate || ""}
              >
                <input
                  type="date"
                  className="w-[90%] border border-neutral-300 font-leagueSpartan-400  mt-2 rounded-lg max-sm:w-full "
                  defaultValue={values.endDate}
                  min={moment().format("YYYY-MM-DD")}
                  onChange={(e) => setFieldValue("endDate", e.target.value)}
                />
              </Field>
            </div>
            <p className="font-leagueSpartan-400 mt-4 text-neutrals-600">
              {values.startDate &&
                `The Touchpoint will be live on Recipients' page from  ${moment(
                  values.startDate
                ).format("DD MMM YYYY")}`}
              {values.startDate && values.endDate
                ? ` until
              ${moment(values.endDate).format("DD MMM YYYY")}.`
                : ""}
            </p>
          </div>
          {id && (
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate.to("/dashboard/touchpointResponses/:id", {
                  key: ":id",
                  value: id,
                });
              }}
            >
              <UserList
                className="py-5"
                header="Responses"
                userList={interactedUserList}
              />
            </div>
          )}
          <div className="flex items-center justify-end mt-4">
            <button
              type="submit"
              className="font-leagueSpartan-400 text-[16px] bg-orange text-white rounded-lg py-2 px-5"
            >
              {id ? "Save Changes" : "Create Touchpoint"}
            </button>
          </div>
        </form>
        <ToastCustomized />
      </div>
    </Main>
  );
};

export default TouchpointCreate;
