import images from "../constants/images";

const NotFound = () => {
  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex justify-center">
          <img
            src={images.logo}
            className=" w-52 mt-[30vh]"
            alt="smilie logo"
          />
        </div>
        <div className="text-green-500 flex justify-center text-[32px] font-leagueSpartan-600">
          404
        </div>
        <div className=" flex justify-center text-[16px] text-neutral-500 font-leagueSpartan-300 text-center">
          Perhaps you entered incorrect URL?
          <br />
          Contact us, if you need any help at all
        </div>
        <div className="flex justify-center">
          <button
            className="p-2 px-4 flex gap-2 items-center bg-orange rounded-lg text-white font-leagueSpartan-300 mt-5"
            onClick={() => {
              window.location.href = "mailto:gifting@smilie.io?subject=Subject";
            }}
          >
            Send Email to Support <img src={images.rightArrowWhite} alt="" />
          </button>
        </div>
      </div>
    </>
  );
};

export default NotFound;
