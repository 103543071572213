import images from "../../constants/images";

type props = {
  image?: string;
  name: string;
  brand?: string;
  quantity: number;
  credits: number;
  handleDelete: () => {};
  handleIncrement: () => {};
  handleDecrement: () => {};
};

const CheckouthamperCartItem = ({
  image,
  name,
  brand,
  quantity,
  credits,
  handleDecrement,
  handleDelete,
  handleIncrement,
}: props) => {
  return (
    <div className="flex">
      <div className="grid grid-cols-12 gap-2 my-2 mt-4 w-full">
        <div className="col-span-3 max-lg:col-span-5">
          <img
            src={image}
            className="w-[80px] h-[80px] rounded-2xl object-contain"
            alt=""
          />
        </div>
        <div className="col-span-6">
          <p className="font-leagueSpartan-400 text-[18px] text-neutrals-900">
            {name}
          </p>
          {brand && (
            <div className="font-leagueSpartan-400 text-md text-neutrals-700 pt-1 ">
              <span className="font-leagueSpartan-500">{brand} </span>
            </div>
          )}
          <div className="grid grid-cols-3 border rounded-lg justify-items-center w-[80%] mt-4">
            <button
              className="p-1.5 text-neutrals-600"
              onClick={handleDecrement}
            >
              -
            </button>
            <button className="p-1.5 font-leagueSpartan-400 text-[18px] text-neutrals-900">
              {quantity}
            </button>
            <button
              className="p-1.5 text-neutrals-600"
              onClick={handleIncrement}
            >
              +
            </button>
          </div>
        </div>
        <div className="flex flex-col justify-between items-end max-lg:items-start col-span-3 max-lg:col-span-12 ">
          <p className="text-sm text-orange">{credits} Credits</p>
          <img
            src={images.dustbin}
            alt=""
            className="cursor-pointer"
            onClick={handleDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default CheckouthamperCartItem;
