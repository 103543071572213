import React from "react";

type NumberInputProps = {
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const NumberInput = (props: NumberInputProps) => {
  const handleDecrement = () => {
    const newValue = props.value - 10;
    const event = { target: { value: newValue.toString() } } as React.ChangeEvent<HTMLInputElement>;
    props.onChange(event);
  };

  const handleIncrement = () => {
    const newValue = props.value + 10;
    const event = { target: { value: newValue.toString() } } as React.ChangeEvent<HTMLInputElement>;
    props.onChange(event);
  };

  return (
    <div className="flex items-center border rounded-lg overflow-hidden">
      <button
        type="button"
        onClick={handleDecrement}
        className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 border-r border-gray-300 p-3 h-11 focus:ring-2 focus:outline-none"
      >
        <svg
          className="w-3 h-3 text-gray-900 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 2"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 1h16"
          />
        </svg>
      </button>
      <input
        type="text"
        value={props.value}
        onChange={props.onChange}
        className="bg-gray-50 text-center  font-leagueSpartan text-gray-900 text-lg focus:ring-blue-500 focus:border-blue-500 block w-full h-11 py-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="0"
        required
      />
      <button
        type="button"
        onClick={handleIncrement}
        className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 border-l border-gray-300 p-3 h-11 focus:ring-2 focus:outline-none"
      >
        <svg
          className="w-3 h-3 text-gray-900 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 1v16M1 9h16"
          />
        </svg>
      </button>
    </div>
  );
};

export default NumberInput;
