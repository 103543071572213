import "./App.css";
import AppRoutes from "./Routes/AppRoutes";
import Loading from "./components/common/Loading";
import { useAppSelector } from "./redux/hooks";
import ToastCustomized from "./components/common/ToastCustomized";

function App() {
  const globalState = useAppSelector((state) => state.globalSliceState);
  return (
    <>
      <AppRoutes />
      {globalState.isLoading && <Loading />}
      <ToastCustomized />
    </>
  );
}

export default App;
