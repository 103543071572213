import React, { HTMLProps, useCallback, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../../constants/api";
import images from "../../../constants/images";
import { TCampaignValue } from "../../../pages/dashboard/CampaignCreate";
import Divider from "../../common/Divider";
import { EditableTable } from "../EditableTable";
import FileUpload from "../FileUpload";

interface IProps extends HTMLProps<HTMLDivElement> {
  setRecipientList: React.Dispatch<
    React.SetStateAction<OrderRecipientPartial[]>
  >;
  setPaginationIndex: React.Dispatch<React.SetStateAction<number>>;
  setSelectedRecipientGroup: React.Dispatch<React.SetStateAction<number>>;
  recipientList: OrderRecipientPartial[];
  recipientGroups: GetAllRecipientGroupData[];
  numberOfLinks?: string;
  setnumberOfLinks: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedRecipientGroup: number;
  selectedRecipients: OrderRecipientPartial[];
  setSelectedRecipients: React.Dispatch<
    React.SetStateAction<OrderRecipientPartial[]>
  >;
  selectAllRecipients: () => void;
  totalPages: number;
  values: TCampaignValue;
  updateValues: (value: string | boolean, key: keyof TCampaignValue) => void;
  setCampaignValue: React.Dispatch<React.SetStateAction<TCampaignValue>>;
  pageSize: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  customFields?: TCustomField[];
}

const RecipientsProgress = ({
  selectedRecipients,
  setSelectedRecipients,
  setRecipientList,
  setPaginationIndex,
  setSelectedRecipientGroup,
  recipientList,
  recipientGroups,
  numberOfLinks,
  setnumberOfLinks,
  selectedRecipientGroup,
  selectAllRecipients,
  totalPages,
  values,
  setCampaignValue,
  children,
  className,
  pageSize,
  setPageNumber: setParentPageNumber,
  customFields = [],
}: IProps) => {
  const [fileName, setFileName] = useState("");
  const [fileUploaded, setFileUploaded] = useState(false);
  const [recipientListGroupCached, setRecipientListGroupCached] = useState<
    OrderRecipientPartial[]
  >([]);
  const [recipientListUploadedCached, setRecipientListUploadedCached] =
    useState<OrderRecipientPartial[]>([]);
  const [allRecipientListSelected, setAllRecipientListSelected] =
    useState(false);

  const [pageNumber, setPageNumber] = useState(1);

  const handleFileUpload = useCallback(
    (file: File | null | undefined) => {
      if (file!.size / 1024 > 5120) {
        toast.error("File too large");
        return;
      }

      setFileName(file!.name);
      setFileUploaded(true);

      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = e.target?.result as string;
          const csv = require("csvtojson");

          const jsonObj = await csv().fromString(data);
          const parsedData: OrderRecipientPartial[] = [];

          jsonObj.forEach((i: any) => {
            const recipientCustomFieldValues: OrderRecipientPartial["recipientCustomFieldValues"] =
              (customFields || [])
                .map((field) => ({
                  value: i[field.field],
                  recipientCustomField: {
                    field: field.field,
                    type: "date",
                  },
                }))
                .filter((field) => field.value);

            const recipient: OrderRecipientPartial = {
              firstName: i["First Name"],
              lastName: i["Last Name"],
              email: i["Email"],
              phoneNumber: i["Phone Number"],
              birthDate: "",
              role: i["Role"],
              department: i["Department"],
              checked: true,
              recipientCustomFieldValues,
              recipientMessage: i["Message"],
            };

            // Only skip if `firstName` is missing or empty
            if (!recipient.firstName || recipient.firstName.trim() === "") {
              return;
            }

            parsedData.push(recipient);
          });

          if (parsedData.length > 100) {
            toast.warn("Max 100 recipients allowed!");
            return;
          }

          setRecipientList(parsedData);
          setSelectedRecipients(parsedData);
          setAllRecipientListSelected(true);
        };

        reader.readAsText(file);
      }
    },
    [setRecipientList, customFields]
  );

  let paginationList = (
    <div className="flex gap-2 overflow-auto">
      {[...Array(totalPages)].map((_, i) => {
        return (
          <div
            key={i}
            className={`p-1 px-2 border rounded-lg cursor-pointer ${
              pageNumber === i + 1 && "bg-orange text-white"
            }`}
            onClick={() => {
              const newPageNumber = i + 1;

              setPageNumber(newPageNumber);
              const newState = !allRecipientListSelected;
              if (newState) {
                setParentPageNumber(newPageNumber);
              }
            }}
          >
            {i + 1}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={className}>
      <p className="font-leagueSpartan-500 text-[28px] text-neutrals-900">
        Recipient List
      </p>
      {/* Toggle */}
      <div className="rounded-3xl bg-lightOrange justify-start text-xs w-fit mt-4">
        <button
          className={`p-3 px-6 font-leagueSpartan-500 text-[16px] rounded-3xl ${
            values.selectedGreetingCard === "SelectGroup"
              ? "bg-orange text-white"
              : "text-orange"
          } `}
          onClick={() => {
            if (values.selectedGreetingCard !== "SelectGroup") {
              if (values.selectedGreetingCard === "Upload")
                setRecipientListUploadedCached(recipientList);
              setCampaignValue((prev: any) => ({
                ...prev,
                selectedGreetingCard: "SelectGroup",
              }));
              setRecipientList(recipientListGroupCached);
              setPaginationIndex(1);
              setSelectedRecipientGroup(-1);
              setAllRecipientListSelected(false);
            }
          }}
        >
          Select Recipient Groups
        </button>
        <button
          className={`p-3 px-6 font-leagueSpartan-500 text-[16px] rounded-3xl ${
            values.selectedGreetingCard === "Upload"
              ? "bg-orange text-white"
              : "text-orange"
          } `}
          onClick={() => {
            if (values.selectedGreetingCard !== "Upload") {
              if (values.selectedGreetingCard === "SelectGroup")
                setRecipientListGroupCached(recipientList);
              setCampaignValue((prev: any) => ({
                ...prev,
                selectedGreetingCard: "Upload",
              }));
              setRecipientList(recipientListUploadedCached);
              setPaginationIndex(1);
              setAllRecipientListSelected(false);
            }
          }}
        >
          Upload Recipients Template
        </button>
        <button
          className={`p-3 px-6 font-leagueSpartan-500 text-[16px] rounded-3xl ${
            values.selectedGreetingCard === "GenerateLinks"
              ? "bg-orange text-white"
              : "text-orange"
          } `}
          onClick={() => {
            if (values.selectedGreetingCard !== "GenerateLinks") {
              if (values.selectedGreetingCard === "SelectGroup") {
                setRecipientListGroupCached(recipientList);
              } else if (values.selectedGreetingCard === "Upload") {
                setRecipientListUploadedCached(recipientList);
              }
              setCampaignValue((prev: any) => ({
                ...prev,
                selectedGreetingCard: "GenerateLinks",
              }));
            }
          }}
        >
          Generate Links
        </button>
      </div>

      {values.selectedGreetingCard === "SelectGroup" && (
        <>
          <p className="font-leagueSpartan-300 text-[18px] text-neutrals-800 mt-6">
            Select one or more recipient groups below
          </p>
          <div className="flex mt-3 overflow-auto">
            {recipientGroups.map((v, i) => (
              <button
                key={i}
                className={`mx-2 p-2 px-4 font-leagueSpartan-300 text-[16px] text-neutrals-900 bg-neutrals-300 rounded-3xl whitespace-nowrap ${
                  selectedRecipientGroup === v.id && "bg-orange text-white"
                }`}
                onClick={() => {
                  setSelectedRecipientGroup(v.id);
                  setAllRecipientListSelected(false);
                }}
              >
                {v.name}
              </button>
            ))}
          </div>
        </>
      )}

      {values.selectedGreetingCard === "Upload" && (
        <>
          <p className="font-leagueSpartan-300 text-[18px] text-neutrals-800 mt-6">
            Download our Bulk Recipient Email Template to upload your
            recipients.
          </p>
          <button
            className="flex p-2 mt-2 px-5 border border-orange text-orange font-leagueSpartan-400 text-[16px]  rounded-lg gap-2"
            onClick={() => Api.downloadTemplate(customFields || [])}
          >
            Download our bulk upload template{" "}
            <img src={images.download} alt="" />
          </button>
          {fileUploaded && (
            <div className="flex gap-2 mt-4 font-leagueSpartan-400 text-[16px] text-neutrals-800">
              <img src={images.clip} alt="" /> {fileName}
              <img
                src={images.dustbinred}
                alt=""
                className="cursor-pointer"
                onClick={() => setFileUploaded(false)}
              />
            </div>
          )}
          {!fileUploaded && <FileUpload handleFileUpload={handleFileUpload} />}
        </>
      )}

      {values.selectedGreetingCard === "GenerateLinks" && (
        <div className="flex mt-4">
          <div className="grid grid-flow-row font-leagueSpartan-400">
            <label
              htmlFor=""
              className="font-leagueSpartan-400 text-[18px] text-neutrals-900"
            >
              {"Addressing name for <Recipient Name> :"}
            </label>
            <input
              type="string"
              defaultValue={"12:00"}
              className="w-[330px] p-2 border border-neutral-300 font-leagueSpartan-400  mt-2 rounded-lg"
              value={values?.recipientAddressingName}
              onChange={(e) => {
                const v = e.target.value;
                setCampaignValue((prev: TCampaignValue) => ({
                  ...prev,
                  recipientAddressingName: v,
                }));
              }}
            />
          </div>

          <div className="grid grid-flow-row mx-2">
            <label
              htmlFor=""
              className="font-leagueSpartan-400 text-[18px] text-neutrals-900"
            >
              Number or links:
            </label>
            <input
              type="text"
              className="w-[330px] border border-neutral-300 font-leagueSpartan-400  mt-2 rounded-lg"
              value={numberOfLinks}
              onChange={(e) => {
                const v = e.target.value;
                const test = /^[0-9]*$/.test(v);
                if (!test) return;
                if (+v > 10000) return;
                setnumberOfLinks(v);
              }}
            />
          </div>
        </div>
      )}

      <p className="font-leagueSpartan-500 text-[18px] text-neutrals-900 mt-8 ">
        View / Edit (Recipients)
      </p>

      {values.selectedGreetingCard === "SelectGroup" && (
        <div>
          <table className="table-auto my-4 text-sm w-full">
            <thead className="bg-neutrals-300 font-leagueSpartan-600 text-neutrals-700 text-[14px] capitalize text-left">
              <tr>
                <th className="p-4">
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => {
                      const newState = !allRecipientListSelected;
                      if (newState) {
                        setSelectedRecipients(recipientList);
                      } else {
                        setSelectedRecipients([]);
                      }
                      setAllRecipientListSelected(newState);

                      selectAllRecipients();
                    }}
                  >
                    <img
                      src={
                        allRecipientListSelected
                          ? images.checkBoxSelected
                          : images.checkBoxInactive
                      }
                      className="h-[20px] min-w-[20px]"
                      alt=""
                    />
                  </div>
                </th>
                <th className="p-4">FIRST NAME</th>
                <th className="p-4">LAST NAME</th>
                <th className="p-4">GROUP</th>
                <th className="p-4">EMAIL</th>
                <th className="p-4">MOBILE NUMBER</th>
                <th className="p-4">OCCASIONS</th>
                {recipientList.find((rl) => rl.editable) && (
                  <th className="p-4">
                    <img src={images.edit} alt="" />
                  </th>
                )}
              </tr>
            </thead>

            <EditableTable
              selected={
                !allRecipientListSelected
                  ? selectedRecipients
                  : selectedRecipients.slice(
                      (pageNumber - 1) * pageSize,
                      pageNumber * pageSize
                    )
              }
              setSelected={setSelectedRecipients}
              recipientList={
                !allRecipientListSelected
                  ? recipientList
                  : recipientList.slice(
                      (pageNumber - 1) * pageSize,
                      pageNumber * pageSize
                    )
              }
              setRecipientList={setRecipientList}
              selectAllRecipients={!allRecipientListSelected}
            />
          </table>

          <div
            className="flex items-center w-fit ml-2 font-leagueSpartan-400 text-[18px] text-orange tracking-[0.16px] cursor-pointer"
            onClick={() => {
              setPaginationIndex(totalPages);

              const newRow = {
                name: "",
                firstName: "",
                lastName: "",
                department: "",
                role: "",
                email: "",
                phoneNumber: "",
                birthDate: "2000-01-01",
                editable: true,
                recipientCustomFieldValues: [], //empty for now
                recipientMessage: "",
              };
              setRecipientList((ol: OrderRecipientPartial[]) => [
                ...ol,
                newRow,
              ]);
            }}
          >
            Add a new row{" "}
            <div className="font-leagueSpartan-300 text-[28px] ml-3">+</div>{" "}
          </div>
          {paginationList}
          <div className="my-6">
            <Divider />
          </div>
        </div>
      )}

      {values.selectedGreetingCard === "Upload" && fileUploaded && (
        <>
          <table className="table-auto my-4 text-sm w-full">
            <thead className="bg-neutrals-300 font-leagueSpartan-600 text-neutrals-700 text-[14px] capitalize text-left">
              <tr>
                <th>
                  <div
                    className="flex items-center cursor-pointer pl-4"
                    onClick={() => {
                      const allSelected = !allRecipientListSelected;
                      setAllRecipientListSelected(allSelected);
                      setRecipientList((prevList) =>
                        prevList.map((recipient) => ({
                          ...recipient,
                          checked: allSelected,
                        }))
                      );
                      setSelectedRecipients(allSelected ? recipientList : []);
                    }}
                  >
                    <img
                      src={
                        allRecipientListSelected
                          ? images.checkBoxSelected
                          : images.checkBoxInactive
                      }
                      className="h-[20px] min-w-[20px]"
                      alt=""
                    />
                  </div>
                </th>
                <th>FIRST NAME</th>
                <th>LAST NAME</th>
                <th>GROUP</th>
                <th>POSITION</th>
                <th>EMAIL</th>
                <th>MOBILE NUMBER</th>
                <th>MESSAGE</th>
                <th>OCCASIONS</th>
              </tr>
            </thead>
            <tbody className="font-leagueSpartan-400 text-[16px] text-neutrals-900">
              {recipientList.map((v, i) => (
                <tr key={i} className="border-b">
                  <td className="p-4">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        setRecipientList((prevList) => {
                          const newList = [...prevList];
                          newList[i].checked = !newList[i].checked;
                          const newSelectedRecipients = newList.filter(
                            (recipient) => recipient.checked
                          );
                          setSelectedRecipients(newSelectedRecipients);
                          return newList;
                        });
                      }}
                    >
                      <img
                        src={
                          v.checked
                            ? images.checkBoxSelected
                            : images.checkBoxInactive
                        }
                        className="h-[20px] min-w-[20px]"
                        alt=""
                      />
                    </div>
                  </td>
                  <td className="p-4 pl-0">{v.firstName}</td>
                  <td className="p-4 pl-0">{v.lastName || ""}</td>
                  <td className="p-4 pl-0">
                    {v.departmentGroup?.map((group) => group.name).join(", ")}
                  </td>
                  <td className="p-4 pl-0">{v.role}</td>
                  <td className="p-4 pl-0">{v.email}</td>
                  <td className="p-4 pl-0">{v.phoneNumber}</td>
                  <td className="p-4 pl-0">{v.recipientMessage}</td>
                  <td className="p-4">
                    {v.recipientCustomFieldValues?.map((fieldValue) => (
                      <span
                        key={fieldValue.id}
                        className="inline-flex items-center px-2.5 py-0.5 mt-2 rounded-full text-base font-medium bg-blue-100 text-blue-800 mr-2 mb-2"
                      >
                        {fieldValue.recipientCustomField.field}
                      </span>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {paginationList}
        </>
      )}

      {!fileUploaded && selectedRecipientGroup === -1 && (
        <p className="font-leagueSpartan-300 text-[18px] text-neutrals-800 mt-3">
          Select recipient group or upload recipients template first to view /
          edit recipients
        </p>
      )}

      {children}
    </div>
  );
};

export default RecipientsProgress;
