import { isAxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ImageUploader } from "../../../components/ImageUploader/ImageUploader";
import Api from "../../../constants/api";

export const CreateCollection = (props: any) => {
  console.log(props, "PROPS");
  const navigate = useNavigate();
  const successMessage = () => toast("Success!");
  const errorMessage = (error?: string) => toast.error(error || "error!");
  const [collectionData, setCollectionData] = useState({
    name: "",
    type: "",
    description: "",
    sentiment: "Positive",
    picture: "",
  });
  const [isRecommended, setIsRecommended] = useState(false);

  /**
   * Function to handle input change
   */

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    console.log(name, value, "yessss");
    setCollectionData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  /**
   * This is to Update Collection
   */
  const updateCollection = () => {
    Api.updateCollection(props.updateData.id, collectionData, isRecommended)
      .then((res) => {
        if (isAxiosError(res))
          errorMessage(res.response?.data.message || "Invalid data!");
        else {
          successMessage();
          // window.location.href = "http://localhost:3000/updateSuccess";
          navigate("/admin", { state: { tab: props?.tabNumber, subtab: 1 } });
        }
      })
      .catch((err) => {
        console.log(err);
        errorMessage();
      });
  };

  const handleCheckbox = (e: any) => {
    setIsRecommended((prev) => {
      return !prev;
    });
  };

  /**
   * Handle Cancel Update
   */

  const handleCancelUpdate = () => {
    // window.location.href = "http://localhost:3000/admin";
    setCollectionData({
      name: "",
      type: "",
      description: "",
      sentiment: "",
      picture: "",
    });
    setIsRecommended(false);
    navigate("/admin", { state: { tab: props?.tabNumber, subtab: 1 } });
  };
  console.log(collectionData, isRecommended, "collec");

  /**THIS function is passed as prop in image uploader to get the image link */
  const getAzureImageUrl = (url: string) => {
    console.log(url, "url");
    setCollectionData((prev) => {
      return { ...prev, picture: url };
    });
  };

  /**
   * UseEffect
   */
  useEffect(() => {
    console.log(props.tabNumber, "tab");
    if (props.updateData && props.tabNumber === 1) {
      let data = props.updateData;
      setCollectionData({
        name: data.name,
        type: data.type,
        description: data.description,
        sentiment: data.sentiment,
        picture: data.picture,
      });
      setIsRecommended(data.isRecommended);
    }
  }, [props]);
  return (
    <div className="flex justify-around">
      <form
        onSubmit={(e) =>
          Api.createCollection(e, collectionData, isRecommended)
            .then((res) => {
              if (isAxiosError(res))
                errorMessage(res.response?.data.message || "Invalid data!");
              else{
                successMessage();
                navigate("/admin", { state: { tab: 1, subtab: 1 } });
              } 
            })
            .catch((err) => {
              console.log(err);
              errorMessage();
            })
        }
        className="m-4 grid grid-flow-row max-w-md grow"
      >
        <div className="flex justify-between">
          <div className="text-lg text-orange">Collection</div>
          <div>
            <button
              onClick={handleCancelUpdate}
              type="button"
              className="border rounded-lg p-2 bg-red-400 relative right-50"
            >
              Cancel
            </button>
          </div>
        </div>
        <input
          type="text"
          name="name"
          placeholder="name"
          onChange={handleInputChange}
          value={collectionData.name}
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="type"
          placeholder="type"
          onChange={handleInputChange}
          value={collectionData.type}
          className="border rounded-lg my-4"
        />
        <input
          type="text"
          name="description"
          placeholder="description"
          onChange={handleInputChange}
          value={collectionData.description}
          className="border rounded-lg my-4"
        />
        <div className="mt-2">
          <label htmlFor="sentiment" className="mr-2">
            Sentiment:
          </label>
          <select
            name="sentiment"
            id="sentiment"
            onChange={handleInputChange}
            value={collectionData.sentiment}
            className="border rounded-lg"
          >
            {["Positive", "Negative", "Neutral"].map((val, i) => (
              <option key={i} value={val}>
                {val}
              </option>
            ))}
          </select>
        </div>
        {/* <input
            type="text"
            name="sentiment"
            placeholder="sentiment"
            className="border rounded-lg my-4"
          /> */}
        <div className="flex my-4">
          <label htmlFor="sentiment" className="mr-2">
            Recommended:
          </label>
          <input
            type="checkbox"
            name="isRecommended"
            onChange={handleCheckbox}
            //   value={isRecommended}
            checked={isRecommended}
            placeholder="Is Recommended"
            className="border rounded-lg mt-1"
          />
        </div>
        {/* <input
            type="text"
            name="picture"
            placeholder="picture url"
            className="border rounded-lg my-4"
          /> */}
        <ImageUploader
          getAzureImageUrl={getAzureImageUrl}
          updateImgUrl={props?.updateData?.picture}
          tabNumber={props?.tabNumber}
          currentTab={props?.currentTab}
        />

        {props?.updateData && props?.tabNumber === props?.currentTab ? (
          <>
            <button
              onClick={updateCollection}
              type="button"
              className="border rounded-lg p-2 bg-lightOrange "
            >
              Update
            </button>
          </>
        ) : collectionData.picture ? (
          <button
            type="submit"
            className="border rounded-lg p-2 bg-lightOrange "
          >
            Submit
          </button>
        ) : (
          <button
            type="button"
            className="border rounded-lg p-2 bg-lightOrange "
            onClick={() => {
              alert("Upload Images before submitting");
            }}
          >
            Submit
          </button>
        )}
      </form>
      {props.updateData?.collectionTags && (
        <div className="h-screen overflow-y-scroll p-2 border">
          <div className="text-lg text-orange">Products in this collection</div>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Product id
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              {props.updateData.collectionTags.map(
                ({
                  id,
                  product,
                }: {
                  id: number;
                  product: { name: string; id: number };
                }) => {
                  return (
                    <tr key={id}>
                      <td className="px-6 py-4">{product.id}</td>
                      <td className="px-6 py-4">{product.name}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
