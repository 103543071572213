import { Dispatch, SetStateAction, useState } from "react";
import { toast } from "react-toastify";

import Api from "../../../constants/api";
import images from "../../../constants/images";
import { TCampaignValue } from "../../../pages/dashboard/CampaignCreate";
import { useAppSelector } from "../../../redux/hooks";
import Divider from "../../common/Divider";
import MessagePreviewModalCampaign from "../MessagePreviewModalCampaign";

type propType = {
  selectedMessageViaEmailCheckBox: boolean;
  selectedMessageViaWhatsappCheckBox: boolean;
  setSelectedMessageViaEmailCheckBox: Dispatch<SetStateAction<boolean>>;
  setSelectedMessageViaWhatsappCheckBox: Dispatch<SetStateAction<boolean>>;
  selectedPreview: "Desktop" | "Mobile";
  setSelectedPreview: Dispatch<SetStateAction<"Desktop" | "Mobile">>;
  setCampaignValue: Dispatch<SetStateAction<TCampaignValue>>;
  value: TCampaignValue;
};

export const socialMediaTypes: {
  name: messageViaType;
  element: JSX.Element;
}[] = [
  {
    name: `Email`,
    element: <></>,
  },
  { name: `Whatsapp`, element: <></> },
];

const email = socialMediaTypes[0];
const whatsapp = socialMediaTypes[1];

const MAX_MESSAGE_CHARACTERS = 200;

const GreetingCardProgress = (props: propType) => {
  const {
    selectedMessageViaEmailCheckBox,
    selectedMessageViaWhatsappCheckBox,
    setSelectedMessageViaEmailCheckBox,
    setSelectedMessageViaWhatsappCheckBox,
    selectedPreview,
    setSelectedPreview,
    setCampaignValue,
    value,
  } = props;

  const {
    recipientMessageFrom,
    recipientMessageFooter,
    recipientMessage,
    recipientMessageTitle,
    messageMethod,
  } = value;

  const corpState = useAppSelector((state) => state.corpSliceState);

  const [emailPreviewModal, setEmailPreviewModal] = useState(false);
  const [testEmail, setTestEmail] = useState("");
  const [testWhatsappNumber, setTestWhatsappNumber] = useState("");

  return (
    <div className="mt-20 mx-auto w-[80%] mb-6">
      <div className="p-2">
        <div className="font-leagueSpartan-500 text-[28px] text-neutrals-900">
          Choose Send Method
        </div>
        <div className="grid grid-cols-2 gap-6 mt-4">
          <div
            className={`grid grid-cols-5 ${
              messageMethod === "automate"
                ? "bg-lightOrange border-orange "
                : "bg-white"
            } border  rounded-2xl p-6 cursor-pointer`}
            onClick={() =>
              setCampaignValue((prev: TCampaignValue) => ({
                ...prev,
                messageMethod: "automate",
              }))
            }
          >
            <div className="col-span-4 grid grid-flow-row">
              <div className="font-leagueSpartan-500 text-[24px] text-neutrals-900">
                We’ll send it for you
              </div>
              <div className="font-leagueSpartan-300 text-[16px] text-neutrals-700">
                Customise a greeting message and we’ll email / WhatsApp each
                recipient on your behalf
              </div>
            </div>
            <div className=" flex justify-center">
              <img src={images.messageEmail} className="h-24" alt="" />
            </div>
          </div>
          <div
            className={`grid grid-cols-5 ${
              messageMethod === "manual"
                ? "bg-lightOrange border-orange"
                : "bg-white"
            } border  rounded-2xl p-6 cursor-pointer`}
            onClick={() =>
              setCampaignValue((prev: TCampaignValue) => ({
                ...prev,
                messageMethod: "manual",
              }))
            }
          >
            <div className="col-span-4 grid grid-flow-row">
              <div className="font-leagueSpartan-500 text-[24px] text-neutrals-900">
                You’ll send it yourself
              </div>
              <div className="font-leagueSpartan-300 text-[16px] text-neutrals-700">
                Copy a unique link for each recipient from your dashboard and
                share via your method of choice
              </div>
            </div>
            <div className=" flex justify-center">
              <img src={images.link} className="h-24" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className={`my-8`}>
        <Divider />
      </div>
      <div className={`flex`}>
        {/* col 1 */}
        <div className="w-[48%] p-4">
          <p className="font-leagueSpartan-500 text-[#383838] text-[28px]">
            Customise your greeting card
          </p>
          <div>
            <div className="flex justify-between mt-4">
              <p className="font-leagueSpartan-500 text-[18px] text-neutrals-900">
                Add message to your recipient(s)
              </p>
            </div>

            <div className="w-full border border-white shadow rounded-lg mt-4">
              <div className="p-4 font-leagueSpartan-500 text-[18px] text-neutrals-800">
                <p>
                  <input
                    type="text"
                    value={recipientMessageTitle}
                    onChange={(e) => {
                      if (e.target.value.length <= 40) {
                        setCampaignValue((prev: TCampaignValue) => ({
                          ...prev,
                          recipientMessageTitle: e.target.value,
                        }));
                      }
                    }}
                    className="border border-white  rounded-lg font-leagueSpartan-500 text-[18px] text-neutrals-800 w-[100px]"
                  />{" "}
                  {"<Recipient Name>,"}
                </p>
              </div>
              <textarea
                className="w-full p-4 h-56 resize-none border font-leagueSpartan-300 border-white text-[18px]  text-neutrals-800 placeholder:font-leagueSpartan-300 placeholder:text-neutrals-600"
                placeholder="Write a heartfelt message to your recipients - show them how much you appreciate them!"
                onChange={(e) => {
                  const length = e.target.value.length;
                  if (length <= MAX_MESSAGE_CHARACTERS) {
                    setCampaignValue((prev: TCampaignValue) => ({
                      ...prev,
                      recipientMessage: e.target.value,
                    }));
                  }
                }}
                value={recipientMessage}
              />
            </div>
            <div className="flex justify-end mt-4">
              <p className="font-leagueSpartan-400 text-[14px] text-neutrals-600">
                {recipientMessage?.length} / {MAX_MESSAGE_CHARACTERS}
              </p>
            </div>
          </div>
          <div>
            <div className="flex justify-between mt-4">
              <p className="font-leagueSpartan-500 text-[18px] text-neutrals-900">
                End your message with a greeting
              </p>
            </div>
            <input
              className="w-full p-4 resize-none font-leagueSpartan-300 text-lg border border-white shadow rounded-lg text-neutrals-800 placeholder:font-leagueSpartan-300 placeholder:text-neutrals-600 focus:ring-0 focus:border-orange"
              placeholder="Warmest regards,"
              value={recipientMessageFooter}
              onChange={(e) => {
                if (e.target.value.length <= 40) {
                  setCampaignValue((prev: TCampaignValue) => ({
                    ...prev,
                    recipientMessageFooter: e.target.value,
                  }));
                }
              }}
            />
            <div className="flex justify-end mt-4">
              <p className="font-leagueSpartan-400 text-[14px] text-neutrals-600">
                {recipientMessageFooter.length} / 40
              </p>
            </div>
          </div>
          <div>
            <div className="flex justify-between mt-4">
              <p className="font-leagueSpartan-500 text-[18px] text-neutrals-900">
                The name they'll see
              </p>
            </div>
            <input
              className="w-full p-4 resize-none font-leagueSpartan-300 text-lg border border-white shadow rounded-lg text-neutrals-800 placeholder:font-leagueSpartan-300 placeholder:text-neutrals-600 focus:ring-0 focus:border-orange"
              placeholder="Your Name"
              value={recipientMessageFrom}
              onChange={(e) => {
                if (e.target.value.length <= 40) {
                  setCampaignValue((prev: TCampaignValue) => ({
                    ...prev,
                    recipientMessageFrom: e.target.value,
                  }));
                }
              }}
            />
            <div className="flex justify-end mt-4">
              <p className="font-leagueSpartan-400 text-[14px] text-neutrals-600">
                {recipientMessageFrom.length} / 40
              </p>
            </div>
          </div>
        </div>
        <div
          className={`w-[4%] flex justify-center ${
            messageMethod === "manual" && "hidden"
          }`}
        >
          <div className="border h-[100%] w-[0.5px] border-neutral-100"></div>
        </div>
        {/* col 2 */}
        <div
          className={`w-[48%] p-4 ${messageMethod === "manual" && "hidden"}`}
        >
          <p className="font-leagueSpartan-500 text-[#383838] text-[24px]">
            Preview
          </p>
          <p className="mt-4 font-leagueSpartan-300 text-[18px] text-neutrals-800">
            This is the greeting card that recipients will see when they click
            on the link through the selected delivery method.
          </p>
          <div className="mt-4">
            <div className="flex">
              <div className="rounded-3xl bg-lightOrange justify-start text-xs">
                <button
                  className={`p-2.5 px-6 font-leagueSpartan-400 text-[16px] rounded-3xl ${
                    selectedPreview === "Desktop"
                      ? "bg-orange text-white"
                      : "text-orange"
                  } `}
                  onClick={() => setSelectedPreview("Desktop")}
                >
                  Desktop
                </button>
                <button
                  className={`p-2.5 px-6 font-leagueSpartan-400 text-[16px] rounded-3xl ${
                    selectedPreview === "Mobile"
                      ? "bg-orange text-white"
                      : "text-orange"
                  } `}
                  onClick={() => setSelectedPreview("Mobile")}
                >
                  Mobile
                </button>
              </div>
            </div>
            <img
              src={
                selectedPreview === "Desktop"
                  ? images.emailPreviewNew
                  : images.whatsappPreview_
              }
              className="mt-4 h-72 cursor-pointer"
              alt=""
              onClick={() => setEmailPreviewModal(true)}
            />
          </div>
          <div
            className="mt-5 rounded-lg p-3 text-orange font-leagueSpartan-400 text-[18px] bg-lightOrange w-fit flex items-center cursor-pointer"
            onClick={() => setEmailPreviewModal(true)}
          >
            <img
              src={images.questionMarkOrange}
              className="h-[18px] mr-2"
              alt=""
            />{" "}
            How will recipients see this?
          </div>
        </div>
      </div>
      {emailPreviewModal && (
        <MessagePreviewModalCampaign
          recipientMessage={recipientMessage}
          setEmailPreviewModal={setEmailPreviewModal}
          image={
            selectedPreview === "Desktop"
              ? images.emailPreview
              : images.whatsappPreview
          }
          selectedPreview={selectedPreview}
        />
      )}
    </div>
  );
};

export default GreetingCardProgress;
