import { NavigateFunction } from "react-router-dom";
import { pathType } from "../Routes/Routes";

class CustomNavigate {
  private navigate: NavigateFunction;
  private recipientId?: string | null;
  corpId?: string | null;
  constructor(
    navigate: NavigateFunction,
    other?: { recipientId?: string | null; corpId?: string | null }
  ) {
    this.navigate = navigate;
    if (other) {
      this.recipientId = other.recipientId;
      this.corpId = other.corpId;
    }
  }
  to(path: pathType | -1, ids?: { key: string; value: any }, state?: any) {
    let link: string | -1 = path;
    if (ids) {
      link = link.toString().replace(ids.key, ids.value);
    }
    if (this.corpId) {
      link = link.toString().replace(":corpName", this.corpId);
    }
    if (this.recipientId) {
      link = `${link}?recid=${this.recipientId}`;
      console.log("to ->", link);
    }
    // @ts-ignore
    this.navigate(link, { state });
  }
  toAny(path: string, state?: any) {
    this.navigate(path, { state });
  }
  back() {
    this.navigate(-1);
  }
}

export default CustomNavigate;
