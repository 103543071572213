import classNames from "classnames";
import { useNavigate, useSearchParams } from "react-router-dom";
import colours from "../../constants/colours";
import { useAppSelector } from "../../redux/hooks";
import CustomNavigate from "../../utils/navigate";
import BackButtonGifting from "../giftSelection/BackButton";

type propType = {
  title?: string;
  logo: string;
};

const PreviewHeaderHamper = (props: propType) => {
  const { logo, title } = props;
  const cartState = useAppSelector((state) => state.cartState);

  const recipientId =
    useSearchParams()[0].get("recid") ||
    cartState.campaignRecipientData?.result.url_id;
  const navigate = new CustomNavigate(useNavigate(), { recipientId });

  return (
    <div
      className={classNames(
        "fixed",
        "top-0",
        "w-screen",
        "z-10",
        "grid",
        "grid-cols-3",
        "md:grid-cols-5",
        "gap-[5px]",
        "py-4",
        "px-8",
        "shadow-md",
        "text-[#F8FAFB]"
      )}
      style={{ backgroundColor: colours.darkGrey }}
    >
      {/* Back Button (sm-only) */}
      <div className="col-span-1 md:hidden">
        <BackButtonGifting navigate={navigate} isPreview />
      </div>
      {/* Logo */}
      <div className="col-span-1 flex justify-center items-center">
        <img src={logo} className="h-[25px] w-[49px] object-cover" alt="" />
      </div>
      <div className="max-md:hidden md:col-span-3 text-center font-leagueSpartan-400 text-[16px]">
        {title ??
          "This is a preview link. Please activate your link before sending to your recipient."}
      </div>
      <div className="col-span-1"></div>
      <div className="col-span-full md:hidden text-center font-leagueSpartan-400 text-[10px]">
        {title ??
          "This is a preview link. Please activate your link before sending to your recipient."}
      </div>
    </div>
  );
};

export default PreviewHeaderHamper;
