import images from "./images";

export const categoriesDummyData: categoryT[] = [
  {
    name: "Nox Dine in the Dark: Dinner for 1",
    credits: 10,
    image: images.sampleProduct,
    images: [
      images.sampleProduct,
      images.sample1,
      images.sample2,
      images.sample3,
      images.sample4,
    ],
    topUp: false,
  },
  {
    name: "Nox Dine in the Dark: Dinner for 1",
    credits: 10,
    image: images.sample5,
    images: [
      images.sampleProduct,
      images.sample1,
      images.sample2,
      images.sample3,
      images.sample4,
    ],
    topUp: false,
  },
  {
    name: "Nox Dine in the Dark: Dinner for 1",
    credits: 10,
    image: images.sample6,
    images: [
      images.sampleProduct,
      images.sample1,
      images.sample2,
      images.sample3,
      images.sample4,
    ],
    topUp: false,
  },
  {
    name: "Nox Dine in the Dark: Dinner for 1",
    credits: 10,
    image: images.sample7,
    images: [
      images.sampleProduct,
      images.sample1,
      images.sample2,
      images.sample3,
      images.sample4,
    ],
    topUp: false,
  },
  {
    name: "Nox Dine in the Dark: Dinner for 1",
    credits: 10,
    image: images.sample8,
    images: [
      images.sampleProduct,
      images.sample1,
      images.sample2,
      images.sample3,
      images.sample4,
    ],
    topUp: false,
  },
  {
    name: "Nox Dine in the Dark: Dinner for 1",
    credits: 10,
    image: images.sampleProduct,
    images: [
      images.sampleProduct,
      images.sample1,
      images.sample2,
      images.sample3,
      images.sample4,
    ],
    topUp: false,
  },
  {
    name: "Nox Dine in the Dark: Dinner for 1",
    credits: 10,
    image: images.sample7,
    images: [
      images.sampleProduct,
      images.sample1,
      images.sample2,
      images.sample3,
      images.sample4,
    ],
    topUp: false,
  },
  {
    name: "Nox Dine in the Dark: Dinner for 1",
    credits: 10,
    image: images.sample6,
    images: [
      images.sampleProduct,
      images.sample1,
      images.sample2,
      images.sample3,
      images.sample4,
    ],
    topUp: false,
  },
];

export const occasionsDummyData = [
  { image: images.giftRedBlack, text: "Birthday" },
  { image: images.calendarRed, text: "Work Anniversary" },
  { image: images.travelBag, text: "Onboarding" },
  { image: images.newBorn, text: "New Born" },
  { image: images.prize, text: "Spot Rewards" },
  { image: images.thumb, text: "Get Well Soon" },
  { image: images.giftRedBlack, text: "Bereavement" },
  { image: images.calendarRed, text: "Birthday" },
  { image: images.travelBag, text: "Work Anniversary" },
  { image: images.newBorn, text: "Onboarding" },
];

export const amountsDummyData = [
  { val: 25, str: "$25" },
  { val: 50, str: "$50" },
  { val: 75, str: "$75" },
  { val: 100, str: "$100" },
  { val: 0, str: "Other Amount" },
];

export const categoriesCampaignDummyData = [
  {
    name: "Gourmet Sea Salt & Black Pepper Toasted Cashews",
    credits: 20,
    image: images.sample13,
    images: [
      images.sample8,
      images.sample7,
      images.sample9,
      images.sample5,
      images.sample6,
    ],
    topUp: false,
  },
  {
    name: "Royal Treatmint Tea Big Heart Tea Co.",
    credits: 10,
    image: images.sample5,
    images: [
      images.sample8,
      images.sample7,
      images.sample9,
      images.sample5,
      images.sample6,
    ],
    topUp: false,
  },
  {
    name: "Awfully Chocolate Caramel Brittle & Sea Salt Chocolate",
    credits: 13,
    image: images.sample6,
    images: [
      images.sample8,
      images.sample7,
      images.sample9,
      images.sample5,
      images.sample6,
    ],
    topUp: false,
  },
  {
    name: "Awfully Chocolate 6 Pcs Dark Chocolate Truffles",
    credits: 22,
    image: images.sample7,
    images: [
      images.sample8,
      images.sample7,
      images.sample9,
      images.sample5,
      images.sample6,
    ],
    topUp: false,
  },
  {
    name: "Sugarfina's Happy Birthday Let Them Eat Cake Bundle",
    credits: 30,
    image: images.sample9,
    images: [
      images.sample8,
      images.sample7,
      images.sample9,
      images.sample5,
      images.sample6,
    ],
    topUp: false,
  },
  {
    name: "Wine Lover's Infused Gourmet Popcorn",
    credits: 70,
    image: images.sample10,
    images: [
      images.sample8,
      images.sample7,
      images.sample9,
      images.sample5,
      images.sample6,
    ],
    topUp: false,
  },
  {
    name: "Bonnie & Pop Happy Birthday Assorted Chocolates",
    credits: 60,
    image: images.sample11,
    images: [
      images.sample8,
      images.sample7,
      images.sample9,
      images.sample5,
      images.sample6,
    ],
    topUp: false,
  },
  {
    name: "Microchip Cookie Jar: The Original Chocolate Chip (150g)",
    credits: 20,
    image: images.sample12,
    images: [
      images.sample8,
      images.sample7,
      images.sample9,
      images.sample5,
      images.sample6,
    ],
    topUp: false,
  },
];
