import { useState } from "react";
import images from "../../constants/images";
import { SubscriptionType } from "../../utils/constant";
import "../common/dropdown.css";

type DropdownScheduleSendProps = {
  value: string;
  setRecipientTriggerTime: (value: boolean) => void;
};

const DropdownScheduleSendTriggerTime = ({
  setRecipientTriggerTime,
}: DropdownScheduleSendProps) => {
  const [listDisplay, setlistDisplay] = useState(false);
  const [option, setOption] = useState<SubscriptionType>(
    SubscriptionType.OneTime
  );
  const subscriptions: SubscriptionType[] = Object.values(SubscriptionType);

  const handleChange = (selectedOption: SubscriptionType) => {
    setOption(selectedOption);
    setRecipientTriggerTime(selectedOption === SubscriptionType.Recurring);
    setlistDisplay((listDisplay) => !listDisplay);
  };

  return (
    <div className="dropdown inline-block relative">
      <button
        className="bg-white border w-[330px] text-gray-700 font-leagueSpartan-400 py-2 px-4 rounded flex justify-between items-center"
        onClick={() => setlistDisplay(!listDisplay)}
      >
        <span className="mr-1">{option}</span>
        <img src={images.dropdownIcon} className="h-4 w-4" alt="dropdown" />
      </button>
      {listDisplay && (
        <ul className="absolute text-gray-700 pt-1 w-full z-10">
          {subscriptions.map((subscription, index) => (
            <li key={index} onClick={() => handleChange(subscription)}>
              <div className="rounded-t bg-neutrals-300 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap">
                {subscription}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownScheduleSendTriggerTime;
