import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

// Define the initial state using that type
const initialState: {
  data?: CreateCampaignResponseData;
} = {};

export const campaignSlice = createSlice({
  name: "campaignSlice",
  initialState,
  reducers: {
    setCampaignResponseData: (
      state,
      action: PayloadAction<CreateCampaignResponseData>
    ) => {
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCampaignResponseData } = campaignSlice.actions;

export const selectCount = (state: RootState) => state.cartState;

export default campaignSlice.reducer;
