type propType = {
  quantityAvailable: number;
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
};

const Quantity = ({ quantityAvailable, quantity, setQuantity }: propType) => {
  const insuffecientCredits = quantity > quantityAvailable;
  return (
    <div className="shadow-md p-4 rounded-lg my-4">
      <div className="grid grid-cols-5 my-3">
        <div className="col-span-3 font-leagueSpartan-400">Quantity</div>
        <div
          className={`col-span-2 grid grid-cols-3 border rounded-lg justify-items-center ${
            insuffecientCredits &&
            "bg-neutrals-300 text-neutrals-600 text-[18px]"
          }`}
        >
          <button
            className="p-1"
            onClick={() =>
              setQuantity((previousQuantity) =>
                Math.max(0, previousQuantity - 1)
              )
            }
          >
            -
          </button>
          <button className="p-1 font-leagueSpartan-400 ">{quantity}</button>
          <button
            className="p-1"
            onClick={() =>
              setQuantity((previousQuantity) =>
                Math.min(previousQuantity + 1, quantityAvailable)
              )
            }
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};
export default Quantity;
