import React from "react";

import images from "../../constants/images";

type propType = {
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  createCampaign: (isDraft?: boolean) => void;
};
const SaveDraftModalCampaign = (props: propType) => {
  const { setModalVisible, createCampaign } = props;

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[45%]">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-6">
              {/* Body */}
              <div className="flex justify-between">
                <div className="font-leagueSpartan-400 text-lg">
                  Save as draft for now?
                </div>
                <button
                  onClick={() => setModalVisible(false)}
                  className="self-start h-7"
                >
                  <img src={images.cross} className="w-5" alt="" />
                </button>
              </div>
              <div className="border-t border-b mt-2 py-4 font-leagueSpartan-200 ">
                Your progress will be saved. You can find and edit this campaign
                another time under draft the "Draft" tab in "My Campaigns".
              </div>
              <div className="flex justify-end pt-4 ">
                <button
                  onClick={() => {
                    setModalVisible(false);
                  }}
                  className="self-start mr-2 px-4 p-2.5  text-xs border border-orange text-orange rounded-lg"
                >
                  Continue editing
                </button>
                <button
                  onClick={() => {
                    createCampaign(true);
                  }}
                  className="self-start px-4 p-2.5 text-xs bg-orange text-white rounded-lg"
                >
                  Save draft
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveDraftModalCampaign;
