import { useState, useEffect } from "react";
import Api from "../../../constants/api";
import { useNavigate } from "react-router-dom";
export const Products = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isDeletingProduct, setIsDeletingProduct] = useState(false);
  const [productToDelete, setProductToDelete] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const getAllProducts = () => {
    Api.getAllProducts()
      .then((res: any) => {
        setProducts(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const editProduct = (item: object) => {
    navigate("/admin", { state: { item, tab: 3, subTab: 0 } });
  };

  const handleDeleteProduct = () => {
    if (!productToDelete?.id) {
      resetDeleting();
      return;
    }
    setIsDeletingProduct(true);

    Api.deleteProduct(productToDelete.id).then(() => {
      resetDeleting();
      getAllProducts();
    });
  };

  const resetDeleting = () => {
    setShowConfirmDelete(false);
    setIsDeletingProduct(false);
    setProductToDelete(null);
  };

  return (
    <>
      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        {products?.map((item: any) => {
          return (
            <>
              <div style={{ border: "2px solid black", padding: "20px" }}>
                <h1>{item?.id}</h1>
                <h2>{item?.name}</h2>
                <img style={{ width: "100px" }} src={item?.picture} />
                <button
                  onClick={() => {
                    editProduct(item);
                  }}
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    boxShadow: "2px 2px 2px black",
                  }}
                >
                  Edit
                </button>
                <button
                  className="mx-3"
                  onClick={() => {
                    setProductToDelete({
                      id: item.id,
                      name: item.name,
                    });
                    setShowConfirmDelete(true);
                  }}
                  style={{
                    backgroundColor: "Red",
                    color: "white",
                    boxShadow: "2px 2px 2px black",
                  }}
                >
                  Delete
                </button>
              </div>
            </>
          );
        })}
      </div>
      {showConfirmDelete && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md w-96">
            <h2 className="text-xl font-semibold mb-4">Delete Product</h2>
            <div className="mb-5">
              Are you sure you want to delete{" "}
              <span className="font-bold">{productToDelete?.name}</span>?
            </div>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 mr-2 text-white bg-gray-500 rounded-md hover:bg-gray-600 disabled:bg-slate-500"
                onClick={() => {
                  setShowConfirmDelete(false);
                }}
                disabled={isDeletingProduct}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-white bg-red-700 rounded-md hover:bg-red-600 disabled:bg-slate-500"
                onClick={() => {
                  handleDeleteProduct();
                }}
                disabled={isDeletingProduct}
              >
                {isDeletingProduct ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
