import { isAxiosError } from "axios";
import { useCallback, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../constants/api";
import { setIsLoading } from "../../redux/globalSlice";
import { useAppDispatch } from "../../redux/hooks";
import JwtService from "../../service/jwtService";
import ToastCustomized from "../common/ToastCustomized";
import VerifyEmailModel from "../common/VerifyEmailModel";
import "./PhoneInput.css";

export default function CreateAccountPage() {
  const [searchParams] = useSearchParams();

  const [username, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<{
    value: string;
    format: string;
  }>({ value: searchParams.get("phoneNumber") || "", format: "" });
  const [confirmpassword, setConfirmPassword] = useState<string>("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    username: "",
    confirmpassword: "",
    phoneNumber: "",
  });

  const [showModel, setShowModel] = useState(false);
  const dispatch = useAppDispatch();

  const register = useCallback(() => {
    if (Object.values(errors).find((v) => v !== "")) {
      toast.error("Enter valid details");
      return;
    }
    if (email === "" || password === "" || username === "") {
      toast.error("Enter valid details");
      return;
    }
    if (password !== confirmpassword) {
      toast.error("Password and confirm password doesnt match");
      return;
    }

    if (!phoneNumber) {
      toast.error("Phone number is required");
      return;
    }

    if (
      phoneNumber.value.length !==
      String(phoneNumber.format).replace(/[^.]/g, "").length
    ) {
      toast.error("Phone number is not valid");
      return;
    }

    dispatch(setIsLoading(true));
    const callback = searchParams.get("callback");

    Api.register({
      email,
      password,
      username,
      phoneNumber: phoneNumber.value,
      callback,
    })
      .then((res) => {
        dispatch(setIsLoading(false));
        console.log(res);
        setShowModel(true);
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        JwtService.logout();
        console.log(err);
        if (isAxiosError(err)) {
          toast.error(err.response?.data.message);
          return;
        }

        toast.error("Invalid Credentials");
      });
  }, [
    confirmpassword,
    dispatch,
    email,
    errors,
    password,
    phoneNumber,
    searchParams,
    username,
  ]);

  return (
    <div className="flex flex-col gap-2 ">
      <label className=" text-zinc-800 text-base font-leagueSpartan-400">
        User Name
      </label>
      <div className="">
        <div
          className="flex items-center border rounded-md  p-1  "
          style={{ width: "100%", height: "45px" }}
        >
          <input
            type="text"
            placeholder=" Enter your user name"
            className="w-full rounded-lg outline-none border-none focus:ring-0 text-gray-700 font-leagueSpartan-300"
            value={username}
            onChange={(e) => {
              const v = e.target.value;
              if (v === "") {
                setErrors((e) => {
                  return {
                    ...e,
                    username: "User name required.",
                  };
                });
              } else {
                setErrors((e) => {
                  return { ...e, username: "" };
                });
              }
              setUserName(v);
            }}
          />
        </div>
        <label className=" text-red-500 text-sm ">{errors.username}</label>
      </div>

      <label className=" text-zinc-800 text-base font-leagueSpartan-400">
        Email Address
      </label>
      <div className="">
        <div
          className="flex items-center  border  rounded-md  p-1 "
          style={{ width: "100%", height: "45px" }}
        >
          <input
            type="text"
            placeholder=" Enter your email address"
            className={`w-full rounded-lg outline-none border-none focus:ring-0 text-gray-700 font-leagueSpartan-300`}
            value={email}
            onChange={(e) => {
              const v = e.target.value;
              if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(v)) {
                setErrors((e) => {
                  return { ...e, email: "Invalid email." };
                });
              } else {
                setErrors((e) => {
                  return { ...e, email: "" };
                });
              }
              setEmail(v);
            }}
          />
        </div>
        <label className=" text-red-500 text-sm ">{errors.email}</label>
      </div>

      <label className=" text-zinc-800 text-base font-leagueSpartan-400">
        Phone Number
      </label>
      <div>
        <div className="flex items-center border rounded-md p-1">
          <PhoneInput
            inputClass={`w-full rounded-lg outline-none border-none border-0 focus:ring-0 text-gray-700 font-leagueSpartan-300`}
            country={"sg"}
            preferredCountries={["sg"]}
            enableSearch={true}
            placeholder=""
            value={phoneNumber?.value}
            inputProps={{
              name: "phoneNumber",
              required: true,
            }}
            onMount={(value, data) => {
              const { format } = { format: "", ...data };
              setPhoneNumber({ value, format });
            }}
            onChange={(value, data) => {
              const { format } = { format: "", ...data };
              setPhoneNumber({ value, format });
            }}
          />
        </div>
        <label className=" text-red-500 text-sm ">{errors.phoneNumber}</label>
      </div>

      <label className="text-zinc-800 text-base font-leagueSpartan-400">
        Password
      </label>
      <div className="">
        <div
          className="flex items-center border rounded-md  p-1  "
          style={{ width: "100%", height: "45px" }}
        >
          <input
            type="password"
            placeholder=" Enter your password"
            className="w-full rounded-lg outline-none border-none focus:ring-0 text-gray-700 font-leagueSpartan-300"
            value={password}
            onChange={(e) => {
              const v = e.target.value;
              if (v.length < 6) {
                setErrors((e) => {
                  return {
                    ...e,
                    password:
                      "Password must be greater than or equal to 6 characters.",
                  };
                });
              } else {
                setErrors((e) => {
                  return { ...e, password: "" };
                });
              }
              setPassword(v);
            }}
          />
        </div>
        <label className=" text-red-500 text-sm ">{errors.password}</label>
      </div>

      <label className="text-zinc-800 text-base font-leagueSpartan-400">
        {" "}
        Confirm Password
      </label>

      <div className="">
        <div
          className="flex items-center border rounded-md  p-1  "
          style={{ width: "100%", height: "45px" }}
        >
          <input
            type="password"
            placeholder=" Enter your confirm password"
            className="w-full rounded-lg outline-none border-none focus:ring-0 text-gray-700 font-leagueSpartan-300"
            value={confirmpassword}
            onChange={(e) => {
              const v = e.target.value;
              if (v !== password) {
                setErrors((e) => {
                  return {
                    ...e,
                    confirmpassword:
                      "Password and confirm password doesn't match.",
                  };
                });
              } else {
                setErrors((e) => {
                  return { ...e, confirmpassword: "" };
                });
              }
              setConfirmPassword(v);
            }}
          />
        </div>
        <label className="text-red-500 text-sm">{errors.confirmpassword}</label>
      </div>

      <div className="flex justify-center">
        <button
          className=" h-[45px] w-full  bg-orange rounded-lg text-white font-leagueSpartan-400 mt-2 text-[15px]"
          onClick={() => register()}
        >
          Sign Up
        </button>
      </div>

      <div className="mt-4">
        <div className=" text-center " style={{ lineHeight: "10px" }}>
          <span className="text-[#7C7E83] text-[10px] font-normal">
            By continuing, you agree to the{" "}
          </span>
          <a
            className="text-orange text-[10px] font-normal cursor-pointer"
            href="https://gifting@smilie.io/"
          >
            Smile Agreement
          </a>
          <span className="text-[#7C7E83] text-[10px] font-normal ">
            {" "}
            on behalf of your organisation and confirm that you have the
            authority to do so. And you confirm that you have read and
            understood the{" "}
          </span>
          <a
            className="text-orange text-[10px] font-normal cursor-pointer"
            href="https://smilie.io/"
          >
            Smilie Privacy,
          </a>
          <span className="text-[#7C7E83] text-[10px]  font-normal "> </span>
          <a
            className="text-orange text-[10px] font-normal cursor-pointer "
            href="https://smilie.io/"
          >
            Acceptable use
          </a>
          <span className="text-[#7C7E83] text-[10px] font-normal"> and </span>
          <a
            className="text-orange text-[10px] font-normal cursor-pointer"
            href="https://smilie.io/"
          >
            Cookies
          </a>
          <span className="text-[#7C7E83] text-[10px] font-normal">
            {" "}
            policies.
          </span>
        </div>
      </div>

      {showModel && <VerifyEmailModel setmodel={setShowModel} email={email} />}
      <ToastCustomized />
    </div>
  );
}
