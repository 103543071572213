import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ToastCustomized from "../../../components/common/ToastCustomized";
import Api from "../../../constants/api";
import images from "../../../constants/images";
import { setIsLoading } from "../../../redux/globalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import CustomNavigate from "../../../utils/navigate";

const perPageItems = [10, 20, 30, 40, 50];
const DEFAULT_TOUCHPOINTS_LIMIT = 10;

const HEADERS = ["Title", "Status", "Start Date", "End Date", "Responses"];

type TFetchTouchpoint = Touchpoint & { interactionsCount: number };
export const Touchpoints = () => {
  const navigate = new CustomNavigate(useNavigate());
  const dispatch = useAppDispatch();

  const [touchpoints, setTouchpoints] = useState<TFetchTouchpoint[]>([]);
  const [pageList, setPageList] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(perPageItems[0]);

  const getAllTouchpoints = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));
      const response = await Api.getAllTouchpoints(DEFAULT_TOUCHPOINTS_LIMIT);
      if (response) {
        setTouchpoints(response.data);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      dispatch(setIsLoading(false));
    }
  }, [dispatch]);

  useEffect(() => {
    getAllTouchpoints();
  }, [getAllTouchpoints]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentTouchpoints = touchpoints.slice(startIndex, endIndex);
  const totalPages = Math.ceil(touchpoints.length / itemsPerPage);
  const _setPageList = useCallback(() => {
    if (totalPages === 0) {
      setPageList([0]);
    }

    let _currentPage = currentPage;
    if (_currentPage > totalPages) {
      _currentPage = 1;
      setCurrentPage(_currentPage);
    }

    if (totalPages <= 3) {
      setPageList([...Array(totalPages)].map((_, i) => i + 1));
    } else {
      if (_currentPage + 2 <= totalPages) {
        setPageList((opl) => {
          if (!opl.includes(_currentPage)) {
            let list = [_currentPage, _currentPage + 1, _currentPage + 2];
            return list;
          }
          return opl;
        });
      } else {
        setPageList((opl) => {
          if (!opl.includes(_currentPage)) {
            const list = [...Array(totalPages - _currentPage + 1)].map(
              (_, i) => i + _currentPage
            );
            if (list.length < 3)
              return [_currentPage - 2, _currentPage - 1, _currentPage];
            return list;
          }
          return opl;
        });
      }
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    _setPageList();
  }, [currentPage, touchpoints, itemsPerPage, _setPageList]);

  let PageList = (
    <div className="pagination font-leagueSpartan-400 text-[16px] text-[#485056]">
      {pageList.length ? (
        pageList.map((val) => (
          <button
            className={`hover:text-orange hover:rounded-lg hover:border hover:border-orange mx-1 w-8 h-8 hover:bg-lightOrange ${
              currentPage === val
                ? "bg-lightOrange text-orange rounded-lg border border-orange"
                : "bg-transparent"
            }`}
            key={val}
            onClick={() => handlePageChange(val)}
          >
            {val}
          </button>
        ))
      ) : (
        <button
          className={
            "mx-1 w-8 h-8 bg-lightOrange text-orange rounded-lg border border-orange"
          }
        >
          {1}
        </button>
      )}
    </div>
  );
  return (
    <div className="p-6 grid grid-flow-row gap-6 w-full font-leagueSpartan">
      <div className="">
        <label className="mb-2 flex items-center justify-end font-leagueSpartan-400 text-[16px]">
          <button
            className="bg-orange text-white rounded-lg py-2 px-5 absolute top-24"
            onClick={() => navigate.to("/dashboard/touchpoints/create")}
          >
            Create
          </button>
        </label>
        <table className="w-full">
          <thead className="bg-neutrals-300 border-b-gray-100 border-b-[1.5px]">
            <tr>
              {HEADERS.map((header, index) => {
                return (
                  <th
                    key={header}
                    className={`uppercase font-leagueSpartan-600 text-[14px] text-neutrals-700 cursor-default  p-4 ${
                      !index ? "text-left" : "text-center"
                    }`}
                  >
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="bg-white text-center">
            {currentTouchpoints?.length !== 0 ? (
              currentTouchpoints.map(
                (touchpoint: TFetchTouchpoint, index: number) => {
                  const startDate = moment(touchpoint.startDate);
                  const endDate = moment(touchpoint.endDate);
                  const now = moment();
                  let status: string = "-";
                  if (endDate.isBefore(now)) {
                    status = "Ended";
                  } else if (startDate.isBefore(now) && endDate.isAfter(now)) {
                    status = "Active";
                  } else if (startDate.isAfter(now)) {
                    status = "Scheduled";
                  }
                  return (
                    <tr
                      key={index}
                      className="border-b-[#f4f4f4] border-b-[1.5px] cursor-pointer"
                      onClick={() =>
                        navigate.to("/dashboard/touchpoints/:id", {
                          key: ":id",
                          value: touchpoint.id,
                        })
                      }
                    >
                      <td className="w-48 text-neutrals-900 text-left p-4">
                        <div className="flex flex-col">
                          <span
                            title={touchpoint.title}
                            className="w-48 font-leagueSpartan-500 text-[16px] overflow-hidden text-ellipsis whitespace-nowrap"
                          >
                            {touchpoint.title}
                          </span>
                          <span
                            title={touchpoint.description}
                            className="w-48 font-leagueSpartan-400 text-[14px] overflow-hidden text-ellipsis whitespace-nowrap text-neutrals-700"
                          >
                            {touchpoint.description}
                          </span>
                        </div>
                      </td>
                      <td className="text-neutrals-900 text-sm p-4">
                        <span
                          className={`px-2 py-1 mt-1 pt-1.5 rounded font-leagueSpartan-500 text-[14px] whitespace-nowrap text-center ${
                            status === "Active" && "bg-green-100 text-green-800"
                          }
                      ${
                        status === "Scheduled" &&
                        "bg-purple-100 text-purple-800"
                      }
                      ${status === "Ended" && "bg-red-100 text-red-800"}
                      `}
                        >
                          {status}
                        </span>
                      </td>
                      <td className="font-leagueSpartan-400 text-[16px] text-neutrals-900 p-4">
                        {touchpoint.startDate
                          ? moment(touchpoint.startDate).format(
                              "DD MMM YYYY, hh:mm"
                            )
                          : "-"}
                      </td>
                      <td className="font-leagueSpartan-400 text-[16px] text-neutrals-900 p-4">
                        {touchpoint.endDate
                          ? moment(touchpoint.endDate).format(
                              "DD MMM YYYY, hh:mm"
                            )
                          : "-"}
                      </td>
                      <td className="text-neutrals-900 font-leagueSpartan-400 text-[16px] p-4">
                        {touchpoint.interactionsCount}
                      </td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td colSpan={5} className="bg-white text-center py-8 p-4">
                  <div className="flex flex-col">
                    <span className="py-2">No Touchpoints created yet!</span>
                    <label className="mb-2 items-center justify-end font-leagueSpartan-400 text-[16px]">
                      <button
                        className="bg-orange text-white rounded-lg py-2 px-5"
                        onClick={() =>
                          navigate.to("/dashboard/touchpoints/create")
                        }
                      >
                        Create One Now
                      </button>
                    </label>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="flex justify-between">
          <div className="flex justify-start mt-2">
            <label className="text-neutral-600 mt-2 text-base">
              Rows per Page:
            </label>
            <select
              className="border-gray-400 rounded-xl mx-1"
              onChange={(e) => {
                setItemsPerPage(+e.target.value);
                setCurrentPage(1);
              }}
            >
              {perPageItems.map((val) => (
                <option key={val}>{val}</option>
              ))}
            </select>
          </div>
          <div className="flex justify-end items-center mt-4">
            <div
              className="text-sm cursor-pointer"
              onClick={() => handlePageChange(1)}
            >
              <img
                src={images.doubleChevronLeft}
                alt=""
                className="w-3 h-3 mr-3"
              />
            </div>
            <img
              src={images.leftChevron}
              alt=""
              className="w-5 h-5 mr-3 cursor-pointer"
              onClick={() => {
                if (currentPage > 1) handlePageChange(currentPage - 1);
              }}
            />
            {PageList}
            <img
              src={images.rightChevron}
              alt=""
              className="w-5 h-5 ml-3 cursor-pointer mt-1"
              onClick={() => {
                if (currentPage < totalPages) handlePageChange(currentPage + 1);
              }}
            />
            <div
              className="text-sm cursor-pointer"
              onClick={() => handlePageChange(totalPages)}
            >
              <img
                src={images.doubleChevronRight}
                alt=""
                className=" w-3 h-3 ml-3"
              />
            </div>
          </div>
        </div>
        <ToastCustomized />
      </div>
    </div>
  );
};
