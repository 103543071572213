import React, { RefObject } from "react";

type propTypes = {
  progress: number;
  nextButtonDisable: boolean;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  setCancelViewModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSaveDraftModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  topDivRef: RefObject<HTMLDivElement>;
  onClickNext: () => void;
  collection?: GetAllOccasionWithCollectionTCollection;
};
const FooterCampaign = (props: propTypes) => {
  const {
    progress,
    nextButtonDisable,
    setCancelViewModalVisible,
    setSaveDraftModalVisible,
    onClickNext,
    collection,
  } = props;

  return (
    <div className="fixed bottom-0 w-screen flex justify-between px-4 py-4  bg-white  border-2 border-t-orange">
      <div className="flex">
        <button
          className="  text-orange font-leagueSpartan-500 text-[16px] rounded-lg p-2 px-2 mx-2"
          onClick={() => setCancelViewModalVisible(true)}
        >
          Cancel
        </button>
        {collection && (
          <div className="h-[46px] flex ml-4">
            {" "}
            <img
              src={collection.picture}
              className="h-full rounded-lg"
              alt=""
            />{" "}
            <div className="self-center ml-4 font-leagueSpartan-400 text-sm ">
              {collection.name}
            </div>
          </div>
        )}
      </div>
      <div>
        {/* <button
          className="border border-orange text-orange font-leagueSpartan-500 text-[16px] rounded-lg p-2.5 px-5 mx-2"
          onClick={() => setSaveDraftModalVisible(true)}
        >
          Save Draft
        </button> */}
        <button
          disabled={nextButtonDisable}
          className={` text-white font-leagueSpartan-500 text-[16px] rounded-lg p-2.5 px-5 mx-2 ${
            nextButtonDisable ? "bg-neutral-300" : "bg-orange"
          }`}
          onClick={() => onClickNext()}
        >
          {progress < 5 ? "Next" : "Confirm & Pay"}
        </button>
      </div>
    </div>
  );
};

export default FooterCampaign;
