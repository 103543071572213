import React, { Dispatch, SetStateAction } from "react";

import images from "../../constants/images";
import { TCampaignValue } from "../../pages/dashboard/CampaignCreate";

type propType = {
  selectedQuickViewProduct: number;
  setModalVisible: Dispatch<React.SetStateAction<boolean>>;
  setCampaignValue: Dispatch<SetStateAction<TCampaignValue>>;
  setQuickViewModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  campaignValue: TCampaignValue
};

const RemoveCollectionModalCampaign = (props: propType) => {
  const {
    setModalVisible,
    setQuickViewModalVisible,
    selectedQuickViewProduct,
    setCampaignValue,
    campaignValue
  } = props;

  const handleRemove = () => {
    const data = { ...campaignValue }
    data?.collectionList.splice(selectedQuickViewProduct, 1);
    setCampaignValue(data);
    setModalVisible(false);
    setQuickViewModalVisible(false);
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-auto rounded-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-[416px]">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-6">
              {/* Body */}
              <div className="flex justify-end">
                <button
                  onClick={handleCancel}
                  className="self-start h-7"
                >
                  <img src={images.cross} className="w-5" alt="" />
                </button>
              </div>
              <div className="flex flex-col justify-center items-center">
                <img src={images.warningGrey} className="w-[48px]" alt="" />
                <div className="font-leagueSpartan-500 text-[20px] text-neutrals-800 text-center">
                  {" "}
                  Are you sure you want to remove this collection?
                </div>
                <div className="flex gap-4 mt-4">
                  <button
                    className="p-2 px-4 font-leagueSpartan-500 text-[16px] rounded-lg text-white bg-red-500"
                    onClick={handleRemove}
                  >
                    Yes, remove
                  </button>
                  <button
                    className="p-2 px-4 font-leagueSpartan-500 text-[16px] rounded-lg text-orange border border-orange"
                    onClick={handleCancel}
                  >
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveCollectionModalCampaign;
