import { Dispatch, SetStateAction } from "react";
import ToastCustomized from "../common/ToastCustomized";
import SearchInput from "./SearchInput";
import images from "../../constants/images";

export const DEFAULT_PAGE_LIMIT = 10;
const PER_PAGE_ITEM_COUNT = [DEFAULT_PAGE_LIMIT, 20, 30, 40, 50];

const Table = ({
  rows,
  header,
  sortOrder,
  setSortOrder,
  sortColumn,
  setSortColumn,
  setSearch,
  setPageNumber,
  pageNumber,
  setLimit,
  limit,
  totalItemCount,
  isSearchable = true,
}: {
  header: {
    key: string;
    heading: string;
    isSortable?: boolean;
  }[];
  rows: string[][];
  sortOrder: "ASC" | "DESC";
  setSortOrder: Dispatch<SetStateAction<"ASC" | "DESC">>;
  sortColumn: string;
  setSortColumn: Dispatch<SetStateAction<string>>;
  setSearch?: Dispatch<SetStateAction<string>>;
  setPageNumber: Dispatch<SetStateAction<number>>;
  pageNumber: number;
  setLimit: Dispatch<SetStateAction<number>>;
  limit: number;
  totalItemCount: number;
  isSearchable?: boolean;
}) => {
  const handlePageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const PaginationList = ({
    currentPageNumber,
  }: {
    currentPageNumber: number;
  }) => {
    const numberOfPages = Math.ceil(totalItemCount / limit);
    return (
      <div className="flex justify-end items-center mt-4">
        <div
          className="text-sm cursor-pointer"
          onClick={() => handlePageChange(1)}
        >
          <img src={images.doubleChevronLeft} alt="" className="w-3 h-3 mr-3" />
        </div>
        <img
          src={images.leftChevron}
          alt=""
          className="w-5 h-5 mr-3 cursor-pointer"
          onClick={() => {
            if (pageNumber > 1) handlePageChange(pageNumber - 1);
          }}
        />
        <div className="pagination font-leagueSpartan-400 text-[16px] text-[#485056]">
          {[currentPageNumber - 1, currentPageNumber, currentPageNumber + 1]
            .filter((element) => element > 0 && element < numberOfPages + 1)
            .map((iteratingNumber) => (
              <button
                className={`hover:text-orange hover:rounded-lg hover:border hover:border-orange mx-1 w-8 h-8 hover:bg-lightOrange ${
                  pageNumber === iteratingNumber
                    ? "bg-lightOrange text-orange rounded-lg border border-orange"
                    : "bg-transparent"
                }`}
                key={iteratingNumber}
                onClick={() => handlePageChange(iteratingNumber)}
              >
                {iteratingNumber}
              </button>
            ))}
        </div>
        <img
          src={images.rightChevron}
          alt=""
          className="w-5 h-5 ml-3 cursor-pointer mt-1"
          onClick={() => {
            if (pageNumber < numberOfPages) handlePageChange(pageNumber + 1);
          }}
        />
        <div
          className="text-sm cursor-pointer"
          onClick={() => handlePageChange(numberOfPages)}
        >
          <img
            src={images.doubleChevronRight}
            alt=""
            className=" w-3 h-3 ml-3"
          />
        </div>
      </div>
    );
  };

  const onSort = (column: string) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortColumn(column);
      setSortOrder("DESC");
    }
  };

  return (
    <div className="p-6 grid grid-flow-row gap-6 w-full">
      {isSearchable && setSearch && (
        <div className="flex justify-between w-full">
          <SearchInput setSearch={setSearch} />
        </div>
      )}

      <div className="font-leagueSpartan">
        <table className="w-full">
          <thead className="bg-neutrals-300">
            <tr>
              {header.map((header, index) => {
                return (
                  <th
                    key={header.key}
                    className="font-leagueSpartan-600 text-[14px] text-neutrals-700 cursor-pointer p-4"
                    onClick={() => {
                      if (header.isSortable !== false) onSort(header.key);
                    }}
                  >
                    <span className="flex items-center w-max">
                      {header.heading}
                      <span
                        className={`arrowsSorts ${
                          sortColumn === header.key
                            ? "opacity-100"
                            : "opacity-0"
                        }`}
                      >
                        <img
                          src={
                            sortOrder === "ASC"
                              ? images.upArrowIcon
                              : images.downArrowIcon
                          }
                          className="w-8 min-w-8 max-w-8 h-4 mr-5"
                          alt=""
                        />
                      </span>
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="bg-white">
            {rows.length !== 0 ? (
              rows.map((row, index) => (
                <tr key={index}>
                  {row.map((entry, entryIndex) => (
                    <td
                      key={entryIndex}
                      className="text-neutrals-900 font-leagueSpartan text-[16px] p-4"
                    >
                      {entry}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={header.length} className="text-center py-8">
                  No Rows
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="flex justify-between">
          <div className="flex justify-start mt-2">
            <label className="text-neutral-600 mt-2 text-base">
              Rows per Page:
            </label>
            <select
              className="border-gray-400 rounded-xl mx-1"
              onChange={(e) => {
                setLimit(+e.target.value);
                setPageNumber(1);
              }}
            >
              {PER_PAGE_ITEM_COUNT.map((val) => (
                <option key={val}>{val}</option>
              ))}
            </select>
          </div>
          <PaginationList currentPageNumber={pageNumber} />
        </div>
        <ToastCustomized />
      </div>
    </div>
  );
};

export default Table;
