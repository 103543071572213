import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Api from "../../constants/api";
import { useAppSelector } from "../../redux/hooks";
import images from "../../constants/images";
import { useDispatch } from "react-redux";
import { updateBalance } from "../../redux/corpSlice";
import { toast } from "react-toastify";

export const TopUpModal = ({
  setShowTopUpModal,
}: {
  setShowTopUpModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const copyToClipBoard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const [paynowUrl, setPaynowUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const corp = useAppSelector((state) => state.corpSliceState);
  const corpId = corp.data?.id;
  const dispatch = useDispatch();

  /**
   * Executes the effect when the `corpId` dependency changes.
   * Fetches the Paynow image source for the given `corpId` and updates the `paynowUrl` state.
   * If `corpId` is null or undefined, logs a message to the console.
   */
  useEffect(() => {
    if (corpId) {
      Api.getCorpPaynowImgSource(corpId)
        .then((res) => {
          setPaynowUrl(res.data);
        })
        .catch((error) => {
          console.error(error);
          setPaynowUrl("");
        });
    } else {
      console.log("corpId is null/undefined");
    }
  }, [corpId]);

  /**
   * Handles the click event when the "Done" button is clicked in the top-up modal.
   * If `corpId` is provided, it makes an API call to retrieve the balance of the corporation,
   * updates the balance using the `updateBalance` dispatch function, and sets the loading state.
   * If `corpId` is null or undefined, it logs a message to the console.
   * Finally, it hides the top-up modal.
   */
  const handleDoneClick = () => {
    if (corpId) {
      setIsLoading(true);
      Api.getOneCorp(corpId)
        .then((res) => {
          if (res.data.balance !== corp.data?.balance && corp.data?.balance !== 0) {
            dispatch(updateBalance(res.data.balance));
            toast.success("Top up successful");
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      console.log("corpId is null/undefined");
    }
    setShowTopUpModal(false);
  };

  return (
    <div className="font-leagueSpartan-400 fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-md w-96">
        <h2 className="text-xl font-semibold mb-4">Top up instructions</h2>
        {paynowUrl !== "" ? (
          // Fetch PayNow QR Code success
          <>
            <div className="text-center">
              <span>PayNow to the QR code below</span>
            </div>
            <div className="flex justify-around">
              <img src={paynowUrl} alt="paynow-qr-code" />
            </div>
            <div className="text-center">
              Click on <b>Done</b>, once you've made the transfer
            </div>
            <div className="flex justify-around pt-5">
              {isLoading ? (
                <button
                  type="button"
                  className="bg-orange flex items-center justify-center rounded-lg p-2 px-5"
                  disabled
                >
                  <svg
                    className="animate-spin -ml-1 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </button>
              ) : (
                <button
                  className="bg-orange text-white text-sm font-leagueSpartan-400 rounded-lg p-2 px-5"
                  onClick={handleDoneClick}
                >
                  Done
                </button>
              )}
            </div>
          </>
        ) : (
          // Fetch PayNow QR Code failure
          <>
            <div className="text-center">
              <span>We're unable to load the PayNow QR code</span>
            </div>
            <div className="flex justify-around">
              <img src={images.brokenImage} alt="broken-qr-code" />
            </div>
            <div className="text-center">
              Please try again after some time or contact support.
            </div>
            <div className="flex justify-around pt-5">
              <button
                className="bg-orange text-white text-sm font-leagueSpartan-400 rounded-lg p-2 px-5"
                onClick={() => setShowTopUpModal(false)}
              >
                Okay
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
