import moment from "moment";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { utils, writeFile } from "xlsx";
import Api from "../../../constants/api";
import JwtService from "../../../service/jwtService";

export const CompletedJournalEntries = (props: any) => {
  const token = useMemo(() => JwtService.getAccessToken(), []);
  const [filterInput, setFilterInput] = useState<JournalEntryFilterInput>({
    status: ["completed"],
    startDate: moment().subtract(1, "month").toDate(),
    endDate: new Date(),
    recentFirst: true,
  });
  const [entries, setEntries] = useState<JournalEntry[]>([]);

  const onStartChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilterInput((prev) => ({
        ...prev,
        startDate: new Date(event.target.value),
      }));
    },
    [setFilterInput]
  );

  const onEndChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilterInput((prev) => ({
        ...prev,
        endDate: new Date(event.target.value),
      }));
    },
    [setFilterInput]
  );

  /**
   * Handles the click event for exporting all orders to a CSV file.
   */
  const handleExport = async () => {
    const headers = [
      "Entry ID",
      "Description",
      "Status",
      "Budget Amount",
      "Actual Cost",
      "Refund Amount",
      "Delivery Fee",
      "Add Ons Amount",
      "Order ID",
      "Created At",
    ];

    const rows = entries.map((entry) => [
      entry.id,
      entry.description || "",
      entry.status,
      entry.budgetAmount,
      entry.actualCost,
      entry.refundAmount,
      entry.deliveryFee,
      entry.addOnsAmount,
      entry.consolidatedOrder?.id || "",
      moment(entry.createdAt).format("LLL"),
    ]);

    const worksheet = utils.aoa_to_sheet([headers, ...rows]);

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Journal Entries");

    writeFile(workbook, "journal_entries.csv");
  };

  useEffect(() => {
    if (token) Api.filterJournalEntries(token, filterInput).then(setEntries);
  }, [token, Api, filterInput]);

  return (
    <div className="p-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Start Date:
          </label>
          <input
            type="date"
            value={moment(filterInput.startDate).format("YYYY-MM-DD")}
            onChange={onStartChange}
            className="mt-1 p-2 border rounded-md w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            End Date:
          </label>
          <input
            type="date"
            value={moment(filterInput.endDate).format("YYYY-MM-DD")}
            onChange={onEndChange}
            className="mt-1 p-2 border rounded-md w-full"
          />
        </div>
      </div>
      <button
        onClick={handleExport}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mb-4"
      >
        Export
      </button>
      <table className="w-full border-collapse border border-gray-500">
        <thead className="bg-gray-200">
          <tr>
            <th>ID</th>
            <th>Description</th>
            <th>Status</th>
            <th>Budget</th>
            <th>Actual Cost</th>
            <th>Refund</th>
            <th>Delivery</th>
            <th>Add Ons</th>
            <th>Order ID</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {entries &&
            entries.map((entry) => (
              <tr key={entry.id} className="border border-gray-500">
                <td className="p-2">{entry.id}</td>
                <td className="p-2">{entry.description}</td>
                <td className="p-2">{entry.status}</td>
                <td className="p-2">{entry.budgetAmount}</td>
                <td className="p-2">{entry.actualCost}</td>
                <td className="p-2">{entry.refundAmount}</td>
                <td className="p-2">{entry.deliveryFee}</td>
                <td className="p-2">{entry.addOnsAmount}</td>
                <td>{entry.consolidatedOrder?.id || "-"}</td>
                <td className="p-2 text-xs">
                  {moment(entry.createdAt).format("LLL")}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
