import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

// Define the initial state using that type
const initialState: {
  data?: CreateOrderResponseData;
  multipleData?: CreateMultipleOrderResData[];
} = { multipleData: [] };

export const orderSlice = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    setOrderData: (state, action: PayloadAction<CreateOrderResponseData>) => {
      state.data = action.payload;
    },
    setMultipleOrderData: (
      state,
      action: PayloadAction<CreateMultipleOrderResData[]>
    ) => {
      state.multipleData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOrderData, setMultipleOrderData } = orderSlice.actions;

export const selectCount = (state: RootState) => state.cartState;

export default orderSlice.reducer;
