import React, { useState, useRef, useEffect } from "react";

export const Popup = ({
  isOpen,
  onClose,
  onEdit,
  onDelete,
}: {
  isOpen: any;
  onClose: any;
  onEdit: any;
  onDelete: any;
}) => {
  const popupRef: any = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      ref={popupRef}
      className="absolute right-0 mt-2 bg-white border border-gray-300 shadow-md rounded-md"
    >
      <button
        className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
        onClick={onEdit}
      >
        Edit
      </button>
      <button
        className="block w-full px-4 py-2 text-left text-red-700 hover:bg-gray-100"
        onClick={onDelete}
      >
        Delete
      </button>
    </div>
  );
};
