import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import Api from "../../../constants/api";
import { ImageUploader } from "../../../components/ImageUploader/ImageUploader";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/common/Loading";

export const CreateProductCat = (props: any) => {
  const navigate = useNavigate();
  const successMessage = () => toast("Success!");
  const errorMessage = (error?: string) => toast.error(error || "error!");
  const [productCatName, setProductCatName] = useState("");
  const [loading, setLoading] = useState(false);
  const [productSubCatNames, setProductSubCatNames] = useState<string[]>([]);
  const [azureImageUrl, setAzureImageUrl] = useState<string>("");
  const [productsOfCollection, setProductsOfCollection] = useState<
    GetAllProductData[]
  >([]);
  const [subCategoryUpdateIndex, setSubCatUpdateIndex] = useState(0);
  const [isAddSubcatOption, setIsAddSubcatOption] = useState(false);
  const getAzureImageUrl = (url: string) => {
    setAzureImageUrl(url);
  };
  /**
   * Update Product Category->
   */
  const updateProductCategory = () => {
    const categoryData = {
      name: productCatName,
      image: azureImageUrl,
    };
    Api.updateProductCategory(props?.updateData?.id, categoryData)
      .then((res) => {
        if (isAxiosError(res))
          errorMessage(res.response?.data.message || "Invalid data!");
        else {
          successMessage();
          navigate("/admin", { state: { tab: props?.tabNumber, subtab: 1 } });
        }
      })
      .catch((err) => {
        console.log(err);
        errorMessage();
      });
  };

  const updateProductSubCategory = () => {
    let subCategoryData = {};
    if (isAddSubcatOption) {
      subCategoryData = {
        name: productSubCatNames[productSubCatNames.length - 1],
        productCategoryId: props?.updateData?.id,
      };
    } else {
      subCategoryData = {
        name: productSubCatNames[subCategoryUpdateIndex],
        productCategoryId: props?.updateData?.id,
      };
    }

    Api.updateSubCategory(
      subCategoryData,
      props?.updateData?.productCategories?.[subCategoryUpdateIndex]?.id || null,
      isAddSubcatOption
    ).then((res) => {
      if (isAxiosError(res))
        errorMessage(res.response?.data.message || "Invalid data!");
      else {
        successMessage();
        navigate("/admin", { state: { tab: props?.tabNumber, subtab: 1 } });
      }
    });
  };

  const getProductsOfCollection = useCallback(() => {
    setLoading(true);
    Api.getProductsByCategoryId(props.updateData?.id).then((res) => {
      if (isAxiosError(res)) {
        setLoading(false);
        errorMessage(res.response?.data?.message || "Invalid data!");
      } else {
        setProductsOfCollection(res);
        setLoading(false);
      }
    });
  }, [props.updateData?.id]);

  /**
   * Cancel Update
   */
  const handleCancelUpdate = () => {
    setProductCatName("");
    setProductSubCatNames([]);
    navigate("/admin", { state: { tab: props?.tabNumber, subtab: 1 } });
  };

  useEffect(() => {
    if (props?.updateData?.id) {
      getProductsOfCollection();
    }
  }, [props?.updateData?.id, getProductsOfCollection]);

  useEffect(() => {
    if (props.updateData && props.tabNumber === 2) {
      const data = props.updateData;
      setProductCatName(data.name);
      setAzureImageUrl(data.image);
      setProductSubCatNames(
        data?.productCategories?.map((val: any) => {
          return val?.name;
        })
      );
    }
  }, [props]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex justify-around md:flex-row flex-col">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="m-4 grid grid-flow-row max-w-md grow"
        >
          <div className="flex justify-between">
            <div className="text-lg text-orange">Product Category</div>
            <div>
              <button
                onClick={handleCancelUpdate}
                type="button"
                className="border rounded-lg p-2 bg-red-400 relative right-50"
              >
                Cancel
              </button>
            </div>
          </div>
          <input
            type="text"
            name="name"
            placeholder="name"
            className="border rounded-lg my-4"
            value={productCatName}
            onChange={(e) => setProductCatName(e.target.value)}
          />
          <ImageUploader
            getAzureImageUrl={getAzureImageUrl}
            updateImgUrl={props?.updateData?.image}
            tabNumber={props?.tabNumber}
            currentTab={props?.currentTab}
          />
          <button
            onSubmit={(e) => e.preventDefault()}
            className="bg-lightOrange w-[50%] rounded-lg p-2"
            onClick={() => {
              setIsAddSubcatOption(true);
              setProductSubCatNames((i) => {
                return [...i, ""];
              });
            }}
          >
            Add sub category +
          </button>
          <div className="ml-0 mt-5 grid grid-flow-row ">
            {productSubCatNames?.map((val, i) => (
              <>
                <input
                  type="text"
                  name="name"
                  placeholder="name"
                  className="border rounded-lg my-1"
                  value={val}
                  onChange={(e) => {
                    setSubCatUpdateIndex(i);
                    setProductSubCatNames((prev) => {
                      prev[i] = e.target.value;
                      return [...prev];
                    });
                  }}
                />
                {props.updateData ? (
                  <button
                    type="button"
                    className="text-left mt-4 mb-2"
                    onClick={updateProductSubCategory}
                  >
                    Update SubCategory
                  </button>
                ) : (
                  ""
                )}
              </>
            ))}
          </div>

          {props.updateData && props?.tabNumber === props?.currentTab ? (
            <>
              <button
                onClick={updateProductCategory}
                type="button"
                className="border rounded-lg p-2 bg-lightOrange "
              >
                Update Category
              </button>
            </>
          ) : (
            <button
              className="border rounded-lg p-2 bg-lightOrange mt-2"
              onClick={(e) => {
                e.preventDefault();
                Api.createProductCategory({
                  name: productCatName,
                  subCats: productSubCatNames,
                  image: azureImageUrl,
                })
                  .then((res) => {
                    if (isAxiosError(res))
                      errorMessage(
                        res.response?.data.message || "Invalid data!"
                      );
                    else successMessage();
                  })
                  .catch((err) => {
                    console.log(err);
                    errorMessage();
                  });
              }}
            >
              Submit
            </button>
          )}
        </form>

        {props?.updateData?.id && (
          <div className="h-screen overflow-y-scroll p-2 border">
            <div className="text-lg text-orange">Products in this collection</div>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Product id
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {productsOfCollection.length === 0 ? (
                  <tr className="p-10">
                    <td>OOPS ! No products found in this collection !</td>
                  </tr>
                ) : (
                  productsOfCollection?.map((product, index) => {
                    return (
                      <tr key={index}>
                        <td className="px-6 py-4">{product?.id}</td>
                        <td className="px-6 py-4">{product?.name}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>)}
      </div>
    </>
  );
};
