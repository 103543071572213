import { useEffect, useState } from "react";

type TOption = {
  id: number;
  field: string;
};
type TSearchableDropdown = {
  options: TOption[];
  dropdownHeight?: number;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  editOccasionName: (newValue: string) => void;
};

export const SearchableDropdown = ({
  options,
  placeholder,
  dropdownHeight,
  className,
  editOccasionName,
  defaultValue,
}: TSearchableDropdown) => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState(defaultValue ?? "");
  const [dropdownOptions, setDropdownOptions] = useState(options);

  const matchPhrase = (input: string, phrase: string) => {
    return phrase.includes(input);
  };

  const handleSelect = (option: TOption) => {
    setText(option.field);
    editOccasionName(option.field);
    setIsOpen(false);
  };

  useEffect(() => {
    setDropdownOptions(
      options.filter((option) => matchPhrase(text, option.field))
    );
  }, [options, text]);

  return (
    <div
      className="relative"
      onMouseOver={() => setIsOpen(true)}
      onMouseOut={() => setIsOpen(false)}
    >
      <input
        type="text"
        className={`border-[1px] border-gray-200 mt-2 ${className}`}
        placeholder={placeholder || "Search"}
        onChange={(e) => setText(e.target.value)}
        onBlur={(e) => {
          editOccasionName(e.target.value);
        }}
        value={text}
      />
      {isOpen && (
        <ul
          className={`max-h-${
            dropdownHeight || "5"
          } overflow-y-scroll absolute z-40 bg-white border-[1px] border-t-0 border-gray-400`}
        >
          {dropdownOptions.map((option, idx) => (
            <li
              key={idx}
              onClick={() => handleSelect(option)}
              className="ps-2 hover:bg-gray-200 cursor-pointer"
            >
              {option.field}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
