import { useCallback, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../constants/api";
import images from "../../constants/images";

export default function SetPhoneNumberModel() {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState<{
    value: string;
    format: string;
  }>();

  const updatePhoneNumber = useCallback(() => {
    if (!phoneNumber || !phoneNumber?.value) {
      toast.error("Enter valid phone number");
      return;
    }
    if (
      phoneNumber.value.length !==
      String(phoneNumber.format).replace(/[^.]/g, "").length
    ) {
      toast.error("Phone number is not valid");
      return;
    }
    Api.updatePhoneNumber({ phoneNumber: phoneNumber?.value })
      .then(() => {
        toast.success("Phone number updated successfully.");
        navigate(0);
      })
      .catch(() => {
        toast.success("Error occurred.");
      });
  }, [navigate, phoneNumber]);

  return (
    <div className="inset-0 fixed bg-[#381B1AE5] flex justify-center items-center">
      <div>
        <div className="w-[596px] min-h-[380px] shadow flex-col rounded-xl bg-white">
          <div className="flex flex-col gap-2 p-7">
            <div className="flex justify-center">
              <img src={images.logo} className="w-15 h-15 mt-5" alt="" />
            </div>
            <div className="text-center text-zinc-800 text-lg mt-2 font-leagueSpartan-500">
              Enter your phone number
            </div>
            <div className="flex items-center border rounded-md mx-auto mt-5">
              <PhoneInput
                inputClass={`rounded-lg outline-none border-none border-0 focus:ring-0 text-gray-700 font-leagueSpartan-300`}
                country={"sg"}
                preferredCountries={["sg"]}
                enableSearch={true}
                placeholder=""
                value={phoneNumber?.value}
                inputProps={{
                  name: "phoneNumber",
                  required: true,
                }}
                onChange={(value, data) => {
                  const { format } = { format: "", ...data };
                  setPhoneNumber({ value, format });
                }}
              />
            </div>
            <div className="flex justify-center">
              <button
                className="p-1.5 mt-5 bg-orange rounded-lg text-white font-leagueSpartan-300 w-6/12"
                onClick={() => updatePhoneNumber()}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
