import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../../constants/api";
import { ToastContainer, toast } from "react-toastify";

export const SingleCorp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);
  const [singleCorp, setSingleCorp] = useState<any>({});

  const [showConfirmAddBalance, setShowConfirmAddBalance] = useState(false);
  const [addBalanceValue, setAddBalanceValue] = useState<number>(0);
  const addBalanceRef = useRef<HTMLInputElement>(null);
  const [isAddingBalance, setIsAddingBalance] = useState(false);

  const getCorp = useCallback(async (corpId: number) => {
    await Api.getOneCorp(corpId)
      .then((res: any) => {
        console.log(res, "datasinglecorp");
        setSingleCorp(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getCorp(location.state.id);
  }, [getCorp, location.state.id]);

  const handleAddBalance = (value: number) => {
    if (isNaN(value)) {
      return;
    }
    setIsAddingBalance(true);
    Api.addBalance(singleCorp.id, value)
      .then(() => getCorp(location.state.id))
      .then(() => {
        setShowConfirmAddBalance(false);
        setAddBalanceValue(0);
        if (addBalanceRef.current !== null) {
          addBalanceRef.current.value = "";
        } 
        toast.success("Successfully added balance"); 
      })
      .catch((error) => toast.error(error))
      .finally(() => setIsAddingBalance(false));
  };

  const renderData = (obj: any) => {
    return Object.keys(obj).map((key) => {
      if (typeof obj[key] === "object") {
        return null; // Skip rendering nested objects and arrays
      } else {
        // Render simple key-value pairs or an image for "logo" key

        return (
          <div key={key}>
            {key === "logo" ? (
              <img style={{ width: "200px" }} src={obj[key]} alt="Logo" />
            ) : (
              <>
                <strong>{key}: </strong>
                {obj[key]}
              </>
            )}
          </div>
        );
      }
    });
  };

  /**
   * Handle edit corp
   */

  const handleEditCorp = () => {
    navigate("/admin", { state: { item: singleCorp, tab: 6, subTab: 0 } });
  };

  return (
    <>
      {/* <h1>Full Name:{singleCorp?.firstName} {singleCorp?.middleName} {singleCorp?.lastName}</h1>
        <h1>logo: <img style={{width: "200px"}} src={singleCorp?.logo}/></h1>
        <h1>Organisation Name: {singleCorp?.organisationName}</h1>
        <h1>EmployeeRange: {singleCorp?.}</h1> */}
      <div style={{ border: "2px solid black" }}>
        <button
          onClick={handleEditCorp}
          style={{
            padding: "0.5rem 2rem",
            backgroundColor: "red",
            color: "white",
          }}
        >
          Edit
        </button>
      </div>
      <div>{renderData(singleCorp)}</div>
      <div className=" py-3">
        <div className="font-bold">Add balance</div>
        <input
          ref={addBalanceRef}
          type="number"
          placeholder="Enter amount..."
          className="border rounded-lg mr-[1px] max-w-[170px] placeholder:font-leagueSpartan-400 placeholder:text-[15px] placeholder:text-neutral-400"
        />
        <button
          className="self-start mx-2 px-5 p-2 bg-green-400 text-sm border border-orange text-white rounded-lg"
          onClick={(e) => {
            const addBalanceValue = Number(addBalanceRef.current?.value);
            if (addBalanceValue) {
              setAddBalanceValue(addBalanceValue);
              setShowConfirmAddBalance(true);
            } else {
              toast.error(
                "Invalid add balance value: " + addBalanceRef.current?.value
              );
            }
          }}
        >
          Add
        </button>
        {showConfirmAddBalance && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-6 rounded-md shadow-md w-96">
              <h2 className="text-xl font-semibold mb-4">
                Confirm Transaction
              </h2>
              <div>Current balance: {singleCorp.balance}</div>
              <div>
                After transaction balance:
                {addBalanceValue + singleCorp.balance}
              </div>
              <div className="flex justify-end">
                <button
                  className="px-4 py-2 mr-2 text-white bg-gray-500 rounded-md hover:bg-gray-600 disabled:bg-slate-500"
                  onClick={() => {
                    setShowConfirmAddBalance(false);
                  }}
                  disabled={isAddingBalance}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600 disabled:bg-slate-500"
                  onClick={() => {
                    handleAddBalance(addBalanceValue);
                  }}
                  disabled={isAddingBalance}
                >
                  {isAddingBalance ? "Adding balance..." : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};
