import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { getUsedCredits } from "../utils/cartUtils";
import moment from "moment";

type dashboardSliceDataType = {
  fromDate: string;
  toDate: string;
};
type dashboardSliceType = {
  campaignList: CampaignListData[];
  data: dashboardSliceDataType;
  isDataLoaded: boolean;
  isDashboardLoaded: boolean;
};
const initialState: dashboardSliceType = {
  campaignList: [],
  data: {
    fromDate: moment().startOf("day").subtract(1, "month").format("yyyy-MM-DD"),
    toDate: moment().endOf("day").format("yyyy-MM-DD"),
  },
  isDataLoaded: false,
  isDashboardLoaded: false,
};

export const dashboardSlice = createSlice({
  name: "dashboardState",
  initialState,
  reducers: {
    setIsDashboardCampaignList: (
      state,
      action: PayloadAction<CampaignListData[]>
    ) => {
      state.campaignList = action.payload;
    },
    setDashboardData: (
      state,
      action: PayloadAction<dashboardSliceDataType>
    ) => {
      state.data = { ...state, ...action.payload };
    },
    setIsCampaignDashboardDataLoaded: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDataLoaded = action.payload;
    },
    setIsDashboradLoaded: (state, action: PayloadAction<boolean>) => {
      state.isDashboardLoaded = action.payload;
    },
    updateCampaign: (state, action: PayloadAction<{ updatedCampaign: any }>) => {
      const { updatedCampaign } = action.payload;
      const index = state.campaignList.findIndex(campaign => campaign.id === updatedCampaign.id);
      if (index !== -1) {
        state.campaignList[index] = updatedCampaign;
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsDashboardCampaignList,
  setIsCampaignDashboardDataLoaded,
  setDashboardData,
  updateCampaign
} = dashboardSlice.actions;


export const selectCount = (state: RootState) => state.cartState;

export default dashboardSlice.reducer;
