import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import NotFound from "../pages/NotFound";
import Forms from "../pages/TempForms/Forms";
import Dashboard from "../pages/dashboard/Dashboard";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import JwtService from "../service/jwtService";
import routes from "./Routes";

const authRoute = routes.find((r) => r.path === "/auth")!;
const notFound = routes.find((r) => r.path === "/notFound")!;
const unAuthRoutes = routes.filter(
  (r) => r.auth === false || r.auth === undefined
);
const authRoutes = routes.filter(
  (r) => r.auth === true || r.auth === undefined
);
authRoutes.push(authRoute);

const AppRoutes = () => {
  const globalState = useAppSelector((state) => state.globalSliceState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    JwtService.init(dispatch);
  }, [dispatch]);

  if (globalState.isAuthenticated === undefined) {
    return <></>;
  }

  if (!globalState.isAuthenticated) {
    return (
      <BrowserRouter>
        <Routes>
          <Route>
            <Route index element={<Navigate to="/auth" />} />
            {unAuthRoutes.map((r) => {
              return (
                <Route key={r.path} path={r.path} element={<r.component />} />
              );
            })}
            {authRoutes.map((r) => {
              return (
                <Route
                  key={r.path}
                  path={r.path}
                  element={<Navigate to="/auth" />}
                />
              );
            })}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route index element={<Dashboard />} />
          {authRoutes.map((r) => {
            return (
              <Route key={r.path} path={r.path} element={<r.component />} />
            );
          })}
          {/* TODO: temporary fix for admin access restriction */}
          {globalState?.authData?.email.toLowerCase() ===
            process.env.REACT_APP_ADMIN_EMAIL && (
            <Route key={"/admin"} path={"/admin"} element={<Forms />} />
          )}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
