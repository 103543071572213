import { useCallback, useState } from "react";

import images from "../../../constants/images";
import "./accordin.css";

const title = "Description";
const description =
  "These Sea Salt and Black Pepper Toasted Cashews are perfectly balanced with a classic salt and pepper duo, complimenting the natural nuttiness of the cashews. Located in Northern California Black Bow Sweets is handmade and women owned.";
const _data: AccordinType[] = [{ id: 1, title, description, checked: false }];
type propsType = {
  data?: AccordinType[];
};
const AccordinGifting = (props: propsType) => {
  const { data } = props;

  const [list, setList] = useState(data || _data);
  const setItem = useCallback((key: number, checked: boolean) => {
    setList((l) => {
      l[key].checked = checked;
      return [...l];
    });
  }, []);

  console.log(list);

  return (
    <div className="accordion flex flex-col items-center">
      {/* <!--  Panel 1  --> */}
      {list.map((a, index) => {
        return (
          <div className="w-full" key={a.id}>
            <input
              type="checkbox"
              name="panel"
              id={`panel-${a.id}`}
              className="hidden"
              checked={a.checked}
              onChange={(e) => setItem(index, e.target.checked)}
            />
            <label
              htmlFor={`panel-${a.id}`}
              className="relative block bg-white font-leagueSpartan-500 text-[24px] text-orange p-4"
            >
              {a.title}
              <img
                src={images.downAngularArrow}
                className={`float-right ${a.checked ? "rotate-180" : ""}`}
                alt=""
              />
            </label>
            <div className="accordion__content overflow-hidden bg-grey-lighter">
              <p
                className="accordion__body p-4 text-[16px]"
                id="panel1"
                dangerouslySetInnerHTML={{ __html: a.description }}
              >
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AccordinGifting;
