import { useState } from "react";
import images from "../../constants/images";
import { CampaignOccasion } from "../../utils/campaign.utils";
import "../common/dropdown.css";

type DropdownScheduleSendProps = {
  value: string;
  customFieldValueMap: {
    [key: string]: number;
  };
  onOptionSelected?: (option: string) => void;
  setRecipientCustomField: (value: string) => void;
};

const DropdownScheduleSendCustomField = ({
  value,
  customFieldValueMap,
  setRecipientCustomField,
}: DropdownScheduleSendProps) => {
  const [listDisplay, setlistDisplay] = useState(false);
  const [option, setOption] = useState(value);

  const handleRecipientFieldUpdate = (occasion: string, occasionId: number) => {
    setRecipientCustomField(occasion);
    setlistDisplay(!listDisplay);
    setOption(occasion);
  };
  return (
    <div className="dropdown inline-block relative">
      <button
        className="bg-white border w-[330px] text-gray-700 font-leagueSpartan-400 py-2 px-4 rounded flex justify-between items-center"
        onClick={() => setlistDisplay(!listDisplay)}
      >
        <span className="mr-1">{option}</span>
        <img src={images.dropdownIcon} className="h-4 w-4" alt="dropdown" />
      </button>
      {listDisplay && (
        <ul className="absolute text-gray-700 pt-1 w-full z-10">
          <li
            onClick={() => {
              setOption(CampaignOccasion.NO_OCCASION);
              setRecipientCustomField("");
              setlistDisplay(!listDisplay);
            }}
          >
            <div className="rounded-t bg-neutrals-300 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap">
              No occasion
            </div>
          </li>

          {Object.entries(customFieldValueMap).map(([occasion, occasionId]) => (
            <li
              key={occasionId}
              onClick={() => handleRecipientFieldUpdate(occasion, occasionId)}
            >
              <div className="rounded-t bg-neutrals-300 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap">
                {occasion}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownScheduleSendCustomField;
