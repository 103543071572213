const DeactivatedHeader = ({ logo }: { logo: string }) => {
  return (
    <div className="fixed top-0 z-10 w-screen flex p-3 px-6 shadow-md bg-red-500 items-center justify-start max-md:justify-start">
      <div className="flex items-center">
        <img src={logo} className="h-[50px]" alt="" />
        <div className="ml-8 font-leagueSpartan-400 max-sm:text-[16px] text-[22px] text-white">
          This link has been deactivated.
        </div>
      </div>
    </div>
  );
};
export default DeactivatedHeader;