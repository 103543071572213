import React, { useCallback, useState } from "react";
import images from "../../constants/images";
import { toast } from "react-toastify";
import Api from "../../constants/api";
import CustomNavigate from "../../utils/navigate";
import { useNavigate } from "react-router-dom";

type propType = {
  setmodel: React.Dispatch<React.SetStateAction<boolean>>;
  resetPasswordCode: string;
};

export default function ResetPasswordModel(props: propType) {
  const { setmodel, resetPasswordCode } = props;

  const navigate = new CustomNavigate(useNavigate());

  const [password, setPassword] = useState<string>("");
  const [confirmpassword, setConfirmPassword] = useState<string>("");

  const [errors, setErrors] = useState({
    password: "",
    confirmpassword: "",
  });

  const resetPassword = useCallback(() => {
    if (
      password === "" ||
      errors.password !== "" ||
      errors.confirmpassword !== ""
    ) {
      toast.error("Enter valid details");
      return;
    }
    Api.resetPasswordVerify({ resetPasswordCode, password })
      .then(() => {
        toast.success("Password reset successful.");
        setTimeout(() => {
          navigate.to("/auth");
        }, 5000);
      })
      .catch(() => {
        toast.success("Error occurred.");
      });
  }, [errors, password, resetPasswordCode]);

  return (
    <>
      <div className="inset-0 fixed bg-[#381B1AE5] flex justify-center items-center">
        <div>
          <div className="w-[596px] min-h-[380px] shadow flex-col rounded-xl   bg-white">
            <div className="flex justify-end mx-3  ">
              <div className="w-6 h-6 " />{" "}
              <button
                className="text-black text-xl mt-2"
                onClick={() => setmodel(false)}
              >
                <img src={images.Close} />
              </button>
            </div>
            <div className="flex flex-col gap-2 p-7">
              <div className="flex justify-center ">
                <img src={images.logo} className=" w-15 h-15 mt-5 " alt="" />
              </div>
              <div className="text-center text-zinc-800 text-lg mt-2  font-leagueSpartan-500">
                Enter new password
              </div>
              <label className="mx-1 mt-5 font-leagueSpartan-400">
                Password
              </label>
              <div>
                <div className="flex justify-center">
                  <input
                    type="password"
                    className="w-[100%] bg-zinc-100 border-none rounded-md mt-0"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => {
                      const v = e.target.value;
                      if (v.length < 6) {
                        setErrors((e) => {
                          return {
                            ...e,
                            password:
                              "Password must be greater than or equal to 6 characters.",
                          };
                        });
                      } else {
                        setErrors((e) => {
                          return { ...e, password: "" };
                        });
                      }
                      setPassword(v);
                    }}
                  />
                </div>
                <label className=" text-red-500 text-sm ">
                  {errors.password}
                </label>
              </div>
              <label className="mx-1 mt-5 font-leagueSpartan-400">
                {" "}
                Confirm Password
              </label>
              <div>
                <div className="flex justify-center">
                  <input
                    type="password"
                    className="w-[100%] bg-zinc-100 border-none rounded-md mt-0"
                    placeholder="Enter Confirm Password"
                    value={confirmpassword}
                    onChange={(e) => {
                      const v = e.target.value;
                      if (v !== password) {
                        setErrors((e) => {
                          return {
                            ...e,
                            confirmpassword:
                              "Password and confirm password doesn't match.",
                          };
                        });
                      } else {
                        setErrors((e) => {
                          return { ...e, confirmpassword: "" };
                        });
                      }
                      setConfirmPassword(v);
                    }}
                  />
                </div>
                <label className="text-red-500 text-sm">
                  {errors.confirmpassword}
                </label>
              </div>
              <div className="flex justify-center">
                <button
                  className="p-1.5 mt-5 bg-orange rounded-lg text-white font-leagueSpartan-300"
                  style={{ width: "100%" }}
                  onClick={() => resetPassword()}
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
