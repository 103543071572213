import { isAxiosError } from "axios";
import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import MultipleImageUploader from "../../../components/MulitpleImageUploader/MultipleImageUploader";
import Field from "../../../components/common/Field";
import Api from "../../../constants/api";
import { inputClass } from "../../../constants/classes";

type AddOnData = Pick<
  AddOn,
  | "name"
  | "description"
  | "image_1"
  | "image_2"
  | "image_3"
  | "image_4"
  | "listPrice"
  | "costPrice"
  | "maxQuantity"
>;

const initialValues: AddOnData = {
  name: "",
  description: "",
  image_1: "",
  image_2: "",
  image_3: "",
  image_4: "",
  listPrice: 0,
  costPrice: 0,
  maxQuantity: 1,
};

const validationSchema = yup.object<AddOnData>().shape({
  name: yup.string().required("Add On name is required"),
  description: yup.string().required("Description is required"),
  image_1: yup.string().required("Please upload Images before submitting"),
  listPrice: yup
    .number()
    .positive("List Price must be positive")
    .required("List Price is required"),
  costPrice: yup
    .number()
    .positive("List Price must be positive")
    .required("List Price is required"),
  maxQuantity: yup
    .number()
    .integer("Max Quantity must be an integer")
    .positive("Max Quantity must be positive")
    .required("Max Quantity is required"),
});

export const CreateAddOn = (props: any) => {
  const navigate = useNavigate();
  const successMessage = () => toast("Success!");
  const errorMessage = (error?: string) => toast.error(error || "error!");
  const isEdit = props?.updateData && props?.tabNumber === props?.currentTab;

  const createAddOn = useCallback(
    (values: AddOnData) => {
      Api.createAddOn(values)
        .then(successMessage)
        .catch((err) => {
          if (isAxiosError(err))
            errorMessage(err.response?.data.message || "Invalid Data");
          else errorMessage();
        });
    },
    [Api, successMessage, errorMessage]
  );

  const updateAddOn = useCallback(
    (values: AddOnData) => {
      Api.updateAddOn(props?.updateData?.id, values)
        .then(successMessage)
        .then(() =>
          navigate("/admin", { state: { tab: props?.tabNumber, subTab: 1 } })
        )
        .catch((err) => {
          if (isAxiosError(err))
            errorMessage(err.response?.data.message || "Invalid Data");
          else errorMessage();
        });
    },
    [Api, successMessage, errorMessage]
  );

  const {
    errors,
    values,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue,
  } = useFormik<AddOnData>({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values): Promise<void> => {
      console.log(values);
      if (isEdit) {
        updateAddOn(values);
      } else {
        createAddOn(values);
      }
    },
  });

  /**
   * Handle Cancel Update
   */

  const handleCancelUpdate = () => {
    setValues(initialValues);
    navigate("/admin", { state: { tab: props?.tabNumber, subtab: 1 } });
  };

  /**THIS function is passed as prop in image uploader to get the image link */
  const getAzureImageUrl = (imgUrlArray: string[], isAutoUpdate: boolean) => {
    setFieldValue("image_1", imgUrlArray[0] || "");
    setFieldValue("image_2", imgUrlArray[1] || "");
    setFieldValue("image_3", imgUrlArray[2] || "");
    setFieldValue("image_4", imgUrlArray[3] || "");
  };

  /**
   * UseEffect while updating
   */
  useEffect(() => {
    if (props?.updateData && props.tabNumber === 10) {
      let data = props?.updateData;
      setValues({
        name: data?.name,
        description: data?.description,
        image_1: data?.image_1,
        image_2: data?.image_2,
        image_3: data?.image_3,
        image_4: data?.image_4,
        listPrice: data?.listPrice,
        costPrice: data?.costPrice,
        maxQuantity: data?.maxQuantity,
      });
    }
  }, [props?.updateData, props?.tabNumber]);

  return (
    <>
      <form onSubmit={handleSubmit} className="m-4 grid grid-flow-row max-w-md">
        <div className="text-lg text-orange">Add On</div>
        <Field label="Name" errorMessage={errors.name}>
          <input
            type="text"
            name="name"
            onChange={handleChange}
            value={values.name}
            placeholder="Name"
            className={inputClass}
          />
        </Field>
        <Field label="Description" errorMessage={errors.description}>
          <input
            type="text"
            name="description"
            onChange={handleChange}
            value={values.description}
            placeholder="Description"
            className={inputClass}
          />
        </Field>
        <Field label="Images" errorMessage={errors.image_1}>
          <MultipleImageUploader
            getAzureImageUrl={getAzureImageUrl}
            updateImgUrl={[
              props?.updateData?.image_1,
              props?.updateData?.image_2,
              props?.updateData?.image_3,
              props?.updateData?.image_4,
            ]}
            numOfImages={4}
          />
        </Field>
        <Field label="List Price" errorMessage={errors.listPrice}>
          <input
            type="number"
            name="listPrice"
            onChange={handleChange}
            value={values.listPrice}
            placeholder="List Price"
            className={inputClass}
          />
        </Field>
        <Field label="Cost Price" errorMessage={errors.costPrice}>
          <input
            type="number"
            name="costPrice"
            onChange={handleChange}
            value={values.costPrice}
            placeholder="Cost Price"
            className={inputClass}
          />
        </Field>
        <Field label="Max Quantity" errorMessage={errors.maxQuantity}>
          <input
            type="number"
            name="maxQuantity"
            onChange={handleChange}
            value={values.maxQuantity}
            placeholder="Max Quantity"
            className={inputClass}
          />
        </Field>
        <button
          type="submit"
          className="border rounded-lg p-2 bg-lightOrange mt-2"
        >
          Submit
        </button>
        {isEdit && (
          <button
            onClick={handleCancelUpdate}
            type="button"
            className="bg-red-600 text-white absolute left-2/4 w-40 h-12 "
          >
            Cancel
          </button>
        )}
      </form>
    </>
  );
};
