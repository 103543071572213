import { useEffect, useState } from "react";
import images from "../../constants/images";
import { Voucher } from "../../types/vouchers";

type propType = {
  productProp?: {
    products: GetCampaignRecipientProduct[];
    handleProductClick: (product: GetCampaignRecipientProduct) => void;
  };
  voucherProp?: {
    vouchers: Voucher[];
    handleVoucherClick: (voucher: Voucher) => void;
  };
  handleQuickViewClick: (index: number) => void;
  hamperProps?: {
    conversationRatio: number;
    unusedCredits: number;
  };
  isSelfPickupOnly?: boolean;
};

const ProductDetailsCard = ({
  productProp,
  voucherProp,
  handleQuickViewClick,
  hamperProps,
  isSelfPickupOnly,
}: propType) => {
  const [details, setDetail] = useState<
    GetCampaignRecipientProduct[] | Voucher[]
  >([]);

  useEffect(() => {
    if (productProp && !voucherProp) {
      setDetail(productProp.products);
    } else if (voucherProp) {
      setDetail(voucherProp.vouchers);
    }
  }, [productProp, voucherProp]);

  const handleClick = (index: number) => {
    if (productProp && !voucherProp) {
      const { products, handleProductClick } = productProp;
      handleProductClick(products[index]);
    } else if (voucherProp) {
      const { vouchers, handleVoucherClick } = voucherProp;
      handleVoucherClick(vouchers[index]);
    }
  };

  return (
    <div className="flex flex-wrap max-sm:flex justify-center md:gap-8 max-md:gap-4 md:mt-12 max-md:mt-5 md:mx-[8%] max-md:mx-5">
      {details.map((detail, index) => {
        const credits = detail.credits;
        const totalProducts = details.length;

        let row: any;
        if (totalProducts === 5) {
          if (index === 2) {
            row = (
              <div
                key={index}
                className="hidden lg:block"
                style={{ width: "100%" }}
              />
            );
          }
        }
        if (totalProducts === 6) {
          if (index === 3) {
            row = (
              <div
                key={index}
                className="hidden lg:block"
                style={{ width: "100%" }}
              />
            );
          }
        }
        if (totalProducts === 7) {
          if (index === 3) {
            row = (
              <div
                key={index}
                className="hidden lg:block"
                style={{ width: "100%" }}
              />
            );
          }
        }
        return (
          <div key={index} className="max-sm:basis-[47%] max-xsm:basis-[90%]">
            {row}
            <div
              className={`flex flex-col justify-between shadow rounded-xl overflow-hidden md:w-[267px] md:min-w-[267px] min-h-full`}
            >
              <div
                className="cursor-pointer"
                onClick={() => handleClick(index)}
              >
                <div className="relative rounded-t-xl overflow-hidden hoverimagezoom">
                  <img
                    src={detail.image_1}
                    className="w-[100%] aspect-square object-contain"
                    alt=""
                  />
                  {credits &&
                    hamperProps &&
                    credits > hamperProps.unusedCredits && (
                      <div className="absolute top-[40%] w-full p-2 bg-white/95 text-center font-leagueSpartan-400 text-[14px] text-neutrals-900">
                        Not enough credits to select item
                      </div>
                    )}
                </div>

                {"brand" in detail && detail.brand && (
                  <div className="font-leagueSpartan-400 text-md text-neutrals-700 pt-4 px-4">
                    <span className="font-leagueSpartan-500">
                      {detail.brand}
                    </span>
                  </div>
                )}
                <div className="text-container overflow-hidden h-[65px] line-clamp-2">
                  <div className="font-leagueSpartan-400 text-[18px] leading-[24px] px-4 pt-4">
                    {detail.name}
                  </div>
                </div>
              </div>
              <div className="flex gap-4 p-4 py-[12px] px-[16px] md:min-h-[187spx]">
                <div className="self-end">
                  {!voucherProp && credits && (
                    <div className="font-leagueSpartan-400 text-[14px] text-neutrals-800 ">
                      {credits} credits
                    </div>
                  )}

                  <div
                    className="flex text-orange font-leagueSpartan-500 text-[16px] items-center mt-2 cursor-pointer"
                    onClick={() => handleQuickViewClick(index)}
                  >
                    Quick View
                    <img src={images.eye} className="mx-2 h-[12px]" alt="" />
                  </div>
                </div>
                <div className="flex ml-auto">
                  {"hasSelfPickupOption" in detail &&
                    detail.hasSelfPickupOption && (
                      <img src={images.pickup} className="mx-1" alt="" />
                    )}
                  {detail.hasDeliveryOption && !isSelfPickupOnly && (
                    <img src={images.delivery} className="mx-1" alt="" />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductDetailsCard;
