import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import images from "../../constants/images";
import { Voucher } from "../../types/vouchers";
import { getVoucherOrderKey } from "../../utils/giftUtils";
import VoucherDetailsModal from "./VoucherDetailsModal";

type propsType = {
  orderDetails: GetOrderResponseData;
};

const ThankyouNotesHamper = ({ orderDetails }: propsType) => {
  const state: { skipped: boolean } = useLocation().state;
  const skipped = state?.skipped || false;
  const email = orderDetails.email;
  const [voucherIndexModal, setVoucherIndexModal] = useState<number>(-1);
  const [toGenerateIdsMap, setToGenerateIdsMap] = useState<
    Map<string, VoucherOrder[]>
  >(new Map());
  const [voucherInfoMap, setVoucherInfoMap] = useState<
    Map<string, [number, Voucher]>
  >(new Map());

  useEffect(() => {
    const newtoGenerateIdsMap = new Map<string, VoucherOrder[]>();
    const newVoucherInfoMap = new Map<string, [number, Voucher]>();
    orderDetails.voucherOrders.forEach((voucherOrder) => {
      const key: string = getVoucherOrderKey(voucherOrder);
      const voucher = voucherOrder.voucher;
      const voucherInfo = newVoucherInfoMap.get(key);
      const quantity = voucherInfo ? voucherInfo[0] : 0;
      voucher.name = `${voucher.name} - $${voucherOrder.denomination}`;
      newVoucherInfoMap.set(key, [quantity + 1, voucher]);
      if (voucherOrder.status === "TO_GENERATE") {
        const ids = newtoGenerateIdsMap.get(key) ?? [];
        ids.push(voucherOrder);
        newtoGenerateIdsMap.set(key, ids);
      }
    });
    setVoucherInfoMap(newVoucherInfoMap);

    setToGenerateIdsMap(newtoGenerateIdsMap);
  }, [orderDetails.voucherOrders]);
  const status = orderDetails.status.name;
  return (
    <>
      <div className="p-4 mt-[100px]">
        <div className="flex justify-center font-leagueSpartan-500 text-[32px]">
          My Gift Status
        </div>
        <div className="flex justify-center w-[40%] max-md:w-[90%] m-auto mt-6">
          <div className="relative mx-1">
            <img src={images.checkCircle} alt="" />
          </div>
          <div
            className={`border-2  ${
              status === "PROCURED" || status === "COMPLETED"
                ? "border-emerald-600"
                : "border-neutrals-500"
            } w-[40%] self-center`}
          ></div>
          <div className="relative mx-1">
            <img
              src={
                status === "PROCURED" || status === "COMPLETED"
                  ? images.truckGreen
                  : images.truckGrey
              }
              className="mt-1"
              alt=""
            />
          </div>
          <div
            className={`border-2  ${
              status === "COMPLETED"
                ? "border-emerald-600"
                : "border-neutrals-500"
            } w-[40%] self-center`}
          ></div>
          <div className="relative mx-1">
            <img
              src={
                status === "COMPLETED"
                  ? images.giftGreen
                  : images.giftGrey
              }
              alt=""
            />
          </div>
        </div>
        <div className="grid grid-cols-3 w-[42%] max-md:w-[90%] m-auto mt-2">
          <div className="font-leagueSpartan-400 text-[16px] place-content-start flex top-4 text-neutrals-900">
            Order Received
          </div>
          <div className="font-leagueSpartan-400 text-[16px] place-content-center flex top-4 text-neutrals-900 text-center">
            On the way
          </div>
          <div className="font-leagueSpartan-400 text-[16px] place-content-end flex top-4 text-neutrals-900">
            Delivered
          </div>
        </div>
      </div>
      <div
        className={`mt-4 grid grid-cols-2 max-lg:grid-cols-1 gap-5 lg:mx-auto  ${
          skipped ? "lg:w-[65%]" : "lg:w-[40%]"
        }  max-lg:mx-6`}
      >
        <div
          className={`shadow rounded-lg ${
            !skipped && "col-span-2"
          } bg-white p-6`}
        >
          <div className="font-leagueSpartan-500 text-[24px]">My Gift</div>
          {/* ORDER - VOUCHER & PRODUCT - DETAILS */}
          {Array.from(voucherInfoMap).map(
            ([key, [totalVoucherCount, voucher]], index: number) => {
              const voucherOrders = toGenerateIdsMap.get(key) || [];
              const failedVoucherCount =
                totalVoucherCount - voucherOrders.length;
              const { image_1: image, name } = voucher;
              return (
                <div
                  key={index}
                  className="flex flex-col sm:flex-row gap-4 my-2 py-2"
                >
                  <img
                    className="w-[80px] h-[80px] aspect-square rounded-xl object-contain"
                    src={image || images.defaultVoucher}
                    alt=""
                  />
                  <div className="flex-1">
                    <p className="font-leagueSpartan-400 text-[18px] text-neutrals-900">
                      {name}
                    </p>
                    <p className="font-leagueSpartan-400 text-[18px] text-neutrals-700">
                      To be Activated: {voucherOrders.length}
                    </p>
                    {failedVoucherCount > 0 && (
                      <p className="font-leagueSpartan-400 text-[18px] text-neutrals-700">
                        Processing: {failedVoucherCount}
                      </p>
                    )}
                    <p className="font-leagueSpartan-400 text-[18px] text-neutrals-700">
                      Total: {totalVoucherCount}
                    </p>
                  </div>
                  <div>
                    <button
                      className="w-full sm:w-[212px] sm:mt-0 bg-orange text-white font-leagueSpartan-500 text-[16px] rounded-lg p-2 px-3 h-[44px]"
                      onClick={() => {
                        setVoucherIndexModal(index);
                      }}
                    >
                      View Voucher Details
                    </button>
                  </div>
                  {voucherIndexModal === index && (
                    <VoucherDetailsModal
                      voucher={voucher}
                      voucherOrders={voucherOrders}
                      email={email}
                      availableVouchers={voucherOrders.length}
                      setModalVisible={setVoucherIndexModal}
                      toGenerateIdsMap={toGenerateIdsMap}
                      setToGenerateIdsMap={setToGenerateIdsMap}
                    />
                  )}
                </div>
              );
            }
          )}
          {orderDetails.orders.map((order) => {
            const { image_1: image, name, id } = order.product;
            return (
              <div key={id} className="flex gap-4 my-2 py-2">
                <img
                  className="w-[80px] h-[80px] aspect-square rounded-xl object-contain"
                  src={image}
                  alt=""
                />
                <div>
                  <p className="font-leagueSpartan-400 text-[18px] text-neutrals-900">
                    {name}
                  </p>
                  <p className="font-leagueSpartan-400 text-[18px] text-neutrals-700">
                    Quantity: {order.quantity}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ThankyouNotesHamper;
