import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import images from "../../constants/images";
import CustomNavigate from "../../utils/navigate";
import Accordin from "../common/Accordin";
type propType = {
  charity: GetCampaignRecipientCharity;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
const CharityDetailsModalHamper = (props: propType) => {
  const { setModalVisible, charity } = props;

  const navigate = new CustomNavigate(useNavigate());

  const [Quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState(0);

  const productImages = [];
  if (charity.pic1) productImages.push(charity.pic1);
  if (charity.pic2) productImages.push(charity.pic2);
  if (charity.pic3) productImages.push(charity.pic3);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setModalVisible(false);
      }
    },
    [setModalVisible]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, false);
    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [handleKeyPress]);

  const nextImage = useCallback(() => {
    setSelectedImage((si) => {
      if (si < productImages.length - 1) {
        return si + 1;
      }
      return si;
    });
  }, [productImages.length]);

  const previousImage = useCallback(() => {
    setSelectedImage((si) => {
      if (si - 1 > -1) {
        return si - 1;
      }
      return si;
    });
  }, []);
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center max-sm:p-0 text-center max-sm:items-end sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-sm:mt-7 sm:my-8 sm:w-full sm:max-w-[800px]">
            <div className="bg-white px-2 pb-2 pt-2 sm:p-8 sm:pb-6">
              {/* Body */}
              <div className="sm:hidden flex justify-end">
                <button
                  onClick={() => setModalVisible(false)}
                  className="self-start"
                >
                  <img src={images.cross} className="h-[13.5px]" alt="" />
                </button>
              </div>
              <div className="flex relative">
                <div className="grid grid-cols-2 max-sm:grid-cols-1">
                  {/* Images */}
                  <div className="">
                    <div className="relative">
                      <button
                        className="absolute top-[45%] left-1 p-2 rounded-full bg-neutrals-200"
                        onClick={() => previousImage()}
                      >
                        <img src={images.leftArrow} alt="" />
                      </button>
                      <button
                        className="absolute top-[45%] right-1 p-2 rounded-full bg-neutrals-200"
                        onClick={() => nextImage()}
                      >
                        <img src={images.rightArrow} alt="" />
                      </button>
                      <div className="flex justify-center">
                        <img
                          src={productImages[selectedImage]}
                          className="w-[368px] aspect-square rounded-2xl object-cover"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex overflow-x-auto mt-5">
                      {productImages.map((p, i) => {
                        return (
                          <img
                            src={p}
                            key={i}
                            className={`w-[20%] aspect-square mr-1 rounded-lg border ${
                              selectedImage === i && "border-orange"
                            }`}
                            alt=""
                            onClick={() => setSelectedImage(i)}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className="ml-4 flex flex-col">
                    <div className="font-leagueSpartan-400 text-[24px] text-neutrals-900 my-4">
                      {charity.name}
                    </div>
                    <div className="my-4">
                      <Accordin
                        data={[
                          {
                            id: 1,
                            title: "Description",
                            description: charity.description,
                            checked: true,
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="max-sm:hidden absolute right-[-6px] top-[-6px]">
                  <button
                    onClick={() => setModalVisible(false)}
                    className="self-start"
                  >
                    <img src={images.cross} className="w-[18.5px]" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharityDetailsModalHamper;
