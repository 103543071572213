import { isAxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { utils, writeFile } from "xlsx";
import { ConfirmationBox } from "../../../components/common/ConfirmBox";
import Api from "../../../constants/api";
import images from "../../../constants/images";

const DEFAULT_ORDERS_LIMIT = 10;
interface OrderData {
  costInCredits: number;
  costInCurrency: number;
  createdAt: string;
  currency: string;
  id: number;
  is_draft: boolean;
  packagingPreference: string | null;
  quantity: number | null;
  shippingMethod: string;
  createdBy: any;
  corp: any;
  campaign: any;
  product: any;
  shippingDetails: any;
  status: any;
}

const Headers = [
  {
    key: "ConsolidatedOrder.id",
    heading: "Order Id",
  },
  {
    key: "ConsolidatedOrder.packagingPreference",
    heading: "Packaging Preference",
  },
  {
    key: "ConsolidatedOrder.shippingMethod",
    heading: "Shipping Method",
  },
  {
    key: "ConsolidatedOrder.createdAt",
    heading: "Created On  (dd-mm-yyyy)",
  },
  {
    key: "ConsolidatedOrder.deliveryDate",
    heading: "Delivery  Date(dd-mm-yyyy)",
  },
  {
    key: "createdBy.email",
    heading: "Created By",
  },
  {
    key: "corp.organisationName",
    heading: "Corp Name",
  },
  {
    key: "campaign.name",
    heading: "Campaign Name",
  },
  {
    key: "product.name",
    heading: "Product Name and Quantity",
  },
  {
    key: "shippingDetails.shipperName",
    heading: "Shipper Name",
  },
  {
    key: "orderType.name",
    heading: "Order Type",
  },
  {
    key: "status.name",
    heading: "Order Status",
  },
  {
    key: "ConsolidatedOrder.note",
    heading: "Note",
  },
  {
    key: "ConsolidatedOrder.firstName",
    heading: "FirstName",
  },
  {
    key: "ConsolidatedOrder.lastName",
    heading: "LastName",
  },
  {
    key: "ConsolidatedOrder.address",
    heading: "Address",
  },
  {
    key: "ConsolidatedOrder.apartment",
    heading: "Apartment",
  },
  {
    key: "ConsolidatedOrder.postalCode",
    heading: "Postal Code",
  },
  {
    key: "ConsolidatedOrder.phoneNumber",
    heading: "Phone Number",
  },
  {
    key: "ConsolidatedOrder.email",
    heading: "Email",
  },
  {
    key: "ConsolidatedOrder.street",
    heading: "Street",
  },
  {
    key: "ConsolidatedOrder.city",
    heading: "City",
  },
];
export const AllOrders = () => {
  const successMessage = () => toast("Success!");
  const errorMessage = (error?: string) => toast.error(error || "error!");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    orderId: +"",
    statusId: +"",
    note: "",
    deliveryDate: "",
  });
  const [orders, setAllOrders] = useState<OrderData[]>([]);
  const [orderStatus, setOrderStatus] = useState<any[]>([]);
  const [orderStatusList, setOrderStatusList] = useState<
    CreateOrderResponseStatus[]
  >([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<number>();
  const [showNoteBox, setShowNoteBox] = useState(false);
  const [note, setNote] = useState("");
  const [sortColumn, setSortColumn] = useState<string>(
    "ConsolidatedOrder.createdAt"
  );
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");

  const limit = DEFAULT_ORDERS_LIMIT;
  const [pageNumber, setPageNumber] = useState(0);

  /**
   * Get All Orders
   */
  const getOrders = useCallback(() => {
    return Api.getAllConsolidatedOrders(
      limit,
      pageNumber * limit,
      selectedStatusFilter,
      sortColumn,
      sortOrder
    )
      .then((res: any) => {
        if (isAxiosError(res)) {
          errorMessage(res.response?.data.message || "Invalid data!");
          return;
        }

        return res.data;
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [limit, pageNumber, selectedStatusFilter, sortColumn, sortOrder]);

  /**
   * Get All Orders Status According to orderType
   */
  const getOrderStatus = useCallback(() => {
    return Api.getStatusAccToOrderType()
      .then((res) => {
        if (isAxiosError(res)) {
          errorMessage(res.response?.data.message || "Invalid data!");
          return;
        }

        return res.data;
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  /**
   * Once we update orderstatus, deliveryDate or note then changing the state in frontend too so that we dont need extra api hit.
   */
  const updateOrderStatusInData = (orderId: number) => {
    let updatedData = {};
    if (selectedOption.statusId) {
      updatedData = { status: { id: selectedOption.statusId } };
    } else if (selectedOption.note) {
      updatedData = { note: selectedOption.note };
    } else if (selectedOption.deliveryDate) {
      updatedData = { deliveryDate: selectedOption.deliveryDate };
    }
    setAllOrders((prevData) =>
      prevData.map((order) =>
        order.id === orderId ? { ...order, ...updatedData } : order
      )
    );
  };

  /**
   * Handles the click event for exporting all orders to a CSV file.
   */
  const handleExportClick = async () => {
    let limit = 0;
    let offset = 0;

    await Api.getAllConsolidatedOrders(limit, offset)
      .then((res: any) => {
        const allConsolidatedOrders = res.data;

        // Define the headers
        const headers = [
          "Order ID",
          "Campaign ID",
          "Campaign Name",
          "Campaign Budget",
          "Corp Name",
          "Order Date/Time",
          "Product Category Name",
          "Brand Name",
          "Product Name",
          "List Price",
          "Cost Price",
          "Sell Platform (Link)",
          "Quantity",
          "Ship Method",
          "Remarks",
          "First Name",
          "Last Name",
          "Email",
          "Phone Number",
          "Address",
          "Apartment",
          "Postal Code",
          "Street",
        ];

        // Initialize an array for the rows
        let rows: any = [];

        for (const consolidatedOrder of allConsolidatedOrders) {
          if (consolidatedOrder.campaign && consolidatedOrder.orders) {
            const {
              id: orderId,
              campaign,
              createdAt: orderTime,
              orders,
              corp: { organisationName: corpName },
            } = consolidatedOrder;
            if (campaign) {
              const {
                name: campaignName,
                id: campaignId,
                perPersonBudget: campaignBudget,
              } = campaign;
              for (const order of orders) {
                if (order && order.product) {
                  const {
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    shippingMethod,
                    address,
                    apartment = address,
                    postalCode,
                    street,
                    product: {
                      brand: brandName,
                      name: productName,
                      sellPlatformUrl,
                      listPrice,
                      costPrice,
                      productCategory: { name: productCategoryName },
                    },
                    quantity,
                  } = order;
                  const row = [
                    orderId,
                    campaignId,
                    campaignName,
                    campaignBudget,
                    corpName,
                    orderTime,
                    productCategoryName,
                    brandName,
                    productName,
                    listPrice,
                    costPrice,
                    sellPlatformUrl,
                    quantity,
                    shippingMethod,
                    "",
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    address,
                    apartment,
                    postalCode,
                    street,
                  ];
                  rows.push(row);
                }
              }
            }
          }
        }

        // Create a new worksheet from the rows
        const worksheet = utils.aoa_to_sheet([headers, ...rows]);
        // Create a new workbook and add the worksheet to it
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Orders");
        // Write the workbook to a CSV file
        writeFile(workbook, "orders.csv");
      })
      .catch((error) => {
        console.log("Failed to fetch all consolidated orders:", error);
      });
  };

  useEffect(() => {
    getOrders().then((res) => setAllOrders(res));
    getOrderStatus().then((res) => setOrderStatus(res));
  }, [getOrderStatus, getOrders, limit, pageNumber]);

  useEffect(() => {
    Api.getAllOrderStatus().then((res) => {
      setOrderStatusList(res.data);
    });
  }, []);

  /**
   * This function sets the selected option state based on different input objects from what we are chaning,
   * is it delivery date, note or orderstatus.
   * THIS ONLY CHANGES THE SELECTED STATE DATE IN FRONTEND.
   */

  const handleSelectedOptionState = (options: any) => {
    setSelectedOption(options);
  };

  /**
   * tHIS IS ACTUAL API CALL, FOR SAME UPDATE API, WE HAVE DIFFERENT DATA IN THAT FUNCTION.
   */
  const handleUpdateOrderData = (data: any) => {
    Api.updateConsolidatedOrderDate(data)
      .then((res) => {
        if (isAxiosError(res)) {
          errorMessage(res.response?.data.message || "Invalid data!");
          return;
        }
        if (
          res.data?.affected === 1 &&
          (selectedOption.statusId || selectedOption.deliveryDate)
        ) {
          setShowConfirmation(false);
        }
        //temporary updating thte state according to the updated data.
        updateOrderStatusInData(selectedOption.orderId);
        successMessage();
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  /**
   * Function to convert the date into desired Date format.
   */
  function convertTimestampToDDMMYYYY(dateString: string) {
    if (dateString === null) {
      return "";
    }
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  /**
   * For every order this function will run and return the orderStatus type.
   */
  const getStatusTypesForOrderType = (orderType: string) => {
    const matchedOrderType = orderStatus.find((item) => {
      return item.name === orderType;
    });
    return matchedOrderType?.orderStatusTypes;
  };

  /**
   * Handle note change
   */
  const handleNoteChange = (e: any) => {
    setNote(e.target.value);
    setSelectedOption((prev) => {
      return { ...prev, note: e.target.value };
    });
  };

  const onSort = (column: string) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortColumn(column);
      setSortOrder("DESC");
    }
  };

  return (
    <>
      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        <div className="p-6">
          <h1 className="text-2xl font-semibold mb-4">Order Table</h1>
          <div className="flex items-center gap-4">
            Status:
            <select
              // value={selectedStatusFilter} // Set the selected status ID for the dropdown
              onChange={(e) => {
                setSelectedStatusFilter(+e.target.value);
              }}
            >
              <option value={""}>All</option>
              {orderStatusList?.map((order) => (
                <option key={order.id} value={order?.id}>
                  {order?.name}
                </option>
              ))}
            </select>
            <button
              style={{ border: "2px solid black", backgroundColor: "#FFD580" }}
              onClick={handleExportClick}
            >
              Export
            </button>
          </div>

          <div className="mb-4" />
          <div className="bg-white rounded shadow table-responsive overflow-x-auto overflow-y-auto">
            <table className="w-full  table-auto">
              <thead>
                <tr className="bg-gray-200">
                  {Headers.map((header) => {
                    return (
                      <th
                        key={header.key}
                        className="px-4 py-2 cursor-pointer"
                        onClick={() => onSort(header.key)}
                      >
                        <span className="flex items-center w-max">
                          {header.heading}
                          <span className="arrowsSorts">
                            {sortColumn === header.key && (
                              <>
                                {sortOrder === "ASC" ? (
                                  <img
                                    src={images.upArrowIcon}
                                    className="w-8 min-w-8 max-w-8 h-4 mr-5"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={images.downArrowIcon}
                                    className="w-8 min-w-8 max-w-8 h-4 mr-5"
                                    alt=""
                                  />
                                )}
                              </>
                            )}
                          </span>
                        </span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {orders?.map((item: any) => {
                  return (
                    <>
                      <tr className="border-b">
                        <td className="px-4 py-2">{item?.id}</td>
                        <td className="px-4 py-2">
                          {item?.packagingPreference}
                        </td>
                        <td className="px-4 py-2">{item?.shippingMethod}</td>
                        <td className="px-4 py-2">
                          {convertTimestampToDDMMYYYY(item?.createdAt)}
                        </td>
                        <td className="px-4 py-2">
                          <div className="flex gap-1">
                            <DatePicker
                              selected={
                                item?.deliveryDate
                                  ? new Date(item?.deliveryDate)
                                  : null
                              }
                              dateFormat="dd/MM/yyyy"
                              minDate={new Date()}
                              onChange={(date) => {
                                handleSelectedOptionState({
                                  orderId: item?.id,
                                  deliveryDate: date?.toISOString(),
                                });
                                setShowConfirmation(true);
                              }}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          </div>
                        </td>
                        <td className="px-4 py-2">{item?.createdBy?.email}</td>
                        <td className="px-4 py-2">
                          {item?.corp.organisationName}
                        </td>
                        <td className="px-4 py-2">{item?.campaign?.name}</td>
                        <td className="px-4 py-2">
                          {item?.orders &&
                            item?.orders.map((order: any) => (
                              <div key={order.product?.id} className="w-max">
                                {order.product?.name}
                                <span className="ms-3 bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border-blue-400">
                                  x{order.quantity}
                                </span>
                              </div>
                            ))}
                        </td>
                        <td className="px-4 py-2">
                          {item?.shippingDetails?.shipperName}
                        </td>
                        <td className="px-4 py-2">{item?.orderType?.name}</td>
                        <td className="px-4 py-2">
                          <div className="mt-2">
                            <select
                              name="orderStatus"
                              id="orderStatus"
                              className="border rounded-lg"
                              value={item?.status?.id} // Set the selected status ID for the dropdown
                              onChange={(e) => {
                                handleSelectedOptionState({
                                  orderId: +item?.id,
                                  statusId: +e.target.value,
                                });
                                setShowConfirmation(true);
                              }}
                            >
                              {getStatusTypesForOrderType(
                                item?.orderType?.name
                              )?.map((val: any, i: any) => (
                                <option key={i} value={val?.status?.id}>
                                  {val?.status?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                        <td>
                          {item?.note}
                          <button
                            className="border rounded-lg"
                            onClick={() => {
                              handleSelectedOptionState({
                                orderId: +item?.id,
                                note: item?.note,
                              });
                              setNote(item?.note);
                              setShowNoteBox(true);
                            }}
                          >
                            Add Note
                          </button>
                        </td>
                        <td className="px-4 py-2">{item?.firstName}</td>
                        <td className="px-4 py-2">{item?.lastName}</td>
                        <td className="px-4 py-2">{item?.address}</td>
                        <td className="px-4 py-2">{item?.apartment}</td>
                        <td className="px-4 py-2">{item?.postalCode}</td>
                        <td className="px-4 py-2">{item?.phoneNumber}</td>
                        <td className="px-4 py-2">{item?.email}</td>
                        <td className="px-4 py-2">{item?.street}</td>
                        <td className="px-4 py-2">{item?.city}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-between w-full px-5 pb-3">
          <button
            className="mt-2 bg-orange text-white p-2 px-3 rounded-lg disabled:bg-slate-500"
            type="submit"
            onClick={() => setPageNumber(pageNumber > 0 ? pageNumber - 1 : 0)}
            disabled={pageNumber === 0}
          >
            Previous
          </button>
          <button
            className="mt-2 bg-orange text-white p-2 px-3 rounded-lg disabled:bg-slate-500"
            type="submit"
            onClick={() =>
              setPageNumber(
                orders?.length === limit ? pageNumber + 1 : pageNumber
              )
            }
            disabled={orders?.length !== limit}
          >
            Next
          </button>
        </div>
        {/* This code shows the confirmation box based on a boolean state */}
        {showConfirmation && (
          <ConfirmationBox
            message={`Are you sure you want to update ${
              selectedOption.statusId
                ? "ORDER STATUS"
                : selectedOption.deliveryDate
                ? "DELIVERY DATE"
                : ""
            }`}
            onConfirm={() => {
              handleUpdateOrderData(selectedOption);
              setShowConfirmation(false);
            }}
            onCancel={() => {
              setShowConfirmation(false);
              return;
            }}
          />
        )}
        {/* This code show the note box based on a boolean state */}
        {showNoteBox && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-6 rounded-md shadow-md w-96">
              <h2 className="text-xl font-semibold mb-4">Add a Note</h2>
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring focus:ring-blue-300"
                rows={5}
                value={note}
                onChange={handleNoteChange}
                placeholder="Enter your note here..."
              />
              <div className="flex justify-end">
                <button
                  className="px-4 py-2 mr-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
                  onClick={() => {
                    setShowNoteBox(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                  onClick={() => {
                    handleUpdateOrderData(selectedOption);
                    setShowNoteBox(false);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
