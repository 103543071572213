import EngagementTabs from "../../../components/dashboard/EngagementTabs";
import Main from "../../../components/dashboard/Main";

const Engagement = () => {
  return (
    <Main title="Engagement" logo={true}>
      <EngagementTabs />
    </Main>
  );
};

export default Engagement;
