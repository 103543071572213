type PropTypes = {
  product: ProductT;
  closeModal: () => void;
};

export const ProductQuickView = ({ product, closeModal }: PropTypes) => {
  const { name, description, image_1 ,listPrice} = product;

  return (
    <div className="bg-black bg-opacity-60 flex items-center justify-center overflow-hidden fixed top-0 left-0 w-full h-full z-40">
      <div className="bg-white rounded-lg sm:w-3/5 xl:w-1/2  overflow-auto max-h-full">
        <div className="flex flex-col justify-center">
          <div className="flex sticky top-0 z-50 bg-white rounded-xl w-full items-start ">
            <button
              type="button"
              className="hover:underline focus:outline-none text-black font-medium p-4 text-lg "
              onClick={closeModal}
            >
              ← Back
            </button>
          </div>
          <div className="mt-4 grid grid-cols-1 md:grid-cols-12 md:gap-4">
            {/* Images */}
            <div className="flex justify-center items-center col-span-1 md:col-span-5">
              <div className="border rounded-2xl">
                <img src={image_1} className="aspect-square" alt="" />
                <div className="font-leagueSpartan-400 text-lg text-center text-neutrals-900 my-4 p-4">
                ${listPrice}
              </div>
              </div>
            </div>
            {/* Content */}
            <div className="flex flex-col items-start text-left col-span-1 md:col-span-7">
              <div className="font-leagueSpartan-400 text-2xl text-neutrals-900 my-4 p-4">
                {name}
              </div>
              <div className="shadow-lg p-4 rounded-lg w-full">
                <div className="overflow-auto">
                  <p className="font-bold text-2xl text-orange-300">
                    Description
                  </p>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
