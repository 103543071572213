import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ToastCustomized from "../../components/common/ToastCustomized";
import Api from "../../constants/api";
import images from "../../constants/images";
import CustomNavigate from "../../utils/navigate";

const perPageItems = [10, 20, 30, 40, 50];

export const CampaignDetailRecipent = () => {
  const navigate = new CustomNavigate(useNavigate());
  const campaign = useLocation().state as CampaignListData;
  const CampaignRecipent = campaign.recipients;
  const [campaignsrecipent, setCampaignsRecipent] = useState(CampaignRecipent);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = campaignsrecipent.slice(startIndex, endIndex);
  const totalPages = Math.ceil(campaignsrecipent.length / itemsPerPage);

  const [pageList, setPageList] = useState<number[]>([]);
  const _setPageList = useCallback(() => {
    let _currentPage = currentPage;
    if (_currentPage > totalPages) {
      _currentPage = 1;
      setCurrentPage(_currentPage);
    }

    if (totalPages <= 3) {
      setPageList([...Array(totalPages)].map((_, i) => i + 1));
    } else {
      if (_currentPage + 2 <= totalPages) {
        setPageList((opl) => {
          if (!opl.includes(_currentPage)) {
            let list = [_currentPage, _currentPage + 1, _currentPage + 2];
            // if (!list.includes(totalPages)) list.push(totalPages);
            // if (!list.includes(1)) list = [1, ...list];
            return list;
          }
          return opl;
        });
      } else {
        setPageList((opl) => {
          if (!opl.includes(_currentPage)) {
            const list = [...Array(totalPages - _currentPage + 1)].map(
              (_, i) => i + _currentPage
            );
            if (list.length < 3)
              return [_currentPage - 2, _currentPage - 1, _currentPage];
            return list;
          }
          return opl;
        });
      }
    }
  }, [currentPage, totalPages, itemsPerPage]);

  useEffect(() => {
    _setPageList();
  }, [currentPage, CampaignRecipent, itemsPerPage, _setPageList]);

  let PageList = (
    <div className="pagination">
      {pageList.map((v, index) => (
        <button
          className={`text-orange rounded-lg border border-orange mx-1 w-8 h-8 hover:bg-lightOrange ${
            currentPage === v ? "bg-lightOrange" : "bg-transparent"
          }`}
          key={v}
          onClick={() => handlePageChange(v)}
        >
          {v}
        </button>
      ))}
    </div>
  );

  const handleSendEmailClick = (data: CampaignListRecipient) => {
    Api.sendEmailToRecipient(
      campaign.isSingle,
      campaign.messageFromName ?? "Smilie",
      data
    )
      .then((res) => {
        toast.success("Email Sent!");
      })
      .catch((error) => {
        toast.error(error || "Something went wrong!");
      });
  };

  // Pagination end

  return (
    <div className="mx-8 pb-2 w-full">
      <div className="flex justify-end">
        <button
          className="bg-orange text-white text-base rounded-lg py-2 px-4"
          onClick={() =>
            navigate.to(
              "/dashboard/campaigns/:id/add-recipients",
              {
                key: ":id",
                value: campaign.id,
              },
              campaign
            )
          }
        >
          New Recipient
        </button>
      </div>
      <table className="mt-6 w-full">
        <thead className="bg-neutrals-300  p-4 text-left">
          <tr>
            <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700 text-left p-4">
              RECIPENT
            </th>
            <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700 p-4">
              GROUP
            </th>
            <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700 p-4">
              STATUS
            </th>
            <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700 p-4">
              DATE SENT
            </th>
            <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700 p-4">
              DATE CLAIMED
            </th>
            <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700 p-4">
              ACCOUNT SPENT
            </th>
            <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700 p-4">
              GIFT SELECTED
            </th>
            <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700 p-4">
              LINK
            </th>
            <th className="font-leagueSpartan-600 text-[14px] text-neutrals-700 p-4">
              SEND EMAIL
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {currentItems.map((val, index) => {
            return (
              <tr key={index}>
                <td className="p-4">
                  <div className="flex">
                    <div className="grid grid-flow-row  ">
                      <div className="text-neutrals-900 font-leagueSpartan-500 text-[16px]">
                        {val.name}
                      </div>
                      <div className="text-neutrals-600 font-leagueSpartan-300 text-[16px]">
                        {val.email}
                      </div>
                    </div>
                  </div>
                </td>
                {/* <td className="text-neutrals-900 text-sm cursor-pointer">
                  {val.account}
                </td> */}
                <td className="font-leagueSpartan-300 text-[16px] p-4">
                  {val.group}
                </td>
                {/* Status */}
                <td className="text-center p-4">
                  <div className="flex text-sm font-leagueSpartan-400">
                    <div
                      className={`px-2 mt-1 rounded font-leagueSpartan-500 text-[14px] whitespace-nowrap ${
                        val.isForTesting
                          ? "bg-gray-100 text-gray-800"
                          : [
                              "Redeemed",
                              "COLLECTED",
                              "DELIVERED",
                              "ORDER_RECEIVED",
                            ].includes(val.status)
                          ? "bg-green-100 text-green-800"
                          : [
                              "Opened",
                              "ON_ROUTE",
                              "SHIPPED",
                              "PLACED",
                              "GIFT_READY_TO_COLLECT",
                              "PROCESSING",
                            ].includes(val.status)
                          ? "bg-orange-100 text-orange"
                          : ["Not opened", "CANCELLED", "NOT_PLACED"].includes(
                              val.status
                            )
                          ? "bg-red-100 text-red-800"
                          : ""
                      }`}
                    >
                      {val.isForTesting ? "Unactivated" : val.status}
                    </div>
                  </div>
                </td>
                <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px] p-4">
                  {val.dateSent
                    ? moment(val.dateSent).format("DD MMM YYYY, hh:mm")
                    : "-"}
                </td>
                <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px] p-4">
                  {val.dateClaimed
                    ? moment(val.dateClaimed).format("DD MMM YYYY, hh:mm")
                    : "-"}
                </td>
                <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px]  p-4">
                  ${val.accountSpent}
                </td>
                <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px] max-w-[200px] p-4">
                  {val.giftSelected || "-"}{" "}
                </td>
                <td
                  className="text-orange text-sm cursor-pointer p-4"
                  onClick={() => {
                    navigator.clipboard.writeText(val.link);
                    toast.success("Link Copied.");
                  }}
                >
                  Copy
                </td>
                <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px] max-w-[200px] p-4">
                  <span>
                    {!val.isForTesting &&
                      (val.status === "Not opened" ||
                        val.status === "Opened") && (
                        <button
                          className="px-2 pt-1 rounded font-leagueSpartan-500 text-[14px] whitespace-nowrap bg-orange text-orange-100"
                          onClick={() => handleSendEmailClick(val)}
                        >
                          Send Email
                        </button>
                      )}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="flex justify-between">
        <div className="flex justify-start mt-2">
          <label className="text-neutral-600 mt-2 text-base">
            Rows per Page:
          </label>
          <select
            className="border-gray-400 rounded-xl mx-1"
            onChange={(e) => {
              setItemsPerPage(+e.target.value);
              setCurrentPage(1);
            }}
          >
            {perPageItems.map((v) => (
              <option key={v}>{v}</option>
            ))}
          </select>
        </div>
        <div className="flex justify-end mt-4">
          <div
            className="pt-1 text-sm cursor-pointer"
            onClick={() => handlePageChange(1)}
          >
            {"<<"}
          </div>
          <img
            src={images.leftsarrow}
            alt=""
            className="w-8 h-8"
            onClick={() => {
              if (currentPage > 1) handlePageChange(currentPage - 1);
            }}
          />
          {PageList}
          <img
            src={images.rightarrow}
            alt=""
            className=" w-8 h-8 "
            onClick={() => {
              if (currentPage < totalPages) handlePageChange(currentPage + 1);
            }}
          />
          <div
            className="pt-1 text-sm cursor-pointer"
            onClick={() => handlePageChange(totalPages)}
          >
            {">>"}
          </div>
        </div>
      </div>
      <ToastCustomized />
    </div>
  );
};
