import React, { Dispatch, SetStateAction, useState } from "react";
import images from "../../constants/images";

type propType = {
  setShowMyModel: Dispatch<SetStateAction<boolean>>;
};
const Model = (props: propType) => {
  const { setShowMyModel } = props;
  return (
    <>
      <div className="inset-0 fixed backdrop-blur-sm bg-black bg-opacity-25 flex justify-center items-center">
        <div>
          <div className="w-[596px] h-[280px] p-6 shadow flex-col justify-start items-start gap-6 inline-flex  bg-white">
            <div className="self-stretch justify-between items-center gap-[15px] inline-flex">
              <div className="text-zinc-800 text-2xl font-semibold leading-loose">
                How would you like to add a new account?
              </div>
              <div className="justify-start items-start gap-2.5 flex">
                <div className="w-12 p-3 rounded-xl justify-center items-start gap-2.5 flex">
                  <div className="w-6 h-6 relative" />{" "}
                  <button
                    className="text-black text-xl place-self-end"
                    onClick={() => setShowMyModel(false)}
                  >
                    X
                  </button>
                </div>
              </div>
            </div>
            <div className="self-stretch justify-start items-start gap-6 inline-flex">
              <div className="grow shrink basis-0 p-4 rounded-lg border border-neutral-200 flex-col justify-center items-center gap-2.5 inline-flex">
                <div className=" relative" />
                <div>
                  <img src={images.uploadbulkexcel}></img>
                </div>
                <div className="self-stretch text-center text-zinc-800 text-base font-normal leading-relaxed font-leagueSpartan-400">
                  Bulk Upload with Excel
                </div>
              </div>
              <div className="grow shrink basis-0 p-4 rounded-lg border border-neutral-200 flex-col justify-center items-center gap-2.5 inline-flex">
                <div className=" relative" />
                <div>
                  <img src={images.uploadCRM}></img>
                </div>
                <div className="self-stretch text-center text-zinc-800 text-base font-leagueSpartan-400 font-normal leading-relaxed">
                  Connect to CRM
                </div>
              </div>
            </div>
            <div className="self-stretch justify-end items-center gap-6 inline-flex">
              <div className="justify-start items-start gap-2 flex">
                <button
                  className="text-orange text-base font-medium leading-relaxed tracking-tight font-leagueSpartan-400"
                  onClick={() => setShowMyModel(false)}
                >
                  Cancel
                </button>
              </div>
              <div className="w-[97px] px-7 py-2 bg-orange rounded-lg border border-orange-500 justify-end items-center gap-2 flex">
                <button className="text-white text-base font-medium leading-relaxed tracking-tight font-leagueSpartan-400">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Model;
