import { useLocation } from "react-router-dom";
import Divider from "../../components/common/Divider";
import images from "../../constants/images";
import ClaimedVoucherFooter from "./ClaimedVoucherFooter";
import UnclaimedVoucherThankYouNotes from "./UnclaimedVoucherFooter";

type propsType = {
  voucherOrder: VoucherOrder;
  email: string;
  dataForAccordin: {
    title: string;
    value: string;
  }[];
};

const ThankyouNotesVoucher = (props: propsType) => {
  const state: { skipped: boolean } = useLocation().state;
  const skipped = state?.skipped || false;
  const { voucherOrder, dataForAccordin, email } = props;
  const { id, status, voucher, updatedAt, denomination } = voucherOrder;
  const { image_1: image, name } = voucher;
  const isClaimed = status === "GENERATED";
  return (
    <>
      {/* STATUS */}
      <div className="p-4 mt-[100px]">
        <div className="flex justify-center font-leagueSpartan-500 text-[32px]">
          My Gift Status
        </div>
        <div className="flex justify-center w-[40%] max-md:w-[90%] m-auto mt-6">
          <div className="relative mx-1">
            <img src={images.checkCircle} alt="" />
          </div>
          <div className="border-2 border-emerald-600 w-[40%] self-center"></div>
          <div className="relative mx-1">
            <img src={images.truckGreen} className="mt-1" alt="" />
          </div>
          <div className="border-2 border-emerald-600 w-[40%]  self-center"></div>
          <div className="relative mx-1">
            <img src={images.giftGreen} alt="" />
          </div>
        </div>
        <div className="grid grid-cols-3 w-[42%] max-md:w-[90%] m-auto mt-2">
          <div className="font-leagueSpartan-400 text-[16px] place-content-start flex top-4 text-neutrals-900">
            Order Received
          </div>
          <div className="font-leagueSpartan-400 text-[16px] place-content-center flex top-4 text-neutrals-900 text-center">
            On the way
          </div>
          <div className="font-leagueSpartan-400 text-[16px] place-content-end flex top-4 text-neutrals-900">
            Delivered
          </div>
        </div>
      </div>
      <div
        className={`mt-4 grid grid-cols-2 max-lg:grid-cols-1 gap-5 lg:mx-auto  ${
          skipped ? "lg:w-[65%]" : "lg:w-[40%]"
        }  max-lg:mx-6`}
      >
        {/* ORDER - VOUCHER - DETAILS */}
        <div
          className={`shadow rounded-lg ${
            !skipped && "col-span-2"
          } bg-white p-6`}
        >
          <div className="font-leagueSpartan-500 text-[24px]">My Gift</div>

          <div key={id} className="flex gap-4 my-2 py-2">
            <img
              className="w-[80px] h-[80px] aspect-square rounded-xl object-contain"
              src={image || images.defaultVoucher}
              alt=""
            />
            <div>
              <p className="font-leagueSpartan-400 text-[18px] text-neutrals-900">
                {`${name} - $${denomination}`}
              </p>
            </div>
          </div>
          <div className="my-6">
            <Divider />
          </div>
          <div>
            {isClaimed ? (
              <ClaimedVoucherFooter email={email} date={updatedAt} />
            ) : (
              <UnclaimedVoucherThankYouNotes
                dataForAccordin={dataForAccordin}
                voucherOrder={voucherOrder}
                email={email}
                voucher={voucherOrder.voucher}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankyouNotesVoucher;
