import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import ForgotPasswordModel from "../../components/common/ForgotPasswordModel";
import ToastCustomized from "../../components/common/ToastCustomized";
import Api from "../../constants/api";
import images from "../../constants/images";
import { setAccessData, setIsLoading } from "../../redux/globalSlice";
import { useAppDispatch } from "../../redux/hooks";
import JwtService from "../../service/jwtService";

type propType = {
  setSelected: (value: React.SetStateAction<number>) => void;
};

export default function Signup(props: propType) {
  const { setSelected } = props;
  const dispatch = useAppDispatch();

  const [remember, setRemember] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    username: "",
    confirmpassword: "",
  });
  const [password, setpassword] = useState("");
  const [model, setModel] = useState(false);

  const login = useCallback(() => {
    if (Object.values(errors).find((v) => v !== "")) {
      toast.error("Enter valid details");
      return;
    }
    if (email === "" || password === "") {
      toast.error("Enter valid details");
      return;
    }
    dispatch(setIsLoading(true));
    Api.login(email, password)
      .then(async (res) => {
        dispatch(setIsLoading(false));
        await JwtService.signInWithToken(res.data.tokens.access_token);
        dispatch(setAccessData(res.data));
      })
      .catch(() => {
        dispatch(setIsLoading(false));
        JwtService.logout();
        toast.error("Invalid Credentials");
      });
  }, [errors, email, password, dispatch]);

  return (
    <>
      <div className="flex flex-col mt-4 justify-center gap-2 w-full">
        <label className="text-neutrals-900 text-[16px] font-leagueSpartan-400">
          Email Address
        </label>
        <div className="flex flex-col">
          <div
            className={`flex items-center border rounded-md  p-1`}
            style={{ width: "100%", height: "45px" }}
          >
            <input
              type="email"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => {
                const v = e.target.value;
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(v)) {
                  setErrors((e) => {
                    return { ...e, email: "Invalid email." };
                  });
                } else {
                  setErrors((e) => {
                    return { ...e, email: "" };
                  });
                }
                setEmail(v);
              }}
              className="w-full rounded-lg outline-none border-none focus:ring-0 text-gray-700 font-leagueSpartan-300 placeholder:text-neutral-400"
            />
          </div>
          <label className=" text-red-500 text-sm ">{errors.email}</label>
        </div>

        <label className="text-neutrals-900 text-[16px] font-leagueSpartan-400">
          Password
        </label>
        <div className="flex flex-col">
          <div
            className="flex items-center border rounded-md  p-1  "
            style={{ width: "100%", height: "45px" }}
          >
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => {
                const v = e.target.value;
                if (v.length < 6) {
                  setErrors((e) => {
                    return {
                      ...e,
                      password:
                        "Password must be greater than or equal to 6 characters.",
                    };
                  });
                } else {
                  setErrors((e) => {
                    return { ...e, password: "" };
                  });
                }
                setpassword(v);
              }}
              className="w-full rounded-lg outline-none border-none focus:ring-0 text-gray-700  font-leagueSpartan-300 placeholder:text-neutral-400"
            />
          </div>
          <label className=" text-red-500 text-sm ">{errors.password}</label>
        </div>
        <div className="flex flex-wrap justify-between mt-2">
          <div
            className="flex auto cursor-pointer"
            onClick={() => setRemember((val) => !val)}
          >
            <img
              src={remember ? images.checkBoxInactive : images.checkBoxSelected}
              alt=""
              className="rounded-sm outline-none  w-[18px] h-[18px]"
            />
            <span className="text-[15px] text-neutrals-900 -mt-0.5 mx-2 font-leagueSpartan-400">
              Remember me
            </span>
          </div>
          <div className="flex auto">
            <div
              className="font-leagueSpartan-400 text-[15px] text-neutrals-900 -mt-0.5 cursor-pointer"
              onClick={() => setModel(true)}
            >
              Forgot Password?
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            className=" h-[45px] w-full bg-orange rounded-lg text-white font-leagueSpartan-400 mt-2 text-[15px]"
            onClick={() => login()}
          >
            Sign In
          </button>
        </div>
        <div className="mt-4">
          <div className=" text-center " style={{ lineHeight: "10px" }}>
            <span className="text-neutrals-900 font-leagueSpartan-400 text-[14px]">
              Don’t have an account?{" "}
            </span>
            <span
              className="text-[#6358DC] font-leagueSpartan-400 text-[14px] cursor-pointer"
              onClick={() => {
                setSelected(1);
              }}
            >
              Register
            </span>
          </div>
        </div>
        <ToastCustomized />
        {model === true && <ForgotPasswordModel setModel={setModel} />}
      </div>
    </>
  );
}
