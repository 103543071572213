import { useState, useEffect } from "react";
import Api from "../../../constants/api";
import { Link, useNavigate } from "react-router-dom";
export const Collections = () => {
  const navigate = useNavigate();
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    Api.getAllCollection()
      .then((res: any) => {
        console.log(res, "res");
        setCollections(res.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);

  const editCollection = (item: object) => {
    navigate("/admin", { state: { item, tab: 1, subTab: 0 } });
  };


  return (
    <>
      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        {collections?.map((item: any) => {
          return (
            <>
              <div style={{ border: "2px solid black", padding: "20px" }}>
                <h1>{item.id}</h1>
                <h2>{item.name}</h2>
                <img style={{ width: "100px" }} src={item.picture} />
                <button
                  onClick={() => {
                    editCollection(item);
                  }}
                  style={{
                    backgroundColor: "Red",
                    color: "white",
                    boxShadow: "2px 2px 2px black",
                  }}
                >
                  Edit
                </button>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
