import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProductDetailsCard from "../../components/common/ProductDetailsCard";
import BackButtonGifting from "../../components/giftSelection/BackButton";
import Header from "../../components/giftSelection/Header";
import ProdutDetailsModal from "../../components/giftSelection/ProdutDetailsModal";
import Api from "../../constants/api";
import { setCorpData } from "../../redux/corpSlice";
import { setIsLoading } from "../../redux/globalSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setCampaignRecipientData } from "../../redux/selectGiftSlice";
import { Voucher } from "../../types/vouchers";
import {
  isShowVoucher,
  parseVouchers,
  populateHeader,
} from "../../utils/giftUtils";
import CustomNavigate from "../../utils/navigate";

const ChooseCategory = () => {
  const selectGiftState = useAppSelector((state) => state.selectGiftState);

  const recipientId =
    useSearchParams()[0].get("recid") ||
    selectGiftState.campaignRecipientData?.result.url_id;
  const navigate = new CustomNavigate(useNavigate(), { recipientId });
  const dispatch = useAppDispatch();

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<number>(0);
  const [isSelfPickupOnly, setIsSelfPickupOnly] = useState<boolean>();

  const groupedProduct =
    selectGiftState.campaignRecipientData?.groupedProduct || [];
  const vouchers: Voucher[] = parseVouchers(
    selectGiftState.campaignRecipientData?.vouchers || []
  );
  const filteredGroupedProduct = useMemo(() => {
    const now = new Date();
    return groupedProduct.map((group) => ({
      ...group,
      products: group.products.filter((product) => {
        const endVisibilityDate = new Date(product.endVisibility);
        return !product.endVisibility || endVisibilityDate >= now;
      }),
    }));
  }, [groupedProduct]);
  const headers = useMemo(
    () => populateHeader(groupedProduct, vouchers),
    [groupedProduct, vouchers]
  );

  useEffect(() => {
    if (selectGiftState.campaignRecipientData || !recipientId) return;
    dispatch(setIsLoading(true));
    Api.getCampaignRecipient(recipientId!)
      .then((res) => {
        setIsSelfPickupOnly(res.data.result.campaign.isSelfPickupOnly);
        dispatch(setCampaignRecipientData(res.data));
        Api.getOneCorp(res.data.result.campaign.corp.id).then((res) => {
          dispatch(setCorpData(res.data));
        });
      })
      .finally(() => dispatch(setIsLoading(false)));
  }, [
    dispatch,
    recipientId,
    selectGiftState.campaignRecipientData,
    isSelfPickupOnly,
  ]);

  useEffect(() => {
    if (window.innerWidth < 1024) return;
    const element = document.querySelector(".hoverRight");
    let idx: any;
    if (element) {
      element.addEventListener("mouseenter", function () {
        idx = setInterval(() => (element.scrollLeft += 1), 10);
      });

      element.addEventListener("mouseleave", function () {
        clearInterval(idx);
      });
    }
    return () => {
      // @ts-ignore
      if (element) element?.removeEventListener("mouseenter", {});
      // @ts-ignore
      if (element) element?.removeEventListener("mouseleave", {});
    };
  }, []);

  useEffect(() => {
    if (!selectGiftState.campaignRecipientData) return;
    const template =
      selectGiftState.campaignRecipientData.result.campaign.corp.template;

    if (template && template !== "") {
      navigate.corpId = template;
      navigate.to("/gift-selection/:corpName/choose-category");
      return;
    }
    if (!selectGiftState.campaignRecipientData.result.campaign.isSingle) {
      navigate.corpId = template;
      navigate.to("/hamper/gift-selection/choose-category");
      return;
    }
  }, [selectGiftState.campaignRecipientData]);

  const handleProductClick = (product: GetCampaignRecipientProduct) => {
    if (product) {
      navigate.to(
        "/gift-selection/choose-category/:id",
        { key: ":id", value: 1 },
        product
      );
    }
  };

  const handleVoucherClick = (voucher: Voucher) => {
    if (voucher) {
      navigate.to(
        "/gift-selection/choose-voucher-category/:id",
        { key: ":id", value: 1 },
        { voucher: voucher, isHamper: false }
      );
    }
  };

  const handleQuickViewClick = (index: number) => {
    if (index !== -1) {
      setSelectedProduct(index);
      setModalVisible(true);
    }
  };

  const isDeactivated = useMemo(
    () => selectGiftState.campaignRecipientData?.result?.isForTesting ?? false,
    [selectGiftState.campaignRecipientData?.result?.isForTesting]
  );

  return (
    <div className="">
      <Header
        title="Choose a gift from one of the categories!"
        navigate={navigate}
        isPreview={isDeactivated}
      />
      <div className="m-6 mt-[100px]">
        <BackButtonGifting navigate={navigate} />
      </div>
      {/* CATEGORIES MD */}
      <div className="sticky top-[74px] z-10 bg-white">
        <div className="py-2 mx-5 flex overflow-x-auto md:mx-[10%] hoverRight lg:justify-center">
          {headers.map((category, index) => {
            return (
              <div
                key={index}
                className={`grid grid-flow-col auto-cols-max mx-2 max-sm:py-[6px] max-sm:px-[12px] py-[8px] px-[16px] w-fit text-center border  hover:bg-lightOrange rounded-lg cursor-pointer ${
                  index === selectedCategory
                    ? "border-orange bg-lightOrange text-orange"
                    : "border-neutrals-400 text-neutrals-700"
                } `}
                onClick={() => setSelectedCategory(index)}
              >
                <div
                  className={`flex items-center font-leagueSpartan-400  max-sm:text-[14px] text-[16px] tracking-[2.24px]`}
                >
                  &nbsp;&nbsp;
                  {category.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* PRODUCTS */}
      {isShowVoucher(selectedCategory, headers, vouchers) && (
        <ProductDetailsCard
          voucherProp={{
            vouchers: vouchers,
            handleVoucherClick: handleVoucherClick,
          }}
          handleQuickViewClick={handleQuickViewClick}
          isSelfPickupOnly={isSelfPickupOnly}
        />
      )}
      {!isShowVoucher(selectedCategory, headers, vouchers) && (
        <ProductDetailsCard
          productProp={{
            products:
              filteredGroupedProduct.length > 0
                ? filteredGroupedProduct[selectedCategory].products
                : [],
            handleProductClick: handleProductClick,
          }}
          handleQuickViewClick={handleQuickViewClick}
          isSelfPickupOnly={isSelfPickupOnly}
        />
      )}

      {modalVisible && (
        <>
          {!isShowVoucher(selectedCategory, headers, vouchers) &&
            selectedProduct <
              groupedProduct[selectedCategory].products.length && (
              <ProdutDetailsModal
                setModalVisible={setModalVisible}
                product={
                  groupedProduct[selectedCategory].products[selectedProduct]
                }
              />
            )}
          {isShowVoucher(selectedCategory, headers, vouchers) &&
            selectedProduct < vouchers.length && (
              <ProdutDetailsModal
                setModalVisible={setModalVisible}
                voucher={
                  selectedProduct < vouchers.length
                    ? vouchers[selectedProduct]
                    : undefined
                }
              />
            )}
        </>
      )}
    </div>
  );
};

export default ChooseCategory;
