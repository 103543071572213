import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

// Define the initial state using that type
const initialState: {
  address: AddressT;
  shippingMethod?: ShippingMethodTypes;
  paymentMethod?: string;
  product?: GetRecipientRecommendedProduct;
  campaignCharity?: GetCampaignRecipientCampaignCharity;
  recipientId?: string;
  campaignRecipientData?: GetCampaignRecipientData;
  redirectUrl?: string;
} = {
  address: {
    firstName: "",
    lastName: "",
    address: "",
    apartment: "",
    postalCode: "",
    phoneNumber: "",
    email: "",
  },
  redirectUrl: "",
};

export const selectGiftSlice = createSlice({
  name: "selectGiftState",
  initialState,
  reducers: {
    setCampaignRecipientData: (
      state,
      action: PayloadAction<GetCampaignRecipientData>
    ) => {
      state.campaignRecipientData = action.payload;
    },
    setAddress: (state, action: PayloadAction<AddressT>) => {
      state.address = action.payload;
    },
    setShippingMethod: (state, action: PayloadAction<ShippingMethodTypes>) => {
      state.shippingMethod = action.payload;
    },
    setpaymentMethod: (state, action: PayloadAction<string>) => {
      state.paymentMethod = action.payload;
    },
    setRecipientId: (state, action: PayloadAction<string>) => {
      state.recipientId = action.payload;
    },
    setProduct: (
      state,
      action: PayloadAction<GetRecipientRecommendedProduct | undefined>
    ) => {
      state.product = action.payload;
    },
    setCharity: (
      state,
      action: PayloadAction<GetCampaignRecipientCampaignCharity | undefined>
    ) => {
      state.campaignCharity = action.payload;
    },
    setRedirectUrl: (state, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAddress,
  setShippingMethod,
  setProduct,
  setCharity,
  setpaymentMethod,
  setRecipientId,
  setCampaignRecipientData,
  setRedirectUrl,
} = selectGiftSlice.actions;

export const selectCount = (state: RootState) => state.cartState;

export default selectGiftSlice.reducer;
