export type TUserList = TUser[];

type TUser = {
  id: number;
  firstName: string;
  lastName: string;
};

const stringToColour = (str: string) => {
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};

const getInitials = (firstName = "", lastName = "") => {
  let initials = "";
  if (firstName.length) initials += firstName[0];
  if (lastName.length) initials += lastName[0];

  return initials.toUpperCase();
};

export const UserList = ({
  header,
  className,
  userList,
}: {
  header: string;
  className?: string;
  userList: TUserList;
}) => {
  return (
    <div className={className}>
      <div className="pb-3">{header}</div>
      <div className="flex gap-2">
        {userList.length ? (
          userList.map((user, index) => (
            <div
              key={index}
              className="flex justify-center items-center bg-lightOrange text-orange rounded-full text-xs w-8 aspect-square"
            >
              <span className="p-0 m-0">
                {getInitials(user.firstName, user.lastName)}
              </span>
            </div>
          ))
        ) : (
          <span className="text-neutrals-600">No Responses</span>
        )}
      </div>
    </div>
  );
};
