import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../../constants/api";
import Table, { DEFAULT_PAGE_LIMIT } from "../../../components/Table/Table";

const HEADERS = [
  {
    key: "CampaignReferral.name",
    heading: "NAME",
  },
  {
    key: "CampaignReferral.phoneNumber",
    heading: "PHONE NUMBER",
  },
  {
    key: "orderRecipientDetails.firstName",
    heading: "REFERRER NAME",
  },
  {
    key: "CampaignReferral.createdAt",
    heading: "DATE / TIME",
  },
  {
    key: "campaign.name",
    heading: "CAMPAIGN",
  },
];

export const EngagementReferrals = () => {
  const [referrals, setReferrals] = useState<string[][]>([]);
  const [search, setSearch] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [sortColumn, setSortColumn] = useState<string>(
    "CampaignReferral.createdAt"
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(DEFAULT_PAGE_LIMIT);
  const [totalReferralCount, setTotalReferralCount] = useState<number>(0);

  useEffect(() => {
    Api.getAllReferrals(limit, pageNumber, sortColumn, sortOrder, search)
      .then(({ data }) => {
        setReferrals(transformDataIntoArray(data.referrals));
        setTotalReferralCount(data.count);
      })
      .catch(() => toast.error("Something went wrong!"));
  }, [limit, pageNumber, search, sortColumn, sortOrder]);

  const transformDataIntoArray = (data: any[]) => {
    return data.map((row) => {
      return [
        row.name,
        row.phoneNumber,
        `${row.recipient.orderRecipientDetails.firstName} 
          ${row.recipient.orderRecipientDetails.lastName}`,
        row.createdAt
          ? moment(row.createdAt).format("DD MMM YYYY, hh:mm")
          : "-",
        row.campaign.name,
      ];
    });
  };

  return (
    <Table
      header={HEADERS}
      rows={referrals}
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      setSortColumn={setSortColumn}
      setSearch={setSearch}
      setPageNumber={setPageNumber}
      pageNumber={pageNumber}
      setLimit={setLimit}
      limit={limit}
      totalItemCount={totalReferralCount}
    />
  );
};
