import { useState } from "react";

type propType = {
  productCredit: number;
  creditsAvailable: number;
  addToCartHandler: (quantity: number) => void;
};

const HamperQuantity = ({
  productCredit,
  creditsAvailable,
  addToCartHandler,
}: propType) => {
  const [quantity, setQuantity] = useState(1);
  const insuffecientCredits = creditsAvailable < productCredit;
  return (
    <div className="shadow-md p-4 rounded-lg my-4">
      <div className="text-orange text-lg font-leagueSpartan-600 my-3">
        {productCredit} Credit
      </div>
      <div className="grid grid-cols-5 my-3">
        <div className="col-span-3 font-leagueSpartan-400">Quantity</div>
        <div
          className={`col-span-2 grid grid-cols-3 border rounded-lg justify-items-center ${
            insuffecientCredits &&
            "bg-neutrals-300 text-neutrals-600 text-[18px]"
          }`}
        >
          <button
            className="p-1"
            onClick={() =>
              setQuantity((previousQuantity) => {
                const newQuantity = previousQuantity - 1;
                return newQuantity < 1 ? 1 : newQuantity;
              })
            }
            disabled={insuffecientCredits}
          >
            -
          </button>
          <button
            className="p-1 font-leagueSpartan-400 "
            disabled={insuffecientCredits}
          >
            {quantity}
          </button>
          <button
            className="p-1"
            onClick={() =>
              setQuantity((previousQuantity) => {
                const newQuantity = previousQuantity + 1;
                return newQuantity * productCredit > creditsAvailable
                  ? previousQuantity
                  : newQuantity;
              })
            }
            disabled={insuffecientCredits}
          >
            +
          </button>
        </div>
      </div>
      <button
        className={`w-full p-2  rounded-lg text-white font-leagueSpartan-400 my-3 ${
          insuffecientCredits ? "bg-neutrals-500" : "bg-orange"
        }`}
        onClick={() => addToCartHandler(quantity)}
        disabled={insuffecientCredits}
      >
        {insuffecientCredits ? "Insufficient productCredit" : "Add to basket"}
      </button>
    </div>
  );
};
export default HamperQuantity;
