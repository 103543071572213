import { Dispatch, SetStateAction, useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import Api from "../../../constants/api";
import images from "../../../constants/images";
import { updateBalance } from "../../../redux/corpSlice";
import { setIsLoading } from "../../../redux/globalSlice";
import { useAppSelector } from "../../../redux/hooks";
import Divider from "../../common/Divider";
import PaymentFormCampaign from "../PaymentFormHamper";

export type propType = {
  setSlectedCard: Dispatch<SetStateAction<number>>;
  selectedCard: number;
  balance: number;
  totalAmount: number;
  amountWithoutFee: number;
  numberOfSelectedRecipient: number;
  totalAmountWithoutFee: number;
  totalFeeAmount: number;
  corpState: { fee: number };
  onclickTopUpWallet: () => void;
};

const Payment = ({
  setSlectedCard,
  selectedCard,
  balance,
  totalAmount,
  amountWithoutFee,
  numberOfSelectedRecipient,
  totalAmountWithoutFee,
  totalFeeAmount,
  corpState,
  onclickTopUpWallet,
}: propType) => {
  const corp = useAppSelector((state) => state.corpSliceState);
  const liveBalance = corp.data?.balance || 0;
  const corpId = corp.data?.id;
  const dispatch = useDispatch();

  const onRefresh = useCallback(() => {
    if (corpId) {
      dispatch(setIsLoading(true));
      Api.getOneCorp(corpId)
        .then((res) => {
          dispatch(updateBalance(res.data.balance));
          toast.success("Balance refreshed!");
        })
        .catch((error) => {
          console.error(error);
          toast.error("There was an error refreshing your balance.");
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    } else {
      console.log("corpId is null/undefined");
    }
  }, [corpId, dispatch]);

  return (
    <div className="mt-20 mx-auto w-[80%] mb-6">
      <div className="grid grid-cols-5">
        {/* Col 1 */}
        <div className="col-span-3 p-4">
          <div className="font-leagueSpartan-600 text-neutrals-800 text-xl">
            Choose Payment Method
          </div>
          <div className="mt-4">
            <div
              className="flex cursor-pointer"
              onClick={() => setSlectedCard(1)}
            >
              <img
                src={
                  selectedCard === 1 ? images.radioActive : images.radioInactive
                }
                alt=""
                className="self-baseline mr-4"
              />
              <div>
                <div className="text-neutrals-800 font-leagueSpartan-400">
                  Smilie Wallet
                </div>
                <div className="flex items-center gap-2">
                  <div className=" font-leagueSpartan-400 text-neutrals-700">
                    Your balance: ${liveBalance}{" "}
                    {balance < totalAmount && "(Insufficient)"}
                  </div>
                  <button
                    className="bg-orange text-white text-sm font-leagueSpartan-400 rounded-lg p-2 px-3"
                    onClick={() => onclickTopUpWallet()}
                  >
                    Top up wallet
                  </button>
                  <button
                    className="border border-orange text-orange text-sm font-leagueSpartan-400 rounded-lg p-2 px-3"
                    onClick={onRefresh}
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="py-8"></div>
          {selectedCard === 3 && (
            <div className="">
              <PaymentFormCampaign />
            </div>
          )}
        </div>
        {/* Col 2 */}

        {/* Col 2 */}
        <div className="col-span-2 p-4">
          <div className="p-4">
            <p className="text-xl font-leagueSpartan-500 text-neutrals-800">
              Payment Summary
            </p>
            <div className="flex justify-between my-2 mt-4">
              {" "}
              <div className="text-neutrals-800 font-leagueSpartan-400 flex">
                Max gift cost
                <div className="ml-1 ">
                  S${amountWithoutFee} x {numberOfSelectedRecipient} recipients
                </div>
              </div>
              <div className="text-neutrals-900 font-leagueSpartan-500">
                {" "}
                S${totalAmountWithoutFee}
              </div>
            </div>
            <div className="flex justify-between my-2">
              {" "}
              <div className="text-neutrals-800 font-leagueSpartan-400 flex">
                Smilie platform fee
                <img
                  src={images.questionMark}
                  alt=""
                  className="ml-2"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={`Smilie platform fee is ${corpState.fee}% of the Gift Budget. It helps us to run the platform!`}
                />
                <Tooltip id="my-tooltip" />
              </div>
              <div> S${totalFeeAmount}</div>
            </div>
            <div className="my-3">
              <Divider />
            </div>
            <div className="flex justify-between text-neutrals-800 font-leagueSpartan-500 text-lg">
              {" "}
              <div>Total Cost </div>
              <div> S${totalAmount} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
