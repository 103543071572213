import xlsx from "json-as-xlsx";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Header from "../../components/dashboard/Header";
import Api from "../../constants/api";
import images from "../../constants/images";
import { setCorpData, setCorpFee } from "../../redux/corpSlice";
import { setIsLoading } from "../../redux/globalSlice";
import { useAppSelector } from "../../redux/hooks";
import CustomNavigate from "../../utils/navigate";

const CampaignCreated = () => {
  const state = useLocation().state as {
    isDraft: boolean;
    campaignName: string;
  };
  const dispatch = useDispatch();

  const isDraft = state.isDraft;
  const campaignName = state.campaignName;
  const navigate = new CustomNavigate(useNavigate());
  const campaignState = useAppSelector((state) => state.campaignSliceState);
  const corpState = useAppSelector((state) => state.corpSliceState);
  const campaignRecipentsDownloadData =
    campaignState.data && campaignState.data.recipients
      ? campaignState.data?.recipients.map((cr) => {
          let link = `https://${window.location.host}/g/` + cr.url_id;

          return {
            firstName: cr.orderRecipientDetails?.firstName || "",
            lastName: cr.orderRecipientDetails?.firstName || "",
            phoneNumber: cr.orderRecipientDetails?.phoneNumber || "NA",
            email: cr.orderRecipientDetails?.email || "NA",
            url: link,
          };
        })
      : undefined;

  useEffect(() => {
    if (corpState.data)
      Api.getOneCorp(corpState.data.id)
        .then((res) => {
          dispatch(setCorpData(res.data));
          dispatch(
            setCorpFee(res.data.customPlans[0].defaultPlan?.commission || 0)
          );
        })
        .finally(() => dispatch(setIsLoading(false)));
  }, []);

  const downloadRecipientsData = useCallback(() => {
    if (!campaignRecipentsDownloadData) {
      return;
    }
    let data = [
      {
        sheet: "Recipients",
        columns: [
          { label: "First Name", value: "firstName" },
          { label: "Last Name", value: "firstName" },
          { label: "Phone Number", value: "phoneNumber" },
          { label: "Email", value: "email" },
          { label: "Gift Link", value: "url" },
        ],
        content: campaignRecipentsDownloadData,
      },
    ];
    let settings = {
      fileName: "Recipients", // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
      // RTL: true, // Display the columns from right-to-left (the default value is false)
    };
    xlsx(data, settings);
  }, [campaignRecipentsDownloadData]);

  return (
    <div className="relative min-h-screen">
      <Header logo={true} title=""></Header>
      <div className="absolute top-[10vh] left-[50%] translate-x-[-50%] w-[500px] text-center">
        <div className="flex justify-center">
          <img src={images.succcessGif} className="h-[185px]" alt="" />
        </div>
        <div className="font-leagueSpartan-600 text-[32px] text-green-500">
          {isDraft ? "Draft saved successfully" : "Campaign launched!"}
        </div>
        <div className="font-leagueSpartan-400 text-[18px] text-neutrals-700 mt-4">
          Yay, your “{campaignName}” campaign has been successfully launched!
          You can modify the campaign details and track analytics under My
          Campaigns.
        </div>
        <div className="flex mt-4 justify-center gap-4">
          <button
            className="border border-orange text-orange text-[14px] rounded-lg font-leagueSpartan-400 p-2 px-4"
            onClick={() => navigate.to("/dashboard")}
          >
            Back to Dashboard
          </button>
          <button
            className="bg-orange text-white text-[14px] rounded-lg font-leagueSpartan-400 p-2 px-4 flex justify-center items-center gap-2"
            onClick={() => navigate.to("/dashboard")}
          >
            View campaign <img src={images.rightArrowWhite} alt="" />
          </button>
          {campaignRecipentsDownloadData && (
            <button
              className="bg-orange text-white text-[14px] rounded-lg font-leagueSpartan-400 p-2 px-4 flex justify-center items-center gap-2"
              onClick={() => downloadRecipientsData()}
            >
              Download{" "}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignCreated;
