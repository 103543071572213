type TCallbacks = "whatsapp" | "default";

const DEFAULT_CALLBACK_URL = "/dashboard";

const CALLBACK_URLS: {
  [key in TCallbacks]: string;
} = {
  default: DEFAULT_CALLBACK_URL,
  whatsapp: process.env.REACT_APP_WHATSAPP_CALLBACK_URL || DEFAULT_CALLBACK_URL,
};

export const getCallbackURL = (callback: string) => {
  return CALLBACK_URLS[callback as TCallbacks] || DEFAULT_CALLBACK_URL;
};
