import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { VoucherCart } from "../../types/vouchers";
import ProgressbarHamper from "./ProgressbarHamper";

type propTypes = {
  productCart: RecipientRecommendedProductCartT[];
  voucherCart: VoucherCart[];
  onViewBasketClick: () => void;
  credits?: { total: number; used: number };
  showProgress?: boolean;
};
const FooterHamper = (props: propTypes) => {
  const {
    voucherCart,
    productCart,
    onViewBasketClick,
    credits,
    showProgress = false,
  } = props;

  const percentUsed = useMemo(
    () => (credits ? (credits.used * 100) / credits.total : 0),
    [credits]
  );
  const [cart, setCart] = useState<
    (RecipientRecommendedProductCartT | VoucherCart)[]
  >([]);

  useEffect(() => {
    setCart([...productCart, ...voucherCart]);
  }, [voucherCart, productCart]);

  const selected = useMemo(() => cart.slice(0, 3), [cart]);
  const extra = useMemo(() => cart.length - 3, [cart]);

  return (
    <div
      className={classNames(
        "fixed",
        "bottom-0",
        "w-full",
        "grid",
        "grid-cols-3",
        "md:grid-cols-5",
        "gap-2",
        "p-4",
        "bg-white",
        "border",
        "border-t-orange"
      )}
    >
      {/* Selected items */}
      <div
        className={classNames(
          "col-span-2",
          "flex",
          "gap-4",
          "justify-start",
          "items-center",
          "max-sm:overflow-x-clip"
        )}
      >
        {/* Just to maintain height when nothing is selected */}
        <div className="h-12"></div>
        {/* First 2 items */}
        {selected.map((c) => (
          <div className="relative h-12" key={c.id}>
            <img
              src={c.image_1}
              alt=""
              className="h-full aspect-square object-contains"
            />
            <div
              className={classNames(
                "absolute",
                "right-[-3.5px]",
                "top-[-3.5px]",
                "border",
                "border-orange",
                "text-white",
                "bg-orange",
                "rounded-full",
                "h-5",
                "w-5",
                "text-[12px]",
                "text-center"
              )}
            >
              {c.quantity}
            </div>
          </div>
        ))}
        {/* Extra */}
        {extra > 0 && <div className="text-sm text-orange">+{extra} more</div>}
      </div>
      {/* View Basket */}
      <div className="md:col-span-3 flex gap-4 items-center justify-end">
        {showProgress && (
          <>
            {/* Progress (MD & above) */}
            <div className="grow max-md:hidden">
              <ProgressbarHamper progress={percentUsed} />
            </div>
            {/* Credits (MD & above) */}
            {credits && (
              <div className="max-md:hidden font-leagueSpartan-300 text-[14px] text-neutrals-800">
                {`${credits.used}/${credits.total}`} credits used
              </div>
            )}
          </>
        )}
        <button
          className={classNames(
            "bg-orange",
            "disabled:bg-gray-400",
            "text-white",
            "rounded-lg",
            "p-1.5",
            "px-4",
            "max-sm:text-sm",
            "font-leagueSpartan-400"
          )}
          onClick={() => onViewBasketClick()}
          disabled={cart.length === 0}
        >
          View Basket {`(${cart.length})`}
        </button>
      </div>
      {/* Progress & Credits (SM) */}
      {showProgress && (
        <div className="col-span-full md:hidden flex gap-4 items-center">
          {/* Progress (SM) */}
          <div className="grow">
            <ProgressbarHamper progress={percentUsed} />
          </div>
          {/* Credits (SM) */}
          {credits && (
            <div className="font-leagueSpartan-300 text-[14px] text-neutrals-800">
              {`${credits.used}/${credits.total}`} credits used
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FooterHamper;
