import { useEffect, useRef } from "react";
import images from "../../constants/images";
type propType = {
  handleFileUpload: (file: File | null | undefined) => void;
};
const FileUpload = (props: propType) => {
  const { handleFileUpload } = props;
  const drop = useRef<HTMLLabelElement>(null);
  useEffect(() => {
    if (!drop.current) return;
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
    const _drop = drop.current;

    return () => {
      if (!_drop) return;
      _drop.removeEventListener("dragover", handleDragOver);
      _drop.removeEventListener("drop", handleDrop);
    };
  }, [drop]);

  const handleDragOver = (e: globalThis.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: globalThis.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // @ts-ignore
    const { files } = e.dataTransfer;
    console.log("e.dataTransfer", e.dataTransfer);

    if (files && files.length) {
      console.log(files);
      handleFileUpload(files[0]);
    }
  };

  return (
    <label
      ref={drop}
      // onDragEnter={(e) => {
      //   e.preventDefault();
      //   // e.stopPropagation();
      //   console.log(e.dataTransfer.files);
      // }}
      className="relative flex flex-col items-center justify-center bg-neutrals-200 p-6 mt-4 rounded-lg border border-dashed cursor-pointer"
      // onClick={() => setFileUploaded(true)}
    >
      <input
        id="fileSelect"
        type="file"
        className="absolute -z-30"
        onChange={(e) => {
          const file = e.target.files?.item(0);
          handleFileUpload(file);
        }}
        onSelect={(e) => {
          // console.log(e);
        }}
        accept=".csv"
      />
      <div className="bg-neutrals-400 rounded-full p-1.5">
        <img src={images.upload} alt="" />
      </div>
      <div className="font-leagueSpartan-400 text-[18px] text-neutrals-900 mt-2">
        Click or drag CSV file to this area to upload file
      </div>
      <div className="font-leagueSpartan-400 text-[16px] text-neutrals-700">
        5MB max file size
      </div>
    </label>
  );
};

export default FileUpload;
