import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { SearchableDropdown } from "../../components/common/SearchableDropdown";
import ToastCustomized from "../../components/common/ToastCustomized";
import Main from "../../components/dashboard/Main";
import { RecipientsTable } from "../../components/dashboard/RecipientsTable";
import Api from "../../constants/api";
import images from "../../constants/images";

export const IndividualRecipient = () => {
  const { state } = useLocation();
  const { campaigns, firstName, lastName, phoneNumber, email, role, id } =
    state;
  const [allOccassions, setAllOccassions] = useState<
    { id: number; field: string }[]
  >([]);

  const [recipientInfo, setRecipientInfo] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    role: "",
  });

  const [groups, setGroups] = useState<string[]>([]);
  const [occasions, setOccasions] = useState<
    {
      id?: number;
      name: string;
      dateString: string;
    }[]
  >([]);

  const [newGroup, setNewGroup] = useState(false);
  const [newGroupValue, setNewGroupValue] = useState("");

  useEffect(() => {
    if (state) {
      setRecipientInfo({
        firstName,
        lastName,
        phoneNumber,
        email,
        role,
      });
    }
    Api.getAllRecipientCustomFields().then((suggestions) =>
      setAllOccassions(
        suggestions.reduce<typeof allOccassions>((acc, suggestion) => {
          if (suggestion.type === "date") {
            acc.push({ id: suggestion.id, field: suggestion.field });
          }
          return acc;
        }, [])
      )
    );

    Api.getAllRecipientCustomFieldsValues(state.id).then(
      (customFieldValues) => {
        setOccasions(
          customFieldValues.map((customFieldValue) => ({
            id: customFieldValue.id,
            name: customFieldValue.recipientCustomField.field,
            dateString: customFieldValue.value,
          }))
        );
      }
    );

    Api.getRecipientGroupsByRecipientId(id as number).then((resp) => {
      setGroups(resp.data.map((group) => group.name));
    });
  }, [email, firstName, lastName, phoneNumber, role, state]);

  const addOccasion = () => {
    setOccasions((prev) => [...prev, { name: "", dateString: "" }]);
  };

  const deleteOccasion = (index: number) => {
    const newOccasion = [...occasions];
    newOccasion.splice(index, 1);
    setOccasions(newOccasion);
  };

  const editOccasionName = (index: number, newValue: string) => {
    const newOccasion = [...occasions];
    newOccasion[index].name = newValue;
    setOccasions(newOccasion);
  };

  const editOccasionDate = (index: number, newValue: string) => {
    const newOccasion = [...occasions];
    newOccasion[index].dateString = newValue;
    setOccasions(newOccasion);
  };

  const handleOccasionInfoChange = (value: number | string, field: string) => {
    setRecipientInfo((prev) => ({ ...prev, [field]: value }));
  };

  const deleteGroup = (index: number) => {
    const newGroups = [...groups];
    newGroups.splice(index, 1);
    setGroups(newGroups);
  };

  const isDuplicated = (array: string[]) => {
    const occasionSet = new Set(array.map((element) => element.toLowerCase()));
    return occasionSet.size != array.length;
  };

  const update = () => {
    for (const occasion of occasions) {
      const { name, dateString } = occasion;
      if (!name || !dateString) {
        toast.error("There is an invalid occasion name or date");
        return;
      }
    }

    if (isDuplicated(occasions.map((occasion) => occasion.name))) {
      toast.error("The are duplicated occasion name");
      return;
    }
    if (isDuplicated(groups)) {
      toast.error("The are duplicated group name");
      return;
    }
    if (groups.some((group) => !group)) {
      toast.error("There is an invalid group name");
      return;
    }
    axios
      .patch(`${process.env.REACT_APP_CORP_URL}recipient/${state.id}`, {
        ...recipientInfo,
        occasions,
        groups,
      })
      .then(() => {
        toast.success("Recipient updated successfully");
      })
      .catch((error) => {
        toast.error(
          `Recipient update failed: ${error?.response?.data?.message}`
        );
      });
  };

  const handleEnter = useCallback(
    (e: KeyboardEvent) => {
      if (newGroup && e.key === "Enter") {
        setGroups([...groups, newGroupValue]);
        setNewGroup(false);
        setNewGroupValue("");
      }
    },
    [groups, newGroup, newGroupValue]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleEnter);

    return () => {
      window.removeEventListener("keydown", handleEnter);
    };
  }, [handleEnter]);

  return (
    <>
      <Main title="All Recipients" logo={true}>
        <div>
          <div className="p-6 grid grid-flow-row gap-6 font-leagueSpartan">
            <div className="flex justify-end">
              <Link to="../dashboard/recipents">
                <button className="text-stone-400 font-bold py-2 px-8">
                  Cancel
                </button>
              </Link>
              <button
                className="bg-orange text-white font-bold py-2 px-8 rounded-lg"
                onClick={update}
              >
                Save
              </button>
            </div>
            <div className="rounded-lg bg-white p-8">
              <div className="grid grid-cols-3 gap-y-4 gap-x-2">
                <div className="col-span-1">
                  <label htmlFor="" className="block text-base">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={recipientInfo.firstName}
                    onChange={(e) =>
                      handleOccasionInfoChange(e.target.value, "firstName")
                    }
                    className="rounded-lg border-[1px] border-gray-200 w-full mt-2"
                  />
                </div>
                <div className="col-span-1">
                  <label htmlFor="" className="block text-base">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={recipientInfo.lastName}
                    onChange={(e) =>
                      handleOccasionInfoChange(e.target.value, "lastName")
                    }
                    className="rounded-lg border-[1px] border-gray-200 w-full mt-2"
                  />
                </div>
                <div className="col-span-1">
                  <label htmlFor="" className="block text-base">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    name="lastName"
                    placeholder="Phone Number"
                    value={recipientInfo.phoneNumber}
                    onChange={(e) =>
                      handleOccasionInfoChange(e.target.value, "phoneNumber")
                    }
                    className="rounded-lg border-[1px] border-gray-200 w-full mt-2"
                  />
                </div>
                <div className="col-span-1">
                  <label htmlFor="" className="block text-base">
                    Email
                  </label>
                  <input
                    type="email"
                    name="lastName"
                    placeholder="Email"
                    value={recipientInfo.email}
                    onChange={(e) =>
                      handleOccasionInfoChange(e.target.value, "email")
                    }
                    className="rounded-lg border-[1px] border-gray-200 w-full mt-2"
                  />
                </div>
                <div className="col-span-1">
                  <label htmlFor="" className="block text-base">
                    Role Type
                  </label>
                  <select
                    name="Role Type"
                    value={recipientInfo.role}
                    onChange={(e) =>
                      handleOccasionInfoChange(e.target.value, "roleType")
                    }
                    className="rounded-lg border-[1px] border-gray-200 w-full mt-2"
                  >
                    <option value="employee">Employee</option>
                  </select>
                </div>
              </div>
              <div className="text-md mt-8 flex">Groups</div>
              <div className="mt-2 flex items-center">
                {groups.map((group, i) => (
                  <div
                    key={group}
                    className="rounded-lg mx-2 bg-gray-200 text-gray-500 px-2 py-2 h-6 flex items-center"
                  >
                    {group}
                    <button>
                      <img
                        src={images.cross}
                        alt=""
                        className="w-4 h-4 ms-1"
                        onClick={() => deleteGroup(i)}
                      />
                    </button>
                  </div>
                ))}
                <button
                  className="rounded-lg cursor-pointer inline-block"
                  onClick={() => setNewGroup(true)}
                >
                  <img
                    src={images.plus}
                    alt=""
                    className="rounded-lg mx-2 border-2 border-gray-200 p-2"
                  />
                </button>
                {newGroup && (
                  <input
                    type="text"
                    placeholder="New Group"
                    className="rounded-lg border-[1px] border-gray-200 mt-2"
                    onChange={(e) => setNewGroupValue(e.target.value)}
                  />
                )}
              </div>
              <div className="text-md mt-8">Occasions</div>
              {occasions.map((occasion, index) => (
                <div key={index} className="flex pb-3">
                  <div className="flex flex-col">
                    <SearchableDropdown
                      className="rounded-lg"
                      options={allOccassions}
                      defaultValue={occasion.name}
                      editOccasionName={(name: string) =>
                        editOccasionName(index, name)
                      }
                    />
                    <input
                      type="date"
                      className="rounded-lg border-[1px] border-gray-200 mt-2"
                      value={occasion.dateString}
                      onChange={(e) => editOccasionDate(index, e.target.value)}
                    />
                  </div>
                  <button
                    className="border-gray-200 mb-auto pt-5 ps-2"
                    onClick={() => deleteOccasion(index)}
                  >
                    <img src={images.dustbin} className="w-4 h-4" alt="" />
                  </button>
                </div>
              ))}
              <button
                className="inline-flex items-center mt-2"
                onClick={addOccasion}
              >
                <img src={images.plusOrange} alt="" className="w-5 h-5" />
                <span className="ps-2 pt-1 text-orange">Add Occasion</span>
              </button>
            </div>
            <div className="rounded-lg bg-white p-8">
              <div className="text-3xl">Campaign</div>
              <div className="h-80 overflow-scroll">
                <RecipientsTable campaigns={campaigns} />
              </div>
            </div>
          </div>
          <ToastCustomized />
        </div>
      </Main>
    </>
  );
};
