import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../components/giftSelection/Header";
import Api from "../../constants/api";
import images from "../../constants/images";
import { useAppSelector } from "../../redux/hooks";
import CustomNavigate from "../../utils/navigate";

const ThankyouHamper = () => {
  const cartState = useAppSelector((state) => state.cartState);

  const recipientId =
    useSearchParams()[0].get("recid") ||
    cartState.campaignRecipientData?.result.url_id;
  const navigate = new CustomNavigate(useNavigate(), { recipientId });

  const fromName =
    cartState.campaignRecipientData?.result.campaign.messageFromName || "";

  const [referrals, setReferrals] = useState([
    { id: 0, name: "", contact: "" },
  ]);
  const [message, setMessage] = useState(
    `Thank you ${fromName} for this thoughtful gesture! I am touched and grateful. Can't wait to get it!`
  );
  const [touchpoints, setTouchpoints] = useState<Touchpoint[]>([]);
  const [selectedTouchpointIds, setSelectedTouchpointIds] = useState<number[]>(
    []
  );

  const getAllTouchpoints = useCallback(async (recipientId: string) => {
    try {
      const response = await Api.getActiveTouchpoints(recipientId);
      if (response.data) {
        setTouchpoints(response.data);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  }, []);

  useEffect(() => {
    if (recipientId) getAllTouchpoints(recipientId);
  }, [getAllTouchpoints, recipientId]);

  const messsageRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    setTimeout(() => {
      messsageRef.current?.focus();
    }, 500);
  }, []);

  return (
    <div>
      <Header />
      <div className="h-[90vh] flex relative justify-center text-center">
        <img
          className="absolute top-[10vh] max-md:hidden"
          src={images.thankyou}
          alt=""
        />
        <img
          className="absolute top-[10vh] md:hidden max-md:top-[40vh]"
          src={images.thankyouSm}
          alt=""
        />
        <div className="max-sm:mt-3 mt-14 z-10">
          <div className="my-6 font-leagueSpartan-500 text-orange text-[56px] tracking-[1.45px]">
            Before you go...
          </div>
          <div className="my-6 font-leagueSpartan-300">
            Confirmation will be sent to your email.
          </div>
          <div className="m-auto mt-10 bg-neutrals-200 text-center max-sm:w-[90%] w-[600px] p-2 rounded">
            <div className="font-leagueSpartan-400 text-[24px] text-neutrals-900">
              Send a thank you note to {fromName}
            </div>
            <div className="font-leagueSpartan-400 text-md text-neutrals-700 mt-2">
              Show your appreciation to your sender with a message - it won't
              take long!
            </div>
            <div className="relative mt-3 m-2 bg-yellow h-72 flex">
              <textarea
                className="w-[50%] h-[100px] m-auto text-neutrals-900 max-sm:text-[16px] text-[18px] font-leagueSpartan-400 border border-yellow resize-none bg-yellow text-center rounded-xl "
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                ref={messsageRef}
              />

              <img
                src={images.flowers}
                className="absolute bottom-2 left-0 max-sm:w-[90px]"
                alt=""
              />
              <img
                src={images.smilieYellow}
                className="absolute bottom-4 right-4 max-sm:w-[70px]"
                alt=""
              />
            </div>
            <div className="text-left">
              {touchpoints.length > 0 &&
                touchpoints.map((touchpoint: Touchpoint) => (
                  <div className="flex bg-white rounded-xl p-2 my-5">
                    <div className="my-auto px-2">
                      <input
                        className="border-neutral-200"
                        type="checkbox"
                        value={touchpoint.id}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedTouchpointIds((prev: any) => [
                              ...prev,
                              touchpoint.id,
                            ]);
                          } else {
                            setSelectedTouchpointIds((prev) =>
                              prev.filter((id) => id !== +e.target.value)
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="ps-4 py-2">
                      <div>{touchpoint.title}</div>
                      <div className="text-sm text-neutrals-900">
                        {touchpoint.description}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {/* TODO: Add referral section for hamper */}
            {/* <ReferralSection
              referrals={referrals}
              setReferrals={setReferrals}
            /> */}
            <div className="my-4 flex justify-center">
              <button
                className="mx-2 p-2 px-4 rounded-lg text-sm font-leagueSpartan-400 border border-orange text-orange"
                onClick={() => {
                  navigate.to(
                    "/hamper/gift-selection/thank-you-notes",
                    undefined,
                    { skipped: true }
                  );
                }}
              >
                Skip for now
              </button>
              <button
                className="mx-2 p-2 px-4 rounded-lg text-sm font-leagueSpartan-400 bg-orange text-white"
                onClick={() => {
                  navigate.to(
                    "/hamper/gift-selection/thank-you-notes",
                    undefined,
                    { skipped: false }
                  );
                }}
              >
                Send note
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankyouHamper;
