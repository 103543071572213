import images from "../constants/images";
import { Voucher } from "../types/vouchers";

export const populateHeader = (
  groupedProducts: GroupedProduct[],
  vouchers: Voucher[]
) => {
  const productCategory: GetCampaignRecipientProductSubCategory[] =
    groupedProducts.map((groupedProduct) => groupedProduct.productCategory);

  let categories = [...productCategory];

  if (vouchers.length > 0) {
    categories.push({
      id: 0,
      name: "Vouchers",
      icon: "🎟️",
      image: images.voucher,
      createdAt: "",
    });
  }

  return categories;
};

export const isShowVoucher = (
  index: number,
  array: GetCampaignRecipientProductSubCategory[],
  voucher: Voucher[]
) => {
  return index === array.length - 1 && voucher.length > 0;
};

export const parseVouchers = (vouchers: Voucher[]): Voucher[] => {
  return vouchers.map(
    (
      {
        name,
        image_1,
        image_2,
        image_3,
        image_4,
        image_5,
        image_6,
        ...voucher
      },
      index
    ) => {
      if (
        [image_1, image_2, image_3, image_4, image_5, image_6].every(
          (image) => !image || image === ""
        )
      ) {
        image_1 = images.defaultVoucher;
      }
      return {
        name: `${name} - $${Math.max(...vouchers[index].denominations)}`,
        image_1,
        image_2,
        image_3,
        image_4,
        image_5,
        image_6,
        ...voucher,
      };
    }
  );
};

export const getVoucherOrderKey = (voucherOrder: VoucherOrder) =>
  `${voucherOrder.voucher.id}-${voucherOrder.denomination}`;
