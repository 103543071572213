import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import images from "../../constants/images";
import CustomNavigate from "../../utils/navigate";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

const CampaignSearch = () => {
  const navigate = new CustomNavigate(useNavigate());
  const dashboardState = useAppSelector((state) => state.dashboardState);
  const campaigns = dashboardState.campaignList;

  const [filteredList, setfilteredList] = useState<CampaignListData[]>([]);

  const [showList, setShowList] = useState(false);

  const ref = useRef<HTMLInputElement>(null);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const searchVal = event.target.value;
      if (searchVal === "") {
        setfilteredList(campaigns);
        return;
      }
      const filtered = campaigns.filter((c) =>
        c.name.toLowerCase().includes(searchVal.toLowerCase())
      );
      setfilteredList(filtered);
    },
    [campaigns]
  );
  useEffect(() => {
    setfilteredList(campaigns);

    return () => {};
  }, [campaigns]);

  return (
    <div
      className="relative flex items-center border border-neutrals-400 bg-white rounded-lg"
      onFocus={() => setShowList(true)}
      onBlur={() => setShowList(false)}
      onMouseEnter={() => setShowList(true)}
      onMouseLeave={(e) => {
        console.log("onMouseLeave");
        console.log(document.activeElement, ref.current);

        if (document.activeElement !== ref.current) setShowList(false);
      }}
    >
      <img src={images.search} alt="" className="m-3" />
      <input
        type="text"
        placeholder="Search"
        className="px-2 outline-none focus:ring-0 border-none rounded-lg font-leagueSpartan-300 h-full w-[250px] text-lg"
        onChange={onChange}
        ref={ref}
      />
      <div
        className={`absolute flex top-[46px] w-full shadow ${
          !showList && "hidden"
        }`}
      >
        <ul className="w-full my-1 p-1 border rounded bg-white max-h-[300px] overflow-scroll">
          {filteredList.map((c, index) => {
            return (
              <li
                key={index}
                className="flex gap-2 font-leagueSpartan-400 my-1.5 hover:bg-neutrals-300 cursor-pointer"
                onClick={() => {
                  navigate.to(
                    "/dashboard/campaigns/:id",
                    {
                      key: ":id",
                      value: index,
                    },
                    c
                  );
                }}
              >
                {" "}
                <img src={c.collectionImage} alt="" className="h-10" /> {c.name}{" "}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CampaignSearch;
