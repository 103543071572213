import { Dispatch, SetStateAction, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../constants/api";
import { ActivationSuccessModal } from "./ActivationSuccessModal";

export const ActivateVoucherModal = ({
  setShowActivateVoucherModal,
  voucherOrders,
  onSuccessDone,
  email,
  setIsActivated,
}: {
  setShowActivateVoucherModal: Dispatch<SetStateAction<boolean>>;
  voucherOrders: VoucherOrder[];
  onSuccessDone: () => void;
  email: string;
  setIsActivated?: (isActivated: boolean) => void;
}) => {
  const [showActivationSuccessModal, setShowActivationSuccessModal] =
    useState(false);
  const recipientUrlId = useSearchParams()[0].get("recid");
  const [isLoading, setIsLoading] = useState(false);
  /**
   *Handles activation success modal opening once yes,activate is clicked
   uses a callback function to close both modals
   */

  const handleActivateClick = () => {
    if (recipientUrlId != null) {
      setIsLoading(true);
      Api.activateVoucher({
        voucherOrderIds: voucherOrders.map((voucherOrder) => voucherOrder.id),
        recipientUrlId,
      })
        .then((status: number) => {
          setIsLoading(false);
          if (status === 200) {
            toast.success(
              "Please kindly check your email for any updates regarding the activation of your voucher."
            );
            onSuccessDone();
            setShowActivationSuccessModal(true);
            if (setIsActivated) {
              setIsActivated(true);
            }
          } else {
            toast.warning("There is an issue with voucher activation");
            setShowActivateVoucherModal(false);
          }
        })
        .catch((error: any) => {
          setShowActivateVoucherModal(false);
          setIsLoading(false);
          toast.warning("There is an issue with activating the vouchers");
        });
    }
  };

  return (
    <div className="font-leagueSpartan-400 fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-md w-96 space-y-2">
        <>
          <div className="text-center text-sm">
            <span>Are you sure you would like to activate the voucher?</span>
          </div>

          <div className="text-center text-sm text-gray-500">
            Please note that this action can not be undone.
          </div>
          <div className="flex justify-around pt-5">
            <div className="space-x-2">
              <button
                className="bg-white text-orange text-sm font-leagueSpartan-400 rounded-lg p-2 px-5 border border-orange hover:border-opacity-70 focus:outline-none focus:border-opacity-70"
                onClick={() => setShowActivateVoucherModal(false)}
              >
                No, Cancel
              </button>
              <button
                className={`text-white text-sm font-leagueSpartan-400 rounded-lg p-2 px-5 ${
                  isLoading ? "bg-neutrals-500" : "bg-orange"
                }`}
                onClick={handleActivateClick}
                disabled={isLoading}
              >
                Yes, activate
              </button>
            </div>
          </div>
          {showActivationSuccessModal && (
            <ActivationSuccessModal
              setShowActivationSuccessModal={setShowActivationSuccessModal}
              onSuccessDone={onSuccessDone}
              email={email}
            />
          )}
        </>
      </div>
    </div>
  );
};
