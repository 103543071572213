import { HTMLProps } from "react";
import { TCampaignValue } from "../../pages/dashboard/CampaignCreate";

import DropdownScheduleSendCustomField from "./DropdownScheduleSendCustomField";
import DropdownScheduleSendTriggerTime from "./DropdownScheduleSendTriggerTime";

type IProps = HTMLProps<HTMLDivElement> & {
  values: TCampaignValue;
  updateValues: (
    value: string | boolean | null,
    key: keyof TCampaignValue
  ) => void;
  customFieldValueMap: {
    [key: string]: number;
  };
};

const ScheduleSend = ({
  values,
  updateValues,
  className,
  customFieldValueMap,
}: IProps) => {
  return (
    <div className={className}>
      <p className="font-leagueSpartan-500 text-[28px] text-neutrals-900 mb-8">
        Schedule to send
      </p>

      <p className=" font-leagueSpartan-400 text-[18px] text-neutrals-900 mb-8">
        When should we send the gifts?
      </p>

      <div className="flex flex-col">
        <div className="flex items-center mb-4">
          <div className="font-leagueSpartan-400 text-[18px] text-neutrals-900 mr-4 w-48">
            Please select an option
          </div>
          <DropdownScheduleSendTriggerTime
            value={values.recipientCustomFieldTriggerTime}
            setRecipientTriggerTime={(value) =>
              updateValues(value, "isRecurring")
            }
          />
        </div>
        <div className="flex items-center ">
          <div className="font-leagueSpartan-400 text-[18px] text-neutrals-900 mr-4 w-48">
            What's the occasion?
          </div>
          <DropdownScheduleSendCustomField
            value={values.recipientCustomField}
            customFieldValueMap={customFieldValueMap}
            setRecipientCustomField={(value) => {
              updateValues(value, "recipientCustomField");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduleSend;
