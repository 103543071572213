type propType = {
  date: any;
  email: string;
};

const ClaimedVoucherFooter = (props: propType) => {
  const { date, email } = props;
  return (
    <div>
      <p className="font-leagueSpartan-400 text-[18px] text-neutrals-700">
        Date of Activation : {date}
      </p>
      <p className="font-leagueSpartan-400 text-[18px] text-neutrals-700">
        Voucher sent to {email}
      </p>
    </div>
  );
};

export default ClaimedVoucherFooter;
