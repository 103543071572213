import images from "../../constants/images";

export const ProductCard = ({
  product,
  isSelected,
  displayQuickViewModal,
  updateCart,
}: {
  product: any;
  updateCart: () => void;
  isSelected: boolean;
  displayQuickViewModal: (
    e: React.MouseEvent<HTMLDivElement>,
    productId: number
  ) => void;
}) => {
  return (
    <div
      className="border rounded-lg p-4 flex flex-col items-center cursor-pointer max-w-xs font-leagueSpartan"
      onClick={(e) => {
        e.stopPropagation();
        displayQuickViewModal(e, product.id);
      }}
    >
      <img
        src={product.image_1}
        alt={product.name}
        className="w-full h-48 object-cover mb-4 rounded"
      />
      <div className="text-md mb-1 line-clamp-2">{product.name}</div>
      <div className="flex w-full items-center mt-auto">
        <div className="flex-1 text-gray-600 text-sm">${product.listPrice.toFixed(2)}</div>
        <button
          className={`px-4 py-2 rounded flex-none w-1/2 ${isSelected ? 'bg-white text-black border border-black' : 'bg-orange-500 text-white'}`}
          onClick={(e) => {
            e.stopPropagation();
            updateCart();
          }}
        >
          {isSelected ? <><span>&#10003;</span> Added</> : "Add"}
        </button>
      </div>
    </div>
  );
};
