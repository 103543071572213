import moment from "moment";

type TRecipientsTable = {
  campaigns: DashboardRecipientDataCampaign[];
};

const tableHeaders = [
  "CAMPAIGN",
  "STATUS",
  "DATE SENT",
  "DATE CLAIMED",
  "ACCOUNT SPENT",
  "GIFT SELECTED",
];

export const RecipientsTable = ({ campaigns }: TRecipientsTable) => {
  return (
    <table className="w-full border">
      <thead className="bg-neutrals-300 sticky top-0">
        <tr>
          {tableHeaders.map((header) => (
            <th
              className="font-leagueSpartan-600 text-[14px] text-neutrals-700"
              key={header}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white">
        {campaigns.map((val, index) => {
          return (
            <tr key={index}>
              <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px] max-w-[200px]">
                {val.camapignName || "-"}{" "}
              </td>
              <td className="text-center">
                <div className="flex text-sm font-leagueSpartan-400">
                  <div
                    className={`px-2 mt-1 rounded font-leagueSpartan-500 text-[14px] whitespace-nowrap ${
                      val.status === "Redeemed" && "bg-green-100 text-green-800"
                    }
                                ${
                                  val.status === "Opened" &&
                                  "bg-orange-100 text-orange"
                                }
                                ${
                                  val.status === "Not opened" &&
                                  "bg-red-100 text-red-800"
                                }`}
                  >
                    {val.status}
                  </div>
                </div>
              </td>
              <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px] ">
                {val.dateSent
                  ? moment(val.dateSent).format("DD MMM YYYY, hh:mm")
                  : "-"}
              </td>
              <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px] ">
                {val.dateClaimed
                  ? moment(val.dateClaimed).format("DD MMM YYYY, hh:mm")
                  : "-"}
              </td>
              <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px]  ">
                ${val.totalSpent}
              </td>
              <td className="text-neutrals-900 font-leagueSpartan-300 text-[16px] max-w-[200px]">
                {val.giftSelected || "-"}{" "}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
