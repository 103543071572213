import { useCallback, useEffect, useState } from "react";
import Header from "../../components/giftSelection/Header";
import images from "../../constants/images";
import { useAppSelector } from "../../redux/hooks";
import moment from "moment";

const GiftSelected = () => {
  const corpState = useAppSelector((state) => state.corpSliceState);
  const selectGiftState = useAppSelector((state) => state.selectGiftState);
  const icon = corpState.data?.logo || undefined;

  return (
    <div>
      <Header logo={icon} />
      <div className="mt-[8%] p-4 flex flex-col items-center gap-4">
        <div>
          <img src={images.logo} className="w-[156px]" alt="" />
        </div>
        <div className="font-leagueSpartan-600 text-[56px] text-neutrals-900 text-center">
          Gift Already selected
        </div>
        <div className="font-leagueSpartan-500 text-[18px] text-neutrals-600 text-center w-[40%]">
          {`Please look out for an email from ${corpState.data?.email} regarding the collection date and location.`}
        </div>
      </div>
    </div>
  );
};

export default GiftSelected;
