import colours from "../../constants/colours";
import { useAppSelector } from "../../redux/hooks";

type propType = {
  title?: string;
  logo: string;
};

const PreviewHeader = (props: propType) => {
  const { logo, title } = props;
  const selectGiftState = useAppSelector((state) => state.selectGiftState);
  return (
    <div
      className="flex md:flex-row flex-col gap-2 py-2"
      style={{ backgroundColor: colours.darkGrey }}
    >
      {/* Logo */}
      <div className="m-auto p-4">
        <img src={logo} className="h-6 w-12 object-cover" alt="" />
      </div>
      <div className="grow text-white font-leagueSpartan px-4 m-auto text-center">
        {title ?? "This is a preview link - it will need to be activated."}
        Close this tab to return to continue link activation.
        <a
          href={selectGiftState.redirectUrl}
          className="inline-block bg-orange py-2 px-5 grow text-center rounded-full font-leagueSpartan-400 text-sm mx-2"
        >
          Exit preview
        </a>
      </div>
    </div>
  );
};

export default PreviewHeader;
