import { useState, useEffect } from "react";
import Api from "../../../constants/api";
import { Link, useNavigate } from "react-router-dom";

export const AllCharity = () => {
  const navigate = useNavigate();
  const [charity, setCharity] = useState([]);

  useEffect(() => {
    Api.getAllCharity()
      .then((res: any) => {
        console.log(res.data);
        setCharity(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  /**
   * Handle edit
   */

  const handleEdit = (item: any) => {
    navigate("/admin", { state: { item, tab: 7, subTab: 0 } });
  };

  return (
    <>
      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        {charity?.map((item: any) => {
          return (
            <>
              <div style={{ border: "2px solid black", padding: "20px" }}>
                <h1>{item.id}</h1>
                <h2>{item.accountInfo}</h2>
                <h2>{item.name}</h2>
                <img style={{ width: "100px" }} src={item.pic1} />
                <h2>{item.description}</h2>
                <h2>{item.balance}</h2>
                <h2>{item.location}</h2>
                <button
                  type="button"
                  onClick={() => {
                    handleEdit(item);
                  }}
                  style={{
                    backgroundColor: "Red",
                    color: "white",
                    boxShadow: "2px 2px 2px black",
                  }}
                >
                  Edit
                </button>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
