import { useNavigate, useSearchParams } from "react-router-dom";

import classNames from "classnames";
import images from "../../constants/images";
import { useAppSelector } from "../../redux/hooks";
import CustomNavigate from "../../utils/navigate";
import BackButtonGifting from "../giftSelection/BackButton";
import PreviewHeaderHamper from "./PreviewHeaderHamper";
import DeactivatedHeaderHamper from "./DeactivatedHeaderHamper";

type propType = {
  title?: string;
  isPreview?: boolean;
  preViewTitle?: string;
  isDeactivated?: boolean;
};

const HeaderHamper = (props: propType) => {
  const { title, isPreview, preViewTitle, isDeactivated } = props;
  const cartState = useAppSelector((state) => state.cartState);

  const recipientId =
    useSearchParams()[0].get("recid") ||
    cartState.campaignRecipientData?.result.url_id;
  const navigate = new CustomNavigate(useNavigate(), { recipientId });

  const corpState = useAppSelector((state) => state.corpSliceState);
  const logo =
    corpState.data && corpState.data.logo && corpState.data.logo !== ""
      ? corpState.data.logo
      : undefined;

  if (isDeactivated) {
    return <DeactivatedHeaderHamper logo={logo ?? images.logo} />;
  }
  if (isPreview)
    return (
      <PreviewHeaderHamper
        title={preViewTitle}
        logo={logo || isPreview ? images.whiteLogo : images.logo}
      />
    );

  return (
    <div
      className={classNames(
        "fixed",
        "top-0",
        "w-screen",
        "z-10",
        "max-md:grid",
        "max-md:grid-cols-3",
        "md:flex",
        "gap-8",
        "p-3",
        "px-6",
        "shadow-md",
        "bg-white",
        "items-center"
      )}
    >
      {/* Back Button */}
      <div className="md:hidden">
        <BackButtonGifting navigate={navigate} />
      </div>
      {/* Logo */}
      <div>
        <img
          src={logo && logo !== "" ? logo : images.logo}
          className="h-[50px]"
          alt=""
        />
      </div>
      {/* Title */}
      <div
        className={classNames(
          "grow",
          "font-leagueSpartan-400",
          "text-[22px]",
          "max-md:hidden"
        )}
      >
        {title}
      </div>
    </div>
  );
};

export default HeaderHamper;
