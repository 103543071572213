import { useCallback, useEffect, useState } from "react";
import Header from "../../components/giftSelection/Header";
import images from "../../constants/images";
import { useAppSelector } from "../../redux/hooks";
import moment from "moment";

const EventStartingSoon = () => {
  const corpState = useAppSelector((state) => state.corpSliceState);
  const selectGiftState = useAppSelector((state) => state.selectGiftState);
  const icon = corpState.data?.logo || undefined;

  const [time, setTime] = useState([
    { name: "Days", val: "02" },
    { name: "Hours", val: "18" },
    { name: "Minutes", val: "18" },
    { name: "Seconds", val: "18" },
  ]);

  const refreshTimer = useCallback(() => {
    const now = moment();
    const eventTime = moment(
      selectGiftState.campaignRecipientData?.result.campaign.startDate
    );
    const _Seconds = eventTime.diff(now, "seconds");
    const Days = Math.floor(_Seconds / (60 * 60 * 24));
    const Hours = Math.floor((_Seconds % (60 * 60 * 24)) / (60 * 60));
    const Minutes = Math.floor((_Seconds % (60 * 60)) / 60);
    const Seconds = Math.floor(_Seconds % 60);
    setTime([
      { name: "Days", val: Days.toString() },
      { name: "Hours", val: Hours.toString() },
      { name: "Minutes", val: Minutes.toString() },
      { name: "Seconds", val: Seconds.toString() },
    ]);
    setTimeout(() => {
      refreshTimer();
    }, 1000);
  }, [selectGiftState.campaignRecipientData]);

  useEffect(() => {
    refreshTimer();

    return () => {};
  }, [refreshTimer]);

  return (
    <div>
      <Header logo={icon} />
      <div className="mt-[8%] p-4 flex flex-col items-center gap-4">
        <div>
          <img src={images.logo} className="w-[156px]" alt="" />
        </div>
        <div className="font-leagueSpartan-600 text-[56px] text-neutrals-900 text-center">
          Coming Soon
        </div>
        <div className="font-leagueSpartan-500 text-[18px] text-neutrals-600 text-center">
          Get ready for the biggest marketing launch of the year
        </div>
        <div className="grid grid-cols-4 max-md:grid-cols-2 gap-4 mt-16">
          {time.map((v, i) => (
            <div
              className="h-[150px] aspect-square bg-lightOrange p-6 rounded-full flex flex-col items-center bg-gradient-to-b from-[#FFF4EF] to-[#FFE0D2] backdrop-blur-lg"
              key={i}
            >
              <div className="font-leagueSpartan-600 text-[50px] text-neutrals-900 text-center">
                {v.val}
              </div>
              <div>{v.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventStartingSoon;
