import React, { useState } from "react";

import images from "../../constants/images";
import { getFilteredProducts } from "../../utils/campaign.utils";

type propType = {
  quickProduct: number;
  collection: GetAllOccasionWithCollectionTCollection;
  selectedProduct: number;
  setSelectedProduct: React.Dispatch<React.SetStateAction<number>>;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveCollectionModalVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  amountWithoutFee: number;
};
const ProdutDetailsModalCampaign = (props: propType) => {
  const {
    collection,
    quickProduct,
    setModalVisible,
    selectedProduct,
    setSelectedProduct,
    setRemoveCollectionModalVisible,
    amountWithoutFee,
  } = props;

  // const navigate = new CustomNavigate(useNavigate());
  const [selectedTab, setSelectedTab] = useState<number>(1);

  const filteredProducts = getFilteredProducts(collection, amountWithoutFee);

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform rounded-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[909px] min-h-[510px] max-h-[610px]">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-6 rounded-xl">
              {/* Body */}
              <div className="flex justify-between">
                <div className="font-leagueSpartan-500 text-[24px] text-neutrals-800 ">
                  {collection.name}
                </div>
                <button
                  onClick={() => setModalVisible(false)}
                  className="self-start h-7"
                >
                  <img src={images.cross} className="w-5" alt="" />
                </button>
              </div>
              <div className="grid grid-cols-2 mt-4 border-b text-center">
                <div
                  className={`font-leagueSpartan-400 text-[18px] pb-3 cursor-pointer ${
                    selectedTab === 1 &&
                    "border-b border-b-orange text-orange font-leagueSpartan-600"
                  } `}
                  onClick={() => setSelectedTab(1)}
                >
                  Overview
                </div>
                <div
                  className={`font-leagueSpartan-300 text-[18px] pb-3 text-neutrals-900 cursor-pointer ${
                    selectedTab === 2 &&
                    "border-b border-b-orange text-orange font-leagueSpartan-600"
                  }`}
                  onClick={() => setSelectedTab(2)}
                >
                  What’s inside
                </div>
              </div>
              {selectedTab === 1 && (
                <div className="grid grid-cols-2 h-[470px] text-center mt-6 pb-6 min-h-[414px] max-h-[514px]">
                  <div className="">
                    <img
                      src={collection.picture}
                      className="h-full w-full object-cover"
                      alt=""
                    />
                  </div>
                  <div className="p-6 ml-4 shadow rounded-lg flex flex-col justify-between">
                    <p className="text-left font-leagueSpartan-300 text-[18px]">
                      {collection.description}
                    </p>
                    <div className="grid grid-cols-2 gap-2 text-xs mt-4">
                      <button
                        className="bg-white border border-orange rounded-lg font-leagueSpartan-400 text-[16px] tracking-[0.16px] text-orange p-3"
                        onClick={() => setRemoveCollectionModalVisible(true)}
                      >
                        Remove this collection
                      </button>
                      <button
                        className={` ${
                          selectedProduct === quickProduct
                            ? "bg-neutrals-500"
                            : "bg-orange"
                        } rounded-lg font-leagueSpartan-400 text-[16px] tracking-[0.16px] text-white p-3`}
                        onClick={() => {
                          if (selectedProduct === quickProduct) return;
                          setSelectedProduct(quickProduct);
                          setModalVisible(false);
                        }}
                      >
                        Select collecition
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === 2 && (
                <div className="grid grid-cols-3 gap-2 border-b text-center py-4  overflow-auto min-h-[414px] max-h-[514px]">
                  {filteredProducts.map((collectionTag, index) => {
                    return (
                      <div className={`shadow rounded-xl overflow-hidden`}>
                        <div
                          className="cursor-pointer"
                        >
                          <div className="relative w-[100%]  rounded-t-xl overflow-hidden hoverimagezoom">
                            <img
                              src={collectionTag.product.image_1}
                              className="w-full aspect-square object-cover"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="grid gap-4 p-4 py-[12px] px-[16px] md:min-h-[187spx]">
                          <div className="font-leagueSpartan-400 text-[16px] text-neutrals-900 text-left leading-[24px]">
                            {collectionTag.product.name}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProdutDetailsModalCampaign;
