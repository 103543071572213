import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import images from "../../../constants/images";
import CustomNavigate from "../../../utils/navigate";
import Progressbar from "../../common/Progressbar";
import EllipsisPopover from "./EllipsisPopover";
import MaxWhatsappCampaignsErrorModal from "./MaxWhatsappCampaignsErrorModal";
import WhatsappNameModal from "./WhatsappNameModal";

interface CampaignRowProps {
  campaign: any;
  index: number;
  updateCampaign: (
    updatedCampaign: any,
    index: number,
    success?: boolean,
    toastMessage?: string
  ) => void;
  selectedCampaign: any;
  setSelectedCampaign: (campaign: any) => void;
  showOnWhatsappCount: number;
}

const campaignURL = process.env.REACT_APP_GIFTING_URL + "campaign/";

const CampaignRow = ({
  campaign,
  index,
  updateCampaign,
  selectedCampaign,
  setSelectedCampaign,
  showOnWhatsappCount,
}: CampaignRowProps) => {
  const progress =
    campaign.claimedBy && campaign.recipients.length
      ? (campaign.claimedBy / campaign.recipients.length) * 100
      : 0;

  const navigate = new CustomNavigate(useNavigate());
  let start = campaign.startDate
    ? moment(campaign.startDate).format("DD MMM")
    : "No Start Date";
  let end = campaign.endDate
    ? `${moment(campaign.endDate).format("DD MMM YYYY")}`
    : "No End Date";
  const duration = `${start} - ${end}`;

  const [isMaxCampaignsErrorModalVisible, setIsMaxCampaignsErrorModalVisible] =
    useState<boolean>(false);

  const [whatsappNameModalVisibility, setWhatsappNameModalVisibility] =
    useState<boolean>(false);

  const [popover, setPopover] = useState<boolean>(false);
  /**
   * Close the popover on window resize.
   */
  useEffect(() => {
    const handleResize = () => {
      setPopover(false);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setPopover]);

  /**
   * Close the modal when clicking outside of it.
   */
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (popover && !e.target.closest(".modal-container")) {
        setPopover(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [setPopover, popover]);

  /**
   * Handle click on ellipsis button.
   * @param e - The click event.
   */
  const handleEllipsisClick = (e: any) => {
    setSelectedCampaign(campaign);
    setPopover(true);
  };

  const handleToggleWhatsapp = (showOnWhatsapp: boolean) => {
    // If enabling showOnWhatsapp and there are already 3 campaigns on WhatsApp
    if (showOnWhatsapp && showOnWhatsappCount === 3) {
      setIsMaxCampaignsErrorModalVisible(true);
      return;
    }

    // If enabling showOnWhatsapp, prompt modal to set whatsappName
    if (
      showOnWhatsapp &&
      (campaign.whatsappName === null || campaign.whatsappName === "")
    ) {
      setWhatsappNameModalVisibility(true);
    }
    // Else If disabling showOnWhatsapp, set whatsappName to empty string
    else {
      const whatsappName = "";
      handleAxiosRequests(showOnWhatsapp, whatsappName);
    }
  };

  const handleWhatsappNameSubmit = (whatsappName: string) => {
    setWhatsappNameModalVisibility(false);
    handleAxiosRequests(!campaign.showOnWhatsapp, whatsappName);
  };

  const handleAxiosRequests = async (
    showOnWhatsapp: boolean,
    whatsappName: string
  ) => {
    try {
      // Update whatsappName
      const res1 = await axios.patch(campaignURL + campaign.id, {
        whatsappName,
      });

      // Update showOnWhatsapp status
      const res2 = await axios.patch(
        campaignURL + campaign.id + "/whatsappStatus",
        {
          showOnWhatsapp,
        }
      );

      if (res1.data.success === true && res2.data.success === true) {
        const updatedCampaign = { ...campaign, showOnWhatsapp, whatsappName };
        updateCampaign(updatedCampaign, index, true);
      } else {
        updateCampaign(campaign, index, false);
      }
    } catch (error: any) {
      updateCampaign(
        campaign,
        index,
        false,
        `Error updating ${campaign.name}: ${error}`
      );
    }
  };

  return (
    <tr
      className="hover:bg-neutrals-200 cursor-pointer"
      onClick={() =>
        navigate.to(
          "/dashboard/campaigns/:id",
          {
            key: ":id",
            value: campaign.id,
          },
          campaign
        )
      }
    >
      {/* CAMPAIGN DETAILS */}
      <td className="p-4">
        <div className="flex">
          <div className="">
            <img
              src={campaign.collectionImage || images.sample13Square}
              alt=""
              className="h-12 rounded"
            />
          </div>
          <div className="grid grid-flow-row gap-1 ml-4">
            <div className="text-sm text-neutrals-900 font-leagueSpartan-600">
              {campaign.name}
            </div>
            <div className="text-sm text-neutrals-900 font-leagueSpartan-400">
              {`$${campaign.perPersonBudget} per pax`}
            </div>
            {campaign.showOnWhatsapp && (
              <div className="flex items-center">
                <div className="rounded-md flex items-center justify-center text-center bg-emerald-200 w-24 h-5">
                  <span className="text-sm text-green-700 font-leagueSpartan-400">
                    On WhatsApp
                  </span>
                </div>
                <div className="ml-2">
                  <span className="text-sm text-green-700 font-leagueSpartan-400">
                    as {campaign.whatsappName}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </td>
      {/* DURATION */}
      <td className="font-leagueSpartan-400 text-[16px] text-neutrals-900 p-4">
        {duration}
      </td>
      {/* CLAIBMED BY */}
      <td className="grid grid-flow-row gap-2 p-4">
        <div className="flex text-sm font-leagueSpartan-400">
          <div className="font-leagueSpartan-400 flex">
            {`${campaign.claimedBy} / ${campaign.recipients.length} recipients`}
          </div>
        </div>
        <Progressbar progress={progress} />
      </td>
      {/* TOTAL SPENT */}
      <td className="text-neutrals-900 text-sm text-center p-4">{`$${campaign.totalSpent}`}</td>
      {/* ELLIPSIS */}
      <td
        className="p-4"
        onClick={(e) => {
          e.stopPropagation();
          handleEllipsisClick(e);
        }}
      >
        <img src={images.options} alt="" />
        {/* POPOVER */}
        {popover && selectedCampaign === campaign && (
          <EllipsisPopover
            campaign={selectedCampaign}
            handleToggleWhatsapp={handleToggleWhatsapp}
            setPopover={setPopover}
          />
        )}
        {isMaxCampaignsErrorModalVisible && (
          <MaxWhatsappCampaignsErrorModal
            setIsMaxCampaignsErrorModalVisible={
              setIsMaxCampaignsErrorModalVisible
            }
          />
        )}
        {whatsappNameModalVisibility && (
          <WhatsappNameModal
            setWhatsappNameModalVisibility={setWhatsappNameModalVisibility}
            handleWhatsappNameSubmit={handleWhatsappNameSubmit}
          />
        )}
      </td>
    </tr>
  );
};

export default CampaignRow;
