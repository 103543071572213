import React from "react";
type propType = {
  progress: number;
};
const Progressbar = (props: propType) => {
  const { progress } = props;
  return (
    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
      <div
        className={`bg-green-400 h-2.5 rounded-full`}
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default Progressbar;
