import { useState } from "react";
import { CampaignDetailOverview } from "../../pages/dashboard/CampaignDetailOverview";
import { CampaignDetailRecipent } from "../../pages/dashboard/CampaignDetailRecipent";
import { CampaignDetailThanknotes } from "../../pages/dashboard/CampaignDetailThanknotes";

const data = ["Overview", "Recipients", "Thank You Note"];

const CampaignDetailsTabs = () => {
  const [selected, setSelected] = useState(0);
  return (
    <div>
      <div
        className="text-base font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700"
        style={{ marginLeft: "30px", marginRight: "30px" }}
      >
        <ul className="flex flex-wrap -mb-px ">
          {data.map((i, index) => {
            return (
              <li
                className="mr-2 cursor-pointer"
                key={index}
                onClick={() => setSelected(index)}
              >
                <div
                  className={
                    "inline-block p-4 rounded-t-lg border-b-2" +
                    (index === selected
                      ? " text-orange  border-orange active dark:text-orange dark:border-orange"
                      : " border-transparent text-neutral-900 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")
                  }
                  aria-current="page"
                >
                  <div
                    className={
                      "flex justify-star text-[18px] " +
                      (index === selected
                        ? " font-leagueSpartan-600"
                        : " font-leagueSpartan-300")
                    }
                  >
                    {i}{" "}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      {/* Body */}
      <div className="mt-4">
        <div className="flex">
          {selected === 0 && (
            <>
              <div>
                <CampaignDetailOverview />
              </div>
            </>
          )}
        </div>
        <div className="flex">
          {selected === 1 && (
            <>
              <CampaignDetailRecipent />
            </>
          )}
        </div>
        <div className="flex">
          {selected === 2 && (
            <>
              <CampaignDetailThanknotes />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignDetailsTabs;
