import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cartSlice";
import selectGiftReducer from "./selectGiftSlice";
import corpReducer from "./corpSlice";
import globalReducer from "./globalSlice";
import campaignReducer from "./campaignSlice";
import orderReducer from "./orderSlice";
import dashboardSlice from "./dashboardSlice";

const store = configureStore({
  reducer: {
    cartState: cartReducer,
    selectGiftState: selectGiftReducer,
    corpSliceState: corpReducer,
    globalSliceState: globalReducer,
    campaignSliceState: campaignReducer,
    orderState: orderReducer,
    dashboardState: dashboardSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: for updating states
export type AppDispatch = typeof store.dispatch;

export default store;
