import { isAxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import CreateCorpCollection from "../../components/forms/CreateCorpCollection";
import CreateProductOccasionTag from "../../components/forms/CreateProductOccasionTag";
import Api from "../../constants/api";
import { AllAddOn } from "./AddOn/AllAddOn";
import { CreateAddOn } from "./AddOn/CreateAddOn";
import { AllCharity } from "./Charity/AllCharity";
import { CreateCharity } from "./Charity/CreateCharity";
import { Collections } from "./Collection/Collections";
import { CreateCollection } from "./Collection/CreateCollection";
import { Corps } from "./Corps/AllCorps";
import { CreateCorp } from "./Corps/CreateCorp";
import { CompletedJournalEntries } from "./JournalEntries/CompletedJournalEntries";
import { PendingJournalEntries } from "./JournalEntries/PendingJournalEntries";
import { CreateOcassion } from "./Ocassion/CreateOcassion";
import { Ocassions } from "./Ocassion/Ocassions";
import { AllOrders } from "./Orders/AllOrders";
import { CreateProductCat } from "./Product Category/CreateProductCat";
import { ProductCategory } from "./Product Category/ProductCat";
import { CreateProduct } from "./Product/CreateProduct";
import { Products } from "./Product/Products";

const tabs = [
  "Occasion",
  "Collection",
  "Product Category",
  "Product",
  "Product Collection Tags",
  "AllOrders",
  "Corps",
  "Charity",
  "Occasion Collection Link",
  "Corp Collection",
  "AddOn",
  "Journal Entries",
];

const Forms = () => {
  const location = useLocation();
  const successMessage = () => toast("Success!");
  const errorMessage = (error?: string) => toast.error(error || "error!");

  const [selectedTab, setSelectedTab] = useState(0);
  const [subTab, setSubTab] = useState(0);

  const [collectionList, setCollectionList] = useState<CollectionList[]>([]);
  const [productList, setProductList] = useState<GetAllProductData[]>([]);

  const getCollections = useCallback(() => {
    Api.getAllCollection().then((res) => {
      setCollectionList(res.data);
    });
  }, []);

  const getProducts = useCallback(() => {
    Api.getAllProducts().then((res) => {
      setProductList(res.data);
    });
  }, []);

  useEffect(() => {
    getCollections();
    getProducts();

    if (location.state) {
      setSelectedTab(location?.state?.tab);
      setSubTab(location?.state?.subTab);
    }
  }, [getCollections, getProducts, location.state]);

  return (
    <div>
      <div className="flex border border-b-orange">
        {tabs.map((val, i) => (
          <button
            key={i}
            className={`${selectedTab === i && "bg-lightOrange"} p-2 border`}
            onClick={() => setSelectedTab(i)}
          >
            {val}
          </button>
        ))}
      </div>

      {selectedTab === 0 && (
        <>
          <div className="flex border border-b-orange">
            <button
              style={{
                border: "2px solid black",
                marginRight: "3px",
                backgroundColor: "#FFD580",
              }}
              onClick={() => {
                setSubTab(0);
              }}
            >
              Create Ocassion
            </button>
            <button
              style={{ border: "2px solid black", backgroundColor: "#FFD580" }}
              onClick={() => {
                setSubTab(1);
              }}
            >
              All Ocassion
            </button>
          </div>
          {subTab === 0 ? (
            <CreateOcassion
              updateData={location?.state?.item}
              tabNumber={location?.state?.tab}
              currentTab={selectedTab}
            />
          ) : (
            <Ocassions />
          )}
        </>
      )}

      {selectedTab === 1 && (
        <>
          <div className="flex border border-b-orange">
            <button
              style={{
                border: "2px solid black",
                marginRight: "3px",
                backgroundColor: "#FFD580",
              }}
              onClick={() => {
                setSubTab(0);
              }}
            >
              Create Collection
            </button>
            <button
              style={{ border: "2px solid black", backgroundColor: "#FFD580" }}
              onClick={() => {
                setSubTab(1);
              }}
            >
              All Collection
            </button>
          </div>
          {subTab === 0 ? (
            <CreateCollection
              updateData={location?.state?.item}
              tabNumber={location?.state?.tab}
              currentTab={selectedTab}
            />
          ) : (
            <Collections />
          )}
        </>
      )}

      {selectedTab === 2 && (
        <>
          <div className="flex border border-b-orange">
            <button
              style={{
                border: "2px solid black",
                marginRight: "3px",
                backgroundColor: "#FFD580",
              }}
              onClick={() => {
                setSubTab(0);
              }}
            >
              Create Product Category
            </button>
            <button
              style={{ border: "2px solid black", backgroundColor: "#FFD580" }}
              onClick={() => {
                setSubTab(1);
              }}
            >
              All Product Category
            </button>
          </div>
          {subTab === 0 ? (
            <CreateProductCat
              updateData={location?.state?.item}
              tabNumber={location?.state?.tab}
              currentTab={selectedTab}
            />
          ) : (
            <ProductCategory />
          )}
        </>
      )}

      {selectedTab === 3 && (
        <>
          <div className="flex border border-b-orange">
            <button
              style={{
                border: "2px solid black",
                marginRight: "3px",
                backgroundColor: "#FFD580",
              }}
              onClick={() => {
                setSubTab(0);
                if (location?.state) location.state.item = null;
              }}
            >
              Create Product
            </button>
            <button
              style={{ border: "2px solid black", backgroundColor: "#FFD580" }}
              onClick={() => {
                setSubTab(1);
              }}
            >
              All Product
            </button>
          </div>
          {subTab === 0 ? (
            <CreateProduct
              updateData={location?.state?.item}
              tabNumber={location?.state?.tab}
              currentTab={selectedTab}
            />
          ) : (
            <Products />
          )}
        </>
      )}

      {selectedTab === 4 && (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            // @ts-ignore
            const collectionId = +e.target[0].value;
            const productIds: number[] = [];
            productList.forEach((v, i) => {
              // @ts-ignore
              const checked = e.target[i + 1].checked;
              if (checked) productIds.push(v.id);
            });
            const data = { collectionId, productIds };

            Api.createProductCollectionTag(data)
              .then((res) => {
                if (isAxiosError(res))
                  errorMessage(res.response?.data.message || "Invalid data!");
                else successMessage();
              })
              .catch((err) => {
                console.log(err);
                errorMessage();
              });
          }}
          className="m-4 grid grid-flow-row max-w-md"
        >
          <div className="text-lg text-orange">Product Collection Tags</div>
          <div className="mt-2">
            <label htmlFor="Collection" className="mr-2">
              Collection:
            </label>
            <select
              name="Collection"
              id="Collection"
              className="border rounded-lg"
            >
              {collectionList.map((val, i) => (
                <option key={i} value={val.id}>
                  {val.name}
                </option>
              ))}
            </select>
          </div>

          <label htmlFor="Collection" className="mr-2">
            Products:
          </label>
          {productList.map((val, i) => (
            <div>
              <input
                type="checkbox"
                name="products"
                value={val.id}
                className="border rounded-lg my-4 mr-2"
              />
              {val.name}
            </div>
          ))}

          <button
            className="border rounded-lg p-2 bg-lightOrange mt-2"
            type="submit"
          >
            Submit
          </button>
        </form>
      )}

      {selectedTab === 5 && <AllOrders />}
      {selectedTab == 6 && (
        <>
          <div className="flex border border-b-orange">
            <button
              style={{
                border: "2px solid black",
                marginRight: "3px",
                backgroundColor: "#FFD580",
              }}
              onClick={() => {
                setSubTab(0);
              }}
            >
              Create Corp
            </button>
            <button
              style={{ border: "2px solid black", backgroundColor: "#FFD580" }}
              onClick={() => {
                setSubTab(1);
              }}
            >
              All Corp
            </button>
          </div>
          {subTab === 0 ? (
            <CreateCorp
              updateData={location?.state?.item}
              tabNumber={location?.state?.tab}
              currentTab={selectedTab}
            />
          ) : (
            <Corps />
          )}
        </>
      )}
      {selectedTab == 7 && (
        <>
          <div className="flex border border-b-orange">
            <button
              style={{
                border: "2px solid black",
                marginRight: "3px",
                backgroundColor: "#FFD580",
              }}
              onClick={() => {
                setSubTab(0);
              }}
            >
              Create Charity
            </button>
            <button
              style={{ border: "2px solid black", backgroundColor: "#FFD580" }}
              onClick={() => {
                setSubTab(1);
              }}
            >
              All Charity
            </button>
          </div>
          {subTab === 0 ? (
            <CreateCharity
              updateData={location?.state?.item}
              tabNumber={location?.state?.tab}
              currentTab={selectedTab}
            />
          ) : (
            <AllCharity />
          )}
        </>
      )}
      {selectedTab === 8 && <CreateProductOccasionTag />}
      {selectedTab === 9 && <CreateCorpCollection />}
      {selectedTab == 10 && (
        <>
          <div className="flex border border-b-orange">
            <button
              style={{
                border: "2px solid black",
                marginRight: "3px",
                backgroundColor: "#FFD580",
              }}
              onClick={() => {
                setSubTab(0);
              }}
            >
              Create Add On
            </button>
            <button
              style={{ border: "2px solid black", backgroundColor: "#FFD580" }}
              onClick={() => {
                setSubTab(1);
              }}
            >
              All Add On
            </button>
          </div>
          {subTab === 0 ? (
            <CreateAddOn
              updateData={location?.state?.item}
              tabNumber={location?.state?.tab}
              currentTab={selectedTab}
            />
          ) : (
            <AllAddOn />
          )}
        </>
      )}
      {selectedTab == 11 && (
        <>
          <div className="flex border border-b-orange">
            <button
              style={{
                border: "2px solid black",
                marginRight: "3px",
                backgroundColor: "#FFD580",
              }}
              onClick={() => {
                setSubTab(0);
              }}
            >
              Pending
            </button>
            <button
              style={{ border: "2px solid black", backgroundColor: "#FFD580" }}
              onClick={() => {
                setSubTab(1);
              }}
            >
              Completed
            </button>
          </div>
          {subTab === 0 ? (
            <PendingJournalEntries
              updateData={location?.state?.item}
              tabNumber={location?.state?.tab}
              currentTab={selectedTab}
            />
          ) : (
            <CompletedJournalEntries
              updateData={location?.state?.item}
              tabNumber={location?.state?.tab}
              currentTab={selectedTab}
            />
          )}
        </>
      )}

      <ToastContainer />
    </div>
  );
};

export default Forms;
