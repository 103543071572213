import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

// Define the initial state using that type
const initialState: {
  isLoading: boolean;
  isAuthenticated?: boolean;
  authData?: LoginResData;
} = { isLoading: false };

export const globalSlice = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setAccessData: (state, action: PayloadAction<LoginResData | undefined>) => {
      state.authData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoading, setIsAuthenticated, setAccessData } =
  globalSlice.actions;

export const selectCount = (state: RootState) => state.cartState;

export default globalSlice.reducer;
