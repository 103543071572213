import moment from "moment";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import Api from "../../../constants/api";
import images from "../../../constants/images";
import JwtService from "../../../service/jwtService";

export const PendingJournalEntries: React.FC<any> = (props) => {
  const token = useMemo(() => JwtService.getAccessToken(), []);
  const [filterInput, setFilterInput] = useState<JournalEntryFilterInput>({
    status: ["pending"],
    startDate: moment().subtract(1, "month").toDate(),
    endDate: new Date(),
    recentFirst: true,
  });
  const [entries, setEntries] = useState<JournalEntry[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const enteredCostRef = useRef<number>(0);

  const onStartChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilterInput((prev) => ({
        ...prev,
        startDate: new Date(event.target.value),
      }));
    },
    [setFilterInput]
  );

  const onEndChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilterInput((prev) => ({
        ...prev,
        endDate: new Date(event.target.value),
      }));
    },
    [setFilterInput]
  );

  const onActualCost = useCallback(
    (id: number) => {
      if (token)
        Api.updateJournalEntry(token, id, enteredCostRef.current)
          .then(() => Api.filterJournalEntries(token, filterInput))
          .catch((err) =>
            toast.error(err.response?.data.message ?? err.message)
          )
          .finally(() => setIsModalOpen(false));
    },
    [token, enteredCostRef.current]
  );

  useEffect(() => {
    if (token) Api.filterJournalEntries(token, filterInput).then(setEntries);
  }, [token, Api, filterInput]);

  return (
    <div className="p-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Start Date:
          </label>
          <input
            type="date"
            value={moment(filterInput.startDate).format("YYYY-MM-DD")}
            onChange={onStartChange}
            className="mt-1 p-2 border rounded-md w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            End Date:
          </label>
          <input
            type="date"
            value={moment(filterInput.endDate).format("YYYY-MM-DD")}
            onChange={onEndChange}
            className="mt-1 p-2 border rounded-md w-full"
          />
        </div>
      </div>

      <table className="w-full border-collapse border border-gray-500">
        <thead className="bg-gray-200">
          <tr>
            <th>ID</th>
            <th>Description</th>
            <th>Status</th>
            <th>Budget</th>
            <th>Actual Cost</th>
            <th>Refund</th>
            <th>Delivery</th>
            <th>Add Ons</th>
            <th>Order ID</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {entries &&
            entries.map((entry) => (
              <tr key={entry.id} className="border border-gray-500">
                <td className="p-2">{entry.id}</td>
                <td className="p-2">{entry.description}</td>
                <td className="p-2">{entry.status}</td>
                <td className="p-2">{entry.budgetAmount}</td>
                <td className="p-2">{entry.actualCost}</td>
                <td className="p-2">{entry.refundAmount}</td>
                <td className="p-2">{entry.deliveryFee}</td>
                <td className="p-2">{entry.addOnsAmount}</td>
                <td>{entry.consolidatedOrder?.id || "-"}</td>
                <td className="p-2 text-xs">
                  {moment(entry.createdAt).format("LLL")}
                </td>
                <td className="p-2 relative">
                  <img
                    src={images.options}
                    alt="Ellipsis"
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsModalOpen(true)}
                  />
                  {isModalOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                      <div className="bg-white p-4 rounded">
                        <div className="mb-4">Enter Actual Cost</div>
                        <input
                          type="number"
                          placeholder="Enter Actual Cost"
                          onChange={(e) =>
                            (enteredCostRef.current = Number(e.target.value))
                          }
                          className="p-2 mb-2 border border-gray-500"
                        />
                        <div className="flex justify-end">
                          <button
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                            onClick={() => {
                              onActualCost(entry.id);
                            }}
                          >
                            Submit
                          </button>
                          <button
                            className="px-4 py-2 bg-gray-500 text-white rounded"
                            onClick={() => setIsModalOpen(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
