import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface IProps {
  placeholder?: string;
  value?: any;
  onChangeProp?: (val: any) => void;
}

const TextEditor = ({ placeholder, value, onChangeProp }: IProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const preventValuePopulateRef = useRef<boolean>(false);

  const setValue = (value?: string) => {
    if (value) {
      const blocksFromHTML = convertFromHTML(value);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  };

  useEffect(() => {
    if (preventValuePopulateRef.current) return;
    setValue(value);
  }, [value]);

  const onChange = (editorState: EditorState) => {
    if (onChangeProp) {
      preventValuePopulateRef.current = true;
      const contentState = editorState.getCurrentContent();
      onChangeProp?.(stateToHTML(contentState));
    }
    setEditorState(editorState);
  };

  return (
    <div
      className={
        "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block max-w-md p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-60 overflow-y-auto"
      }
    >
      <Editor
        placeholder={placeholder ?? "Enter here."}
        editorState={editorState}
        onEditorStateChange={(state: any) => onChange(state)}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "link",
            "emoji",
            "colorPicker",
            "history",
          ],
          list: { options: ["unordered", "ordered"] },
          link: { options: ["link"] },
        }}
      />
    </div>
  );
};

export default TextEditor;
