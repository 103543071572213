import React from "react";

interface EllipsisPopoverProps {
  campaign: any;
  handleToggleWhatsapp: (showOnWhatsapp: boolean) => void;
  setPopover: (show: boolean) => void;
}

const EllipsisPopover: React.FC<EllipsisPopoverProps> = ({
  campaign,
  handleToggleWhatsapp,
  setPopover,
}) => {
  return (
    <>
      <div
        className="absolute inline-block shadow bg-white hover:bg-gray-100  rounded border border-gray-300 p-3 whitespace-nowrap font-leagueSpartan z-10 -translate-x-full"
        onClick={(e) => {
          e.stopPropagation();
          setPopover(false);
          handleToggleWhatsapp(!campaign.showOnWhatsapp);
        }}
      >
        {campaign.showOnWhatsapp ? "Remove from WhatsApp" : "Show on WhatsApp"}
      </div>
    </>
  );
};

export default EllipsisPopover;
