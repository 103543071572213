import { useNavigate } from "react-router-dom";

import images from "../../constants/images";
import { useAppSelector } from "../../redux/hooks";
import JwtService from "../../service/jwtService";
import CustomNavigate from "../../utils/navigate";
import { formatCurrency } from "../common/Currency";

type propType = {
  title?: string;
  logo?: boolean;
  children?: JSX.Element;
};

const Header = (props: propType) => {
  const { logo, title, children } = props;
  const navigate = new CustomNavigate(useNavigate());

  const corp = useAppSelector((state) => state.corpSliceState);
  const balance = corp.data?.balance || 0;

  return (
    <div className="shadow bg-white p-3 px-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {logo && <img src={images.logo} className="mr-4  h-[50px]" alt="" />}
          {children}
          <div className="text-[24px] text-neutrals-800 font-leagueSpartan-400 ml-2">
            {title}
          </div>
        </div>
        <div className="grid grid-flow-col">
          <button
            className="flex bg-neutrals-200 rounded-lg px-2 p-2 mr-2"
            onClick={() => {
              navigate.to("/wallet-transaction")
            }}
          >
            <img src={images.questionMark} className="h-[18px]" alt="" />
            <div className="font-leagueSpartan-400  text-[16px] ml-2 text-neutrals-700">
              Your Wallet Balance (S${formatCurrency(balance) || "0"})
            </div>
          </button>
          <button
            className="p-2 px-4 rounded-lg font-leagueSpartan-400 text-sm hover:bg-orange hover:text-white"
            onClick={() => {
              JwtService.logout();
              navigate.to("/auth");
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
