import { useState } from "react";

import { EngagementReferrals } from "../../pages/dashboard/Engagment/EngagementReferrals";
import { Touchpoints } from "../../pages/dashboard/Engagment/Touchpoints";

const tabs = ["Touchpoints", "Referrals"];

const EngagementTabs = () => {
  const [selected, setSelected] = useState(0);

  return (
    <div>
      <div className="text-base mx-[30px] mr- font-medium text-center text-gray-500 border-b border-none dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px ">
          {tabs.map((tab, index) => {
            return (
              <li
                className="mr-2 cursor-pointer"
                key={index}
                onClick={() => setSelected(index)}
              >
                <div
                  className={
                    "inline-block p-4 rounded-t-lg border-b-2 border-gray-200 " +
                    (index === selected
                      ? " text-orange  border-orange active dark:text-orange dark:border-orange"
                      : "text-neutral-900  hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")
                  }
                  aria-current="page"
                >
                  <div
                    className={
                      "flex justify-star text-[18px] " +
                      (index === selected
                        ? " font-leagueSpartan-600"
                        : " font-leagueSpartan-300")
                    }
                  >
                    {tab}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      {/* Body */}
      <div className="mt-4">
        <div className="flex">{selected === 0 && <Touchpoints />}</div>
        <div className="flex">{selected === 1 && <EngagementReferrals />}</div>
      </div>
    </div>
  );
};

export default EngagementTabs;
