import images from "../../constants/images";
import { DetailedHTMLProps } from "react";

type propType = DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const BackButton = (props: propType) => {
  return (
    <button
      {...props}
      className="flex items-center text-orange hover:bg-slate-100 rounded-md p-1.5 px-3"
    >
      <img src={images.leftArrow} alt="" />
      <div className="ml-2">Back</div>
    </button>
  );
};

export default BackButton;
