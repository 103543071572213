import axios, { isAxiosError } from "axios";
import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import Api from "../../constants/api";
import { toast, ToastContainer } from "react-toastify";

interface MyComponentProps {
  getAzureImageUrl: Function;
  updateImgUrl: string;
  tabNumber: number;
  currentTab: number;
}
export const ImageUploader: React.FC<MyComponentProps> = ({
  getAzureImageUrl,
  updateImgUrl,
  tabNumber,
  currentTab,
}) => {
  const successMessage = () => toast("Success!");
  const errorMessage = (error?: string) => toast.error(error || "error!");
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  //this imageAzureUrl will be sent in backend.
  const [imageAzureUrl, setImageAzureUrl] = useState<string>("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [updatingImgUrl, setUpdatingImageUrl] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleImageChange = (event: any) => {
    const file = event.target.files?.[0];

    if (
      selectedImage !== null ||
      imageAzureUrl !== "" ||
      imagePreview !== null ||
      updatingImgUrl !== null
    ) {
      handleClearImage();
      handleTempClear();
    }
    // if (file) {
    setSelectedImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImagePreview(reader.result as string);
    };
    // }
    // else {
    //   // User clicked "Cancel" or selected no file
    //   setSelectedImage(null);
    //   setImagePreview(null);
    // }
  };

  useEffect(() => {}, [selectedImage]);

  const uploadImageToAzure = async (e: any) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("file", selectedImage as File);
      Api.uploadingImgToAzure(formData).then((res) => {
        if (isAxiosError(res)) {
          errorMessage(res.response?.data.message || "Invalid data!");
        } else {
          successMessage();
          setImageAzureUrl(res?.data);

          /**This function here passes the link to the parent form component */
          getAzureImageUrl(res?.data);
        }
      });
    } catch (error) {
      console.log(error);
      errorMessage();
    }
  };

  const handleClearImage = () => {
    if (imageAzureUrl) {
      deleteImageFromAzure();
      return;
    }
  };

  const deleteImageFromAzure = async () => {
    try {
      console.log(imageAzureUrl, "image");
      Api.deletingImgFromAzure(imageAzureUrl).then((res) => {
        if (isAxiosError(res)) {
          errorMessage(res.response?.data?.message || "Invalid data!");
        } else {
          successMessage();
        }
      });
    } catch (error) {
      console.log(error);
      errorMessage();
    }
  };

  /**
   * handle temp clear
   *
   */

  const handleTempClear = () => {
    setUpdatingImageUrl(null);
    setImageAzureUrl("");
  };
  useEffect(() => {
    if (updateImgUrl && tabNumber === currentTab) {
      setImageAzureUrl(updateImgUrl);
      setUpdatingImageUrl(updateImgUrl);
    } else if (updateImgUrl && tabNumber === currentTab) {
      setImageAzureUrl(updateImgUrl);
      setUpdatingImageUrl(updateImgUrl);
    } else if (updateImgUrl && tabNumber === currentTab) {
      setImageAzureUrl(updateImgUrl);
      setUpdatingImageUrl(updateImgUrl);
    }
  }, [updateImgUrl]);

  return (
    <div style={{ marginTop: "1rem" }}>
      <input
        type="file"
        multiple={true}
        ref={inputRef}
        onChange={handleImageChange}
        accept="image/*"
      />
      {imagePreview && (
        <div style={{ display: "flex", alignItems: "center", margin: "1rem" }}>
          <img src={imagePreview} alt="Preview" style={{ maxWidth: "300px" }} />
        </div>
      )}
      {/* The below code will work when the image will also be updated */}
      {updatingImgUrl && (
        <div style={{ display: "flex", alignItems: "center", margin: "1rem" }}>
          <img
            src={updatingImgUrl}
            alt="Preview"
            style={{ maxWidth: "300px" }}
          />
        </div>
      )}

      {selectedImage &&
      (imageAzureUrl === "" || imageAzureUrl === undefined) ? (
        <button
          type="button"
          onClick={uploadImageToAzure}
          style={{
            backgroundColor: "green",
            color: "white",
            padding: "0.5rem",
            marginTop: "1rem",
          }}
        >
          Upload
        </button>
      ) : (
        ""
      )}
      {imageAzureUrl ? (
        <h2 style={{ color: "green" }}>Image uploaded successfully</h2>
      ) : (
        ""
      )}
    </div>
  );
};
