import moment from "moment";
import React, { useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import images from "../../constants/images";
import DashboardDatePickerModel from "../common/DashboardDatePickerModel";

type propType = {
  datePickerModel: boolean;
  setDatePickerModel: React.Dispatch<React.SetStateAction<boolean>>;
};

const DashboardDateSelect = (props: propType) => {
  const { datePickerModel, setDatePickerModel } = props;
  const dashboardState = useAppSelector((state) => state.dashboardState);
  const from = moment(dashboardState.data.fromDate).format("DD MMM");
  const to = moment(dashboardState.data.toDate).format("DD MMM YYYY");

  const daysDiff =
    moment(dashboardState.data.toDate).diff(
      moment(dashboardState.data.fromDate),
      "days"
    ) + 1;
  return (
    <>
      <div
        className="flex items-center border border-neutrals-400 bg-white rounded-lg p-3 cursor-pointer"
        onClick={() => setDatePickerModel(true)}
      >
        <img src={images.calendar} alt="" />
        <div className="ml-3 font-leagueSpartan-300 text-[16px]">
          <span className="font-leagueSpartan-600">
            Last {daysDiff} {daysDiff < 2 ? "Day" : "Days"}
          </span>
          : {from} - {to}
        </div>
      </div>
      {datePickerModel && (
        <DashboardDatePickerModel setmodel={setDatePickerModel} />
      )}
    </>
  );
};

export default DashboardDateSelect;
