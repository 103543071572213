import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../redux/globalSlice";
import Api from "../../constants/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import JwtService from "../../service/jwtService";
import CustomNavigate from "../../utils/navigate";
import { useAppSelector } from "../../redux/hooks";
import { toast } from "react-toastify";
import { getCallbackURL } from "../../utils/callbackUrl";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const verificationCode = searchParams.get("verificationCode");
  const callback = searchParams.get("callback");
  const globalState = useAppSelector((state) => state.globalSliceState);
  const navigate = new CustomNavigate(useNavigate());
  const dispatch = useDispatch();

  const [apiCalled, setApiCalled] = useState(false);

  useEffect(() => {
    if (globalState.isAuthenticated === undefined || apiCalled) {
      return;
    }
    if (globalState.isAuthenticated === true) {
      navigate.to("/dashboard");
      JwtService.logout();
      return;
    }
    dispatch(setIsLoading(true));
    if (verificationCode) {
      setApiCalled(true);
      Api.verifyEmail(verificationCode)
        .then(async (res) => {
          dispatch(setIsLoading(false));
          console.log(res);
          await JwtService.signInWithToken(res.data.tokens.access_token);
          if (callback === "whatsapp") {
            Api.getSubscription()
              .then(({ data }) => {
                if (data.active) {
                  // Already has an active subscription
                  return getCallbackURL(callback);
                }
                // Generate a subscription payment link
                return Api.getStripeCheckoutUrl().then(({ data }) => {
                  return data.checkoutUrl;
                });
              })
              .then((redirectUrl) => {
                window.location.href = redirectUrl;
              });
          } else {
            navigate.to("/dashboard");
            return;
          }
        })
        .catch((err) => {
          toast.error("Unauthorized!");
          setTimeout(() => {
            dispatch(setIsLoading(false));
            navigate.to("/auth");
          }, 500);
        });
    }
  }, [dispatch, globalState.isAuthenticated, verificationCode, apiCalled]);

  return <div></div>;
};

export default VerifyEmail;
