export const imagePath = "/images/";
export const imageIconPath = "/images/icons/";
const images = {
  logo: imagePath + "logo.svg",
  dropdownIcon: imageIconPath + "dropdownIcon.svg",
  whiteLogo: imagePath + "whiteLogo.svg",
  gift: imagePath + "gift.svg",
  redGiftBox: imagePath + "redGiftBox.png",
  pinkGiftBoxes: imagePath + "pinkGiftBoxes.png",
  infoButton: imageIconPath + "infoButton.svg",
  giftYellow: imagePath + "giftYellow.svg",
  giftGif: imagePath + "gift.gif",
  envelopeOpen: imagePath + "envelopeOpen.svg",
  EyeOrange: imagePath + "EyeOrange.svg",
  envelopeClosed: imagePath + "envelopeClosed.svg",
  envelopeForeground: imagePath + "envelopeForeground.svg",
  CircleCheck: imagePath + "CircleCheck.svg",
  paper: imagePath + "paper.svg",
  sparkles: imagePath + "sparkles.gif",
  balloonBlue: imagePath + "balloonBlue.svg",
  balloonBlueGif: imagePath + "balloonBlue.gif",
  balloonOrange: imagePath + "balloonOrange.svg",
  balloonOrangeGif: imagePath + "balloonOrange.gif",
  balloonYellow: imagePath + "balloonYellow.svg",
  balloonYellowGif: imagePath + "balloonYellow.gif",
  balloons1Gif: imagePath + "balloons1.gif",
  balloons2Gif: imagePath + "balloons2.gif",
  brokenImage: imagePath + "brokenImage.jpg",
  confettiGif: imagePath + "confetti.gif",
  newConfettiGif: imagePath + "NewConfetti.gif",
  envelopeOpenGif: imagePath + "envelopeOpenGif.gif",
  PersonalizedGiftBackground: imagePath + "FromAllYourFriends.png",
  leftArrow: imageIconPath + "leftArrow.svg",
  leftArrowGrey: imageIconPath + "leftArrowGrey.svg",
  rightArrow: imageIconPath + "rightArrow.svg",
  rightArrow_1: imageIconPath + "rightArrow_1.svg",
  rightArrowWhite: imageIconPath + "rightArrowWhite.svg",
  truck: imageIconPath + "truck.svg",
  dollar: imageIconPath + "dollar.svg",
  "🍱": imageIconPath + "abcd1234.svg",
  downAngularArrow: imageIconPath + "downAngularArrow.svg",
  upAngularArrow: imageIconPath + "upAngularArrow.svg",
  dashboard: imageIconPath + "dashboard.svg",
  dashboardGrey: imageIconPath + "dashboardGrey.svg",
  accounts: imageIconPath + "accounts.svg",
  accountsGrey: imageIconPath + "accountsGrey.svg",
  campaigns: imageIconPath + "campaigns.svg",
  campaignsGrey: imageIconPath + "campaignsGrey.svg",
  recipents: imageIconPath + "recipents.svg",
  recipentsGrey: imageIconPath + "recipentsGrey.svg",
  billing: imageIconPath + "billing.svg",
  settings: imageIconPath + "settings.svg",
  calendar: imageIconPath + "calendar.svg",
  giftBorder: imageIconPath + "giftBorder.svg",
  search: imageIconPath + "search.svg",
  options: imageIconPath + "options.svg",
  CardTypes: imageIconPath + "CardTypes.svg",
  radioInactive: imageIconPath + "radioInactive.svg",
  radioActive: imageIconPath + "radioActive.svg",
  checkBoxSelected: imageIconPath + "checkBoxSelected.svg",
  checkBoxInactive: imageIconPath + "checkBoxInactive.svg",
  checkCircle: imageIconPath + "checkCircle.svg",
  truckGreen: imageIconPath + "truckGreen.svg",
  truckGrey: imageIconPath + "truckGrey.svg",
  homeGrey: imageIconPath + "homeGrey.svg",
  homeGreen: imageIconPath + "homeGreen.svg",
  smilieYellow: imageIconPath + "smilieYellow.svg",
  cross: imageIconPath + "cross.svg",
  eye: imageIconPath + "eye.svg",
  dustbin: imageIconPath + "dustbin.svg",
  questionMark: imageIconPath + "questionMark.svg",
  questionMarkOrange: imageIconPath + "questionMarkOrange.svg",
  download: imageIconPath + "download.svg",
  upload: imageIconPath + "upload.svg",
  edit: imageIconPath + "edit.svg",
  toggleOn: imageIconPath + "toggleOn.svg",
  toggleOff: imageIconPath + "toggleOff.svg",
  mailTemplateIcon: imageIconPath + "mailTemplateIcon.svg",
  sociamMediaIcons: imageIconPath + "sociamMediaIcons.png",
  warningGrey: imageIconPath + "warningGrey.svg",
  giftGreen: imageIconPath + "giftGreen.svg",
  giftGrey: imageIconPath + "giftGrey.svg",
  collectedGrey: imageIconPath + "collectedGrey.svg",
  collectedGreen: imageIconPath + "collectedGreen.svg",
  calender: imageIconPath + "calendar-icon.svg",
  Close: imagePath + "Close.svg",
  ellipsis: imageIconPath + "ellipsis.svg",
  delivery: imageIconPath + "delivery.svg",
  pickup: imageIconPath + "pickup.svg",
  clip: imagePath + "clip.svg",
  dustbinred: imagePath + "dustbin.svg",
  sampleProduct: imagePath + "sampleProduct.png",
  sample1: imagePath + "sample1.png",
  sample2: imagePath + "sample2.png",
  sample3: imagePath + "sample3.png",
  sample4: imagePath + "sample4.png",
  sample5: imagePath + "sample5.png",
  sample6: imagePath + "sample6.png",
  sample7: imagePath + "sample7.png",
  sample8: imagePath + "sample8.png",
  sample9: imagePath + "sample9.png",
  sample10: imagePath + "sample10.png",
  sample11: imagePath + "sample11.png",
  sample12: imagePath + "sample12.png",
  sample13: imagePath + "sample13.png",
  sample13Square: imagePath + "sample13Square.png",
  sampleAirPods: imagePath + "sampleAirPods.png",
  thankyou: imagePath + "thankyou.svg",
  thankyouSm: imagePath + "thankyouSm.svg",
  desktopPreview: imagePath + "desktopPreview.png",
  whatsappPreview: imagePath + "whatsappPreview.png",
  whatsappPreview_: imagePath + "whatsappPreview_.png",
  whatsappPreviewChatBox: imagePath + "whatsappPreviewChatBox.svg",
  flowers: imagePath + "flowers.png",
  payNow: imagePath + "payNow.png",
  thumb: imagePath + "thumb.png",
  prize: imagePath + "prize.png",
  giftRedBlack: imagePath + "giftRedBlack.png",
  travelBag: imagePath + "travelBag.png",
  newBorn: imagePath + "newBorn.png",
  calendarRed: imagePath + "calendarRed.png",
  emailPreview: imagePath + "emailPreview.png",
  emailPreviewNew: imagePath + "emailPreviewNew.png",
  sampleProfile: imagePath + "sampleProfile.png",
  packaging1: imagePath + "packaging1.png",
  packaging2: imagePath + "packaging2.png",
  packaging3: imagePath + "packaging3.png",
  succcessGif: imagePath + "succcessGif.gif",
  messageEmail: imagePath + "messageEmail.png",
  link: imagePath + "link.png",
  charity1: imagePath + "charity1.png",
  charity2: imagePath + "charity2.png",
  charity3: imagePath + "charity3.png",
  charity4: imagePath + "charity4.png",
  charity5: imagePath + "charity5.png",
  charity6: imagePath + "charity6.png",
  trackOrderBottom1: imagePath + "trackOrderBottom1.png",
  trackOrderBottom2: imagePath + "trackOrderBottom2.png",
  necBanner:
    "https://uatsmilieio.blob.core.windows.net/test/dccb464c-b1e8-4732-b7ca-7784671042da.png",
  necBannerMobile:
    "https://uatsmilieio.blob.core.windows.net/test/2b712faa-d98c-4d27-8648-abbef054dc04.png",
  uploadbulkexcel: imagePath + "uploadbulkexcel.png",
  uploadCRM: imagePath + "uploadCRM.png",
  // whatsappPreview: imagePath + "whatsappPreview.png",
  googlesymbol: imagePath + "googlesymbol.png",
  emailimage: imagePath + "emailimage.png",
  password: imagePath + "password.png",
  passwordimage: imagePath + "passwordimage.png",
  or: imagePath + "or.png",
  rightarrow: imagePath + "rightarrow.png",
  leftsarrow: imagePath + "leftsarrow.png",
  GiftsClaimed: imagePath + "GiftsClaimed.svg",
  currency: imagePath + "currency.svg",
  totalsaved: imagePath + "totalsaved.svg",
  donate: imagePath + "donate.svg",
  thankyouNoteCone: imagePath + "thankyouNoteCone.svg",
  upArrowIcon: imageIconPath + "caret-up-solid.svg",
  downArrowIcon: imageIconPath + "caret-down-solid.svg",
  engagement: imageIconPath + "engagement.svg",
  engagementGrey: imageIconPath + "engagementGrey.svg",
  leftChevron: imageIconPath + "leftChevron.svg",
  rightChevron: imageIconPath + "rightChevron.svg",
  doubleChevronRight: imageIconPath + "doubleChevronRight.svg",
  doubleChevronLeft: imageIconPath + "doubleChevronLeft.svg",
  refresh: imageIconPath + "refresh.svg",
  voucher: imageIconPath + "voucher.svg",
  defaultVoucher: imagePath + "defaultVoucher.png",
  plus: imageIconPath + "plus.svg",
  plusOrange: imageIconPath + "plusOrange.svg",
};
export default images;
