import React from "react";
import { useNavigate } from "react-router-dom";

import images from "../../constants/images";
import CustomNavigate from "../../utils/navigate";

type propType = {
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
const CancelModalCampaign = (props: propType) => {
  const { setModalVisible } = props;

  const navigate = new CustomNavigate(useNavigate());

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[45%]">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-6">
              {/* Body */}
              <div className="flex justify-between">
                <div className="font-leagueSpartan-400 text-lg">
                  Hey, you have some unsaved changes
                </div>
                <button
                  onClick={() => setModalVisible(false)}
                  className="self-start h-7"
                >
                  <img src={images.cross} className="w-5" alt="" />
                </button>
              </div>
              <div className="border-t border-b py-4 font-leagueSpartan-200 ">
                Are you sure you want to leave without saving this campaign?
                Your progress will be lost.
              </div>
              <div className="flex justify-end pt-4 ">
                <button
                  onClick={() => setModalVisible(false)}
                  className="self-start mr-2 px-2 p-2  text-sm border border-orange text-orange rounded-lg"
                >
                  No, keep editing
                </button>
                <button
                  onClick={() => navigate.to("/dashboard")}
                  className="self-start px-4 p-2 text-sm bg-orange text-white rounded-lg"
                >
                  Leave without saving
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelModalCampaign;
