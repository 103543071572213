import React from "react";
import images from "../../constants/images";

const ThankyouNotesBottomNec = () => {
  return (
    <div
      className={`absolute bottom-0 mt-32 h-[300px] max-md:h-[470px] w-screen`}
    >
      <img
        src={images.necBanner}
        alt=""
        className="absolute left-0 top-0 h-full w-full max-md:hidden"
      />
      <img
        src={images.necBannerMobile}
        alt=""
        className="absolute left-0 top-0 h-full w-full md:hidden"
      />

      <a
        href="https://necms.sharepoint.com/sites/SGDashboard/lifeatnec/SitePages/2023-Business-Excellence-Awards-Gala-Dinner.aspx"
        className="absolute max-md:left-[50%] left-[50%] translate-x-[-50%]  max-md:bottom-[30%] bottom-[20%]"
      >
        <button className="p-2 px-6 bg-orange rounded-lg font-leagueSpartan-500 text-[16px] text-white mt-6">
          Go to event page
        </button>
      </a>
    </div>
  );
};

export default ThankyouNotesBottomNec;
