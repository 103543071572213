import { useCallback, useEffect, useState } from "react";
import Header from "../../components/giftSelection/Header";
import images from "../../constants/images";
import { useAppSelector } from "../../redux/hooks";
import moment from "moment";
import ThankyouNotesBottom from "../../components/common/ThankyouNotesBottom";
import ThankyouNotesBottomNec from "../../components/common/ThankyouNotesBottom";

const CampaignEnded = () => {
  const corpState = useAppSelector((state) => state.corpSliceState);
  const icon = corpState.data?.logo || undefined;

  return (
    <div className="min-h-screen bg-neutrals-200 relative">
      <Header logo={icon || ""} />

      <div className="flex flex-col gap-4 max-md:mt-[10vh] md:mt-[20vh] px-4 text-center">
        <div className="font-leagueSpartan-600 text-[32px] text-neutrals-900">
          Sorry, Gift Selection has ended.
        </div>
        <div className="font-leagueSpartan-300 text-[20px] text-neutrals-900">
          We hope you’ve enjoyed the Gala Dinner.
        </div>
      </div>

      <div className="max-md:h-[500px] md:h-[350px]"></div>

      {/* Bottom */}
      {/* <ThankyouNotesBottom /> */}
      <ThankyouNotesBottomNec />
    </div>
  );
};

export default CampaignEnded;
